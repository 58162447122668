// @ts-nocheck
import { useState, useContext} from "react";
import "./SignUp.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { InputGroup, Modal, ModalFooter, ModalHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
import Countries from "./../../../Files/Countries.json";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import camera from './../../../assest/images/camera.svg';
import Ellipse from './../../../assest/images/Ellipse.svg'
// export const number=createContext();

const SignUp = () => {
    const { ChangeAuthInfo } = useContext(AuthContext);
    const [username, setUsername] = useState("");
    const [fullName , setFullName] = useState("")
    const [phone , setPhone] = useState("")
    const [whats , setWhats] = useState("")
    const [password , setPassword] = useState("")
    const [rePassword , setRePassword] = useState("")
    const [accept , setAccept] = useState(true)
    const [country , setCountry] = useState("")
    const [showSignUpAlert, setShowSignUpAlert] = useState(false);
    const [status, setStatus] = useState("");
    const [action, setAction] = useState("");
    const [message, setMessage] = useState("");
    const [image, setImage] = useState(null);
    const [accept_terms, setAccept_terms] = useState(true);
    const [isLoading, setisLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        username: "",
        fullname: "",
        phone_number: "",
        whatsapp: "",
        country: "",
        password: "",
        image:image,
        password_confirmation: "",
        accept_terms: true,
    });
    // a function to get the file of the choosen image and send it to userInfo
    // Function to handle clicking the camera icon for image upload
  const handleCameraClick = () => {
    const imageInput = document.getElementById('imageInput');
    imageInput.click();
  };

  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };
    // a function to get the data of the checkbox input and send it to userInfo
    const checkInput = (event) => {
        setAccept_terms(!accept_terms);
        setUserInfo({ ...userInfo, accept_terms });
    };
    // a function to get the data of the  inputs and send it to userInfo
    const handleForm = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
        localStorage.setItem("signUpNumber", userInfo.phone_number);
    };
    // a function to post the data to the backend
    const handleSubmit = (e) => {
        e.preventDefault();
        setisLoading(true);
        setShowSignUpAlert(true);
        const formData = new FormData();
        formData.append('fullname', fullName);
        formData.append('username', username);
        formData.append('phone_number',arabicCountries[country]+phone); 
        formData.append('whatsapp', arabicCountries[country]+whats); 
        formData.append('accept_terms', accept);
        formData.append('password', password);
        formData.append('password_confirmation', rePassword);
        formData.append('country', country);
        if (image) {
          formData.append('image', image);
        }
        axios
            .post(
                " https://api.whitesooq.com/api/v1/register ",
                formData,
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                    },
                }
            )
            .then((response) => {
                if (response?.data?.token) {
                    ChangeAuthInfo(response.data);
                    localStorage.setItem("token", response.data.token);
                }
                ChangeAuthInfo(response.data);
                if(response.status===200)
                setMessage("تم تسجيل الدخول بنجاح");
                else
                setMessage(response.data.message);
                setisLoading(false)
                setAction("signUp");
                localStorage.setItem("action", action);
                setStatus("1");
            })
            .catch((Error) => {
                setMessage(Error?.response?.data?.message);
                setisLoading(false)
            });
    };

    const arabicCountries = {
        "الأردن": "+962",
        "الجزائر": "+213",
        "البحرين": "+973",
        "جيبوتي": "+253",
        "مصر": "+20",
        "العراق": "+964",
        "الكويت": "+965",
        "لبنان": "+961",
        "ليبيا": "+218",
        "موريتانيا": "+222",
        "المغرب": "+212",
        "عمان": "+968",
        "فلسطين": "+970",
        "قطر": "+974",
        "المملكة العربية السعودية": "+966",
        "الصومال": "+252",
        "السودان": "+249",
        "سوريا": "+963",
        "تونس": "+216",
        "الإمارات العربية المتحدة": "+971",
        "اليمن": "+967",
    };
    const handleCountry=(e)=>{
        setCountry(e.target.value)
    }
    const closeIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
        >
            <g filter="url(#filter0_d_171_1455)">
                <circle cx="35" cy="31" r="20" fill="#F2F2F2" />
            </g>
            <path
                d="M41 23.7109L29 35.2119"
                stroke="#E63946"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29 23.7109L41 35.2119"
                stroke="#E63946"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_171_1455"
                    x="0"
                    y="0"
                    width="70"
                    height="70"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="7.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.611613 0 0 0 0 0.679146 0 0 0 0 0.721667 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_171_1455"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_171_1455"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
    return (
        <div className="signup" id="signup">
            {/* alert */}
            <section className="SignUpAlert">
                <Modal show={showSignUpAlert} variant="none" style={{direction:'rtl'}}  backdrop='static' centered>
                {isLoading ? <><ModalHeader> تتم العملية الان الرجاء الانتظار </ModalHeader>
                <div class="typewriter">
                <div class="slide"><i></i></div>
                <div class="paper"></div>
                <div class="keyboard"></div>
                </div>
                </>
                : 
                    <>
                    <ModalHeader>{message}</ModalHeader>
                    
                    <ModalFooter className="d-flex justify-content-center">
                        <Button
                            onClick={() => setShowSignUpAlert(false)}
                            style={{ textDecoration:'none' ,backgroundColor:'#1D3557', marginLeft:'30px',color:'#FFFFFF'}}
                        >
                            {status === "1" ? (
                                <Link
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                    }}
                                    to="/ "
                                >
                                    موافق
                                </Link>
                            ) : (
                                "موافق"
                            )}
                        </Button>
                    </ModalFooter>
                    </>}
                </Modal>
                {!showSignUpAlert}
            </section>

            {/* sign up */}
            <Form>
                        <Link  className="navv" to='/'>{closeIcon}</Link>
                        <div className="form">
                        <Form.Text style={{ color: "black" }} className="miian-tit">
                            {" "}
                            إنشاء حساب جديد{" "}
                        </Form.Text>
                   

               

                    <Form.Group
                        as={Col}
                        controlId="formGridPassword"
                        id="placeholder"
                    >
                    <div className="d-flex part-form" dir="rtl">
                    <div>
                        <Form.Label style={{marginTop:'20px'}}>
                            {" "}
                            <i>*</i> ادخل اسم المستخدم{" "}
                        </Form.Label>
                        
                        <Form.Control
                            className=" fs-6"
                            type="text"
                            placeholder="Majd"
                            name="username"
                            value = {username}
                            onChange={(e)=>setUsername(e.target.value)}
                        />
                        </div>
                    
                    <div className='inputiimg'>
                <input
          type="file"
          id="imageInput"
          style={{ display: 'none' }}
          onChange={handleImageUpload} 
    />
        <div onClick={handleCameraClick} className="circle-imagee">
        <img src={camera}  className="iimg2"/>
        <img src={image?URL.createObjectURL(image):Ellipse} className="iimg1"/>
      
        </div>
        </div>
        </div>
        </Form.Group>
        <br/>

                <Form.Group className="mb-3" controlId="formGridAddress1" id="inputt">
                    <Form.Label>
                        {" "}
                        <i>*</i> ادخل الاسم بالكامل{" "}
                    </Form.Label>
                    <Form.Control
                    className=" fs-6"
                        type="text"
                        placeholder="مثال : رنيم أحمد عمر"
                        name="fullname"
                        value = {fullName}
                        onChange={(e)=>setFullName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formGridState" id="inputt">
                    <Form.Label>
                        {" "}
                        <i>*</i> البلد{" "}
                    </Form.Label>
                    <Form.Select
                        className="selectInput fs-6"
                        style={{
                            height: "57px",
                            marginBottom: "5%",
                        }}
                        value = {country}
                        name="country"
                        onChange={handleCountry}
                    >
                        {Object.entries(Countries).map((Country) => (
                            <option key={Country[0]}> {Country[1]} </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {/*      */}
                

                <Row className="mb-3 line-numberr">
                    <Col className="numberCol">
                        <header className="mb-2 code-symboll">
                            الرجاء ادخال رقم الهاتف الخاص بالواتساب{" "}
                        </header>

                        <InputGroup className="mb-3" as={Col} id="inputt">
                        <InputGroup.Text id="basic-addon1">{arabicCountries[country]}</InputGroup.Text>
                            <Form.Control
                                className=" fs-6"
                                placeholder="مثال: +962 99955566" aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={(e)=>setWhats(e.target.value)}
                                value={whats}
                                name="whatsapp"
                            />
                        </InputGroup>
                    </Col>
                    <Col className="numberCol">
                        <header className="mb-2 code-symbol">
                        <i style={{color:'red'}}>*</i> الرجاء ادخال رقم الهاتف مع رمز البلد{" "}
                        </header>

                        <InputGroup
                            className="mb-3"
                            as={Col}
                            id="responsiveInput"
                            id="inputt"
                        >
                        <InputGroup.Text id="basic-addon1">{arabicCountries[country]}</InputGroup.Text>
                            <Form.Control
                        className=" fs-6"
                                placeholder="مثال: +962 99955566"                               
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={(e)=> setPhone(e.target.value)}
                                value={phone}
                                name="phone_number"
                            />
                        </InputGroup>
                    </Col>
                </Row>

                {/*              */}
                <Form.Group className="mb-3" controlId="formGridAddress2" id="inputt">
                    <Form.Label>
                        {" "}
                        <i>*</i> الرجاء ادخال كلمة المرور{" "}
                    </Form.Label>
                    <Form.Control
                        placeholder=" ادخل كلمة المرور هنا ..."
                        type="password"
                        className=" fs-6"

                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        name="password"
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGridAddress2" id="inputt">
                    <Form.Label>
                        {" "}
                        <i>*</i> تأكيد كلمة المرور{" "}
                    </Form.Label>
                    <Form.Control
                        className=" fs-6"

                        placeholder=" ادخل كلمة المرور هنا ..."
                        type="password"
                        name="password_confirmation"
                        onChange={(e)=>setRePassword(e.target.value)}
                        value={rePassword}
                    />
                </Form.Group>

                <Form.Group
                    className="mb-3 check"
                    id="formGridCheckbox"
                    name="accept_terms"
                    onChange={checkInput}
                >
                    <span className="check">
                        <input type="checkbox" />
                        <Form.Label style={{ marginRight: "20px" }}>
                            {" "}
                            أوافق على{" "}
                            <Link
                                className="use-polic"
                                to="privacyAndPolicy"
                                style={{
                                    color: "#457B9D",
                                    textDecoration: "none",
                                }}
                            >
                                {" "}
                                سياسات استخدام{" "}
                            </Link>{" "}
                         السوق الابيض .{" "}
                        </Form.Label>
                    </span>
                </Form.Group>

                

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    <Link style={{ color: "white", textDecoration: "none" }} className="butt-nextt">
                        التالي
                    </Link>
                </Button>
                </div>
            </Form>
        </div>
    );
};

export default SignUp;
