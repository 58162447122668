import { Avatar, Box, Container, IconButton, Typography } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import classes from "./ShowUserOnline.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Block,
  Check,
  Close,
  ExpandMore,
  MoreVert,
  WarningAmber,
} from "@mui/icons-material";
import { East } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";

import { AuthContext } from "store/UseAuthContext";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Snack from "components/Utilities/Snack";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowUserOnline = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const [userDeleted, setUserDeleted] = useState(false);
  const [userBlocked, setUserBlocked] = useState(false);
  const [error, setError] = useState("");
  const { authInfo } = useContext(AuthContext);
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    fetch(`https://api.whitesooq.com/api/v1/users/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        if (responseData?.data) {
          setData(responseData.data);
        }
      });
  }, []);

  const handleDelete = () => {
    fetch(`https://api.whitesooq.com/api/v1/users/force-delete/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        } else response.json();
      })
      .then((data) => {
        if (data.status) {
          setUserDeleted(true);
          setOpen(false);
          navigate("/activeUsers-page");
        }
      })
      .catch((error) => {});
  };
  const handleBlock = () => {
    fetch(`https://api.whitesooq.com/api/v1/users/block/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        } else response.json();
      })
      .then((data) => {
        if (data.status) {
          setUserBlocked(true);
          setOpen(false);
          navigate("/activeUsers-page");
        }
      })
      .catch((error) => {});
  };
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Snack alert={alert} />

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4
          style={{
            color: "#1D3557",
            fontSize: "18px",
            marginRight: "5px",
          }}
        >
          المستخدمين /
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/activeUsers-page"
          >
            المستخدمون النشطون
          </Link>
          / تفاصيل المستخدم
        </h4>
      </Form.Label>

      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginRight: "-40px",
            marginTop: "-9px",
          }}
          className={classes.ContainerBoxAdmin}
          sx={{
            padding: 2,
            width: { xs: 330, sm: 650 },
            height: { xs: 550, sm: 700 },
          }}
        >
          <Typography
            className={classes.detailsParagraph}
            sx={{ color: "#fff", m: 1 }}
          >
            تفاصيل المستخدم
          </Typography>
          <Typography className={classes.line}></Typography>
          <Box
            style={{ width: "101%", marginRight: "-9px" }}
            className={classes.ContainerBoxAdminInside}
            sx={{
              padding: 2,
              width: { xs: 330, sm: 560 },
              height: { xs: 550, sm: 600 },
            }}
          >
          <IconButton sx={{ m: 1 }} onClick={() => navigate("/activeUsers-page")}>
              <East />
            </IconButton>
            <Box className={classes.menuOption}>
              <Typography endIcon={<MoreVert />}>
                <FormControl sx={{ m: 1 }}>
                  <Select
                    IconComponent={ExpandMore}
                    // IconComponent={() => ExpandMore, MoreVert)}
                    value={option}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                  >
                    <MenuItem
                      value={"حذف"}
                      onClick={handleClickOpen}
                      sx={{ pl: 6 }}
                    >
                      حذف
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        navigate(`/edite-user-page-page/${data?.id}`)
                      }
                      sx={{ pl: 6 }}
                    >
                      تعديل
                    </MenuItem>
                    <MenuItem
                      onClick={handleClickOpen}
                      value={"حظر"}
                      sx={{ pl: 6 }}
                    >
                      حظر
                    </MenuItem>
                  </Select>
                </FormControl>
              </Typography>
              {(!userDeleted || !userBlocked) && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  {option === "حذف" ? (
                    <WarningAmber
                      sx={{
                        color: "#f9a703",
                        m: "20px auto 0",
                        width: "80px",
                        height: "80px",
                      }}
                    />
                  ) : (
                    <Block
                      sx={{
                        color: "red",
                        m: "20px auto 0",
                        width: "80px",
                        height: "80px",
                      }}
                    />
                  )}
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 40px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {`هل أنت متأكد من ${option} هذا المستخدم`}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      {option === "حذف"
                        ? `لا يمكن استعادة المستخدم عند ال${option}`
                        : ""}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnCancelDialog}
                      onClick={handleClose}
                    >
                      إلغاء
                    </button>
                    <button
                      className={classes.btnOkDialog}
                      onClick={option === "حذف" ? handleDelete : handleBlock}
                    >
                      متأكد
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {error && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {error}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Close sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {userDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {`تم ${option} المستخدم بنجاح`}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Check sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>
            <Typography
              variant="p"
              sx={{
                color: "#1d3557",
                fontWeight: 800,
                fontSize: "27px",
              }}
            >
              عرض المستخدم
            </Typography>
            <Box sx={{ my: 1 }} style={{ direction: "ltr" }}>
              <table className={classes.tableDetails}>
                <tbody className="">
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <div className={classes.flexTable}>
                        <IconButton
                          onClick={() => navigate(`/profile/${data?.id}`)}
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        {/* <Typography>hfgh</Typography> */}
                        <Typography sx={{ fontWeight: 600 }}>
                          {data?.username}
                        </Typography>
                        <Avatar
                          sx={{
                            ml: 1,
                            width: "40px",
                            height: "40px",
                            p: 0,
                          }}
                          src={data?.full_path_image}
                        />
                      </div>
                    </div>
                    <div className={classes.sentence2}>اسم المستخدم</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{data?.fullname}</div>
                    <div className={classes.sentence2}>الاسم الكامل</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>
                      {data?.phone_number}
                    </div>
                    <div className={classes.sentence2}>رقم الموبايل</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{data?.whatsapp}</div>
                    <div className={classes.sentence2}>رقم الواتساب</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{data?.country}</div>
                    <div className={classes.sentence2}>الدولة</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div
                      className={classes.sentence1}
                      style={{
                        color: "rgb(3, 155, 155)",
                      }}
                    >
                      {/* {props.status} */}
                      نشط
                    </div>
                    <div className={classes.sentence2}>حالة المستخدم</div>
                  </div>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
export default ShowUserOnline;
