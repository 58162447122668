import { Button } from "@mui/material";
import classes from "./ButtonAD.module.css";

const ButtonAD = (props) => {
  return (
    <button
      className={classes.btnAD}
      /* sx={{
        mt: 1,
        ml: 1,
        px: 8,
        bgcolor: "#457b9d",
        color: "#fff",
        fontSize: "17px",
        fontWeight: "700",
        ":hover": { bgcolor: "#1d3557" },
      }} */
      // @ts-ignore
      // variant="outlind"
    >
      {props.title}
    </button>
  );
};
export default ButtonAD;
