// @ts-nocheck
import React from "react";
import "./Carddashbord.css";
import { Container } from "react-bootstrap";
import user from "./categ/user.svg";
import clock from "./categ/clock.svg";
import Slider from "react-slick";
import { Link,useNavigate } from "react-router-dom";


const CardsDashbord = (props) => {
  const navigate=useNavigate();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  const filledStarIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
        fill="#FFEE32"
      />
    </svg>
  );

  const emptyStarIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="19"
      viewBox="0 0 11 7"
      fill="none"
    >
      <path
        d="M5.50158 1.45581L6.91741 3.71661L10.0836 4.08138L7.79257 5.84018L8.33325 8.3249L5.50158 7.15116L2.66991 8.3249L3.21058 5.84018L0.919586 4.08138L4.08574 3.71661L5.50158 1.45581Z"
        stroke="#FFEE32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const countRatingZero = (
    <span className="starRating">
      <ul>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
      </ul>
    </span>
  );
  const countRatingOne = (
    <span className="starRating">
      <ul>
        <li>{filledStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
      </ul>
    </span>
  );

  const countRatingTow = (
    <span className="starRating">
      <ul>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
      </ul>
    </span>
  );

  const countRatingThree = (
    <span className="starRating">
      <ul>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{emptyStarIcon}</li>
        <li>{emptyStarIcon}</li>
      </ul>
    </span>
  );

  const countRatingFourth = (
    <span className="starRating">
      <ul>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{emptyStarIcon}</li>
      </ul>
    </span>
  );

  const countRatingFifth = (
    <span className="starRating">
      <ul>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
      </ul>
    </span>
  );

  return (
    <div>
      <Container>
        <div className="cards">
          <Slider {...settings}>
            {props?.data?.map((item) => {
              return (
                <div className="carditem" key={item.id}
                onClick={()=>{
                  {item.type === "buy" ? 
                  navigate(`/active-ads/${item.id}`)
                  :
                  navigate(`/offer-detail-dash-page/${item.id}`)
                }}}
                >
                  <img src={item?.all_images[0]} />
                  {item.pinable===1?
                     <div
                     className="adsmm"
                     style={{
                       position: "absolute",
                       top: "3%",
                       backgroundColor: "orange",
                       width: "100px",
                       height: "40px",
                       borderRadius: "30px 10px 20px 30px",
                       marginLeft: "115px",
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center", 
                       paddingTop:'12px'
                     }}
                   >
                     {" "}
                    
                     <p style={{ color: "#fff" }}>  إعلان مميز</p>
                   </div>
                  :
                  <></>
                  }

                 
                  <div className="nameandprice d-flex">
                    <h4>{item.price}</h4>
                    {item.type === "buy" ? (
                      <Link style={{ textDecoration:'none'}} to={`/active-ads/${item.id}`}>
                        {" "}
                        <h4 style={{ direction:'rtl', width:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', textDecoration: 'none' }}>{item.title}</h4>{" "}
                      </Link>
                    ) : (
                      <Link style={{ textDecoration:'none'}} to={`/offer-detail-dash-page/${item.id}`}>
                        {" "}
                        <h4 style={{ direction:'rtl', width:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }}>{item.title}</h4>{" "}
                      </Link>
                    )}
                  </div>
                  <h5>{item.description}</h5>
                  <div className="details d-flex">
                    <span>
                      {item.from} <img src={clock} />
                    </span>
                    <span>
                      {item.user?.username} <img src={user} />
                    </span>
                  </div>
                  <div className=" d-flex star">
                    <h6>{item.rating_count} </h6>
                    {item.rating == 0
                      ? countRatingZero
                      : item.rating == 1
                      ? countRatingOne
                      : item.rating == 2
                      ? countRatingTow
                      : item.rating == 3
                      ? countRatingThree
                      : item.rating == 4
                      ? countRatingFourth
                      : item.rating == 5
                      ? countRatingFifth
                      : "invalid Rating"}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </div>
  );
};
export default CardsDashbord;
