// @ts-nocheck
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Packages from "components/User/Packages/Packages";
import PurchaseADPage from "pages/PurchaseADPage/PurchaseADPage";
import EditPurchaseADUser from "components/User/Ads/EditPurchaseADUser/EditPurchaseADUser";
import SaleAdPage from "./pages/SaleAdPage/SaleAdPage";
import TypeAdPage from "./pages/TypeAdPage/TypeAdPage";
import SettingAccount from "./components/User/SettingAccount/SettingAccount/SettingAccount";
import Landingpagevisitor from "./pages/Landingpagevisitor/Landingpagevisitor";
import { Productdetailsbuy } from "./pages/Productdetailsbuy/Productdetailsbuy";
import { Route, Routes } from "react-router-dom";
import { Notifications } from "pages/Notifications/Notifications";
import EditPurchaseAD from "components/Admin/EditPurchaseAD/EditPurchaseAD";
import EditSaleAD from "components/Admin/EditSaleAD/EditSaleAD";
import PrivacyAndPolicy from "components/User/privacyAndPolicy/PrivacyAndPolicy";
import UserSubscription from "components/User/UserSubscription/UserSubscription";
import Login from "components/User/login/Login";
import SignUp from "components/User/signup/SignUp";
import VaricationCode from "components/User/login/VaricationCode";
import ChangePassword from "components/User/SettingAccount/SettingAccount/ChangePassword";
import { Statisticsaccount } from "components/User/Statistics account/Statisticsaccount";
import Advert_Static from "components/User/AdvertStaticmajd/AdvertStatic/Advert_Static";
import OfferDetail from "components/User/offerDetail/OfferDetail";
import PackageDetails from "components/User/PackageDetails/PackageDetails";
import ListItem from "components/User/Ads/ListItem/ListItem/ListItem";
import RequireAuth from "./pages/Auth/RequireAuth";
import PersistLogin from "pages/Auth/PersistLogin";
import ContactUs from "components/User/SettingAccount/ContactUs/ContactUs";
import ViewProfile from "components/User/SettingAccount/ViewProfile/ViewProfile";
import CategoryFilter from "components/User/Ads/CategoryFilter/CategoryFilter";
import EditPurchaseADPage from "components/Admin/EditPurchaseAD/EditPurchaseADPage";
import EditSaleADPage from "components/Admin/EditSaleAD/EditSaleADPage";
import PaymentItemPage from "components/Admin/PaymentItem/PaymentItemPage";
import ActivePackagesPage from "components/Admin/ActivePackages/ActivePackagesPage";
import AddPackagePage from "components/Admin/AddPackage/AddPackagePage";
import EditPackagePage from "components/Admin/EditPackage/EditPackagePage";
import PackagesDeletedPage from "components/Admin/PackagesDeleted/PackagesDeletedPage";
import ReportOnUserPage from "components/Admin/Reports/ReportOnUser/ReportOnUserPage";
import ObjectionOnUserPage from "components/Admin/Objections/ObjectionOnUser/ObjectionOnUserPage";
import MessagePage from "components/Admin/Message/MessagePage";
import ShowUserOnlinePage from "components/Admin/ShowUserOnline/ShowUserOnlinePage";
import CommentPage from "components/Admin/Comment/CommentPage";
import ShowUserBlockedPage from "components/Admin/ShowUserBlocked/ShowUserBlockedPage";
import ShowUserAcceptToDeletePage from "components/Admin/ShowUserAcceptToDelete/ShowUserAcceptToDeletePage";
import EditRoleOfOwnerPage from "components/Admin/EditRoleOfOwner/EditRoleOfOwnerPage";
import AdduserpagePage from "components/Admin/Adduserpage/AdduserpagePage";
import EditeuserpagePage from "components/Admin/Adduserpage/EdituserpagePage";
import CategoryServicesPage from "components/Admin/Topcategory/CategoryServicesPage";
import CategoryTetsPage from "components/Admin/Topcategory/CategoryTestPage";
import ViewrolesPage from "components/Admin/Viewroles/ViewrolesPage";
import AddCategoryPage from "components/Admin/AddCategory/AddCategoryPage";
import EditServicesPage from "components/Admin/EditServices/EditServicesPage";
import InfoCategoryPage from "components/Admin/InforCategory/InforCategoryPage";
import ViewOfferPage from "components/Admin/View/ViewOfferPage";
import ViewRatingPage from "components/Admin/View/ViewRatingPage";
import AddPaymentPage from "components/Admin/AddPayment/AddPaymentPage";
import TablePage from "components/Admin/Tables/TablePage";
import AddRolePage from "components/Admin/AddRole/AddRolePage";
import ViewAds from "components/Admin/Viewads/Viewadsactivepag";
import EditSaleADUser from "components/User/Ads/EditSaleADUser/EditSaleADUser";
// Admin
import DetailsPackagePage from "components/Admin/DetailsPackage/DetailsPackagePage";
import ShowManagementPage from "components/Admin/ShowManagement/ShowManagementPage";
import ActiveAdsPage from "components/Admin/ActiveAds/Activeadspage";
import Offerpage from "components/Admin/offerDetailDash/Offerpage";
import ShowRolesPage from "components/Admin/ShowRoles/ShowRolesPage";
import NotificationsViewDetails from "pages/Notifications/NotificationsViewDetails";
import StatisticsPage from "components/Admin/statistics/StatsticsPage";
import RequireBack from "pages/Auth/RequireBack";

function App() {
    return (
        <div>
            <main>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/logIn/forgetPassword"
                        element={<Login type="forgetPassword" />}
                    />
                    <Route
                        path="/logIn/forgetPassword/varicationCode"
                        element={<VaricationCode />}
                    />
                    <Route
                        path="/logIn/forgetPassword/resetpassword"
                        element={<Login type="resetpassword" />}
                    />
                    {/* sign up */}
                    <Route path="/signUp" element={<SignUp />} />
                    <Route
                        path="/signUp/varicationCode"
                        element={<VaricationCode />}
                    />
                    <Route
                        path="/signUp/privacyAndPolicy"
                        element={<PrivacyAndPolicy />}
                    />
                    <Route path="/AdDetail/:id" element={<OfferDetail />} />
                    <Route path="/" element={<Landingpagevisitor />} />
                    <Route
                        path="/detailsbuy/:id"
                        element={<Productdetailsbuy />}
                    />
                    <Route path="/packages" element={<Packages />} />
                    <Route path="/packages/:id" element={<PackageDetails />} />
                    <Route path="/category/:id" element={<CategoryFilter />} />
                    <Route
                        path="/Favourite"
                        element={<Landingpagevisitor type="favorite" />}
                    />
                    {/*                  */}
                    <Route element={<PersistLogin />}>
                        <Route element={<RequireAuth allowedRole={["0"]} />}>
                            <Route
                                path="/statics"
                                element={<Statisticsaccount />}
                            />
                            <Route
                                path="/user-subscription"
                                element={<UserSubscription />}
                            />
                            <Route
                                path="user-subscription"
                                element={<UserSubscription />}
                            />
                            
                            <Route
                                path="/contact/:type/:reportable_type?/:reportable_id?"
                                element={<ContactUs />}
                            />
                        </Route>
                        <Route
                            element={<RequireAuth allowedRole={["0", "1"]} />}
                        >
                            <Route
                                path="/change"
                                element={<ChangePassword />}
                            />
                            <Route
                                path="/setting-account"
                                element={<SettingAccount />}
                            />
                            <Route path="/salee" element={<SaleAdPage />} />
                            <Route
                                path="/notifications"
                                element={<Notifications />}
                            />
                            <Route
                                path="/notifications-view-details/:id"
                                element={<NotificationsViewDetails />}
                            />
                            <Route path="/new-ad" element={<TypeAdPage />} />
                            <Route
                                path="/add-purchase-ad"
                                element={<PurchaseADPage />}
                            />
                            <Route
                                path="/add-sale-ad"
                                element={<SaleAdPage />}
                            />
                            <Route path="/list" element={<ListItem />} />
                            <Route
                                path="/advert"
                                element={<Advert_Static />}
                            />
                            <Route
                                path="/editpurchase"
                                element={<EditPurchaseAD />}
                            />
                            <Route path="/editsale" element={<EditSaleAD />} />
                            <Route
                                path="/editpurchase-user/:id"
                                element={<EditPurchaseADUser />}
                            />
                            <Route
                                path="/editsale-user/:id"
                                element={<EditSaleADUser />}
                            />
                            {/*  */}
                            <Route
                                path="/detailsbuy/:id"
                                element={<Productdetailsbuy />}
                            />
                            <Route
                                path="/profile/:id"
                                element={<ViewProfile />}
                            />
                        </Route>
                        {/* ads */}
                        <Route element={<RequireAuth allowedRole={["1"]} />}>
                            <Route
                                path="/activepackages-page"
                                element={<ActivePackagesPage />}
                            />
                            <Route
                                path="/activepackages-page/:id"
                                element={<DetailsPackagePage />}
                            />
                            <Route
                                path="/addpackage-page"
                                element={<AddPackagePage />}
                            />
                            <Route
                                path="/editpackage-page/:id"
                                element={<EditPackagePage />}
                            />
                            <Route
                                path="/ViewWaitingAds-page"
                                element={<ViewAds  type="waited"/>}
                            />
                            <Route
                                path="/ViewActiveAds-page"
                                element={<ViewAds type="active" />}
                            />
                            <Route
                                path="/warnings-page/:id"
                                element={<ReportOnUserPage />}
                            />
                            <Route
                                path="/showObjection-page/:id"
                                element={<ObjectionOnUserPage />}
                            />
                            <Route
                                path="/messages-page/:id"
                                element={<MessagePage />}
                            />
                            <Route
                                path="/activeUsers-page/:id"
                                element={<ShowUserOnlinePage />}
                            />
                            <Route
                                path="/showComments-page/:id"
                                element={<CommentPage />}
                            />
                            <Route
                                path="/bannedUsers-page/:id"
                                element={<ShowUserBlockedPage />}
                            />
                            <Route
                                path="/askToDelete-page/:id"
                                element={<ShowUserAcceptToDeletePage />}
                            />
                            <Route
                                path="/management-page/:id"
                                element={<ShowManagementPage />}
                            />
                            <Route
                                path="/edit-role-of-owner-page/:id"
                                element={<EditRoleOfOwnerPage />}
                            />
                            <Route
                                path="/paymentitem-page/:id"
                                element={<PaymentItemPage />}
                            />
                            <Route
                                path="/add-user-page-page"
                                element={<AdduserpagePage />}
                            />
                            <Route
                                path="/edite-user-page-page/:id"
                                element={<EditeuserpagePage />}
                            />
                            <Route
                                path="/category-services-page/:id/:type"
                                element={<CategoryServicesPage />}
                            />
                            <Route
                                path="/category-tets-page"
                                element={<CategoryTetsPage />}
                            />
                            {/*  */}
                            <Route
                                path="/add-category-page"
                                element={<AddCategoryPage />}
                            />
                            <Route
                                path="/add-payment-page"
                                element={<AddPaymentPage />}
                            />
                            <Route
                                path="/rating-page/:id"
                                element={<ViewRatingPage />}
                            />
                            <Route
                                path="/showOffers-page/:id"
                                element={<ViewOfferPage />}
                            />
                            <Route
                                path="/infor-category-page/:id/:type"
                                element={<InfoCategoryPage />}
                            />
                            <Route
                                path="/edit-serices-page/:id/:type?"
                                element={<EditServicesPage />}
                            />
                            <Route
                                path="/packagesdeleted-page"
                                element={<PackagesDeletedPage />}
                            />
                            <Route
                                path="/view-roles-page"
                                element={<ViewrolesPage />}
                            />
                            <Route
                                path="/view-roles-page/:id"
                                element={<ShowRolesPage />}
                            />
                            <Route
                                path="/offer-detail-dash-page/:id"
                                element={<Offerpage />}
                            />
                            {/*Show same page */}
                            <Route
                                path="/DeletedData-page"
                                element={<TablePage type="deleted" />}
                            />
                            <Route
                                path="/paymentData-page"
                                element={<TablePage type="payment" />}
                            />
                            <Route
                                path="/nonDeletedData-page"
                                element={<TablePage />}
                            />
                            <Route
                                path="/rating-page"
                                element={<TablePage type="rating" />}
                            />
                            <Route
                                path="/showOffers-page"
                                element={<TablePage type="offers" />}
                            />
                            <Route
                                path="/warnings-page"
                                element={<TablePage type="warnings" />}
                            />
                            <Route
                                path="/messages-page"
                                element={<TablePage type="messages" />}
                            />
                            <Route
                                path="/showObjection-page"
                                element={<TablePage type="objection" />}
                            />
                            <Route
                                path="/showComments-page"
                                element={<TablePage type="comments" />}
                            />
                            <Route
                                path="/activeUsers-page"
                                element={<TablePage type="activeUsers" />}
                            />
                            <Route
                                path="/bannedUsers-page"
                                element={<TablePage type="bannedUsers" />}
                            />
                            <Route
                                path="/askToDelete-page"
                                element={<TablePage type="askToDelete" />}
                            />
                            <Route
                                path="/management-page"
                                element={<TablePage type="management" />}
                            />
                            {/* show same page */}
                            <Route
                                path="/AddRole-page"
                                element={<AddRolePage />}
                            />
                            <Route
                                path="/EditRole-page/:userId/:roleId"
                                element={<AddRolePage type="edit" />}
                            />
                            <Route
                                path="/active-ads/:id"
                                element={<ActiveAdsPage />}
                            />
                            <Route
                                path="/editpurchase-page/:id"
                                element={<EditPurchaseADPage />}
                            />
                            <Route
                                path="/editsale-page/:id"
                                element={<EditSaleADPage />}
                            />
                            <Route
                                path="/statistics-page"
                                element={<StatisticsPage />}
                            />
                        </Route>
                    </Route>
                    <Route path="/*" element={<>Page not Found</>} />
                </Routes>
            </main>
        </div>
    );
}
export default App;
