import {
  Avatar,
  Box,
  Container,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import { East } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import classes from "./ObjectionOnUser.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Form, Image } from "react-bootstrap";
import Home from "./Home.svg";
import { AuthContext } from "store/UseAuthContext";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Snack from "components/Utilities/Snack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ObjectionOnUser = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const [reportDeleted, setReportDeleted] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { authInfo } = useContext(AuthContext);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  useEffect(() => {
    fetch(`https://api.whitesooq.com/api/v1/rating/objection/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        if (responseData?.data) {
          setData(responseData.data);
        }
      });
  }, []);
  // console.log(data?.rating_value);
  const handleDelete = () => {
    fetch(`https://api.whitesooq.com/api/v1/rating/objection/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        response.json();
      })
      .then((data) => {
        // if (data.status) {
        //   setReportDeleted(true);
        //   setOpen(false);
        //   navigate("/showObjection-page");
        // }
        setOpen(false);
        if (data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ حذف الاعتراض بنجاح",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: data?.message ? data?.message : "هناك مشكلة",
          });
        }
        setTimeout(() => {
          navigate("/showObjection-page");
        }, 1000);
      })
      .catch((error) => {});
  };
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Snack alert={alert} />

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/showObjection-page"
          >
            الاعتراضات
          </Link>
          / تفاصيل الاعتراض
        </h4>
      </Form.Label>

      <Container>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginTop: "0px",
            marginRight: "-30px",
          }}
          className={classes.ContainerBoxAdmin}
          sx={{
            padding: 2,
            width: { xs: 350, sm: 650 },
            height: { xs: 700, sm: 800 },
          }}
        >
          <Typography
            className={classes.detailsParagraph}
            sx={{ color: "#fff", m: 1 }}
          >
            تفاصيل الاعتراض
          </Typography>
          <Typography className={classes.line}></Typography>
          <Box
            style={{ width: "101%", marginRight: "-9px" }}
            className={classes.ContainerBoxAdminInside}
            sx={{
              padding: 2,
              width: { xs: 320, sm: 560 },
              height: { xs: 620, sm: 670 },
            }}
          >
            <IconButton
              sx={{ m: 1 }}
              onClick={() => navigate("/showObjection-page")}
            >
              <East />
            </IconButton>
            <Box className={classes.menuOption}>
              <FormControl sx={{ m: 1 }}>
                <Select
                  IconComponent={ExpandMore}
                  value={option}
                  onChange={handleChange}
                  displayEmpty
                  autoWidth
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem onClick={handleClickOpen} sx={{ pl: 6 }}>
                    حذف
                  </MenuItem>
                </Select>
              </FormControl>
              {!reportDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"هل أنت متأكد من حذف هذا الاعتراض"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      لا يمكن استعادة الاعتراض عند الحذف
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnCancelDialog}
                      onClick={handleClose}
                    >
                      إلغاء
                    </button>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleDelete}
                    >
                      متأكد
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {error && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {error}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Close sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {reportDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"تم حذف الاعتراض بنجاح"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Check sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>
            <Typography
              variant="p"
              sx={{
                color: "#1d3557",
                fontWeight: 800,
                fontSize: { xs: "18px", sm: "25px" },
              }}
            >
              عرض الاعتراض
            </Typography>
            <Box sx={{ my: 1 }} style={{ direction: "ltr" }}>
              <table className={classes.tableDetails}>
                <tbody className="">
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <div className={classes.flexTable}>
                        <IconButton
                          onClick={() =>
                            navigate(`/profile/${data?.objection_sender_id}`)
                          }
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        {/* <Typography>hfgh</Typography> */}
                        <Typography sx={{ fontWeight: 600 }}>
                          {data?.objection_sender_username}
                        </Typography>
                        <Avatar
                          sx={{
                            ml: 1,
                            width: "40px",
                            height: "40px",
                            p: 0,
                          }}
                          src={data?.objection_sender_image}
                        />
                      </div>
                    </div>
                    <div className={classes.sentence2}>
                      اسم المستخدم لمقدم الاعتراض
                    </div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{data?.reason}</div>
                    <div className={classes.sentence2}>
                      سبب\وصف الاعتراض المقدم
                    </div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{data?.created_at}</div>
                    <div className={classes.sentence2}>
                      تاريخ تقديم الاعتراض
                    </div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <div className={classes.flexTable}>
                        <IconButton
                          onClick={() =>
                            navigate(`/profile/${data?.rating_applyer_id}`)
                          }
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        <Typography sx={{ fontWeight: 600 }}>
                          {data?.rating_applyer_username}
                        </Typography>
                        <Avatar
                          sx={{
                            ml: 1,
                            /* width: "40px", */ height: "40px",
                            p: 0,
                          }}
                          src={data?.rating_applyer_image}
                        />
                      </div>
                    </div>
                    <div className={classes.sentence2}>
                      اسم المستخدم لمقدم التقييم
                    </div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <Rating
                        className={classes.flexTable}
                        name="read-only"
                        value={data?.rating_value}
                        readOnly
                      />
                    </div>
                    <div className={classes.sentence2}>قيمة التقييم</div>
                  </div>
                  {data?.rateable_type === "User" && (
                    <div className={classes.tableRow}>
                      <div className={classes.sentence1WithoutPadding}>
                        <div className={classes.flexTable}>
                          <Rating
                            className={classes.flexTable}
                            name="read-only"
                            value={data?.rateable?.rating}
                            readOnly
                            sx={{ fontSize: { xs: "18px", sm: "1.5rem" } }}
                          />
                          <Typography
                            sx={{
                              mr: { xs: 2, sm: 4 },
                              ml: 1,
                              fontWeight: 500,
                              fontSize: { xs: "15px", sm: "18px" },
                              display: { xs: "none", sm: "block" },
                            }}
                          >
                            تقييم
                          </Typography>
                          <Typography>{data?.rateable?.username}</Typography>
                          {/* <Typography>hfghfg</Typography> */}
                          <Typography
                            sx={{
                              p: 0,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                navigate(`/profile/${data?.rateable?.id}`)
                              }
                            >
                              {/* <img
                                style={{ width: "50px", height: "50px" }}
                                src={data?.rateable?.full_path_image}
                                alt="Img AD"
                              /> */}
                              <Avatar
                                sx={{
                                  ml: 1,
                                  /* width: "40px", */ height: "40px",
                                  p: 0,
                                }}
                                src={data?.rateable?.full_path_image}
                              />
                            </IconButton>
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.sentence2}>
                        تم تقديم التقييم على المستخدم
                      </div>
                    </div>
                  )}
                  {data?.rateable_type === "Ad" && (
                    <div className={classes.tableRow}>
                      <div className={classes.sentence1WithoutPadding}>
                        <div className={classes.flexTable}>
                          <Typography>{data?.rateable?.title}</Typography>
                          <Typography
                            sx={{
                              ml: 1,
                              p: 0,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                navigate(`/AdDetail/${data?.rateable?.id}`)
                              }
                            >
                              <img
                                style={{ width: "50px", height: "50px" }}
                                src={data?.rateable?.all_images[0]}
                                alt="Img AD"
                              />
                            </IconButton>
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.sentence2}>
                        تم تقديم التقييم على الإعلان
                      </div>
                    </div>
                  )}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ObjectionOnUser;
