// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import "./Favorite.css";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { Category } from "../Landingpagevisitor/Category/Category";
import { Viewmore } from "../Landingpagevisitor/Viewmore/Viewmore";
import { filterDataContext } from "store/FilterDataContext";

const Favorite = () => {
    const { authInfo,ChangeAuthInfo } = useContext(AuthContext);
    const { filterData } = useContext(filterDataContext);
    const [FavoriteData, setFavoriteData] = useState([]);
    const [simillerData, setsimillerData] = useState([]);
    const [updateData, setUpdateData] = useState(false); 

    let url=`https://api.whitesooq.com/api/v1/favorites?${
        filterData.category ? `category=${filterData.category}&` : ""
    }${filterData.sortBy ? filterData.sortBy + "&" : ""}${
        filterData.minPrice && filterData.maxPrice
            ? `price=${filterData.minPrice}to${filterData.maxPrice}&`
            : ""
    }${filterData.type ? `type=${filterData.type}&` : ""}${
        filterData.search ? `search=${filterData.search}` : ""
    }`;

    
    useEffect(() => {
    const getData=async()=>{
        try {
          const response = await axios.get( url,
            {
              headers: {
                accept: 'application/json',
                app_key: 'base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=',
                Authorization: `Bearer ${authInfo.token}`,
              },
            }
          );
          console.log(response);
          setFavoriteData(response.data.data);
            setsimillerData(response.data.similar)
        } catch (err) {
          console.log(err);
        }
    }
    getData()
    }, [updateData,url,authInfo.token]);
    
    const emptyFavoriteIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="none"
        >
            <path
                d="M87.2674 10.8824C76.9355 10.8824 67.3757 15.5425 61.0793 23.5006C53.9403 14.5072 43.0092 10.184 32.5818 10.94C22.6417 11.613 14.3264 17.1396 9.76769 26.1026C5.37554 34.7377 5.55646 44.9676 10.0489 53.2829C5.28681 58.4921 2.35352 65.4004 2.35352 72.9983C2.35352 89.145 15.4902 102.282 31.6369 102.282C38.0024 102.282 43.8817 100.218 48.6904 96.7551L56.2993 105.036C57.5139 106.368 59.247 107.138 61.0493 107.145C61.0585 107.145 61.0654 107.145 61.0746 107.145C62.8815 107.145 64.6215 106.382 65.8615 105.043L107.993 59.1794C115.615 50.8687 117.726 39.1402 113.504 28.5708C109.28 18.0016 99.7865 10.8824 87.2674 10.8824ZM7.07352 72.9984C7.07352 59.456 18.0922 48.4373 31.6369 48.4373C45.1792 48.4373 56.1979 59.456 56.1979 72.9984C56.1979 86.543 45.1792 97.5617 31.6369 97.5617C18.0922 97.5617 7.07352 86.543 7.07352 72.9984ZM104.516 55.9875L62.3906 101.844C62.0449 102.219 61.5771 102.425 61.0746 102.425C60.5814 102.535 60.1228 102.222 59.7817 101.848L52.3088 93.7164C57.624 88.4121 60.9179 81.0832 60.9179 72.9984C60.9179 56.8517 47.7835 43.7173 31.6369 43.7173C24.8375 43.7173 18.5877 46.0669 13.6119 49.9693C10.4015 43.2126 10.4752 35.1269 13.9761 28.2436C17.7627 20.7972 24.6606 16.2086 32.9137 15.6485C43.0045 14.9102 53.2517 19.7125 59.0972 28.8359C59.5305 29.5112 60.2795 29.9214 61.0839 29.9214C61.8905 29.9214 62.6419 29.5089 63.0728 28.8313C68.3552 20.5483 77.4011 15.6024 87.2674 15.6024C97.4389 15.5303 105.529 21.3315 109.12 30.3225C112.663 39.1909 110.897 49.0273 104.516 55.9875Z"
                fill="#457B9D"
            />
            <path
                d="M42.7455 70.1705H33.9969V61.8898C33.9969 60.5853 32.9413 59.5298 31.6369 59.5298C30.3325 59.5298 29.2769 60.5853 29.2769 61.8898V70.1705H20.526C19.2216 70.1705 18.166 71.2261 18.166 72.5305C18.166 73.835 19.2216 74.8905 20.526 74.8905H29.2769V84.1093C29.2769 85.4137 30.3325 86.4693 31.6369 86.4693C32.9414 86.4693 33.9969 85.4137 33.9969 84.1093V74.8905H42.7455C44.05 74.8905 45.1055 73.835 45.1055 72.5305C45.1055 71.2261 44.05 70.1705 42.7455 70.1705Z"
                fill="#457B9D"
            />
        </svg>
    );
    return (
        <div>
            <div
                className={` favoriteContainer${
                    FavoriteData.length === 0  ? " emptyFavoriteContainer " : ""
                } `}
            >
                <div className="favoriteSection">
                    <header>
                        <span className="rightSection"></span>
                        {FavoriteData.length === 0 ? (
                            <h1 className="favf">لا يوجد عناصر في المفضلة</h1>
                        ) : (
                            <h1>
                                الإعلانات المفضلة{" "}
                                <p> {FavoriteData?.length} </p>
                            </h1>
                        )}
                    </header>
                    {FavoriteData.length === 0 ? (
                        <div className="emptyFavorite">
                            <button> {emptyFavoriteIcon} </button>
                        </div>
                    ) : (
                        <>
                                <Category
                                    data={FavoriteData}
                                    checkCategory="favorite"
                                    updateData={updateData}
                                    setUpdateData={setUpdateData}
                                />

                            <div className="favoriteSection">
                                <header>
                                    <span className="rightSection"></span>
                                    <h1> إعلانات مشابهة</h1>
                                </header>
                                    <Category
                                        data={simillerData}
                                        checkCategory="favorite"
                                        updateData={updateData}
                                        setUpdateData={setUpdateData}
                                    />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Viewmore check="" />
        </div>
    );
};

export default Favorite;
