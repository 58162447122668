import { Grid, } from "@mui/material";
import StatisticCard from "./StatisticCard";

export default function Section1({ data }) {
    const statistics_section1 = [
        {
            title: "عدد الزائرين",
            ratio: data?.visitors_ratio,
            count: data?.visitors_count,
        },
        {
            title: "المستخدمين النشطين",
            ratio: data?.active_users_ratio,
            count: data?.active_users_count,
        },
        {
            title: "المستخدمين الجدد",
            ratio: data?.new_users_ratio,
            count: data?.new_users_count,
        },
        {
            title: "عدد الإعلانات",
            ratio: data?.ads_ratio,
            count: data?.ads_count,
        },
        {
            title: "عدد العروض",
            ratio: data?.offers_ratio,
            count: data?.offers_count,
        },
    ];
    // console.log(data);
    return (
        <Grid container rowSpacing={3} columnSpacing={2}>
            {statistics_section1?.map((statistic, i) => (
                <Grid item xs={12} md={12} lg={4} xl={2.4}>
                    <StatisticCard
                        title={statistic.title}
                        ratio={statistic.ratio}
                        count={statistic.count}
                        bgcolor={i % 2 === 0 ? "#F1FAEE" : "#D9EEEE"}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
