import React from "react";
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import EditSaleADForm from "../EditSaleADForm/EditSaleADForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import classes from "./EditSaleAD.module.css";
import Home from './Home.svg'
import { Form ,Image } from "react-bootstrap";


const EditSaleAD = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  return (
    <div style={{ display: "grid", backgroundColor:"#f1f1f1" }}>
    
    <Form.Label style={{display:"flex", direction:"rtl", alignItems:"flex-start", marginRight:"25px", marginTop:"16px" }}>
        <Image style={{ marginTop:'-2px' }} src={Home} />
        <h4 style={{ color:"#1D3557", fontSize:"18px", marginRight:"5px" }}><Link style={{fontFamily:"inherit",color:"inherit"}} to={'/ViewWaitingAds-page'}>الإعلانات قيد الإنتظار</Link> / تعديل إعلان</h4>
    </Form.Label>
    <div style={{ height: '90vh', display: "grid", placeItems:"center", marginRight:"17px", marginLeft:"93px", backgroundColor:"#FFFFFF", borderRadius:"8px" }}>
    
    <Container sx={{ my: { xs: 3, sm: 1 } }} >
    
      <Box
      dir="rtl" style={{ width:"100%", height:'100%',  backgroundColor: "white", padding:"32px", borderRadius:"8px", boxShadow:"0px 4px 25px 0px #41404033", marginTop: '-9px'}}
        className={classes.ContainerBoxAdmin}
        sx={{
          padding: 2,
          width: { xs: 330, sm: 600 },
          height: { xs: 550, sm: 800 },
        }}
      >
        <IconButton onClick={() => navigate(`/offer-detail-dash-page/${id}`)}>
          <East />
        </IconButton>
        <Typography
          sx={{
            color: "#1D3557",
            fontWeight: "800",
          }}
          variant="h5"
        >
          تعديل إعلان بيع جديد
        </Typography>
        <EditSaleADForm />
      </Box>
    </Container>
    </div>
    </div>
  );
};
export default EditSaleAD;
