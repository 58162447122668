// @ts-nocheck
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "store/UseAuthContext";
export default function RequireAuth({ allowedRole }) {
    const location = useLocation();
    const { authInfo } = useContext(AuthContext);
    console.log(authInfo);
    return (
        <>
            {authInfo.token ? (
                allowedRole.includes(authInfo.user.is_admin) === true ? (
                    <Outlet />
                ) : (
                    <Navigate
                        state={{ from: location }}
                        replace
                        to={"/"}
                    />
                )
            ) : (
                <Navigate state={{ from: location }} replace to={"/login"} />
            )}
        </>
    );
}

// import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import { AuthContext } from "store/UseAuthContext";
// export default function RequireAuth({ allowedRole }) {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [user, setUser] = useState("");
//     console.log(user);
//     const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
//     console.log(authInfo);
//     const token = localStorage.getItem("token");
//     console.log(token);
//     useEffect(() => {
//         axios
//             .post(
//                 "https://api.whitesooq.com/api/v1/refresh-token",
//                 {},
//                 {
//                     headers: {
//                         accept: "application/json",
//                         Authorization: `Bearer ${token}`,
//                         app_key:
//                             "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
//                     },
//                 }
//             )
//             .then((response) => {
//                 console.log(response);
//                 setUser(response.data);
//                 localStorage.setItem("token", response.data.token);
//             })
//             .catch((err) => {
//                 console.log(err);
//                 navigate("/login", { replace: true });
//             });
//     }, []);
//     useEffect(() => {
//         ChangeAuthInfo(user);
//         console.log(user);
//         console.log(authInfo);
//     }, [user]);
//     return (
//         <>
//             {localStorage.getItem("token") ? (
//                 user !== "" ? (
//                     // Object.keys(authInfo).length !== 0 ? (
//                     //     <>Loading</>
//                     // ) : allowedRole.includes(authInfo.user.is_admin) ? (
//                     <Outlet />
//                 ) : (
//                     <Navigate state={{ from: location }} replace to={"/login"} />
//                 )
//             ) : (
//                 <Navigate state={{ from: location }} replace to={"/login"} />
//             )}
//         </>
//     );
// }
