import {
  Box,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { East } from "@mui/icons-material";
import React, { useEffect, useState, useContext } from "react";
import classes from "./ShowRoles.module.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import { styled } from "@mui/system";
import { ExpandMore } from "@mui/icons-material";
import Home from "./Home.svg";
import { AuthContext } from "store/UseAuthContext";
import { Form, Image } from "react-bootstrap";

const Root = styled("div")(
  ({ theme }) => `
  table {
    width: 100%;
    --border: 1px solid #A0BED0;
    border-radius: 10px;
    border-spacing: 0;
    border-collapse: separate;
    border: var(--border);
    overflow: hidden;
  }
  td {
    border: 1px solid #A0BED0;
    color: #1d3557;
    text-align: center;
    padding: 8px;
    font-size: 17px;
    font-weight: 700;
  }
  th{
    text-align:center;
    background-color: #1d3557;
    color:#fff;
    padding:8px;
  }
  `
);
const ShowRoles = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const { authInfo } = useContext(AuthContext);
  const [roles, setRoles] = useState([
    { id: 1, ability: "استعراض الفئات", status: "allow" },
    { id: 2, ability: "إضافة فئة", status: "allow" },
    { id: 3, ability: "حذف فئة", status: "allow" },
    { id: 4, ability: "استعادة فئة", status: "allow" },
    { id: 5, ability: "تعديل فئة", status: "allow" },
    { id: 6, ability: "استعراض الإعلانات", status: "not allow" },
    { id: 7, ability: "إضافة إعلان", status: "allow" },
    { id: 8, ability: "حذف إعلان", status: "not allow" },
    { id: 9, ability: "تعديل إعلان", status: "allow" },
    { id: 10, ability: "قبول إعلان", status: "allow" },
    { id: 11, ability: "رفض إعلان", status: "allow" },
    { id: 12, ability: "استعراض الباقات", status: "not allow" },
    { id: 13, ability: "إضافة باقة", status: "allow" },
    { id: 14, ability: "حذف باقة", status: "allow" },
    { id: 15, ability: "استعادة باقة", status: "allow" },
    { id: 16, ability: "تعديل باقة", status: "allow" },
    { id: 17, ability: "استعراض المستخدمين", status: "not allow" },
    { id: 18, ability: "استعراض المسؤولين", status: "not allow" },
    { id: 19, ability: "استعراض الرسائل", status: "not allow" },
    { id: 20, ability: "استعراض التعليقات", status: "not allow" },
    { id: 21, ability: "استعراض العروض", status: "not allow" },
    { id: 22, ability: "استعراض الدفعات", status: "not allow" },
    { id: 23, ability: "استعراض الأدوار", status: "not allow" },
    { id: 24, ability: "استعراض الاعتراضات", status: "not allow" },
    { id: 25, ability: "استعراض التقييمات", status: "not allow" },
    { id: 26, ability: "استعراض البلاغات", status: "not allow" },
  ]);
  useEffect(() => {
    fetch(`https://api.whitesooq.com/api/v1/roles/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        if (responseData?.role) {
          setRoles(responseData.role);
        }
      });
  }, []);
  // console.log(roles);
  const name =
    roles.name === "admin"
      ? "المشرف"
      : roles.name === "super-admin"
      ? "المسؤول"
      : "المالك";
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/view-roles-page"
          >
            الأدوار
          </Link>
          / تفاصيل دور {name}
        </h4>
      </Form.Label>

      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginRight: "-40px",
            marginTop: "-9px",
          }}
          className={classes.BoxShowRole}
          sx={{
            padding: { xs: "16px 8px", sm: 2 },
            width: { xs: 333, sm: 650 },
            height: { xs: 570, sm: 800 },
          }}
        >
          <Typography
            className={classes.detailsParagraph}
            sx={{ color: "#fff", m: "8px 5px" }}
          >
            تفاصيل الدور
          </Typography>
          <Typography className={classes.line}></Typography>
          <Box
            style={{ width: "101%", marginRight: "-9px" }}
            className={classes.BoxShowRoleInside}
            sx={{ p: 1 }}
          >
          <IconButton sx={{ m: 1 }} onClick={() => navigate("/view-roles-page")}>
              <East />
            </IconButton>
            <Box className={classes.menuOption}>
              <FormControl sx={{ m: 1 }}>
                <Select
                  IconComponent={ExpandMore}
                  // IconComponent={() => ExpandMore, MoreVert)}
                  value={option}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem
                    onClick={() => navigate(`/edit-role-of-owner-page/${id}`)}
                    sx={{ pl: 6 }}
                  >
                    تعديل
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Typography
              variant="p"
              sx={{
                mx: 1,
                color: "#1d3557",
                fontWeight: 800,
                fontSize: "27px",
              }}
            >
              {`عرض دور ${name}`}
            </Typography>
            <Box sx={{ mx: 1, my: 2 }} style={{ direction: "ltr" }}>
              <Root sx={{ maxWidth: "100%", width: "100%", mt: 1 }}>
                <table aria-label="custom pagination table">
                  <thead>
                    <tr>
                      <th>مسموح/غير مسموح</th>
                      <th>صلاحية الدور</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roles?.permissions?.map((row) => (
                      <tr key={row.id}>
                        <td style={{ fontWeight: 600 }}>
                          {row.status === "allow" ? "مسموح" : "غير مسموح"}
                        </td>
                        <td
                          style={{
                            width: "40%",
                            color: row.ar_ability.includes("ستعراض")
                              ? "#1d3557"
                              : "#457B9D",
                          }}
                          align="right"
                        >
                          {row.ar_ability}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Root>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
export default ShowRoles;
