import { createContext, useState } from "react";
const ViewMoreContext = createContext({});
const UseViewMoreContext = ({ children }) => {
  const [ViewMore, setViewMore] = useState("");
  const ChangeMoreValue = (Value) => {
    setViewMore(Value);
  };
  return (
    <ViewMoreContext.Provider value={{ ViewMore, ChangeMoreValue }}>
      {children}
    </ViewMoreContext.Provider>
  );
};
export { UseViewMoreContext, ViewMoreContext};
