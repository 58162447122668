import React from "react";
import { Container } from "react-bootstrap";
import "./Adduserpage.css";
import home from "./../admin-image/categ/home.svg";
import { Adduser } from "../Adduser/Adduser";
import { Link } from "react-router-dom";

export const Adduserpage = () => {
  return (
    <div className="adduserpage">
      <Container>
        
          <div className="topuser">
            <img src={home} />
            <h6>المستخدمين /
            <Link
          style={{
            textDecoration: "none",
            fontFamily: "inherit",
            color: "inherit",
            cursor: "pointer",
          }}
          to="/activeUsers-page"
        >المستخدمون النشطون</Link> </h6>
          </div>
        <div className="usercontent">
          <Adduser />
        </div>
      </Container>
    </div>
  );
};
