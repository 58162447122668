// @ts-nocheck
import "./index.css";
import App from "./App";
import { HashRouter  } from "react-router-dom";
import { UseAuthContext } from "./store/UseAuthContext";
import { UseHomeContext } from "./store/HomeDataContext";
import { createRoot } from "react-dom";
import { UseFilterContext } from "store/FilterDataContext";
import { UseCategoryContext } from "store/CategoryContext";
import { UseViewMoreContext } from "store/ViewMoreContext";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const root = createRoot(document.getElementById("root"));
function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}
root.render(
    <HashRouter basename="/">
    <ScrollToTop />
        <UseAuthContext>
            <UseHomeContext>
                <UseCategoryContext>
                    <UseFilterContext>
                        <UseViewMoreContext>
                            <App />
                        </UseViewMoreContext>
                  </UseFilterContext>
                </UseCategoryContext>
            </UseHomeContext>
        </UseAuthContext>
    </HashRouter>
);
