// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./Viewadsactive.css";
import home from "./home.svg";
import search from "./Vector.svg";
import CardsDashbord from "../Viewads/Carddashbord/CardsDashbord";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
export const Viewadsactive = (props) => {
  const authInfo = useContext(AuthContext);
  const [Ads, setAds] = useState([]);
  const navigate = useNavigate();
  const [FilterData, setFilterData] = useState("");
  const [url, seturl] = useState('')
  const location=useLocation()
  useEffect(() => {
    props.type === "active" ?seturl("https://api.whitesooq.com/api/v1/ads") :seturl("https://api.whitesooq.com/api/v1/ads/only-pending")     
    axios.get(url, {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${authInfo.authInfo.token}`,
            },
          })
          .then((response) => {
            setAds(response.data.data);
          })
          .catch((Error) => console.log(Error))
  }, [url,props.type,location.pathname]);

  const handleclick = (e) => {
    e.preventDefault()
    navigate("/new-ad");
  };
  //  function to filter the data depending on the input filed
  const filterData = (e) => {
    e.preventDefault();
    setFilterData(e.target.value);
    var FilterAds = Ads.filter((Ad) => {
      return Ad.title.includes(FilterData);
    });
    setAds(FilterAds);
  };

  return (
    <div className="viewadsactie" dir="rtl" >
      <Container>
        <div className="row title d-flex">
          <img src={home} />
          <h3>
            {props.type === "active"
              ? "الإعلانات النشطة"
              : "الإعلانات قيد الانتظار"}{" "}
          </h3>
        </div>
        <div
          style={{
            placeItems: "center",
            marginRight: "17px",
            marginLeft: "50px",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            padding: "32px",
          }}
        >
          <div className="content" dir="rtl">
            <h2>
              {" "}
              {props.type === "active"
                ? "عرض الإعلانات النشطة"
                : "عرض الإعلانات قيد الانتظار"}
            </h2>

            <div className="searchsection d-flex">
              <div style={{ marginBottom: "32px" }}>
                <img src={search} />
                <input
                  type="text"
                  placeholder="  بحث .."
                  className="ii"
                  onChange={filterData}
                />
              </div>
              {props.type === "active" ? (
                <button onClick={handleclick}>
                  <span style={{ fontSize: "23px", fontWeight: "700" }}>+</span>{" "}
                  إضافة إعلان
                </button>
              ) : (
                " "
              )}
            </div>

            <div className="row allcards">
              <CardsDashbord data={Ads} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};