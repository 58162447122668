// @ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
import "../offerDetail/OfferDetail.css";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Overlay } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Headerlogin from "layouts/Headerlogin/Headerlogin";
import { Searchbar } from "../Landingpagevisitor/Searchbar/Searchbar";
import OfferChoice from "../Ads/Ads_Detail/details/OfferChoice/OfferChoice";
import { Addnew } from "../Productdetailsbuy/Addnew/Addnew";
import Footer from "layouts/Footer/Footer";
import { Category } from "../Landingpagevisitor/Category/Category";
import { Detailsofferandcomment } from "../Ads/Ads_Detail/details/Detailsofferandcomment/Detailsofferandcomment";
import { useParams } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Snack from "components/Utilities/Snack";
import { filterDataContext } from "store/FilterDataContext";

const OfferDetail = (props) => {
  const { filterData, changeFilterData } = useContext(filterDataContext);
  const navigate = useNavigate();
  const params = useParams();
  const { authInfo } = useContext(AuthContext);
  const userlLocation = useLocation();
  // console.log(userlLocation)
  const [showCopied, setShowCopied] = useState(false);
  const target = useRef(null);
  const [DetailData, setDetailData] = useState({});
  const [userData, setuserData] = useState({});
  const [DetailDataComments, setDetailDataComments] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoggedIn, setisLoggedIn] = useState(0);
  const [Rate, setRate] = useState({
    rateable_id: params.id,
    rateable_type: "ad",
    value: 1,
  });
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  useEffect(() => {
    axios
      .get(
        `https://api.whitesooq.com/api/v1/home/show-ad/${params.id}`,
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      )
      .then((response) => {
        setDetailData(response.data.data);
        setDetailDataComments(response.data.data.comments);
        setuserData(response.data.data.user);
        setTags(response.data.data.tags);
        console.log(response.data);
      })
      .catch((Error) => {
        console.log(Error);
      });
    setisLoggedIn(Object.keys(authInfo).length);
  }, []);
  const postAdd = () => {
    axios
      .post(`https://api.whitesooq.com/api/v1/ratings`, Rate, {
        headers: {
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          authorization: `Bearer ${authInfo.token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setAlert({
          open: true,
          type: "success",
          message: "تمَّت العملبة بنجاح",
        });
        window.location.reload();
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.response.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول",
          });
        }
      });
  };
  const AddTofavoritee = () => {
    axios
      .post(
        `https://api.whitesooq.com/api/v1/favorites/${params.id}`,
        "",
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            authorization: `Bearer ${authInfo.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setAlert({
          open: true,
          type: "success",
          message: "تمَّت العملبة بنجاح",
        });
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.response.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول",
          });
        }
      });
  };
  // function to copy the path
  const copyText = async () => {
    try {
      // Copy the text to the clipboard
      await navigator.clipboard.writeText(
        "https://www.whitesooq.online/#" + userlLocation.pathname
      );
      setShowCopied(!showCopied);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    } catch (err) {
      // Handle the error
      console.error("Failed to copy: ", err);
    }
    incrementShare();
  };
  const incrementMessages = () => {
    axios
      .put(
        `https://api.whitesooq.com/api/v1/increment-messages-count/${params.id}`,
        "",
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            authorization: "Bearer " + authInfo.token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // setAlert({
        //     open: true,
        //     type: "success",
        //     message: "تمَّت العملية بنجاح",
        // });
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.response.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
          });
        }
      });
  };
  const incrementWhatsapp = () => {
    axios
      .put(
        `https://api.whitesooq.com/api/v1/increment-whatsapp-count/${params.id}`,
        "",
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            authorization: "Bearer " + authInfo.token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // setAlert({
        //     open: true,
        //     type: "success",
        //     message: "تمَّت العملية بنجاح",
        // });
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.response.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
          });
        }
      });
  };
  const incrementCall = () => {
    axios
      .put(
        `https://api.whitesooq.com/api/v1/increment-call-count/${params.id}`,
        "",
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            authorization: "Bearer " + authInfo.token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // setAlert({
        //     open: true,
        //     type: "success",
        //     message: "تمَّت العملية بنجاح",
        // });
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.response.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
          });
        }
      });
  };
  const incrementShare = () => {
    axios
      .put(
        `https://api.whitesooq.com/api/v1/increment-share-count/${params.id}`,
        "",
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            authorization: "Bearer " + authInfo.token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // setAlert({
        //     open: true,
        //     type: "success",
        //     message: "تمَّت العملية بنجاح",
        // });
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.response.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
          });
        }
      });
  };
  const AddRateOne = () => {
    setRate(Rate, (Rate.value = 1));
    postAdd();
  };
  const AddRateTow = () => {
    setRate(Rate, (Rate.value = 2));
    postAdd();
  };
  const AddRateThree = () => {
    setRate(Rate, (Rate.value = 3));
    postAdd();
  };
  const AddRateFour = () => {
    setRate(Rate, (Rate.value = 4));
    postAdd();
  };
  const AddRateFive = () => {
    setRate(Rate, (Rate.value = 5));
    postAdd();
  };
  const whatsapp = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="whatsapp">
        <path
          id="Vector"
          d="M17.1 14.0001C16.9 13.9001 15.6 13.3001 15.4 13.2001C15.2 13.1001 15 13.1001 14.8 13.3001C14.6 13.5001 14.2 14.1001 14 14.3001C13.9 14.5001 13.7 14.5001 13.5 14.4001C12.8 14.1001 12.1 13.7001 11.5 13.2001C11 12.7001 10.5 12.1001 10.1 11.5001C10 11.3001 10.1 11.1001 10.2 11.0001C10.3 10.9001 10.4 10.7001 10.6 10.6001C10.7 10.5001 10.8 10.3001 10.8 10.2001C10.9 10.1001 10.9 9.9001 10.8 9.8001C10.7 9.7001 10.2 8.5001 10 8.0001C9.9 7.3001 9.7 7.3001 9.5 7.3001H9C8.8 7.3001 8.5 7.5001 8.4 7.6001C7.8 8.2001 7.5 8.9001 7.5 9.7001C7.6 10.6001 7.9 11.5001 8.5 12.3001C9.6 13.9001 11 15.2001 12.7 16.0001C13.2 16.2001 13.6 16.4001 14.1 16.5001C14.6 16.7001 15.1 16.7001 15.7 16.6001C16.4 16.5001 17 16.0001 17.4 15.4001C17.6 15.0001 17.6 14.6001 17.5 14.2001L17.1 14.0001ZM19.6 4.9001C15.7 1.0001 9.4 1.0001 5.5 4.9001C2.3 8.1001 1.7 13.0001 3.9 16.9001L2.5 22.0001L7.8 20.6001C9.3 21.4001 10.9 21.8001 12.5 21.8001C18 21.8001 22.4 17.4001 22.4 11.9001C22.5 9.3001 21.4 6.8001 19.6 4.9001ZM16.9 18.9001C15.6 19.7001 14.1 20.2001 12.5 20.2001C11 20.2001 9.6 19.8001 8.3 19.1001L8 18.9001L4.9 19.7001L5.7 16.7001L5.5 16.4001C3.1 12.4001 4.3 7.4001 8.2 4.9001C12.1 2.4001 17.1 3.7001 19.5 7.5001C21.9 11.4001 20.8 16.5001 16.9 18.9001Z"
          fill="#F1FAEE"
        />
      </g>
    </svg>
  );
  const flagIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M4.5 15C4.5 15 5.5 14 8.5 14C11.5 14 13.5 16 16.5 16C19.5 16 20.5 15 20.5 15V3C20.5 3 19.5 4 16.5 4C13.5 4 11.5 2 8.5 2C5.5 2 4.5 3 4.5 3V15Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 22V15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const shareIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.59 13.5098L15.42 17.4898"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.41 6.50977L8.59 10.4898"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const chatIcon = (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="message-circle">
        <path
          id="Vector"
          d="M21.5 11.5C21.5034 12.8199 21.1951 14.1219 20.6 15.3C19.8944 16.7118 18.8098 17.8992 17.4674 18.7293C16.1251 19.5594 14.5782 19.9994 13 20C11.6801 20.0035 10.3781 19.6951 9.2 19.1L3.5 21L5.4 15.3C4.80493 14.1219 4.49656 12.8199 4.5 11.5C4.50061 9.92179 4.94061 8.37488 5.77072 7.03258C6.60083 5.69028 7.78825 4.6056 9.2 3.90003C10.3781 3.30496 11.6801 2.99659 13 3.00003H13.5C15.5843 3.11502 17.553 3.99479 19.0291 5.47089C20.5052 6.94699 21.385 8.91568 21.5 11V11.5Z"
          stroke="#457B9D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
  const callIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
    >
      <path
        d="M13.9941 5.53589C14.9708 5.72645 15.8684 6.20415 16.5721 6.90782C17.2758 7.6115 17.7535 8.50915 17.9441 9.48589M13.9941 1.53589C16.0233 1.76132 17.9156 2.67006 19.3603 4.1129C20.8049 5.55573 21.7161 7.4469 21.9441 9.47589M20.9441 17.4559V20.4559C20.9452 20.7344 20.8881 21.0101 20.7766 21.2652C20.665 21.5204 20.5014 21.7495 20.2961 21.9378C20.0909 22.126 19.8486 22.2694 19.5848 22.3586C19.321 22.4478 19.0414 22.481 18.7641 22.4559C15.6869 22.1215 12.7311 21.07 10.1341 19.3859C7.71788 17.8506 5.66939 15.8021 4.13406 13.3859C2.44403 10.7771 1.3923 7.80688 1.06406 4.71589C1.03907 4.43936 1.07193 4.16065 1.16056 3.89751C1.24918 3.63438 1.39163 3.39258 1.57882 3.18751C1.76602 2.98244 1.99386 2.8186 2.24785 2.70641C2.50183 2.59422 2.7764 2.53615 3.05406 2.53589H6.05406C6.53936 2.53111 7.00985 2.70297 7.37782 3.01942C7.74579 3.33588 7.98613 3.77534 8.05406 4.25589C8.18068 5.21596 8.41551 6.15862 8.75406 7.06589C8.8886 7.42381 8.91772 7.8128 8.83796 8.18677C8.75821 8.56073 8.57292 8.904 8.30406 9.17589L7.03406 10.4459C8.45761 12.9494 10.5305 15.0223 13.0341 16.4459L14.3041 15.1759C14.5759 14.907 14.9192 14.7217 15.2932 14.642C15.6671 14.5622 16.0561 14.5913 16.4141 14.7259C17.3213 15.0644 18.264 15.2993 19.2241 15.4259C19.7098 15.4944 20.1535 15.7391 20.4706 16.1134C20.7877 16.4877 20.9562 16.9655 20.9441 17.4559Z"
        stroke="#F1FAEE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const rightArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 20L20 15L15 10"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15H20"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const leftArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 10L10 15L15 20"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 15H10"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const filledStarIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
        fill="#FFEE32"
      />
    </svg>
  );
  const clockIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M9.57889 17.1112C13.9137 17.1112 17.4278 13.629 17.4278 9.33344C17.4278 5.03789 13.9137 1.55566 9.57889 1.55566C5.24404 1.55566 1.72995 5.03789 1.72995 9.33344C1.72995 13.629 5.24404 17.1112 9.57889 17.1112Z"
        stroke="black"
        strokeOpacity="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57889 4.66675V9.33341L12.7185 10.889"
        stroke="black"
        strokeOpacity="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const favoriteIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heart">
        <path
          id="Vector"
          d="M20.84 4.60987C20.3292 4.09888 19.7228 3.69352 19.0554 3.41696C18.3879 3.14039 17.6725 2.99805 16.95 2.99805C16.2275 2.99805 15.5121 3.14039 14.8446 3.41696C14.1772 3.69352 13.5708 4.09888 13.06 4.60987L12 5.66987L10.94 4.60987C9.90831 3.57818 8.50903 2.99858 7.05 2.99858C5.59097 2.99858 4.19169 3.57818 3.16 4.60987C2.12831 5.64156 1.54871 7.04084 1.54871 8.49987C1.54871 9.95891 2.12831 11.3582 3.16 12.3899L4.22 13.4499L12 21.2299L19.78 13.4499L20.84 12.3899C21.351 11.8791 21.7564 11.2727 22.0329 10.6052C22.3095 9.93777 22.4518 9.22236 22.4518 8.49987C22.4518 7.77738 22.3095 7.06198 22.0329 6.39452C21.7564 5.72706 21.351 5.12063 20.84 4.60987Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
  const notFavoriteIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M11 7C8.239 7 6 9.216 6 11.95C6 14.157 6.875 19.395 15.488 24.69C15.6423 24.7839 15.8194 24.8335 16 24.8335C16.1806 24.8335 16.3577 24.7839 16.512 24.69C25.125 19.395 26 14.157 26 11.95C26 9.216 23.761 7 21 7C18.239 7 16 10 16 10C16 10 13.761 7 11 7Z"
        stroke="#1D3557"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const emptyStarIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="19"
      viewBox="0 0 11 7"
      fill="none"
    >
      <path
        d="M5.50158 1.45581L6.91741 3.71661L10.0836 4.08138L7.79257 5.84018L8.33325 8.3249L5.50158 7.15116L2.66991 8.3249L3.21058 5.84018L0.919586 4.08138L4.08574 3.71661L5.50158 1.45581Z"
        stroke="#FFEE32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const countRatingZero = (
    <span className="starRating">
      <ul>
        <li onClick={AddRateOne}>{emptyStarIcon}</li>
        <li onClick={AddRateTow}>{emptyStarIcon}</li>
        <li onClick={AddRateThree}>{emptyStarIcon}</li>
        <li onClick={AddRateFour}>{emptyStarIcon}</li>
        <li onClick={AddRateFive}>{emptyStarIcon}</li>
      </ul>
    </span>
  );
  const countRatingOne = (
    <span className="starRating">
      <ul>
        <li onClick={AddRateOne}>{filledStarIcon}</li>
        <li onClick={AddRateTow}>{emptyStarIcon}</li>
        <li onClick={AddRateThree}>{emptyStarIcon}</li>
        <li onClick={AddRateFour}>{emptyStarIcon}</li>
        <li onClick={AddRateFive}>{emptyStarIcon}</li>
      </ul>
    </span>
  );
  const countRatingTow = (
    <span className="starRating">
      <ul>
        <li onClick={AddRateOne}>{filledStarIcon}</li>
        <li onClick={AddRateTow}>{filledStarIcon}</li>
        <li onClick={AddRateThree}>{emptyStarIcon}</li>
        <li onClick={AddRateFour}>{emptyStarIcon}</li>
        <li onClick={AddRateFive}>{emptyStarIcon}</li>
      </ul>
    </span>
  );
  const countRatingThree = (
    <span className="starRating">
      <ul>
        <li onClick={AddRateOne}>{filledStarIcon}</li>
        <li onClick={AddRateTow}>{filledStarIcon}</li>
        <li onClick={AddRateThree}>{filledStarIcon}</li>
        <li onClick={AddRateFour}>{emptyStarIcon}</li>
        <li onClick={AddRateFive}>{emptyStarIcon}</li>
      </ul>
    </span>
  );
  const countRatingFourth = (
    <span className="starRating">
      <ul>
        <li onClick={AddRateOne}>{filledStarIcon}</li>
        <li onClick={AddRateTow}>{filledStarIcon}</li>
        <li onClick={AddRateThree}>{filledStarIcon}</li>
        <li onClick={AddRateFour}>{filledStarIcon}</li>
        <li onClick={AddRateFive}>{emptyStarIcon}</li>
      </ul>
    </span>
  );
  const countRatingFifth = (
    <span className="starRating">
      <ul>
        <li onClick={AddRateOne}>{filledStarIcon}</li>
        <li onClick={AddRateTow}>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
        <li>{filledStarIcon}</li>
      </ul>
    </span>
  );
  const composeMessageWithImage = () => {
    if (
      DetailData &&
      DetailData.all_images &&
      DetailData.all_images.length > 0
    ) {
      const firstImageUrl = DetailData.all_images[0];

      const whatsappMessage = ` ${firstImageUrl} /n https://www.whitesooq.online/#${userlLocation.pathname}`;

      const whatsappUrl = `https://wa.me/${
        userData.phone_number
      }?text=${encodeURIComponent(whatsappMessage)}`;
      window.open(whatsappUrl, "_blank");
    } else {
      console.log("No images found for the ad.");
    }
  };
  
  return (
    <div className="offerDetail" id="offerDetail">
      <Snack alert={alert}></Snack>
      <Headerlogin />
      <Searchbar />
      <div className="offerDetailInformation mt-5 gap-5">
        <div className="carousel">
          {/*      */}
          <span className="favoriteIcon">
            {DetailData.in_favorites ? favoriteIcon : notFavoriteIcon}
          </span>
          {/*      */}
          {DetailData.pinable === 1 ? (
            <span className="specialAd"> اعلان مميز </span>
          ) : (
            <></>
          )}
          {/*      */}
          <Carousel
            className="w-100"
            data-bs-theme="dark"
            nextIcon={rightArrow}
            prevIcon={leftArrow}
            indicators={true}
          >
            {DetailData?.all_images?.map((image, index) => (
              <Carousel.Item key={index} interval={1000} id="middle">
                <img src={image} alt={`Slide ${index}`} />
              </Carousel.Item>
            ))}
          </Carousel>
          {/*  buttons  */}
          <Container className="buttons">
            <span className="d-flex">
              <Col style={{ textAlign: "center" }}>
                <Button onClick={composeMessageWithImage} className="fs-6 p-1">
                  واتساب {whatsapp}
                </Button>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <Button
                  className=" fs-6 p-1"
                  style={{
                    backgroundColor: "#F1FAEE",
                    color: "#457B9D",
                  }}
                  onClick={incrementMessages}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#457B9D",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={`sms:${userData.phone_number}`}
                  >
                    مراسلة {chatIcon}
                  </a>
                </Button>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <Button className=" fs-6 p-1" onClick={incrementCall}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`tel:${userData.phone_number}`}
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    اتصال {callIcon}
                  </a>
                </Button>
              </Col>
            </span>
            <span className="d-flex">
              <Col style={{ textAlign: "center" }}>
                {" "}
                <Button
                  className=" fs-6 p-1"
                  style={{
                    border: "1px solid var(--3, #1D3557)",
                    background: " white ",
                    color: "#1D3557",
                  }}
                >
                  <Link
                    to={`/contact/with-admin/ad/${params.id}`}
                    style={{
                      textDecoration: "none",
                      color: "#1D3557",
                    }}
                  >
                    <span>بلغ عن إساءة </span>
                    {flagIcon}
                  </Link>
                </Button>
              </Col>
              <Col ref={target}>
                <Button
                  onClick={copyText}
                  className=" fs-6 p-1"
                  style={{
                    border: "1px solid var(--3, #1D3557)",
                    background: " white ",
                    color: "#1D3557",
                  }}
                >
                  شارك الإعلان {shareIcon}
                </Button>
              </Col>
              <Col style={{ textAlign: "center" }} onClick={AddTofavoritee}>
                {" "}
                <Button
                  className=" fs-6 p-1"
                  style={{
                    border: "1px solid var(--3, #1D3557)",
                    background: " white ",
                    color: "#1D3557",
                  }}
                >
                  {" "}
                  احفظ الإعلان {favoriteIcon}{" "}
                </Button>{" "}
              </Col>
            </span>
            <Overlay
              target={target.current}
              show={showCopied}
              placement="bottom"
            >
              {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
              }) => (
                <div
                  {...props}
                  style={{
                    position: "absolute",
                    backgroundColor: "var(--3, #1D3557)",
                    padding: "2px 10px",
                    color: "white",
                    borderRadius: 3,
                    ...props.style,
                  }}
                >
                  تم نسخ الرابط
                </div>
              )}
            </Overlay>
          </Container>

          {/*          */}
        </div>
        <span className="offerDetails">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Form.Text className="ttiittllee">{DetailData.title}</Form.Text>
              </Row>
              <span className="rating">
                <span style={{ direction: "ltr" }}>
                  {DetailData.rating == 0
                    ? countRatingZero
                    : DetailData.rating == 1
                    ? countRatingOne
                    : DetailData.rating == 2
                    ? countRatingTow
                    : DetailData.rating == 3
                    ? countRatingThree
                    : DetailData.rating == 4
                    ? countRatingFourth
                    : DetailData.rating == 5
                    ? countRatingFifth
                    : "invalid Rating"}
                </span>
                <span>
                  {" "}
                  <h6 style={{ margin: "10px 0px" }}>
                    {" "}
                    ({DetailData.rating_count} زائر ){" "}
                  </h6>{" "}
                </span>
              </span>
              <Row>
                <Form.Text className=" fs-3">
                  السعر : {DetailData.price}
                </Form.Text>
              </Row>
              <Row>
                <Form.Text
                  className=" fs-6"
                  style={{
                    width: "27vw",
                    fontSize: "1.4vw",
                    marginTop: "11px",
                  }}
                >
                  {DetailData.description}
                </Form.Text>
              </Row>
              <Row>
                <Form.Text style={{ marginTop: "15px" }}>
                  رقم التواصل : <span dir="ltr">{userData.phone_number}</span>
                </Form.Text>
              </Row>
              <span
                className="line"
                style={{ margin: "30px 0px 1px 0px" }}
              ></span>
              <span className="userInfo">
                <ul>
                  <li>
                    {DetailData.from} {clockIcon}{" "}
                  </li>
                  <li
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      if (authInfo.user?.id == userData.id) {
                        navigate("/statics");
                      } else navigate(`/profile/${userData.id}`);
                    }}
                  >
                    {userData.username}{" "}
                  </li>
                  <li
                    onClick={() => {
                      if (authInfo.user?.id == userData.id) {
                        navigate("/statics");
                      } else navigate(`/profile/${userData.id}`);
                    }}
                  >
                    {" "}
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "7px",
                      }}
                      src={userData.full_path_image}
                      alt=""
                      width={20}
                    />{" "}
                  </li>
                </ul>
              </span>
              <span className="line"></span>
              <span className="types">
                {tags.map((item) => {
                  return (
                    <Button
                      key={item.id}
                      onClick={() => {
                        const updatedFilterData = {
                          ...filterData,
                          tag: item.name,
                        };
                        changeFilterData(updatedFilterData);
                      }}
                    >
                      {" "}
                      {item.name}{" "}
                    </Button>
                  );
                })}
              </span>
            </Form.Group>
          </Form>
        </span>
      </div>
      <br />
      <Detailsofferandcomment type="comment" data={DetailDataComments} />
      <Container>
        <OfferChoice type="comment" id={params.id} />
      </Container>
      <Addnew type="sell" />
      <Category data={DetailData.similar_ads} checkCategory="daw" />
      <Footer />
    </div>
  );
};

export default OfferDetail;
