import React from "react";
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import EditPurchaseADForm from "../EditPurchaseADForm/EditPurchaseADForm";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./EditPurchaseAD.module.css";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";

const EditPurchaseAD = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    return (
        <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
            <Form.Label
                style={{
                    display: "flex",
                    direction: "rtl",
                    alignItems: "flex-start",
                    marginRight: "25px",
                    marginTop: "16px",
                }}
            >
                <Image style={{ marginTop: "-2px" }} src={Home} />
                <h4
                    style={{
                        color: "#1D3557",
                        fontSize: "18px",
                        marginRight: "5px",
                    }}
                >
                    الإعلانات قيد الإنتظار / تعديل إعلان ساعة يد بيضاء
                </h4>
            </Form.Label>
            <div
                style={{
                    height: "90vh",
                    display: "grid",
                    placeItems: "center",
                    marginRight: "17px",
                    marginLeft: "93px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                }}
            >
                <Container sx={{ my: { xs: 3, sm: 1 } }}>
                    <Box
                        dir="rtl"
                        style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "white",
                            padding: "32px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 25px 0px #41404033",
                            marginTop: "-9px",
                        }}
                        className={classes.ContainerBoxAdmin}
                        sx={{
                            padding: 2,
                            width: { xs: 330, sm: 600 },
                            height: { xs: 550, sm: 700 },
                        }}
                    >
                        <IconButton onClick={() => navigate(`/active-ads/${id}`)}>
                            <East />
                        </IconButton>
                        <Typography
                            sx={{
                                color: "#1D3557",
                                fontWeight: "800",
                            }}
                            variant="h5"
                        >
                            تعديل إعلان شراء جديد
                        </Typography>
                        <EditPurchaseADForm />
                    </Box>
                </Container>
            </div>
        </div>
    );
};

export default EditPurchaseAD;
