// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Form, Overlay, Row } from "react-bootstrap";
import "./AddsBuy.css";
import location from "../../../../assest/images/Productdetailsbuy/categ/location.svg";
import clock from "../../../../assest/images/Productdetailsbuy/categ/clock.svg";
import user from "../../../../assest/images/Productdetailsbuy/categ/user.svg";
import facee from "../../../../assest/images/Productdetailsbuy/categ/facee.svg";
import contact from "../../../../assest/images/Productdetailsbuy/categ/contact.svg";
import message from "../../../../assest/images/Productdetailsbuy/categ/message.svg";
import save from "../../../../assest/images/Productdetailsbuy/categ/save.svg";
import share from "../../../../assest/images/Productdetailsbuy/categ/share.svg";
import flag from "../../../../assest/images/Productdetailsbuy/categ/flag.svg";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import Snack from "components/Utilities/Snack";
import { filterDataContext } from "store/FilterDataContext";

export const AddsBuy = (props) => {
    const {filterData , changeFilterData} =useContext(filterDataContext);
    const navigate = useNavigate();
    const params = useParams();
    const { authInfo } = useContext(AuthContext);
    const userlLocation = useLocation();
    const [showCopied, setShowCopied] = useState(false);
    const [isLoggedIn, setisLoggedIn] = useState(0);
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });

    const target = useRef(null);
    // let Price= props.detail.price
    // var lowPrice=Price.split('-')
    const whatsapp = (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="whatsapp">
                <path
                    id="Vector"
                    d="M17.1 14.0001C16.9 13.9001 15.6 13.3001 15.4 13.2001C15.2 13.1001 15 13.1001 14.8 13.3001C14.6 13.5001 14.2 14.1001 14 14.3001C13.9 14.5001 13.7 14.5001 13.5 14.4001C12.8 14.1001 12.1 13.7001 11.5 13.2001C11 12.7001 10.5 12.1001 10.1 11.5001C10 11.3001 10.1 11.1001 10.2 11.0001C10.3 10.9001 10.4 10.7001 10.6 10.6001C10.7 10.5001 10.8 10.3001 10.8 10.2001C10.9 10.1001 10.9 9.9001 10.8 9.8001C10.7 9.7001 10.2 8.5001 10 8.0001C9.9 7.3001 9.7 7.3001 9.5 7.3001H9C8.8 7.3001 8.5 7.5001 8.4 7.6001C7.8 8.2001 7.5 8.9001 7.5 9.7001C7.6 10.6001 7.9 11.5001 8.5 12.3001C9.6 13.9001 11 15.2001 12.7 16.0001C13.2 16.2001 13.6 16.4001 14.1 16.5001C14.6 16.7001 15.1 16.7001 15.7 16.6001C16.4 16.5001 17 16.0001 17.4 15.4001C17.6 15.0001 17.6 14.6001 17.5 14.2001L17.1 14.0001ZM19.6 4.9001C15.7 1.0001 9.4 1.0001 5.5 4.9001C2.3 8.1001 1.7 13.0001 3.9 16.9001L2.5 22.0001L7.8 20.6001C9.3 21.4001 10.9 21.8001 12.5 21.8001C18 21.8001 22.4 17.4001 22.4 11.9001C22.5 9.3001 21.4 6.8001 19.6 4.9001ZM16.9 18.9001C15.6 19.7001 14.1 20.2001 12.5 20.2001C11 20.2001 9.6 19.8001 8.3 19.1001L8 18.9001L4.9 19.7001L5.7 16.7001L5.5 16.4001C3.1 12.4001 4.3 7.4001 8.2 4.9001C12.1 2.4001 17.1 3.7001 19.5 7.5001C21.9 11.4001 20.8 16.5001 16.9 18.9001Z"
                    fill="#F1FAEE"
                />
            </g>
        </svg>
    );
    const chatIcon = (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="message-circle">
                <path
                    id="Vector"
                    d="M21.5 11.5C21.5034 12.8199 21.1951 14.1219 20.6 15.3C19.8944 16.7118 18.8098 17.8992 17.4674 18.7293C16.1251 19.5594 14.5782 19.9994 13 20C11.6801 20.0035 10.3781 19.6951 9.2 19.1L3.5 21L5.4 15.3C4.80493 14.1219 4.49656 12.8199 4.5 11.5C4.50061 9.92179 4.94061 8.37488 5.77072 7.03258C6.60083 5.69028 7.78825 4.6056 9.2 3.90003C10.3781 3.30496 11.6801 2.99659 13 3.00003H13.5C15.5843 3.11502 17.553 3.99479 19.0291 5.47089C20.5052 6.94699 21.385 8.91568 21.5 11V11.5Z"
                    stroke="#457B9D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
        </svg>
    );
    const copyText = async () => {
        try {
            // Copy the text to the clipboard
            await navigator.clipboard.writeText(
                "https://www.whitesooq.online/#" + userlLocation.pathname
            );
            setShowCopied(!showCopied);
            setTimeout(() => {
                setShowCopied(false);
            }, 2000);
        } catch (err) {
            // Handle the error
            console.error("Failed to copy: ", err);
        }
        incrementShare()
    };
    const AddTofavoritee = () => {
        axios
            .post(
                `https://api.whitesooq.com/api/v1/favorites/${params.id}`,
                "",
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        authorization: "Bearer " + authInfo.token,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);
                setAlert({
                    open: true,
                    type: "success",
                    message: "تمَّت العملية بنجاح",
                });
            })
            .catch((Error) => {
                console.log(Error);
                if (Error.response.status === 401) {
                    setAlert({
                        open: true,
                        type: "error",
                        message: "يرجى التاكد من تسجيل الدخول",
                    });
                }
            });
    };
    const incrementMessages = () => {
        axios
            .put(
                `https://api.whitesooq.com/api/v1/increment-messages-count/${params.id}`,
                "",
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        authorization: "Bearer " + authInfo.token,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);
                // setAlert({
                //     open: true,
                //     type: "success",
                //     message: "تمَّت العملية بنجاح",
                // });
            })
            .catch((Error) => {
                console.log(Error);
                if (Error.response.status === 401) {
                    setAlert({
                        open: true,
                        type: "error",
                        message:
                            "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
                    });
                }
            });
    };
    const incrementWhatsapp = () => {
        axios
            .put(
                `https://api.whitesooq.com/api/v1/increment-whatsapp-count/${params.id}`,
                "",
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        authorization: "Bearer " + authInfo.token,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);
                // setAlert({
                //     open: true,
                //     type: "success",
                //     message: "تمَّت العملية بنجاح",
                // });
            })
            .catch((Error) => {
                console.log(Error);
                if (Error.response.status === 401) {
                    setAlert({
                        open: true,
                        type: "error",
                        message:
                            "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
                    });
                }
            });
    };
    const incrementCall = () => {
        axios
            .put(
                `https://api.whitesooq.com/api/v1/increment-call-count/${params.id}`,
                "",
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        authorization: "Bearer " + authInfo.token,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);
                // setAlert({
                //     open: true,
                //     type: "success",
                //     message: "تمَّت العملية بنجاح",
                // });
            })
            .catch((Error) => {
                console.log(Error);
                if (Error.response.status === 401) {
                    setAlert({
                        open: true,
                        type: "error",
                        message:
                            "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
                    });
                }
            });
    };
    const incrementShare = () => {
        axios
            .put(
                `https://api.whitesooq.com/api/v1/increment-share-count/${params.id}`,
                "",
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        authorization: "Bearer " + authInfo.token,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);
                // setAlert({
                //     open: true,
                //     type: "success",
                //     message: "تمَّت العملية بنجاح",
                // });
            })
            .catch((Error) => {
                console.log(Error);
                if (Error.response.status === 401) {
                    setAlert({
                        open: true,
                        type: "error",
                        message:
                            "يرجى التاكد من تسجيل الدخول، ومن ثمَّ المحاولة مرة أخرى",
                    });
                }
            });
    };
    return (
        <div
            className="AddsBuy"
            onLoad={() => {
                setisLoggedIn(Object.keys(authInfo).length);
            }}
        >
            <Snack alert={alert}></Snack>
            <Container style={{ padding: "16px 32px" }}>
                <div className="row row1">
                    <div className="col-lg-6 col-md-12 col-sm-12  logobuy">
                        <div className="traingle">
                            {" "}
                            <h3
                                style={{
                                    marginRight: "-17px",
                                    marginTop: "-35px",
                                }}
                            >
                                إعلان شراء
                                <div
                                    style={{
                                        width: "59px",
                                        height: "1px",
                                        marginTop: "10px",
                                        backgroundColor: "#F1FAEE",
                                        marginRight: "22px",
                                        marginLeft: "23px",
                                    }}
                                ></div>
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 detailspro">
                        <h2>{props?.detail?.title}</h2>
                        <h3> {props?.detail?.description}</h3>
                    </div>
                    <Row>
                        <Form.Text
                        className="connecct"
                            style={{
                                marginTop: "15px",
                                direction: "rtl",
                                fontWeight: "700",
                            }}
                        >
                            رقم التواصل :{" "}
                            <span dir="ltr">{props?.detail?.phone_number}</span>
                        </Form.Text>
                    </Row>
                </div>
                <div className="row row2">
                    <div className="col-lg-6 col-md-0 col-sm-0"></div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="div11 d-flex">
                            {/* <h3>السعر الأعلى: أ.د {lowPrice[1]} </h3>
              <h3> السعر الأدنى:أ.د {lowPrice[0]}</h3> */}
                        </div>
                        <div className=" div22 d-flex">
                            <span>
                                {props?.detail?.from}{" "}
                                <img src={clock} alt="image1" />
                            </span>
                            <span
                                onClick={() =>
                                    navigate(`/profile/${props?.user?.id}`)
                                }
                            >
                                {props?.user?.username}{" "}
                                <img
                                    style={{ width: "20px" }}
                                    src={props?.user?.full_path_image}
                                    alt="image1"
                                />
                            </span>
                        </div>
                        <Container className="div33 d-flex">
                            <Button
                                disabled={isLoggedIn === 0}
                                onClick={incrementWhatsapp}
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://wa.me/${props?.user?.phone_number}?text=https://www.whitesooq.online%2F%23${userlLocation.pathname}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                >
                                    واتساب {whatsapp}
                                </a>
                            </Button>
                            <Button
                                disabled={isLoggedIn === 0}
                                onClick={incrementMessages}
                                style={{
                                    backgroundColor: "#F1FAEE",
                                    color: "#457B9D",
                                }}
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`sms:${props?.user?.phone_number}`}
                                    style={{
                                        backgroundColor: "#F1FAEE",
                                        color: "#457B9D",
                                        textDecoration: "none",
                                    }}
                                >
                                    مراسلة {chatIcon}
                                </a>
                            </Button>
                            <Button disabled={isLoggedIn === 0} onClick={incrementCall}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`tel:${props?.user?.phone_number}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "white",
                                    }}
                                >
                                    اتصال
                                </a>
                                <img src={contact} alt="img" />
                            </Button>
                        </Container>
                        <Container className="div33 d-flex">
                            <Button
                                disabled={isLoggedIn === 0}
                                style={{
                                    border: "1px solid var(--3, #1D3557)",
                                    background: " white ",
                                    color: "#1D3557",
                                }}
                            >
                                <Link
                                    to={`/contact/with-admin/ad/${params.id}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "#1D3557",
                                    }}
                                >
                                    بلغ عن إساءة <img src={flag} alt="img" />
                                </Link>
                            </Button>
                            <Button
                                ref={target}
                                onClick={copyText}
                                style={{
                                    border: "1px solid var(--3, #1D3557)",
                                    background: " white ",
                                    color: "#1D3557",
                                }}
                            >
                                شارك الإعلان <img src={share} alt="img" />
                            </Button>
                            <Button
                                onClick={AddTofavoritee}
                                style={{
                                    border: "1px solid var(--3, #1D3557)",
                                    background: " white ",
                                    color: "#1D3557",
                                }}
                            >
                                احفظ الإعلان <img src={save} alt="img" />
                            </Button>
                        </Container>
                        <Overlay
                            target={target.current}
                            show={showCopied}
                            placement="bottom"
                        >
                            {({
                                placement: _placement,
                                arrowProps: _arrowProps,
                                show: _show,
                                popper: _popper,
                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                ...props
                            }) => (
                                <div
                                    {...props}
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "var(--3, #1D3557)",
                                        padding: "2px 10px",
                                        color: "white",
                                        borderRadius: 3,
                                        ...props.style,
                                    }}
                                >
                                    تم نسخ الرابط
                                </div>
                            )}
                        </Overlay>

                        <div className="btnn d-flex">
                            {props.tags.map((tag) => {
                                return (
                                    <button
                                        key={tag.id}
                                        onClick={() =>
                                            {
                                                const updatedFilterData = { ...filterData, tag: tag.name };
                                                changeFilterData(updatedFilterData);
                                            }
                                        }
                                    >
                                        {tag.name}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
