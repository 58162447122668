// @ts-nocheck
import React, {useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navigation.css";
import chervon from "../../../../assest/images/Landingpagevisitor/Navigation/chevron-down.svg";
import ListItem from "components/User/Ads/ListItem/ListItem/ListItem";
import { useNavigate } from "react-router-dom";
import NestedListNavBar from "components/Utilities/NestedListNavBar";
export const Navigation = (props) => {
  const navigate=useNavigate()
  const [showList, setshowList] = useState(false)

  return (
    <div className="navigation">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll>
              {props?.data?.map((item) => {
              return (
                <Nav.Link href="" key={item.id} onClick={()=>navigate(`/category/${item.id}`)}> {item.name} </Nav.Link> 
              );
              })}
            </Nav>
          </Navbar.Collapse>

          <NestedListNavBar category={props.listData}/>
        </Container>
      </Navbar>
    </div>
  );
};
