import { useEffect, useContext } from "react";
import { useState } from "react";
import {
    Form,
    Button,
    Container,
    Row,
    Col,
    Card,
    Image,
    Modal,
} from "react-bootstrap";
// @ts-ignore
import Home from "./Home.svg";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import Arrow from "./Arrow.svg";
// @ts-ignore
import Upload from "./Upload.svg";
// @ts-ignore
import Delete from "./Delete.svg";
// @ts-ignore
import Eye from "./Eye.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import NestedList from "components/Utilities/NestedList";
import Snack from "components/Utilities/Snack";
import './AddCategory.css'

const AddCategory = () => {
    const [category, setCategory] = useState({
        name: "أساسية",
        id: "",
    });
    const handleCategory = (newCategory) => {
        setCategory(newCategory);
    };
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });
    // @ts-ignore
    const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
    const navigate = useNavigate();
    const [categories, setCategories] = useState();
    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");
    const [status, setStatus] = useState("active");
    const [image, setImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const uploadedImages = files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })
        );
        setImage(uploadedImages);
    };
    // console.log(categories);
    const handleDeleteImage = (index) => {
        setImage([]);
    };

    const photoHuge = (photo) => {
        setSelectedImage(photo);
        setShowModal(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(image);
        const formData = new FormData();
        formData.append("name", categoryName);
        formData.append("description", categoryDescription);
        if (category.name !== "أساسية" || category.id !== "")
            formData.append("parent_id", category.id);
        formData.append("status", status);
        image?.forEach((img) => {
            formData.append("image", img);
        });
        // console.log(Object.fromEntries(formData));

        try {
            const response = await axios.post(
                `https://api.whitesooq.com/api/v1/categories`,
                formData,
                {
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${authInfo.token}`,
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                    },
                }
            );
            // console.log(response);
            if (response?.data?.status === 1) {
                setAlert({
                    open: true,
                    type: "success",
                    message: "تمَّ الأضافة بنجاح",
                });
            } else if (response?.data?.status === 0) {
                // console.log(1);
                setAlert({
                    open: true,
                    type: "warning",
                    message: response?.data?.message,
                });
            }
        } catch (err) {
            if (err.request.status === 403) {
                setAlert({
                    open: true,
                    type: "warning",
                    message: "عذراً ليس لديك صلاحية",
                });
            } else if (err?.response?.status === 401) {
                ChangeAuthInfo({});
                setAlert({
                    open: true,
                    type: "error",
                    message: "انتهت صلاحية تسجيل الدخول",
                });
                navigate("/");
            } else if (err?.response?.status === 422) {
                setAlert({
                    open: true,
                    type: "error",
                    message: err?.response?.data?.message,
                });
            } else if (err?.message === "Network Error") {
                setAlert({
                    open: true,
                    type: "warning",
                    message: "مشكلة في الشبكة",
                });
            } else {
                setAlert({
                    open: true,
                    type: "warning",
                    message: "الخادم لا يستجيب",
                });
            }
            // console.log(err);
        }
    };
    useEffect(() => {
        const getAPI = async () => {
            try {
                // @ts-ignore
                const response = await axios.get(
                    `https://api.whitesooq.com/api/v1/get-categories-tags`,
                    {
                        headers: {
                            accept: "application/json",
                            Authorization: `Bearer ${authInfo.token}`,
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        },
                    }
                );
                // console.log(response);
                if (response?.data?.categories) {
                    setCategories(response.data.categories);
                }
            } catch (err) {
                if (err?.response?.status === 401) {
                    navigate("/");
                }
                // console.log(err);
            }
        };
        getAPI();
    }, []);
    return (
        <>
            <Snack alert={alert} />
            <div
                className="cat-container"
                style={{ display: "grid", backgroundColor: "#f1f1f1" }}
            >
                <Form.Label
                    style={{
                        display: "flex",
                        direction: "rtl",
                        alignItems: "flex-start",
                        marginRight: "10px",
                        marginTop: "16px",
                    }}
                >
                    <Image style={{ marginTop: "-2px" }} src={Home} />
                    <Link
                    to={"/nonDeletedData-page"}
                        style={{
                            color: "#1D3557",
                            fontSize: "18px",
                            marginRight: "5px",
                            fontFamily:"inherit"
                        }}
                    >
                        الفئات غير المحذوفة
                    </Link>
                </Form.Label>
                <div
                    style={{
                        display: "grid",
                        placeItems: "center",
                        marginRight: "17px",
                        marginLeft: "93px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "8px",
                    }}
                >
                    <Form
                        dir="rtl"
                        style={{
                            width: "95%",
                            backgroundColor: "white",
                            padding: "32px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 25px 0px #41404033",
                            marginTop: "73px",
                        }}
                    >
                    <Link style={{textDecoration:'none'}} to='/nonDeletedData-page'>
                        <Col style={{ marginRight: "-15px" }}>
                            <Button
                                onClick={() => navigate("/")}
                                style={{
                                    backgroundColor: "white",
                                    border: "1px solid white",
                                }}
                            >
                                <Image src={Arrow} />
                            </Button>
                        </Col>
                    </Link>
                        <Form.Label>
                            <h2
                                style={{
                                    color: "#1D3557",
                                    fontSize: "35px",
                                    marginTop: "16px",
                                }}
                            >
                                إضافة فئة
                            </h2>
                        </Form.Label>
                        <Form.Group controlId="categoryName">
                            <Form.Label
                                style={{
                                    color: "#1D3557",
                                    fontSize: "18px",
                                    marginTop: "16px",
                                }}
                            >
                                اسم الفئة <i style={{ color: "red" }}>*</i>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={categoryName}
                                placeholder="خدمات"
                                onChange={(e) =>
                                    setCategoryName(e.target.value)
                                }
                                required
                                style={{ border: "1px solid #457B9D" }}
                            />
                        </Form.Group>
                        <Form.Group controlId="categoryDescription">
                            <Form.Label
                                style={{
                                    color: "#1D3557",
                                    fontSize: "18px",
                                    marginTop: "16px",
                                }}
                            >
                                وصف الفئة <i style={{ color: "red" }}>*</i>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={categoryDescription}
                                placeholder="فئة خدمية تساعد المستخدمين"
                                onChange={(e) =>
                                    setCategoryDescription(e.target.value)
                                }
                                required
                                style={{ border: "1px solid #457B9D" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="serviceType">
                            <Form.Label
                                style={{
                                    color: "#1D3557",
                                    fontSize: "18px",
                                    marginTop: "16px",
                                }}
                            >
                                {" "}
                                نوع الفئة <i style={{ color: "red" }}>*</i>
                            </Form.Label>
                            <NestedList
                                category={category}
                                handleCategory={handleCategory}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="categoryStatus">
                            <Form.Label
                                style={{
                                    color: "#1D3557",
                                    fontSize: "18px",
                                    marginTop: "16px",
                                }}
                            >
                                {" "}
                                حالة الفئة <i style={{ color: "red" }}>*</i>
                            </Form.Label>
                            <Form.Select
                                name="status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                required
                                style={{
                                    border: "1px solid #457B9D",
                                    height: "57px",
                                    borderRadius: "9px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    fontSize: "15px",
                                }}
                            >
                                <option value="active">فعالة</option>
                                <option value="inactive">غير فعالة</option>
                                <option value="archived">المؤرشفة</option>
                                {status === "" && (
                                    <p
                                        style={{
                                            color: "#E63946",
                                            fontSize: "10px",
                                            marginTop: "0",
                                        }}
                                    >
                                        الرجاء الاختيار{" "}
                                    </p>
                                )}
                            </Form.Select>
                        </Form.Group>
                        <Container>
                            <Row>
                                <Form.Group>
                                    <Form.Label
                                        style={{
                                            width: "237px",
                                            height: "54px",
                                            borderRadius: "10px",
                                            padding: "10px 32px",
                                            border: "1px solid #457B9D",
                                            marginRight: "-12px",
                                            marginTop: "16px",
                                            backgroundColor: "#FFFFFF",
                                            color: "#1D3557",
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#1D3557",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            إضافة ألبوم صور{" "}
                                        </span>
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            multiple
                                            onChange={handleImageUpload}
                                            style={{ display: "none" }}
                                        />
                                        <Image src={Upload} />
                                    </Form.Label>
                                </Form.Group>
                            </Row>
                            <Row>
                                {image?.map((photo, index) => (
                                    <Col key={index} xs={6} md={3}>
                                        <Card
                                            style={{
                                                marginTop: "15px",
                                                marginLeft: "15px",
                                                border: "1px solid white",
                                                marginRight: "-16px",
                                            }}
                                        >
                                            <Card.Img
                                                onClick={() => photoHuge(photo)}
                                                src={photo.preview}
                                                alt={photo.name}
                                                style={{ height: "140px" }}
                                            />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        marginRight: "-3px",
                                                        backgroundColor:
                                                            "white",
                                                        border: "none",
                                                    }}
                                                    onClick={() =>
                                                        handleDeleteImage(index)
                                                    }
                                                >
                                                    <Image
                                                        src={Delete}
                                                        alt="Delete"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Button>
                                                <Button
                                                    style={{
                                                        marginRight: "3px",
                                                        backgroundColor:
                                                            "white",
                                                        border: "none",
                                                    }}
                                                    onClick={() =>
                                                        photoHuge(index)
                                                    }
                                                >
                                                    <Image
                                                        src={Eye}
                                                        alt="Preview"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Button>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <Modal
                                show={showModal}
                                onHide={() => setShowModal(false)}
                                centered
                            >
                                <Modal.Body className="d-flex flex-column align-items-center">
                                    <Image
                                        src={selectedImage}
                                        style={{
                                            width: "500px",
                                            height: "500px",
                                        }}
                                    />
                                    <Button
                                        onClick={() => setShowModal(false)}
                                        style={{ marginTop: "10px" }}
                                    >
                                        إغلاق
                                    </Button>
                                </Modal.Body>
                            </Modal>
                        </Container>
                        <Col style={{ direction: "ltr" }}>
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                style={{
                                    border: "2px solid #457B9D",
                                    backgroundColor: "#457B9D",
                                    height: "60px",
                                    borderRadius: "10px",
                                    marginTop: "16px",
                                    marginBottom: "30px",
                                    cursor: "pointer",
                                    width: "290px",
                                    boxShadow: "0px 4px 19px 0px #7793414D",
                                }}
                            >
                                <Link
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: "22px",
                                        textDecoration: "none",
                                    }}
                                    to=""
                                >
                                    إضافة
                                </Link>
                            </Button>
                        </Col>
                    </Form>
                </div>
            </div>
        </>
    );
};
export default AddCategory;
