// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import "./offerDetailDash.css";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Button,
  FormGroup,
  FormLabel,
  FormSelect,
  FormText,
} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import Dots from "../View/Dots.svg";
import { Avatar, Box, IconButton } from "@mui/material";
import classes from "../View/ViewOffer.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slide from "@mui/material/Slide";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import Snack from "components/Utilities/Snack";
import { East } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OfferDetailDash = (props) => {
  const { authInfo } = useContext(AuthContext);
  console.log(authInfo);
  const [DetailData, setDetailData] = useState([]);
  const [images, setimages] = useState([]);
  const { id } = useParams();
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  useEffect(() => {
    axios
      .get(`https://api.whitesooq.com/api/v1/ads/${id}`, {
        headers: {
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          Authorization: `Bearer ${authInfo.token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setDetailData(response.data.data);
        setimages(response.data.data.all_images);
      })
      .catch((Error) => {
        console.log(Error);
        if (Error.request.status === 403) {
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        } else if (Error?.response?.status === 401) {
          setAlert({
            open: true,
            type: "error",
            message: "انتهت صلاحية تسجيل الدخول",
          });
        } else if (Error?.response?.status === 422) {
          setAlert({
            open: true,
            type: "error",
            message: Error?.response?.data?.message,
          });
        } else if (Error?.message === "Network Error") {
          setAlert({
            open: true,
            type: "warning",
            message: "مشكلة في الشبكة",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: "الخادم لا يستجيب",
          });
        }
      });
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChangee = (event) => {
    setOption(event.target.value);
  };
  const [offerDetailDeleted, setOfferDetailDeleted] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleDetailDeleted = () => {
    fetch(`https://api.whitesooq.com/api/v1/ads/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
      body: {},
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.status) {
          setOfferDetailDeleted(false);
          setOpen(false);
          navigate("/ViewActiveAds-page");
        }
      })
      .catch((error) => setError(error.message));
  };
  const [acceptDash, setAcceptDash] = useState(false);
  const [rejectDash, setRejectDash] = useState(false);
  const handleReject = () => {
    fetch(`https://api.whitesooq.com/api/v1/ads/unaccept/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
      body: {},
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });

        response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.status) {
          setRejectDash(true);
          setOpen(false);
          navigate("/ViewActiveAds-page");
        } else {
          setError("عذراّ حدث خطأ حاول مرة أخرى");
        }
      })
      .catch((error) => setError(error.message));
  };
  const handleAccept = () => {
    fetch(`https://api.whitesooq.com/api/v1/ads/accept/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
        else if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        console.log(data);
        if (data.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ قبول الإعلان ",
          });
          setAcceptDash(true);
          setTimeout(() => {
            navigate("/ViewActiveAds-page");
          }, 750);
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: data.message,
          });
        }
      })
      .catch((error) => {
        setAlert({
          open: true,
          type: "warning",
          message: "هناك مشكلة في عملية القبول",
        });
      });
  };
  // console.log(error);
  const ActiveAds =
    DetailData.status === "pending" ? (
      <>
        <div>
          <Box className={classes.menuOption}>
            <FormControl style={{ marginRight: "70px" }} sx={{ m: 1 }}>
              <Select
                IconComponent={ExpandMore}
                value={option}
                onChange={handleChangee}
                displayEmpty
                autoWidth
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem
                  onClick={handleClickOpen}
                  value={"قبول"}
                  sx={{ pl: 6 }}
                >
                  قبول
                </MenuItem>
                <MenuItem
                  onClick={handleClickOpen}
                  value={"رفض"}
                  sx={{ pl: 6 }}
                >
                  رفض
                </MenuItem>
              </Select>
            </FormControl>
            {(!acceptDash || !rejectDash) && (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                sx={{ textAlign: "center" }}
                aria-describedby="alert-dialog-slide-description"
              >
                {option === "رفض" ? (
                  <Close
                    sx={{
                      color: "red",
                      m: "20px auto 0",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                ) : (
                  <Check
                    sx={{
                      color: "green",
                      m: "20px auto 0",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                )}
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    m: "0 auto",
                    p: "0 40px",
                    color: "#457B9D",
                    fontWeight: 700,
                  }}
                >
                  {`هل أنت متأكد من ${option} هذا الإعلان ؟`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    sx={{ m: 0, p: 0 }}
                    id="alert-dialog-slide-description"
                  ></DialogContentText>
                </DialogContent>
                <DialogActions sx={{ m: "0 auto" }}>
                  <button
                    className={classes.btnCancelDialog}
                    onClick={handleClose}
                  >
                    إلغاء
                  </button>
                  <button
                    className={classes.btnOkDialog}
                    onClick={option === "رفض" ? handleReject : handleAccept}
                  >
                    متأكد
                  </button>
                </DialogActions>
              </Dialog>
            )}
            {error && (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                sx={{ textAlign: "center" }}
                aria-describedby="alert-dialog-slide-description"
              >
                <WarningAmber
                  sx={{
                    color: "#f9a703",
                    m: "5px auto",
                    width: "80px",
                    height: "80px",
                  }}
                />
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    m: "0 auto",
                    p: "10px 60px",
                    color: "#457B9D",
                    fontWeight: 700,
                  }}
                >
                  {error}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    sx={{ m: 0, p: 0 }}
                    id="alert-dialog-slide-description"
                  >
                    <Close sx={{ color: "red" }} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ m: "0 auto" }}>
                  <button className={classes.btnOkDialog} onClick={handleClose}>
                    موافق
                  </button>
                </DialogActions>
              </Dialog>
            )}
            {(acceptDash || rejectDash) && (
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                sx={{ textAlign: "center" }}
                aria-describedby="alert-dialog-slide-description"
              >
                <WarningAmber
                  sx={{
                    color: "#f9a703",
                    m: "0 auto",
                    width: "80px",
                    height: "80px",
                  }}
                />
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    m: "0 auto",
                    p: "0 20px",
                    color: "#457B9D",
                    fontWeight: 700,
                  }}
                >
                  {`تم ${option} الإعلان؟ بنجاح`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    sx={{ m: 0, p: 0 }}
                    id="alert-dialog-slide-description"
                  >
                    <Check sx={{ color: "red" }} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ m: "0 auto" }}>
                  <button className={classes.btnOkDialog} onClick={handleClose}>
                    موافق
                  </button>
                </DialogActions>
              </Dialog>
            )}
          </Box>
        </div>
      </>
    ) : (
      <div>
        <Box className={classes.menuOption}>
          <FormControl sx={{ m: 1 }} style={{ marginRight: "70px" }}>
            <Select
              IconComponent={ExpandMore}
              value={option}
              onChange={handleChangee}
              displayEmpty
              autoWidth
            >
              <MenuItem onClick={handleClickOpen} value={"حذف"} sx={{ pl: 6 }}>
                حذف
              </MenuItem>
              <MenuItem
                onClick={() =>
                  // navigate(`/editpurchase-page/${id}`)
                  navigate(
                    `${
                      DetailData.type === "buy"
                        ? `/editpurchase-page/${id}`
                        : `/editsale-page/${id}`
                    }`
                  )
                }
                value={"تعديل"}
                sx={{ pl: 6 }}
              >
                تعديل
              </MenuItem>
            </Select>
          </FormControl>
          {!offerDetailDeleted && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              {option === "حذف" ? (
                <Check
                  sx={{
                    color: "green",
                    m: "20px auto 0",
                    width: "80px",
                    height: "80px",
                  }}
                />
              ) : (
                ""
              )}
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 50px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {`هل أنت متأكد من حذف هذا الإعلان ؟`}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ m: 0, p: 0 }}
                  id="alert-dialog-slide-description"
                ></DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button
                  className={classes.btnCancelDialog}
                  onClick={handleClose}
                >
                  إلغاء
                </button>
                <button
                  className={classes.btnOkDialog}
                  onClick={handleDetailDeleted}
                >
                  متأكد
                </button>
              </DialogActions>
            </Dialog>
          )}
          {error && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "5px auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "10px 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {error}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ m: 0, p: 0 }}
                  id="alert-dialog-slide-description"
                >
                  <Close sx={{ color: "red" }} />
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button className={classes.btnOkDialog} onClick={handleClose}>
                  موافق
                </button>
              </DialogActions>
            </Dialog>
          )}
          {offerDetailDeleted && (
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              sx={{ textAlign: "center" }}
              aria-describedby="alert-dialog-slide-description"
            >
              <WarningAmber
                sx={{
                  color: "#f9a703",
                  m: "0 auto",
                  width: "80px",
                  height: "80px",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center",
                  m: "0 auto",
                  p: "0 20px",
                  color: "#457B9D",
                  fontWeight: 700,
                }}
              >
                {`تم ${option} الإعلان؟ بنجاح`}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ m: 0, p: 0 }}
                  id="alert-dialog-slide-description"
                >
                  <Check sx={{ color: "red" }} />
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ m: "0 auto" }}>
                <button className={classes.btnOkDialog} onClick={handleClose}>
                  موافق
                </button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </div>
    );

  return (
    <div className="mainDashPage">
      <Snack alert={alert} />
      <p>
        {" "}
        {linkIcon}{" "}
        {DetailData.status === "pending" ? (
          <Link
            style={{ fontFamily: "inherit", color: "inherit" }}
            to={"/ViewWaitingAds-page"}
          >
            الإعلانات قيد الانتظار
          </Link>
        ) : (
          <Link
            style={{ fontFamily: "inherit", color: "inherit" }}
            to={"/ViewActiveAds-page"}
          >
            الإعلانات النشطة
          </Link>
        )}
        / {DetailData.title}
      </p>
      <div className="offerDetailDashContainer">
        <Form className="offerDetailDashContainerHeader">
          <FormText className="word"> تفاصيل الإعلان </FormText>
          <FormLabel
            className="line"
            style={{
              background: "rgba(251, 133, 0, 0.40)",
              width: "100%",
              height: "1px",
              marginBottom: "36px",
            }}
          ></FormLabel>
        </Form>

        <div className="offerDetail">
        <div style={{direction:'rtl', margin:'10px 40px'}}>
        <IconButton onClick={() => navigate(
          `${
            DetailData.status === "pending"
              ? `/ViewWaitingAds-page`
              : `/ViewActiveAds-page`
          }`
        )}>
            <East />
        </IconButton>
        </div>
          <Form className="offerDetailsDashMenu" style={{ width: "20px" }}>
            {ActiveAds}
          </Form>
          <div className="offerDetailInformationDash">
            {/*      */}
            <Carousel
              data-bs-theme="dark"
              nextIcon={rightArrow}
              prevIcon={leftArrow}
              className="carousel"
            >
              {images.map((image, index) => (
                <Carousel.Item key={index} interval={1000}>
                  <Image src={image} alt={`Slide ${index}`} />
                </Carousel.Item>
              ))}
            </Carousel>

            <Form className="offerDetailsDash">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Row className="offerDetailsDashRow">
                  <Col className="visstorr-stars">
                    <Form.Text>
                      <ul
                        style={{
                          position: "absolute",
                          left: "6%",
                          top: "11%",
                        }}
                        id="responsiveul"
                        className="d-flex"
                      >
                        <li className="visitorr">
                          {" "}
                          <i
                            style={{
                              marginRight: "7px",
                              color: "#00000080",
                              fontSize: "1vw",
                              whiteSpace: "nowrap",
                            }}
                          >
                            ({DetailData?.rating_count} زائر)
                          </i>{" "}
                        </li>
                        {DetailData?.rating == 0
                          ? countRatingZero
                          : DetailData?.rating == 1
                          ? countRatingOne
                          : DetailData?.rating == 2
                          ? countRatingTow
                          : DetailData?.rating == 3
                          ? countRatingThree
                          : DetailData?.rating == 4
                          ? countRatingFourth
                          : DetailData?.rating == 5
                          ? countRatingFifth
                          : "invalid Rating"}
                      </ul>
                    </Form.Text>
                  </Col>
                  <Col>
                    <p
                      id="title"
                      style={{
                        margin: "45px 0px 0px 0px ",
                      }}
                    >
                      {" "}
                      {DetailData.title}{" "}
                    </p>
                  </Col>
                </Row>

                <Row className="offerDetailsDashRow">
                  <Col
                    style={{
                      color: "#00000080",
                      textAlign: "left",
                      marginTop: "3%",
                      marginLeft: "6%",
                    }}
                    className="before-hour"
                  >
                    <i>
                      {" "}
                      قبل {DetailData.from} {clockIcon}
                    </i>
                  </Col>
                  <Col>
                    <p
                      style={{
                        margin: "25px 60px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      السعر : {DetailData.price}
                    </p>
                  </Col>
                </Row>

                <Row
                  className="roww"
                  style={{ direction: "rtl" }}
                  id="responsiveWords"
                >
                  <p
                    className="desc-box"
                    style={{
                      width: "50%",
                      fontWeight: "400",
                      color: "#1D3557",
                      marginBottom: "10px",
                    }}
                  >
                    {DetailData.description}
                  </p>
                </Row>
                <h1 style={{ fontSize: "2vw" }}> معلومات صاحب الإعلان </h1>

                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#A8DADC",
                  }}
                  className="line-blue"
                ></div>

                <div className="ad-info-pers">
                  <Row
                    className="userInfo d-flex"
                    style={{
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                    dir="ltr"
                  >
                    <Col
                      className="iconn-eye"
                      style={{
                        alignItems: "center",
                        marginLeft: "35px",
                        marginTop: "-15px",
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          navigate(`/profile/${DetailData?.user_id}`)
                        }
                      >
                        {eyeIcon}
                      </IconButton>
                    </Col>
                    <Col className="col-user" style={{ direction: "ltr" }}>
                      <ul className="ul-user">
                        <li>
                          <ul
                            className="d-flex"
                            style={{
                              margin: "0px 22px",
                              marginTop: "6px",
                            }}
                          >
                            <li>
                              {" "}
                              <Form.Text
                                style={{
                                  marginRight: "4px",
                                  color: "#00000080",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                ( {DetailData?.user?.rating_count} عدد
                                التقييمات)
                              </Form.Text>{" "}
                            </li>
                            {DetailData?.user?.rating == 0
                              ? countRatingZero
                              : DetailData?.user?.rating == 1
                              ? countRatingOne
                              : DetailData?.user?.rating == 2
                              ? countRatingTow
                              : DetailData?.user?.rating == 3
                              ? countRatingThree
                              : DetailData?.user?.rating == 4
                              ? countRatingFourth
                              : DetailData?.user?.rating == 5
                              ? countRatingFifth
                              : "invalid Rating"}
                          </ul>
                        </li>
                        <span
                          style={{
                            display: "flex",
                            width: "180px",
                            alignItems: "center",
                          }}
                        >
                          <li
                            style={{
                              color: "#00000080",
                              fontSize: "1vw",
                            }}
                          >
                            {DetailData?.user?.fullname} {userNameIcon}{" "}
                          </li>
                          <li
                            style={{
                              margin: "0px 0px 0px 0px",
                            }}
                          >
                            {" "}
                            <Avatar
                              sx={{
                                width: 50,
                                height: 50,
                              }}
                              src={DetailData?.user?.full_path_image}
                              alt={DetailData?.user?.username}
                            />
                            {/* <img className=""
                              src={DetailData?.user?.full_path_image}
                              alt=""
                            />{" "} */}
                          </li>
                        </span>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#A8DADC",
                    marginBottom: "20px",
                  }}
                ></div>
                {/*              */}
                <FormGroup
                  className="types"
                  style={{
                    flexDirection: "row-reverse",
                    whiteSpace: "nowrap",
                    padding: "5px 10px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  {DetailData?.tags?.map((tag) => (
                    <Button
                      style={{
                        borderRadius: "25px",
                        background: "#F2F2F2",
                        color: "rgba(0, 0, 0, 0.70)",
                      }}
                    >
                      {" "}
                      {tag.name}{" "}
                    </Button>
                  ))}
                </FormGroup>
              </Form.Group>
            </Form>
            {/* </span> */}
          </div>
        </div>

        {/* <div className={`box ${show ? "active zIndex " : ""}`}>
          <span className="majd">
            <Alert show={show} id="box">
              <Alert.Heading>
                {action === "حذف"
                  ? warningIcon
                  : action === "قبول"
                  ? acceptIcon
                  : action === "رفض"
                  ? rejectIcon
                  : "majd"}
              </Alert.Heading>
              <p>هل انت متأكد من {action} هذا الإعلان ؟</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShow(false)}>متأكد</Button>
                <Button
                  onClick={() => setShow(false)}
                  style={{
                    border: "1px solid var(--3, #1D3557)",
                    background: "none",
                    color: "#1D3557",
                  }}
                >
                  الغاء
                </Button>
              </div>
            </Alert>
          </span>
        </div> */}

        {/* {!show} */}
      </div>
    </div>
  );
};

export default OfferDetailDash;

const filledStarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
      fill="#FFEE32"
    />
  </svg>
);

const emptyStarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="19"
    viewBox="0 0 11 7"
    fill="none"
  >
    <path
      d="M5.50158 1.45581L6.91741 3.71661L10.0836 4.08138L7.79257 5.84018L8.33325 8.3249L5.50158 7.15116L2.66991 8.3249L3.21058 5.84018L0.919586 4.08138L4.08574 3.71661L5.50158 1.45581Z"
      stroke="#FFEE32"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const linkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
  >
    <path
      d="M3 7.875L12 1.75L21 7.875V17.5C21 17.9641 20.7893 18.4092 20.4142 18.7374C20.0391 19.0656 19.5304 19.25 19 19.25H5C4.46957 19.25 3.96086 19.0656 3.58579 18.7374C3.21071 18.4092 3 17.9641 3 17.5V7.875Z"
      stroke="#FB8500"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 19.25V10.5H15V19.25"
      stroke="#FB8500"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const eyeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M1.25 15C1.25 15 6.25 5 15 5C23.75 5 28.75 15 28.75 15C28.75 15 23.75 25 15 25C6.25 25 1.25 15 1.25 15Z"
      stroke="#457B9D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z"
      stroke="#457B9D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const flagIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M4.5 15C4.5 15 5.5 14 8.5 14C11.5 14 13.5 16 16.5 16C19.5 16 20.5 15 20.5 15V3C20.5 3 19.5 4 16.5 4C13.5 4 11.5 2 8.5 2C5.5 2 4.5 3 4.5 3V15Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 22V15"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const shareIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.59 13.5098L15.42 17.4898"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.41 6.50977L8.59 10.4898"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const favoriteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.84 4.60987C20.3292 4.09888 19.7228 3.69352 19.0554 3.41696C18.3879 3.14039 17.6725 2.99805 16.95 2.99805C16.2275 2.99805 15.5121 3.14039 14.8446 3.41696C14.1772 3.69352 13.5708 4.09888 13.06 4.60987L12 5.66987L10.94 4.60987C9.90831 3.57818 8.50903 2.99858 7.05 2.99858C5.59097 2.99858 4.19169 3.57818 3.16 4.60987C2.12831 5.64156 1.54871 7.04084 1.54871 8.49987C1.54871 9.95891 2.12831 11.3582 3.16 12.3899L4.22 13.4499L12 21.2299L19.78 13.4499L20.84 12.3899C21.351 11.8791 21.7564 11.2727 22.0329 10.6052C22.3095 9.93777 22.4518 9.22236 22.4518 8.49987C22.4518 7.77738 22.3095 7.06198 22.0329 6.39452C21.7564 5.72706 21.351 5.12063 20.84 4.60987Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const chatIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M21.5 11.5C21.5034 12.8199 21.1951 14.1219 20.6 15.3C19.8944 16.7118 18.8098 17.8992 17.4674 18.7293C16.1251 19.5594 14.5782 19.9994 13 20C11.6801 20.0035 10.3781 19.6951 9.2 19.1L3.5 21L5.4 15.3C4.80493 14.1219 4.49656 12.8199 4.5 11.5C4.50061 9.92179 4.94061 8.37488 5.77072 7.03258C6.60083 5.69028 7.78825 4.6056 9.2 3.90003C10.3781 3.30496 11.6801 2.99659 13 3.00003H13.5C15.5843 3.11502 17.553 3.99479 19.0291 5.47089C20.5052 6.94699 21.385 8.91568 21.5 11V11.5Z"
      stroke="#F1FAEE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const callIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
  >
    <path
      d="M13.9941 5.53589C14.9708 5.72645 15.8684 6.20415 16.5721 6.90782C17.2758 7.6115 17.7535 8.50915 17.9441 9.48589M13.9941 1.53589C16.0233 1.76132 17.9156 2.67006 19.3603 4.1129C20.8049 5.55573 21.7161 7.4469 21.9441 9.47589M20.9441 17.4559V20.4559C20.9452 20.7344 20.8881 21.0101 20.7766 21.2652C20.665 21.5204 20.5014 21.7495 20.2961 21.9378C20.0909 22.126 19.8486 22.2694 19.5848 22.3586C19.321 22.4478 19.0414 22.481 18.7641 22.4559C15.6869 22.1215 12.7311 21.07 10.1341 19.3859C7.71788 17.8506 5.66939 15.8021 4.13406 13.3859C2.44403 10.7771 1.3923 7.80688 1.06406 4.71589C1.03907 4.43936 1.07193 4.16065 1.16056 3.89751C1.24918 3.63438 1.39163 3.39258 1.57882 3.18751C1.76602 2.98244 1.99386 2.8186 2.24785 2.70641C2.50183 2.59422 2.7764 2.53615 3.05406 2.53589H6.05406C6.53936 2.53111 7.00985 2.70297 7.37782 3.01942C7.74579 3.33588 7.98613 3.77534 8.05406 4.25589C8.18068 5.21596 8.41551 6.15862 8.75406 7.06589C8.8886 7.42381 8.91772 7.8128 8.83796 8.18677C8.75821 8.56073 8.57292 8.904 8.30406 9.17589L7.03406 10.4459C8.45761 12.9494 10.5305 15.0223 13.0341 16.4459L14.3041 15.1759C14.5759 14.907 14.9192 14.7217 15.2932 14.642C15.6671 14.5622 16.0561 14.5913 16.4141 14.7259C17.3213 15.0644 18.264 15.2993 19.2241 15.4259C19.7098 15.4944 20.1535 15.7391 20.4706 16.1134C20.7877 16.4877 20.9562 16.9655 20.9441 17.4559Z"
      stroke="#F1FAEE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const rightArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 20L20 15L15 10"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15H20"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const leftArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10L10 15L15 20"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 15H10"
      stroke="#1D3557"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const yellowStar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
      fill="#FFEE32"
    />
  </svg>
);

const orangeStar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
      fill="#FFAD33"
    />
  </svg>
);

const userNameIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
  >
    <path
      d="M15.8619 16.3334V14.7779C15.8619 13.9527 15.5312 13.1614 14.9424 12.578C14.3536 11.9945 13.555 11.6667 12.7224 11.6667H6.44322C5.61056 11.6667 4.81199 11.9945 4.22321 12.578C3.63443 13.1614 3.30365 13.9527 3.30365 14.7779V16.3334"
      stroke="black"
      strokeOpacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.58278 8.55547C11.3167 8.55547 12.7224 7.16258 12.7224 5.44436C12.7224 3.72614 11.3167 2.33325 9.58278 2.33325C7.84884 2.33325 6.44321 3.72614 6.44321 5.44436C6.44321 7.16258 7.84884 8.55547 9.58278 8.55547Z"
      stroke="black"
      strokeOpacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const clockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M9.57889 17.1112C13.9137 17.1112 17.4278 13.629 17.4278 9.33344C17.4278 5.03789 13.9137 1.55566 9.57889 1.55566C5.24404 1.55566 1.72995 5.03789 1.72995 9.33344C1.72995 13.629 5.24404 17.1112 9.57889 17.1112Z"
      stroke="black"
      strokeOpacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.57889 4.66675V9.33341L12.7185 10.889"
      stroke="black"
      strokeOpacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const locationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
  >
    <path
      d="M15 7.88298C15 13.2284 7.93848 17.8103 7.93848 17.8103C7.93848 17.8103 0.876953 13.2284 0.876953 7.88298C0.876953 6.06022 1.62093 4.31212 2.94523 3.02323C4.26952 1.73434 6.06564 1.01025 7.93848 1.01025C9.81131 1.01025 11.6074 1.73434 12.9317 3.02323C14.256 4.31212 15 6.06022 15 7.88298Z"
      stroke="black"
      strokeOpacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.76732 10.2407C9.06731 10.2407 10.1212 9.20755 10.1212 7.9331C10.1212 6.65864 9.06731 5.62549 7.76732 5.62549C6.46733 5.62549 5.41348 6.65864 5.41348 7.9331C5.41348 9.20755 6.46733 10.2407 7.76732 10.2407Z"
      stroke="black"
      strokeOpacity="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const rejectIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <path
      d="M75 25L25 75"
      stroke="#E63946"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25 25L75 75"
      stroke="#E63946"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const warningIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="63"
    viewBox="0 0 78 63"
    fill="none"
  >
    <path
      d="M76.5002 58.8L40.6002 1.00002C39.8002 -0.299976 38.0002 -0.299976 37.2002 1.00002L1.30016 59C2.80016 56.6 -1.39985 62 3.00015 62.1H75.0002C79.0002 62 75.7001 57.8 76.5002 58.8ZM6.60016 58L38.9002 5.90002L71.4002 58H6.60016ZM41.0002 20V36C41.0002 37.1 40.1002 38 39.0002 38C37.9002 38 37.0002 37.1 37.0002 36V20C37.0002 18.9 37.9002 18 39.0002 18C40.1002 18 41.0002 18.9 41.0002 20ZM39.1002 42C35.8002 42 33.1002 44.7 33.1002 48C33.1002 51.3 35.8002 54 39.1002 54C42.4002 54 45.1002 51.3 45.1002 48C45.0002 44.7 42.3002 42 39.1002 42ZM39.1002 50C38.0002 50 37.1002 49.1 37.1002 48C37.1002 46.9 38.0002 46 39.1002 46C40.2002 46 41.1002 46.9 41.1002 48C41.0002 49.1 40.1002 50 39.1002 50Z"
      fill="#FFAD33"
    />
  </svg>
);

const acceptIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <path
      d="M83.3332 25L37.4998 70.8333L16.6665 50"
      stroke="#02C697"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const countRatingZero = (
  <span className="starRating">
    <ul>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
    </ul>
  </span>
);
const countRatingOne = (
  <span className="starRating">
    <ul>
      <li>{filledStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
    </ul>
  </span>
);

const countRatingTow = (
  <span className="starRating">
    <ul>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
    </ul>
  </span>
);

const countRatingThree = (
  <span className="starRating">
    <ul>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{emptyStarIcon}</li>
      <li>{emptyStarIcon}</li>
    </ul>
  </span>
);

const countRatingFourth = (
  <span className="starRating">
    <ul>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{emptyStarIcon}</li>
    </ul>
  </span>
);

const countRatingFifth = (
  <span className="starRating">
    <ul>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
      <li>{filledStarIcon}</li>
    </ul>
  </span>
);
