// @ts-nocheck
import { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./VaricationCode.css";
import Button from "react-bootstrap/Button";
import Countdown from "./Countdown";
import { Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { useNavigate } from "react-router-dom";
const VaricationCode = (props) => {
    const navigate=useNavigate()
    const { authInfo } = useContext(AuthContext);
    const [code, setCode] = useState({
        firstNumber:"",
        secondNumber:"",
        thirdNumber:'',
        fourthNumber:"",
        fifthNumber:"",
        sixthNumber:""
    });
    const [Codeobj, setCodeobj] = useState({
        code:0
    })
    const [status, setStatus] = useState("");
    const action = localStorage.getItem("action");
    localStorage.setItem("code", code);

    // store the data of the input
    var word = code.firstNumber+code.secondNumber+code.thirdNumber+code.fourthNumber+code.fifthNumber+code.sixthNumber
    // fetch data
    const handlerequest = (e) => {
        e.preventDefault();
        setCodeobj({
            code:+word
        })        
        axios
            .post(
                "https://api.whitesooq.com/api/v1/verify-mobile",
                Codeobj,
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        Authorization: `Bearer ${authInfo.token}`,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);
                setStatus("1");
                response.data.status==1 ?
                navigate('/')
                :navigate('')
            })
            .catch((Error) => console.log(Error));
    };

    const handleNumber=(e)=>{
        setCode({...code,[e.target.name]: e.target.value})
        console.log(code);
    }

    const closeIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
        >
            <g filter="url(#filter0_d_171_1455)">
                <circle cx="35" cy="31" r="20" fill="#F2F2F2" />
            </g>
            <path
                d="M41 23.7109L29 35.2119"
                stroke="#E63946"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29 23.7109L41 35.2119"
                stroke="#E63946"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_171_1455"
                    x="0"
                    y="0"
                    width="70"
                    height="70"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="7.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.611613 0 0 0 0 0.679146 0 0 0 0 0.721667 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_171_1455"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_171_1455"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
    return (
        <div className="varicationCode">
            <Form onSubmit={handlerequest}>
            <Link className="ssvg" to='/'>{closeIcon}</Link>
                <Row id="fontStyle">
                    <Form.Text>تأكيد الحساب الشخصي</Form.Text>
                    <br></br>
                    <Form.Text style={{ color: "#457B9D", fontSize: "16px" }}>
                        {authInfo?.phone_number||authInfo.user?.phone_number} تم ارسال رمز
                        التأكيد إلى{" "}
                    </Form.Text>
                </Row>
                <Row>
                    <Col>
                        <Form.Control
                            type="text"
                            name="firstNumber"
                            maxLength="1"
                            onChange={handleNumber}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name="secondNumber"
                            maxLength="1"
                            onChange={handleNumber}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name="thirdNumber"
                            maxLength="1"
                            onChange={handleNumber}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name="fourthNumber"
                            maxLength="1"
                            onChange={handleNumber}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name="fifthNumber"
                            maxLength="1"
                            onChange={handleNumber}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name="sixthNumber"
                            maxLength="1"
                            onChange={handleNumber}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Form.Text
                                style={{
                                    color: "#457B9D",
                                    fontSize: "16px",
                                    textAlign: "center",
                                }}
                            >
                                {" "}
                                لم يصلك الرمز ؟{" "}
                            </Form.Text>
                        </Row>
                        <Row>
                            <Form.Text
                                style={{
                                    color: "#1D3557",
                                    fontSize: "19px",
                                    display: "flex",
                                }}
                            >
                                {" "}
                                {<Countdown />} اطلب رمز جديد خلال{" "}
                            </Form.Text>
                        </Row>
                    </Col>
                </Row>
                <Button variant="primary" type="submit" >
                    {action === "forgetPassword" && status === "1" ? (
                        <Link
                            style={{ color: "white", textDecoration: "none" }}
                            to="/logIn/forgetPassword/varicationCode/resetpassword"
                        >
                            {" "}
                            تأكيد رقم الهاتف
                        </Link>
                    ) : action === "signUp" && status === "1" ? (
                        <Link
                            style={{ color: "white", textDecoration: "none" }}
                            to="/"
                        >
                            {" "}
                            تأكيد رقم الهاتف
                        </Link>
                    ) : (
                        <Link
                            style={{ color: "white", textDecoration: "none" }}
                        >
                            {" "}
                            تأكيد رقم الهاتف
                        </Link>
                    )}
                </Button>
            </Form>
        </div>
    );
};
export default VaricationCode;