import React from 'react'
import { Link } from 'react-router-dom'
import './PrivacyAndPolicy.css' 

const PrivacyAndPolicy = () => {


    const closeIcon=<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
        <g filter="url(#filter0_d_177_1488)">
        <circle cx="35" cy="31" r="20" fill="#F2F2F2"/>
        </g>
        <path d="M41 24.2987L29 37.9627" stroke="#E63946" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M29 24.2987L41 37.9627" stroke="#E63946" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <filter id="filter0_d_177_1488" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="7.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.611613 0 0 0 0 0.679146 0 0 0 0 0.721667 0 0 0 0.3 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_177_1488"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_177_1488" result="shape"/>
        </filter>
        </defs>
    </svg>






  return (
    <div className='privacyAndPolicy'>
        <header>
            <Link to ="/signUp">
            {closeIcon}
            </Link>
            <h1>سياسات السوق الأبيض</h1>
            <h2>يرجى قراءة سياسات السوق الأبيض هذه بعناية قبل استخدام خدماتنا.</h2>
        </header>
        <div className='Paragraph'>
            <p>
            <h3 > يجب أن تكون للكلمات والتعابير التالية المعاني في سياسة الخصوصية المعينة لها.</h3>
            <i>الخدمات</i> تعني أي من المنتجات أو الخدمات أو المحتويات أو الميزات أو التقنيات أو الوظائف وجميع المواقع والتطبيقات والخدمات ذات الصلة المقدمة من قبلنا
            <br></br>
            <i>المنصة</i> تعني المواقع الإلكترونية أو تطبيقات الهاتف الجوال أو المواقع الإلكترونية المخصصة للهواتف الذكية أو غيرها من الخصائص عبر الإنترنت التي نقدم من خلالها خدماتنا.
            <h4>باستخدام السوق الأبيض والخدمات ذات الصلة، فإنك تمنح للسوق الأبيض موافقة صريحة لجمع واستخدام والكشف عن والاحتفاظ بالمعلومات الشخصية الخاصة بك من قبلنا، كما هو موضح في سياسة الخصوصية هذه وشروط الاستخدام الخاصة بنا.</h4>
            يجوز للسوق الأبيض تغيير سياسة الخصوصية هذه من حين لآخر كما هو موضح أدناه. ننصحك بقراءة هذه السياسة بصورة منتظمة للتأكد من التغييرات. تعتبر أي سياسة خصوصية معدلة سارية المفعول فور نشرها على موقعنا الإلكتروني.
            </p>
        </div>


        <div className='Paragraph'>
            <h4> 1. ما هي البيانات التي نجمعها عنك؟ </h4>
            معلومات التسجيل والحسابات الأخرى: عندما تقوم بالتسجيل لاستخدام منصاتنا، فإنه يجوز لنا أن نجمع المعلومات التالية عنك بناءً على كيفية التسجيل على منصتنا والتي يمكن أن تتضمن رقم الهاتف المحمول والاسم من بين المعلومات أساسية أخرى.
           <h4>  2. لماذا نقوم بمعالجة معلوماتك الشخصية؟ </h4>
            نحن نستخدم بياناتك الشخصية عندما يسمح القانون لنا بذلك. الأكثر شيوعا دون الحصر، سوف نستخدم البيانات التي نحتاج اليها لتنفيذ العقد المزمع إبرامه معكم أو قد تم إبرامه معكم، لتحسين وتخصيص وتقديم خدماتنا و منصاتنا الحالية والقادمة ولنبذل قصارى جهدنا لاكتشاف حالات الغش والاحتيال ولتقديم ميزات الخدمات والمنصة لتقديم خدمة أفضل لكم عن طريق أدوات المساعدة ، والامتثال لالتزام قانوني أو تنظيمي مع السلطات حسب الاقتضاء.
            <h4> 3 . كيف سنطلعكم على التغييرات في سياسة الخصوصية؟ </h4>
            يحق لنا إجراء تعديلات على سياسة الخصوصية هذه من وقت لآخر. سنقوم بنشر هذه التغييرات على هذه الصفحة لأي تغيير نقوم به. وفي حالة عدم موافقتك على التغييرات، يرجى التوقف عن استخدام الخدمات والمنصة.
            <h4> 4 . حقوقك </h4>
            قد يكون لديك حقوق قانونية معينة فيما يتعلق ببياناتك الشخصية استنادا إلى البلد الذي تقيم فيه أو كيفية تعاملنا معك. لن تضطر لدفع رسوم للوصول إلى بياناتك الشخصية (أو لممارسة أي من الحقوق الأخرى). ربما نرفض الموافقة على طلبك في هذه الظروف. ونحاول الاستجابة لجميع الطلبات المشروعة في غضون ثلاثة أشهر. وربما يستغرق الأمر منا أحيانا وقتاً أطول إذا كان طلبك معقدا بشكل خاص أو كان لديك عدة طلبات. وفي هذه الحالة، سوف نقوم بإبلاغك ونبقيك على اطلاع بآخر التطورات.
            <h4> 5 . الاتصال والتسويق </h4>
            سوف نتواصل معك عن طريق البريد الإلكتروني أو الرسائل القصيرة SMS أو استعمال إشعارات التطبيقات للهواتف الذكية فيما يتعلق بخدماتنا أو منصتنا لتأكيد تسجيلك وإبلاغك في حال أصبحت إعلاناتك فعالة أو منتهية الصلاحية, والمراسلة بغرض رسائل المعاملات الأخرى المتعلقة بخدماتنا. ونظرا لأنه من الضروري بالنسبة لنا أن نقدم لك رسائل المعاملات هذه ، فربما لا تتمكن من عدم قبول هذه الرسائل. عند تسجيلك على منصة السوق الابيض أو أحد المنصات التابعة لها أو قمت بتزويد بياناتك عند التسجيل لأي من الخدمات المعروضة على منصة السوق الابيض أو أحد المنصات التابعة لها أو لأحد الشركات التابعة لها، فأنت توافق على استقبال الاتصالات أو الرسائل التسويقية.
            <h4> 6 . مع من نشارك بياناتك؟ </h4>
            قد نضطر إلى تبادل بياناتكم الشخصية مع الأطراف المبينة أدناه للأغراض المبينة أعلاه. إننا نستخدم مزودي خدمات من طرف ثالث لمساعدتنا في تقديم جانب معين من خدماتنا، على سبيل المثال، مرافق التخزين السحابية مثل خدمات الويب الخاصة بشركة أمازون أو غيرها. ربما يكون مزودو الخدمات موجودين داخل أو خارج البلد الذي تقيم فيه حالياً.
            ما لم ينص صراحة على خلاف ذلك في سياسة الخصوصية هذه، فعندئذ تطبق سياسة الخصوصية هذه على استعمال ونقل المعلومات التي نجمعها منك. لا يملك السوق الابيض أي سيطرة على سياسات الخصوصية الخاصة بالأطراف الأخرى التي ربما تنطبق عليك. ولذا فإننا نشجعك على طرح الأسئلة قبل الإفصاح عن معلوماتكم الشخصية للآخرين.
            .من أجل تحسين خدماتنا، سنقوم أحيانا بمشاركة معلوماتك الغير محددة للهوية مع مزودي التحليلات الذين يساعدوننا في تحليل كيفية استخدام الأشخاص لمنصتنا/ خدمتنا. نحن نشارك المعلومات الخاصة بك معهم في شكل غير محدد للهوية لأغراض المراقبة والإبلاغ عن فعالية تسليم الحملات لشركائنا في الأعمال التجارية وتحليل الأعمال الداخلية. يجوز لنا أن الكشف عن بياناتك الشخصية لسلطات تنفيذ القانون والهيئات التنظيمية والهيئات الحكومية أو العامة والأطراف الثالثة الأخرى ذات الصلة للامتثال لأي متطلبات قانونية أو تنظيمية.
            يجوز لنا أن نختار بيع أو نقل أو دمج أجزاء من أعمالنا أو أصولنا. وعوضا عن ذلك، يجوز لنا السعي للإستحواذ على أعمال أخرى أو الاندماج معهم. وفي حالة حدوث تغيير لأعمالنا، عندئذ يجوز للمالكين الجدد استخدام بياناتك الشخصية بالطريقة نفسها المنصوص عليها في سياسة الخصوصية هذه.
            عند قيامك بنشر إعلان باستخدام خدماتنا، فإنه بإمكانك التصريح عن بعض المعلومات الشخصية والتي من الممكن أن تكون متاحة لمستخدمي السوق الابيض. وربما يتضمن ذلك، على سبيل المثال لا الحصر، اسمك وعنوان بريدك الإلكتروني وموقعك ورقم الاتصال بك وصورتك. يرجى ملاحظة أن أي معلومات تقدمها للمستخدمين الآخرين يمكن دائماً مشاركتها مع الآخرين، لذا يرجى ممارسة تقديراتك الخاصة بهذا الصدد.
            <h4> 8 . أين نقوم بتخزين بياناتك وإلى متى؟ </h4>
            يجوز أن يتم تخزين البيانات التي نجمعها عنك ومعالجتها داخل أو خارج البلد الذي تقيم فيه على سيرفرات (خوادم) آمنة من أجل توفير أفضل تجربة للمستخدم للخدمة و/أو المنصة، الحالية أو القادمة. على سبيل المثال، لبناء موقع ويب سريع أو تطبيق الهواتف الذكية. سوف نحتفظ ببياناتك الشخصية فقط طالما كان ذلك ضرورياً للوفاء بالأغراض التي جمعت من أجلها، بما في ذلك لأغراض تلبية أي متطلبات قانونية أو محاسبية أو متطلبات الإبلاغ.
            ولتحديد فترة الاحتفاظ المناسبة للبيانات الشخصية، فإننا نراعي كمية وطبيعة وحساسية البيانات الشخصية، والخطر المحتمل للضرر الناجم عن الاستخدام غير المصرح به أو الكشف عن بياناتك الشخصية، والأغراض التي نعالج بياناتك الشخصية من أجلها، وما إذا كان بإمكاننا تحقيق تلك الأغراض من خلال وسائل أخرى والمتطلبات القانونية المعمول بها.
            <h4> 9 . التدابير التقنية والتنظيمية و المعالجة الأمنية </h4>
            يتم تخزين جميع المعلومات التي نتلقاها عنك على خوادم آمنة ولدينا إجراءات أساسية مناسبة لحماية بياناتك الشخصية. يقوم السوق الابيض بتقييم الأمان بشكل مستمر على شبكته وكفاءة برنامج أمن المعلومات الداخلي المصمم من أجل (أ) المساعدة في تأمين بياناتك ضد الفقدان العرضي أو الوصول غير القانوني اليها أو الكشف غير القانوني عنها، (ب) تحديد المخاطر المتوقعة إلى حد معقول على أمن شبكة السوق الابيض، (ج) تقليل المخاطر الأمنية إلى الحد الأدنى، بما في ذلك من خلال تقييم المخاطر والاختبارات المنتظمة. بالإضافة إلى ذلك، نضمن تشفير جميع بيانات الدفع باستخدام تقنية SSL (تكنولوجيا الوصلات المؤمنة).
            يرجى ملاحظة أنه على الرغم من التدابير التي قمنا بتنفيذها لحماية بياناتك، فإن عملية نقل البيانات عبر الإنترنت أو الشبكات المفتوحة الأخرى ليست آمنة تماماً وهناك خطر من إمكانية الوصول إلى بياناتك الشخصية من قبل أطراف ثالثة غير مصرح لها.
            <h4> 10 . الاتصال </h4>
            للحصول على أي مزيد من المعلومات الإضافية أو لممارسة حقوقك، يرجى الاتصال بفريقنا على info@whitesooq.com، أو الانتقال إلى قسم المساعدة على منصاتنا.
            <h4> 11 . التغييرات في هذه السياسة </h4>
            قد نقوم بتحديث سياسة الخصوصية هذه أو ترقيتها أو تعديلها (جزئيا و /أو كلياً) من حين لآخر لإبقائها مواكبة للمتطلبات القانونية والطريقة التي ندير بها أعمالنا. يرجى مراجعة هذه الصفحة بانتظام للحصول على أحدث إصدار من سياسة الخصوصية.
            يشير استمرار استخدامك لخدماتنا بعد إجراء تعديل إلى قبولك على أي تغييرات.
            <h4> 12 . قبول هذه السياسة </h4>
            أنت تقر بأنك قرأت هذه السياسة وتوافق على جميع أحكامها وشروطها. من خلال الوصول إليها واستخدام الأنظمة الأساسية والخدمات، فإنك توافق على الالتزام بهذه السياسة وبشروط الاستخدام. في حالة عدم الموافقة على الالتزام بشروط هذه السياسة وسياساتنا الأخرى، فأنك غير مخول بالوصول إلى واستخدام المنصة (المنصات) والخدمات.
        </div>

        <Link to='/contact/us' className='Link'>تواصل معنا</Link>
    </div>
  )
}

export default PrivacyAndPolicy