// @ts-nocheck
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import './Detailsofferandcomment.css'
import face from  './face.svg'
export const Detailsofferandcomment = (props) => {

  return (
    <div className='detoffercomment'>
      <Container>
        <div className='row row1' style={{marginBottom:"20px"}}>
        <h3>   {props.type === "comment" ? "عرض التعليقات" : "عرض العروض المقدمة"}</h3>
        </div>
        {
            props.data.map((offer)=>{
              return(
              
              <div className='row row2 d-flex' dir="rtl" key={offer.id}>
       
                <img style={{width:"70px"}} src={offer.user.full_path_image}/>
                 <div className={`desc ${offer.type==="hidden"? "visibleoffer" :""}`} >
                    <h4 className={` ${offer.type!=="visible"? "visibleoffertitle" :""}`}>
                    {offer.user.username}
                    </h4>
                    <p> {offer.content}</p>
                      {props.type === "comment" ? (
                        <></>
                      ) : (
                        <div className='offerprice d-flex'>
                          <h5> السعر المقدم</h5>
                          {
                            offer.type==="visible"?<h6> {offer.value}</h6> : <h6> *******</h6>
                          }
                          
                        </div>
                      )}       
                      <div className='ttiimmee' style={{direction:'ltr', fontWeight:'400', paddingLeft:'25px', color:'#1d355780' }}>  {offer.from} </div>       
                </div>
              </div>
              )
            })
          }           
    </Container>
    </div>
  )
}