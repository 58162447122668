// @ts-nocheck
import { Container, NavDropdown } from "react-bootstrap";
import "./HeaderLogin.css";
// import "./../assest/Style/component/Landingpagevisitor/Header.css";
import face from "../../assest/images/Layouts/Headerlogin/face.png";
import messenger from "../../assest/images/Layouts/Headerlogin/messenger.svg";
import heart from "../../assest/images/Layouts/Headerlogin/heart.svg";
import ring from "../../assest/images/Layouts/Headerlogin/ring.svg";
import plus from "../../assest/images/Landingpagevisitor/Header/user-plus.svg";
import login from "../../assest/images/Landingpagevisitor/Header/login.svg";
import user from "../../assest/images/Layouts/Headerlogin/user.svg";
import lock from "../../assest/images/Layouts/Headerlogin/lock.svg";
import logout from "../../assest/images/Layouts/Headerlogin/logout.svg";
import trello from "./trello (1).svg";
import ads from "./Ads(1).svg";
import news from "./news (1).svg";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
const Headerlogin = () => {
  const [logoShow, setLogoShow] = useState(true);
  useEffect(() => {
    const showLogo = () => {
      if (window.innerWidth > 525) {
        setLogoShow(true);
      } else {
        setLogoShow(false);
      }
    };

    window.addEventListener("resize", showLogo);

    showLogo();

    return () => {
      window.removeEventListener("resize", showLogo);
    };
  }, []);

  const navigate = useNavigate();
  // @ts-ignore
  const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
  const [count, setCount] = useState(0);
  const token = localStorage.getItem("token");
  const refresh = async () => {
    await axios
      .post(
        "https://api.whitesooq.com/api/v1/refresh-token",
        {},
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      )
      .then((response) => {
        console.log(response);
        ChangeAuthInfo(response.data);
        localStorage.setItem("token", response.data.token);
      })
      .catch((err) => {
        console.log(err);
        // if (err.data.message === "Unauthenticated") {
        //     ChangeAuthInfo({});
        // }
      });
  };
  useEffect(() => {
    const notiData = async () => {
      try {
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/notifications/get-unread`,
          {
            headers: {
              accept: "application/json",
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCount(response.data.count);
        console.log(response.data.count);
      } catch (err) {}
    };
    setTimeout(() => {
      // refresh();
      notiData();
    }, 1800);
  }, [count]);
  const handlerLogout = async () => {
    try {
      const response = await axios.delete(
        `https://api.whitesooq.com/api/v1/logout`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      await ChangeAuthInfo({});
      localStorage.removeItem("token");
      navigate("/");
    } catch (err) {
      if (err?.response?.status === 401) {
        ChangeAuthInfo({});
        navigate("/");
      }
    }
  };
  return (
    <div className="Headerlogin header h-auto py-3">
      <Container>
        <div className=" d-flex justify-content-around align-items-center row-gap-3">
          <div className="d-flex justify-content-center flex-wrap align-items-center gap-3 mt-0 imgheader ivhead">
            {Object.keys(authInfo).length === 0 && (
              <>
                <Link
                  to={"/contact/us"}
                  style={{
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span className="spany me-3">تواصل معنا</span>
                </Link>
                <div
                  className="liny"
                  style={{
                    width: "1px",
                    height: "28px",
                    backgroundColor: "#F1FAEE",
                  }}
                ></div>
                <Link to={"/packages"} style={{ textDecoration: "none" }}>
                  <span className="bakatt pacc m-0 ms-3">الباقات</span>
                </Link>
                <div className=" d-flex flex-wrap">
                  <div className="account d-flex mt-0">
                    <Link style={{ marginBottom: "7px" }} to="/signUp">
                      {" "}
                      <img src={plus} />{" "}
                    </Link>
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/signup")}
                    >
                      إنشاء حساب جديد
                    </h4>
                  </div>
                  <div className="loginn d-flex mt-0">
                    <Link style={{ marginBottom: "7px" }} to="/logIn">
                      <img src={login} />
                    </Link>
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/login")}
                    >
                      تسجيل الدخول
                    </h4>
                  </div>
                </div>
              </>
            )}
            {Object.keys(authInfo).length !== 0 && (
              <>
                <Link
                  to={"/contact/us"}
                  style={{
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span className="spany m-0">تواصل معنا</span>
                </Link>
                <div
                  className="liny"
                  style={{
                    width: "1px",
                    height: "28px",
                    backgroundColor: "#F1FAEE",
                  }}
                ></div>
                <Link to={"/packages"} style={{ textDecoration: "none" }}>
                  <span className="bakat pacc m-0">الباقات</span>
                </Link>
                <div className=" d-flex align-items-center">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => navigate("/Favourite")}
                  >
                    <img src={heart} alt="icon" />
                  </IconButton>
                  <IconButton
                    className={count !== 0 ? "notificationsCount" : ""}
                    sx={{ p: 0 }}
                    onClick={() => navigate("/notifications")}
                  >
                    <img src={ring} alt="icon" />
                  </IconButton>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "22px",
                      color: "white",
                    }}
                  >
                    {count !== 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "-22px",
                          right: "-4px",
                          width: "15px",
                          height: "16px",
                          borderRadius: "50%",
                          backgroundColor: "#E63946",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "10px",
                        }}
                      >
                        {count}
                      </div>
                    )}
                  </div>
                  <span style={{ whiteSpace: "nowrap" }} className="mx-2">
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#FFFFFF",
                      }}
                      to="/statics"
                    >
                      {authInfo?.user?.username}
                    </Link>
                  </span>

                  <NavDropdown
                    style={{
                      minWidth: "auto",

                      padding: "0px!important",
                    }}
                    title={
                      <img
                        src={
                          authInfo?.user?.full_path_image
                            ? authInfo?.user?.full_path_image
                            : face
                        }
                        className="facee rounded-circle m-0"
                        alt="icon"
                      />
                    }
                    id="navbarScrollingDropdown"
                  >
                    <NavDropdown.Item>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                        to="/setting-account"
                      >
                        <img src={user} alt="icon" /> إعدادات الحساب
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                        to="/change"
                      >
                        <img src={lock} alt="icon" /> تغيير كلمة المرور
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                        to={`/advert`}
                      >
                        <img src={ads} alt="icon" /> احصائيات الإعلانات
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                        to="/user-subscription"
                      >
                        <img src={news} alt="icon" /> اشتراكاتي
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                        to="/statics"
                      >
                        <img src={trello} alt="icon" />
                        احصائيات الحساب
                      </Link>
                    </NavDropdown.Item>
                    {authInfo.user.is_admin === "1" ? (
                      <NavDropdown.Item>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "#FFFFFF",
                          }}
                          to="/ViewActiveAds-page"
                        >
                          <img src={user} alt="icon" />
                          لوحة التحكم
                        </Link>
                      </NavDropdown.Item>
                    ) : (
                      <></>
                    )}

                    <NavDropdown.Item onClick={handlerLogout}>
                      <NavDropdown.Item
                        onClick={handlerLogout}
                      ></NavDropdown.Item>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                        to="#action5"
                      >
                        <img onClick={handlerLogout} src={logout} alt="icon" />{" "}
                        تسجيل الخروج
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </>
            )}
          </div>

          {logoShow && (
            <div className=" m-0 p-0 logo " style={{ width: " min-content" }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                {" "}
                <h3 className="arabic m-0">السوق الأبيض</h3>
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                {" "}
                <h3 className="english m-0 me-3">WhiteSooq</h3>
              </Link>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
export default Headerlogin;
