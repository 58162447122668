import React from "react";
import { Container } from "react-bootstrap";
import "./Popularity.css";
import "../../../../Pdata";
import { useNavigate } from "react-router-dom";

export const Popularity = (props) => {
  const navigate = useNavigate()
  return (
    <div className="popularity">
      <Container>
        <div className="row">
          <h3>الفئات الأكثر رواجا</h3>

          <ul className="itempopularity">
            {props.data.map((item) => {
              return <li style={{cursor:"pointer"}} onClick={()=>navigate(`/category/${item.id}`)}>{item.name}</li>;
            })}
          </ul>
        </div>
      </Container>
    </div>
  );
};
