// @ts-nocheck
import React from "react";
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import SaleADForm from "../SaleADForm/SaleADForm/SaleADForm";
import { useNavigate } from "react-router-dom";
import classes from "./SaleAD.module.css";
import Footer from "../../../../layouts/Footer/Footer";
import Headerlogin from "../../../../layouts/Headerlogin/Headerlogin";

const SaleAD = () => {
  const navigate = useNavigate();
  return (
    <div>
    <Headerlogin/>
    <Container sx={{ my: { xs: 3, sm: 10 } }}>
      <Box
        className={classes.ContainerBox}
        sx={{
          padding: 2,
          width: { 
            xs: 300, 
            sm: 580,
            md: 650,
            lg: 700,
            xl: 750, 
          },
          height: { 
            xs: 640, 
            sm: 645,
            md: 647,
            lg: 650,
            xl: 655, 
           },
        }}
      >
        <IconButton onClick={() => navigate("/new-ad")}>
          <East />
        </IconButton>
        <Typography
          sx={{
            color: "#1D3557",
            fontWeight: "800",
            /* py: { sm: 2 }, */
          }}
          variant="h5"
        >
          إضافة إعلان بيع جديد
        </Typography>
        <SaleADForm />
      </Box>
    </Container>
    <Footer/>
    </div>
  );
};

export default SaleAD;
