// @ts-nocheck
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import PurchaseADForm from "../PurchaseADForm/PurchaseADForm";
import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./PurchaseAD.module.css";
import Footer from "../../../../layouts/Footer/Footer";
import Headerlogin from "../../../../layouts/Headerlogin/Headerlogin";
const PurchaseAD = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Headerlogin />
      <Container sx={{ my: { xs: 3, sm: 10 } }}>
        <Box
          className={classes.ContainerBox}
          sx={{
            padding: 2,
            width: { 
              xs: 300, 
              sm: 580,
              md: 650,
              lg: 700,
              xl: 750, 
            },
            height: { 
              xs: 605, 
              sm: 605,
              md: 610,
              lg: 615,
              xl: 630, 
             },
          }}
        >
          <IconButton onClick={() => navigate("/new-ad")}>
            <East />
          </IconButton>
          <Typography
            sx={{
              color: "#1D3557",
              fontWeight: "800",
              /*  py: { sm: 2 }, */
            }}
            variant="h5"
          >
            إضافة إعلان شراء جديد
          </Typography>
          <PurchaseADForm />
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default PurchaseAD;
