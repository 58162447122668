// @ts-nocheck
import { Form, FormControl, FormGroup, FormLabel, Modal, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { Container } from "react-bootstrap";
import "./Searchbar.css";
import plus from "../../../../assest/images/Landingpagevisitor/searchbar/plus (1).svg";
import shape from "../../../../assest/images/Landingpagevisitor/searchbar/shape (3).svg";
import search from "../../../../assest/images/Landingpagevisitor/searchbar/search.svg";
import { Link, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { Button } from "react-bootstrap";
import "../Searchbar/searchFilter/Filter.css";
import { filterDataContext } from "../../../../store/FilterDataContext";
import { AuthContext } from "store/UseAuthContext";
export const Searchbar = (props) => {
  const { filterData, changeFilterData } = useContext(filterDataContext);

  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [input, setinput] = useState("");
  const navigate = useNavigate();
  const Filternavigate = useNavigate();

  const { authInfo } = useContext(AuthContext);
  const sortBy = [
    { label: "الإعلانات المميزة", value: "pinned" },
    { label: "الأحدث", value: "newest" },
    { label: "الأقدم", value: "oldest" },
    { label: "الأكثر مشاهدة", value: "mostviewed" },
    { label: "أدنى سعر", value: "bylowestprice" },
    { label: "أعلى سعر", value: "byhighestprice" },
  ];
  const types = [
    { label: " اعلان شراء", value: "buy" },
    { label: "اعلان بيع", value: "sale" },
  ];

  const [post, setPost] = useState({
    category: "",
    sortBy: "",
    maxPrice: "",
    minPrice: "",
    type: "",
    search: "",
    tag:"",
  });
  console.log(authInfo);
  const handle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
    changeFilterData(post);
    console.log(post);
  };
  const [navigateUser, setNavigateUser] = useState("/login");

  const submitHandling = (e) => {
    e.preventDefault();
    changeFilterData(post);
    setShow(false);
    // Filternavigate(`/`);
  };

  const clickHandler = () => {
    window.scrollTo(0, 0);
    if (authInfo.user === undefined) {
      setError("قم بتسجيل الدخول وأعد المحاولة");
    }
    else {
      navigate("/new-ad");
    }
  };
  return (
    <div className="searchbar">
      {error && (
        <section className="logInAlert">
          <Modal 
          show={error} 
          variant="none"
          style={{ direction: "rtl" }}
          backdrop="static"
          centered>
            <ModalHeader>{error}</ModalHeader>
            <ModalFooter className="d-flex text-align-center justify-content-center">
              <Button onClick={() => setError("")} 
              style={{
                backgroundColor: "#1D3557",
                marginLeft: "30px",
            }}>
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to={navigateUser}
                >
                  موافق
                </Link>
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      )}
      <Container style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <button onClick={clickHandler} className="add-ads">
              <span style={{ color: "white" }}>أضف إعلانك الآن</span>{" "}
              <img style={{ marginLeft: "3px" }} src={plus} />
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12  inputsection">
            {show && (
              <div className="background position-fixed ">
                <Container className="h-100">
                  <section
                    id="filter"
                    className="rounded-4 overflow-auto h-100"
                  >
                    <svg
                      onClick={() => {
                        setShow(false);
                      }}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1769_1209)">
                        <path
                          d="M18 6L6 18"
                          stroke="#1D3557"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="#1D3557"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1769_1209">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div>
                      <h1 className="m-auto">فلتر البحث</h1>
                    </div>
                    <Form className=" w-100" onSubmit={submitHandling}>
                      <FormGroup className=" mt-3">
                        <FormLabel>الفئات</FormLabel>
                        <div className="d-flex flex-row-reverse flex-wrap column-gap-3">
                          {props?.data?.map((category) => (
                            <Form.Check
                              key={category.name}
                              id={category.name}
                              className="checkBox"
                            >
                              <Form.Check.Input
                                type="radio"
                                name="category"
                                value={category.name}
                                className="d-none"
                                onChange={handle}
                              />
                              <Form.Check.Label
                                className={
                                  post.category === category.name
                                    ? "selected-radio"
                                    : ""
                                }
                              >
                                {category.name}
                              </Form.Check.Label>
                            </Form.Check>
                          ))}
                        </div>
                      </FormGroup>
                      <FormGroup className=" mt-3">
                        <FormLabel>فرز حسب</FormLabel>
                        <div className="d-flex flex-row-reverse flex-wrap column-gap-3">
                          {sortBy.map((sort) => (
                            <Form.Check key={sort.value} id={sort.value}>
                              <Form.Check.Input
                                type="radio"
                                name="sortBy"
                                value={sort.value}
                                className="d-none"
                                onChange={handle}
                              />
                              <Form.Check.Label
                                className={
                                  post.sortBy === sort.value
                                    ? "selected-radio"
                                    : ""
                                }
                              >
                                {sort.label}
                              </Form.Check.Label>
                            </Form.Check>
                          ))}
                        </div>
                      </FormGroup>
                      <FormGroup className=" mt-3">
                        <FormLabel>تحديد نوع الاعلان </FormLabel>
                        <div className="d-flex flex-row-reverse flex-wrap column-gap-3">
                          {types.map((type) => (
                            <Form.Check key={type.value} id={type.value}>
                              <Form.Check.Input
                                type="radio"
                                name="type"
                                value={type.value}
                                className="d-none"
                                onChange={handle}
                              />
                              <Form.Check.Label
                                className={
                                  post.type === type.value
                                    ? "selected-radio"
                                    : ""
                                }
                              >
                                {type.label}
                              </Form.Check.Label>
                            </Form.Check>
                          ))}
                        </div>
                      </FormGroup>
                      <FormGroup className="mt-3 pb-3 border-0">
                        <FormLabel>تحديد السعر</FormLabel>
                        <Row className=" justify-content-end gap-3 me-0">
                          <FormControl
                            type="number"
                            name="maxPrice"
                            onChange={handle}
                            placeholder="السعر الاعلى"
                          />

                          <FormControl
                            type="number"
                            name="minPrice"
                            // value={post.minPrice}
                            onChange={handle}
                            placeholder="السعر الادنى"
                          />
                        </Row>
                      </FormGroup>
                      <Button type="submit">بحث</Button>
                    </Form>
                  </section>
                </Container>
              </div>
            )}
            <form onSubmit={submitHandling}>
              <img
                onClick={() => setShow(!show)}
                src={shape}
                className="shapeimg"
              />
              <input
                id="searcch"
                type="text"
                placeholder=" ...ابحث عن منتجك"
                name="search"
                // onChange={(e) => setinput(e.target.value)}
                onChange={handle}
              />
              <img src={search} className="searchimg" />
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};
