import {
  Avatar,
  Box,
  Container,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import { East } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import classes from "./ReportOnUser.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Form, Image } from "react-bootstrap";
import Home from "./Home.svg";

import { AuthContext } from "store/UseAuthContext";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Snack from "components/Utilities/Snack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportOnUser = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOption("");
  };
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };
  const [reportDeleted, setReportDeleted] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { authInfo } = useContext(AuthContext);
  const { id } = useParams();
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const [data, setData] = useState({});
  useEffect(() => {
    fetch(`https://api.whitesooq.com/api/v1/reports/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // console.log(responseData);
        if (responseData?.data) {
          setData(responseData.data);
        }
      });
  }, []);
  const date = new Date(data?.created_at);
  const handleDelete = () => {
    fetch(`https://api.whitesooq.com/api/v1/reports/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });

          return response.json();
      })
      .then((data) => {
        // if (data.status) {
        //   setReportDeleted(true);
        //   setOpen(false);
        //   navigate("/warnings-page");
        // }
        setOpen(false);
        if (data?.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ حذف البلاغ بنجاح",
          });
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: data?.message ? data?.message : "هناك مشكلة",
          });
        }
        setTimeout(() => {
          navigate("/warnings-page");
        }, 1000);
      })
      .catch((error) => {});
  };
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Snack alert={alert} />

      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "10px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          <Link
            style={{
              textDecoration: "none",
              fontFamily: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            to="/warnings-page"
          >
            البلاغات
          </Link>
          / تفاصيل البلاغ
        </h4>
      </Form.Label>

      <Container>
        <Box
          dir="rtl"
          style={{
            width: "100%",
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginTop: "0px",
            marginRight: "-30px",
          }}
          className={classes.ContainerBoxAdmin}
          sx={{
            padding: 2,
            width: { xs: 330, sm: 650 },
            height: { xs: 550, sm: 800 },
          }}
        >
          <Typography
            className={classes.detailsParagraph}
            sx={{ color: "#fff", m: 1 }}
          >
            تفاصيل البلاغ
          </Typography>
          <Typography className={classes.line}></Typography>
          <Box
            style={{ width: "101%", marginRight: "-9px" }}
            className={classes.ContainerBoxAdminInside}
            sx={{
              padding: 2,
              width: { xs: 330, sm: 560 },
              height: { xs: 550, sm: 600 },
            }}
          >
            <IconButton
              sx={{ m: 1 }}
              onClick={() => navigate("/warnings-page")}
            >
              <East />
            </IconButton>
            <Box className={classes.menuOption}>
              <FormControl sx={{ m: 1 }}>
                <Select
                  IconComponent={ExpandMore}
                  value={option}
                  onChange={handleChange}
                  displayEmpty
                  autoWidth
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem onClick={handleClickOpen} sx={{ pl: 6 }}>
                    حذف
                  </MenuItem>
                </Select>
              </FormControl>
              {!reportDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"هل أنت متأكد من حذف هذا البلاغ؟"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      لا يمكن استعادة البلاغ عند الحذف
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnCancelDialog}
                      onClick={handleClose}
                    >
                      إلغاء
                    </button>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleDelete}
                    >
                      متأكد
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {error && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {error}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Close sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
              {reportDeleted && (
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  sx={{ textAlign: "center" }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <WarningAmber
                    sx={{
                      color: "#f9a703",
                      m: "0 auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <DialogTitle
                    sx={{
                      textAlign: "center",
                      m: "0 auto",
                      p: "0 20px",
                      color: "#457B9D",
                      fontWeight: 700,
                    }}
                  >
                    {"تم حذف البلاغ بنجاح"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      sx={{ m: 0, p: 0 }}
                      id="alert-dialog-slide-description"
                    >
                      <Check sx={{ color: "red" }} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ m: "0 auto" }}>
                    <button
                      className={classes.btnOkDialog}
                      onClick={handleClose}
                    >
                      ok
                    </button>
                  </DialogActions>
                </Dialog>
              )}
            </Box>
            <Typography
              variant="p"
              sx={{ color: "#1d3557", fontWeight: 800, fontSize: "25px" }}
            >
              عرض البلاغ
            </Typography>
            <Box sx={{ my: 1 }} style={{ direction: "ltr" }}>
              <table className={classes.tableDetails}>
                <tbody className="">
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1WithoutPadding}>
                      <div className={classes.flexTable}>
                        <IconButton
                          onClick={() => navigate(`/profile/${data?.user?.id}`)}
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        {/* <Typography>hfgh</Typography> */}
                        <Typography sx={{ fontWeight: 600 }}>
                          {data?.user?.username}
                        </Typography>
                        <Avatar
                          sx={{
                            ml: 1,
                            width: "40px",
                            height: "40px",
                            p: 0,
                          }}
                          src={data?.user?.full_path_image}
                        />
                      </div>
                    </div>
                    <div className={classes.sentence2}>اسم المستخدم</div>
                  </div>
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>{data?.reason}</div>
                    <div className={classes.sentence2}>وصف البلاغ المقدم</div>
                  </div>
                  {data?.reportable_type?.includes("User") && (
                    <div className={classes.tableRow}>
                      <div className={classes.sentence1}>
                        <div className={classes.flexTable}>
                          <Rating
                            className={classes.flexTable}
                            name="read-only"
                            value={data?.reportable?.rating}
                            readOnly
                          />
                          <Typography
                            sx={{
                              mr: 2,
                              ml: 1,
                              fontWeight: 500,
                              fontSize: "18px",
                            }}
                          >
                            تقييم
                          </Typography>
                          {/* <Typography>{data?.reportable?.rating}</Typography> */}
                          <Typography
                            sx={{
                              ml: 1,
                              p: 0,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                navigate(`/profile/${data?.reportable?.id}`)
                              }
                            >
                              <img
                                style={{ width: "50px", height: "50px" }}
                                src={data?.reportable?.full_path_image}
                                alt="Img AD"
                              />
                            </IconButton>
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.sentence2}>
                        تم تقديم البلاغ على المستخدم
                      </div>
                    </div>
                  )}
                  {data?.reportable_type?.includes("Ad") && (
                    <div className={classes.tableRow}>
                      <div className={classes.sentence1WithoutPadding}>
                        <div className={classes.flexTable}>
                          <Typography>{data?.reportable?.title}</Typography>
                          <Typography
                            sx={{
                              ml: 1,
                              p: 0,
                            }}
                          >
                            <Link to={`offerDetail/${data?.reportable?.id}`}>
                              <img
                                // src={reponseImage}
                                style={{ width: "50px", height: "50px" }}
                                src={data?.reportable?.all_images[0]}
                                alt="Img AD"
                              />
                            </Link>
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.sentence2}>
                        تم تقديم البلاغ على الإعلان
                      </div>
                    </div>
                  )}
                  <div className={classes.tableRow}>
                    <div className={classes.sentence1}>
                      {data?.created_at
                        ? `${date?.getFullYear()}-${date?.getMonth()}-${date?.getDate()}`
                        : ""}
                    </div>
                    <div className={classes.sentence2}>تاريخ تقديم البلاغ</div>
                  </div>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ReportOnUser;
