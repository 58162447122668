import { Container } from '@mui/system';
import React, { useContext, useState } from 'react';
import './Adduser.css';
import arrow from './../admin-image/user/arrow.svg';
import camera from './../../../assest/images/camera.svg';
import Ellipse from './../../../assest/images/Ellipse.svg'
import { Alert, Form, InputGroup, Button, Modal, ModalHeader } from 'react-bootstrap';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from 'store/UseAuthContext';
import { Image } from 'react-bootstrap';
export const Adduser = () => {
  const { authInfo } = useContext(AuthContext);

  // State variables for form input fields
  const [username, setUsername] = useState('');
  const [fullname, setFullname] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneWhats, setPhoneWhats] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [codeWhats, setCodeWhats] = useState('');
  const [image, setImage] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState('1');
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  // List of countries
  const countries = [
    { name: 'الأردن', code: '962' },
    { name: 'الإمارات العربية المتحدة', code: '971' },
    { name: 'البحرين', code: '973' },
    { name: 'الجزائر', code: '213' },
    { name: 'السعودية', code: '966' },
    { name: 'السودان', code: '249' },
    { name: 'الصومال', code: '252' },
    { name: 'العراق', code: '964' },
    { name: 'الكويت', code: '965' },
    { name: 'المغرب', code: '212' },
    { name: 'اليمن', code: '967' },
    { name: 'تونس', code: '216' },
    { name: 'جيبوتي', code: '253' },
    { name: 'سوريا', code: '963' },
    { name: 'عمان', code: '968' },
    { name: 'فلسطين', code: '970' },
    { name: 'قطر', code: '974' },
    { name: 'لبنان', code: '961' },
    { name: 'ليبيا', code: '218' },
    { name: 'مصر', code: '20' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
    { name: 'موريتانيا', code: '222' },
    { name: 'تشاد', code: '235' },
  ];

  // Function to handle clicking the camera icon for image upload
  const handleCameraClick = () => {
    const imageInput = document.getElementById('imageInput');
    imageInput.click();
  };

  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Function to handle the form submission
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('fullname', fullname);
      formData.append('phone_number', `${countryCode} ${phone}`);
      formData.append('whatsapp', `${codeWhats} ${phoneWhats}`);
      formData.append('country', countryName);
      formData.append('password', password);
      formData.append('password_confirmation', repassword);
      formData.append('accept_terms', acceptTerms);
      if (image) {
        formData.append('image', image);
      }

      // Make a POST request to the API
      const response = await axios.post(
        `https://api.whitesooq.com/api/v1/users`,
        formData,
        {
          headers: {
            accept: 'application/json',
            app_key: 'base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=',
            Authorization: `Bearer ${authInfo.token}`,
          },
        }
      );
      console.log(response);
      setShowAlert(true);
      setMessage('تمت اضافة المستخدم بنجاح');
      setUsername("")
      setFullname("")
      setPhone("")
      setPhoneWhats("")
      setCountryCode("")
      setCodeWhats("")
      setPassword("")
      setRepassword("")
      setCountryName("")
      setImage(null)
    } catch (err) {
      console.log(err);
      setShowAlert(true);
      setMessage(err.response.data.message);
    }
  };

  return (
    <div className='adduser' dir='ltr'>
      <Container>
        <div className='contentadd' dir='rtl'>
        <Link style={{textDecoration:'none'}} to='/activeUsers-page'>
          <button style={{ border: '1px solid #FFF' }}>
            <img src={arrow} />
          </button>
        </Link>
          <br />
          <h6>إضافة مستخدم جديد</h6>
        </div>
        <section className='changeAlert'>
          <Modal style={{direction:'rtl'}} show={showAlert} variant='none' backdrop="static" centered>
            <ModalHeader>{message}</ModalHeader>
            
            <Modal.Footer className='d-flex justify-content-start'>
              <Button style={{backgroundColor:'#1D3557', marginRight:'180px'}} onClick={() => setShowAlert(false)}>
                <Link style={{ color: 'white', textDecoration: 'none' }} to='/add-user-page-page'>
                  تأكيد
                </Link>
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
        <Form.Label htmlFor='inputPassword5'>ادخل اسم المستخدم <i style={{ color: 'red' }}> * </i></Form.Label>
        <div className='d-flex' dir='rtl'>
          <Form.Control
            type='text'
            id='inputPassword5'
            aria-describedby='passwordHelpBlock'
            placeholder='Ranim01'
            required
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        <div className='inputiimg'>
        <input
  type="file"
  id="imageInput"
  style={{ display: 'none' }}
  onChange={handleImageUpload} 
/>
<div onClick={handleCameraClick} className="circle-image">
<img src={camera}  className="iimg2"/>
<img src={image?URL.createObjectURL(image):Ellipse} className="iimg1"/>

</div>
        
    </div>
        </div>
        <br />
        <Form.Label htmlFor='inputPassword5' style={{ marginTop: '-10px' }}>
          ادخل الاسم بالكامل <i style={{ color: 'red' }}>*</i>
        </Form.Label>
        <Form.Control
          dir='rtl'
          type='text'
          id='inputPassword5'
          aria-describedby='passwordHelpBlock'
          placeholder='رنيم احمد عمر'
          required
          onChange={(e) => setFullname(e.target.value)}
          value={fullname}
        />
        <br />
        <Form.Label htmlFor='inputPassword5'>ادخل رقم الهاتف مع رمز البلد <i style={{ color: 'red' }}>*</i></Form.Label>
        <InputGroup className='mb-3' dir='rtl'>
          <Form.Control
            placeholder='965455555'
            aria-label="Recipient's username"
            aria-describedby='basic-addon2'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <FormControl variant='outlined'>
            <Select
              value={countryName}
              onChange={(e) => {
                const selectedCountry = countries.find((c) => c.name === e.target.value);
                setCountryName(e.target.value);
                console.log(countryName);
                setCountryCode(`+${selectedCountry.code}`);
              }}
              label='Country'
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.name}>
                  {country.name} (+{country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputGroup>
        <br />
        <Form.Label htmlFor='inputPassword5'>
          ادخل رقم الهاتف الخاص بالواتساب <i style={{ color: 'red' }}>*</i>
        </Form.Label>
        <InputGroup className='mb-3' dir='rtl'>
          <Form.Control
            placeholder='965455555'
            aria-label="Recipient's username"
            aria-describedby='basic-addon2'
            value={phoneWhats}
            onChange={(e) => setPhoneWhats(`${e.target.value}`)}
          />
          <FormControl variant='outlined'>
            <Select
              onChange={(e) => {
                const selectedCountry = countries.find((c) => c.name === e.target.value);
                setCodeWhats(`+${selectedCountry.code}`);
              }}
              label='Country'
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.name}>
                  {country.name} (+{country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputGroup>
        <br />
        <Form.Label htmlFor='inputPassword5'>كلمة المرور <i style={{ color: 'red' }}>*</i></Form.Label>
        <Form.Control
          dir='rtl'
          type='password'
          id='inputPassword5'
          aria-describedby='passwordHelpBlock'
          placeholder='*****'
          required
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <br />
        <Form.Label htmlFor='inputPassword5'>تأكيد كلمة المرور <i style={{ color: 'red' }}>*</i></Form.Label>
        <Form.Control
          dir='rtl'
          type='password'
          id='inputPassword5'
          aria-describedby='passwordHelpBlock'
          placeholder='*****'
          required
          onChange={(e) => setRepassword(e.target.value)}
        />
        <br />
        <div className='adduseradd' dir='ltr'>
          <button style={{ marginBottom: '8px', paddingTop: '5px' }} dir='ltr' className='' type='submit' onClick={handleClick}>
            إضافة
          </button>
        </div>
      </Container>
    </div>
  );
};

