import React, { useState } from "react";
import baggagelogo from "../../../layouts/Sidebar/Sidebaricons/baggagelogo.svg";
import trello from "../../../layouts/Sidebar/Sidebaricons/trello.svg";
import key from "../../../layouts/Sidebar/Sidebaricons/key.svg";
import packageicon from "../../../layouts/Sidebar/Sidebaricons/package.svg";
import menuicon from "../../../layouts/Sidebar/Sidebaricons/menu.svg";
import bookmarkicon from "../../../layouts/Sidebar/Sidebaricons/bookmark.svg";
import crediticon from "../../../layouts/Sidebar/Sidebaricons/creditcard.svg";
import messageicon from "../../../layouts/Sidebar/Sidebaricons/messagesquare.svg";
import staricon from "../../../layouts/Sidebar/Sidebaricons/star.svg";
import keyicon from "../../../layouts/Sidebar/Sidebaricons/key.svg";
import flagicon from "../../../layouts/Sidebar/Sidebaricons/flag.svg";
import alerticon from "../../../layouts/Sidebar/Sidebaricons/alertcircle.svg";
import usericon from "../../../layouts/Sidebar/Sidebaricons/users.svg";
import mailicon from "../../../layouts/Sidebar/Sidebaricons/mail.svg";
import airplayicon from "../../../layouts/Sidebar/Sidebaricons/airplay.svg";
import categoryicon from "../../../layouts/Sidebar/Sidebaricons/category.svg";
import chevrondownicon from "../../../layouts/Sidebar/Sidebaricons/chevrondown.svg";
import avataricon from "../../../layouts/Sidebar/Sidebaricons/avatar.svg";
import managementicon from "../../../layouts/Sidebar/Sidebaricons/user.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../layouts/Sidebar/Sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { Activeads } from "./Activeads";

function Sidebar() {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="wrapper">
      {/* <!-- Sidebar --> */}
      {!isCollapsed && (
        <aside id="sidebar" className={isCollapsed ? "collapsed" : ""}>
          <div className="h-100 side-bar">
            <div className="sidebar-logo">
              <img
                className="sidebar-logo-svg"
                src={baggagelogo}
                onClick={() => navigate("/")}
              />
              <a
                className="sidebar-logo-a"
                href="#"
                onClick={() => navigate("/")}
              >
                السوق الأبيض
              </a>
              <span className="sidebar-logo-span" onClick={() => navigate("/")}>
                WhiteSooq
              </span>
            </div>
            {/* <!-- Sidebar Navigation --> */}
            <ul className="sidebar-nav">
              <li className="sidebar-item">
                <Link to={"/statistics-page"}>
                  <img src={trello} />
                  <span className="sidebar-tap">لوحة القيادة</span>{" "}
                </Link>
              </li>
              {/* الباقات */}

              {/*  */}

              {/*  */}
              <li className="sidebar-item sidebar-item-drop">
                <Link
                  className="sidebar-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#packages"
                  aria-expanded="false"
                  aria-controls="packages"
                >
                  <img className="dropdown-img" src={packageicon} />
                  <span>الباقات</span>
                </Link>
                <Link to={"/activepackages-page"}>
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    الباقات النشطة
                  </span>{" "}
                  <br />
                </Link>
                <Link to={"/packagesdeleted-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    الباقات المحذوفة
                  </span>{" "}
                  <br />
                </Link>
              </li>
              {/* الباقات */}

              {/* الإعلانات */}
              <li className="sidebar-item sidebar-item-drop">
                <Link
                  className="sidebar-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#ads"
                  aria-expanded="false"
                  aria-controls="ads"
                >
                  <img className="dropdown-img" src={airplayicon} />
                  <span>الإعلانات</span>
                </Link>
                <Link to={"/ViewActiveAds-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    الإعلانات النشطة
                  </span>
                </Link>{" "}
                <br />
                <Link to={"/ViewWaitingAds-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    الإعلانات قيد الإنتظار
                  </span>
                </Link>
              </li>
              {/* الإعلانات */}

              {/* الفئات */}
              <li className="sidebar-item sidebar-item-drop">
                <Link
                  className="sidebar-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#category"
                  aria-expanded="false"
                  aria-controls="category"
                >
                  <img className="dropdown-img" src={categoryicon} />
                  <span>الفئات</span>
                </Link>
                <Link to={"/nonDeletedData-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    الفئات الغير محذوفة{" "}
                  </span>{" "}
                </Link>
                <br />
                <Link to={"/DeletedData-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    الفئات المحذوفة{" "}
                  </span>{" "}
                </Link>{" "}
                <br />
              </li>
              {/* الفئات */}

              <li className="sidebar-item">
                <img src={bookmarkicon} />
                <Link to={"/showOffers-page"}>
                  <span className="sidebar-tap">العروض</span>{" "}
                </Link>
              </li>

              <li className="sidebar-item">
                <img src={crediticon} />
                <Link to={"/paymentData-page"}>
                  <span className="sidebar-tap">الدفعات</span>{" "}
                </Link>
              </li>

              <li className="sidebar-item">
                <img src={messageicon} />
                <Link to={"/showComments-page"}>
                  <span className="sidebar-tap">التعليقات</span>{" "}
                </Link>
              </li>

              <li className="sidebar-item">
                <img src={staricon} />
                <Link to={"/rating-page"}>
                  <span className="sidebar-tap">التقييمات</span>{" "}
                </Link>
              </li>

              <li className="sidebar-item">
                <Link to={"/view-roles-page"}>
                  <img src={key} />
                  <span className="sidebar-tap">الأدوار</span>{" "}
                </Link>
              </li>

              <li className="sidebar-item">
                <img src={flagicon} />
                <Link to={"/warnings-page"}>
                  <span className="sidebar-tap">البلاغات</span>{" "}
                </Link>
              </li>

              <li className="sidebar-item">
                <img src={alerticon} />
                <Link to={"/showObjection-page"}>
                  <span className="sidebar-tap">الإعتراضات</span>{" "}
                </Link>
              </li>

              {/* المستخدمون */}
              <li className="sidebar-item sidebar-item-drop">
                <Link
                  className="sidebar-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#users"
                  aria-expanded="false"
                  aria-controls="users"
                >
                  <img className="dropdown-img" src={usericon} />
                  <span>المستخدمون</span>
                </Link>
                <Link to={"/activeUsers-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    المستخدمون النشطون
                  </span>
                </Link>{" "}
                <br />
                <Link to={"/bannedUsers-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    المستخدمون المحظورون
                  </span>
                </Link>{" "}
                <br />
                <Link to={"/askToDelete-page"}>
                  {" "}
                  <span
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    طلبات حذف الحساب
                  </span>
                </Link>{" "}
                <br />
              </li>

              <li className="sidebar-item">
                <Link to={"/management-page"}>
                  <img src={managementicon} />
                  <span className="sidebar-tap">الإدارة</span>{" "}
                </Link>
              </li>
              {/* المستخدمون */}

              <li className="sidebar-item">
                <img src={mailicon} />
                <Link to={"/messages-page"}>
                  <span className="sidebar-tap">الرسائل الواردة للموقع</span>{" "}
                </Link>
              </li>
            </ul>
          </div>
        </aside>
      )}
      {isCollapsed && (
        <ul className="sidebar-nav">
          <Link to="">
            <li className="sidebar-item">
              <img src={trello} />
            </li>
          </Link>
          {/* start packages */}

          <li className="sidebar-item sidebar-item-drop">
            <Link
              to={"/activepackages-page"}
              className="sidebar-link collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#packages"
              aria-expanded="false"
              aria-controls="packages"
            >
              <img className="dropdown-img" src={packageicon} />
            </Link>
          </li>

          {/* end packages */}

          {/* start ads */}

          <li className="sidebar-item sidebar-item-drop">
            <Link
              to={"/"}
              className="sidebar-link collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#ads"
              aria-expanded="false"
              aria-controls="ads"
            >
              <img className="sm-sidebar" src={airplayicon} />
            </Link>
          </li>

          {/* end ads */}

          {/* start categories */}

          <li className="sidebar-item sidebar-item-drop">
            <Link
              to={"/DeletedData-page"}
              className="sidebar-link collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#category"
              aria-expanded="false"
              aria-controls="category"
            >
              <img className="sm-sidebar" src={categoryicon} />
            </Link>
          </li>

          {/* end categories */}

          <li className="sidebar-item">
            <Link to={"/showOffers-page"}>
              <img src={bookmarkicon} />
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to={"/paymentData-page"}>
              <img src={crediticon} />
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to={"/showComments-page"}>
              <img src={messageicon} />
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to={"/rating-page"}>
              <img src={staricon} />
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to={"/warnings-page/:id"}>
              <img src={keyicon} />
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to={"/showObjection-page"}>
              <img src={flagicon} />
            </Link>
          </li>

          {/* Start Users */}

          <li className="sidebar-item sidebar-item-drop">
            <Link
              to={"/activeUsers-page"}
              className="sidebar-link collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#users"
              aria-expanded="false"
              aria-controls="users"
            >
              <img className="dropdown-img" src={usericon} />
            </Link>
          </li>

          {/* End Users */}

          <li className="sidebar-item">
            <Link to={"/messages-page"}>
              <img src={mailicon} />
            </Link>
          </li>
        </ul>
      )}
      <div className="main">
        <nav className="navbar-top navbar-expand px-3 border-bottom">
          <div className="navbar-top-content align-items-right">
            
            <button
              className="btn nav-btn-icon mt-4"
              type="button"
              data-bs-theme="dark"
              onClick={toggleSidebar}
            >
              <img className="navbar-toggler-icon" src={menuicon} />
            </button>
          </div>
        </nav>
        <Activeads />
      </div>
    </div>
  );
}

export default Sidebar;
