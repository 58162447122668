// @ts-nocheck
import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "./Category.css";
import user from "../../../../assest/images/Landingpagevisitor/categ/user.svg";
import clock from "../../../../assest/images/Landingpagevisitor/categ/clock.svg";
import heart from "../../../../assest/images/Landingpagevisitor/categ/heart.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import Snack from "components/Utilities/Snack";
import { filterDataContext } from "store/FilterDataContext";
export const Category = (props) => {
    console.log(props);
    const { authInfo } = useContext(AuthContext);
    const {filterData , changeFilterData} =useContext(filterDataContext);
    const navigate = useNavigate();
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });
    const setUpdateData = props.setUpdateData;
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: false,
                },
            },
            {
                breakpoint: 1290,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true,
                },
            },

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
        ],
    };
    const emptyStarIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
        >
            <g clipPath="url(#clip0_250_2664)">
                <path
                    d="M5.50191 1.45581L6.91775 3.71661L10.0839 4.08138L7.79291 5.84018L8.33358 8.3249L5.50191 7.15116L2.67024 8.3249L3.21092 5.84018L0.919922 4.08138L4.08608 3.71661L5.50191 1.45581Z"
                    stroke="#FFEE32"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_250_2664">
                    <rect
                        width="10.9968"
                        height="8.66762"
                        fill="white"
                        transform="translate(0.00341797 0.733521)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
    const filledStarIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
        >
            <path
                d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
                fill="#FFEE32"
            />
        </svg>
    );
    const countRatingZero = (
        <span className="starRating">
            <ul>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );
    const countRatingOne = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );
    const countRatingTow = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );
    const countRatingThree = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );
    const countRatingFourth = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );
    const countRatingFifth = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
            </ul>
        </span>
    );
    const heartIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
        >
            <path
                d="M11.4799 3.39442C11.2177 3.11053 10.9064 2.88534 10.5638 2.73169C10.2211 2.57804 9.8539 2.49896 9.48302 2.49896C9.11214 2.49896 8.7449 2.57804 8.40227 2.73169C8.05964 2.88534 7.74834 3.11053 7.48616 3.39442L6.94202 3.98331L6.39789 3.39442C5.86829 2.82126 5.14999 2.49926 4.40102 2.49926C3.65205 2.49926 2.93376 2.82126 2.40416 3.39442C1.87455 3.96758 1.57703 4.74496 1.57703 5.55553C1.57703 6.36611 1.87455 7.14348 2.40416 7.71664L2.94829 8.30553L6.94202 12.6278L10.9358 8.30553L11.4799 7.71664C11.7422 7.43289 11.9503 7.09598 12.0923 6.72517C12.2342 6.35436 12.3073 5.95691 12.3073 5.55553C12.3073 5.15415 12.2342 4.7567 12.0923 4.38589C11.9503 4.01508 11.7422 3.67817 11.4799 3.39442Z"
                stroke="#1D3557"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
    const deletIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
        >
            <path
                d="M13.3819 2.77308H2.59758L3.57797 14.1176H11.4211L12.4015 2.77308H1.61719M7.49954 5.60922V11.2815M9.95052 5.60922L9.46033 11.2815M5.04856 5.60922L5.53876 11.2815M5.53876 2.77308L6.02895 0.882324H8.97013L9.46033 2.77308"
                stroke="#1D3557"
                strokeWidth="1.56"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
    return (
        <>
            <Snack alert={alert} />
            <div className="category" id="category">
                <Container>
                    {props?.checkCategory !== "favorite" || "category" ? (
                        <>
                            <h3>
                                {" "}
                                {props?.checkCategory === "All"
                                    ? "جميع الإعلانات"
                                    : props?.data?.name}{" "}
                            </h3>
                            <div className="buttton" style={{  direction:'ltr' }}>
                                {props?.checkCategory === "All" ? (
                                    <button className="btn-category">
                                        الكل
                                    </button>
                                ) : (
                                    props?.data?.tags?.map((item) => {
                                        return (
                                            <button
                                                className="btn-category"
                                                key={item.id}
                                                onClick={() =>
                                                    {
                                                        const updatedFilterData = { ...filterData, tag: item.name };
                                                        changeFilterData(updatedFilterData);
                                                    }
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        );
                                    })
                                )}
                                </div>
                        </>
                    ) : (
                        <div style={{display:"none"}}></div>
                    )}
                    <div className="cards" >
                        <Slider {...settings}>
                            {props.checkCategory === "All" ||
                            props.checkCategory === "favorite"||props.checkCategory === "category"
                                ? props?.data?.map((item) => {
                                      return (
                                          <div
                                              className="carditem"
                                              key={item.id}
                                              style={{ position: "relative" }}
                                             
                                          >
                                              <div className="heart">
                                                  {props.checkCategory ===
                                                  "favorite" ? (
                                                      <button
                                                          onClick={() => {
                                                              axios
                                                                  .delete(
                                                                      `https://api.whitesooq.com/api/v1/favorites/${item.fav_id}`,
                                                                      {
                                                                          headers:
                                                                              {
                                                                                  accept: "application/json",
                                                                                  app_key:
                                                                                      "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                                                                  authorization:
                                                                                      "Bearer " +
                                                                                      authInfo.token,
                                                                              },
                                                                      }
                                                                  )
                                                                  .then(
                                                                      (
                                                                          response
                                                                      ) => {
                                                                          console.log(
                                                                              response.data
                                                                          );
                                                                          setUpdateData(
                                                                              (
                                                                                  prevState
                                                                              ) =>
                                                                                  !prevState
                                                                          );
                                                                          setAlert(
                                                                              {
                                                                                  open: true,
                                                                                  type: "success",
                                                                                  message:
                                                                                      "تمَّ الحذف بنجاح",
                                                                              }
                                                                          );
                                                                      }
                                                                  )
                                                                  .catch(
                                                                      (
                                                                          Error
                                                                      ) => {
                                                                          console.log(
                                                                              Error
                                                                          );
                                                                          if (
                                                                              Error
                                                                                  .response
                                                                                  .status ===
                                                                              401
                                                                          ) {
                                                                              setAlert(
                                                                                  {
                                                                                      open: true,
                                                                                      type: "error",
                                                                                      message:
                                                                                          "يرجى التاكد من تسجيل الدخول",
                                                                                  }
                                                                              );
                                                                          }
                                                                      }
                                                                  );
                                                          }}
                                                      >
                                                          {deletIcon}
                                                      </button>
                                                  ) : (
                                                      <button
                                                      
                                                          onClick={() => {
                                                            
                                                              axios
                                                                  .post(
                                                                      `https://api.whitesooq.com/api/v1/favorites/${item.id}`,
                                                                      "",
                                                                      {
                                                                          headers:
                                                                              {
                                                                                  accept: "application/json",
                                                                                  app_key:
                                                                                      "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                                                                  authorization:
                                                                                      "Bearer " +
                                                                                      authInfo.token,
                                                                              },
                                                                      }
                                                                  )
                                                                  .then(
                                                                      (
                                                                          response
                                                                      ) => {
                                                                          console.log(
                                                                              response.data
                                                                          );
                                                                          setAlert(
                                                                              {
                                                                                  open: true,
                                                                                  type: "success",
                                                                                  message:
                                                                                      "تمَّ الأضافة بنجاح",
                                                                              }
                                                                          );
                                                                      }
                                                                  )
                                                                  .catch(
                                                                      (
                                                                          Error
                                                                      ) => {
                                                                          console.log(
                                                                              Error
                                                                          );
                                                                          if (
                                                                              Error
                                                                                  .response
                                                                                  .status ===
                                                                              401
                                                                          ) {
                                                                              setAlert(
                                                                                  {
                                                                                      open: true,
                                                                                      type: "error",
                                                                                      message:
                                                                                          "يرجى التاكد من تسجيل الدخول",
                                                                                  }
                                                                              );
                                                                          }
                                                                      }
                                                                  );
                                                          }}
                                                      >
                                                          {heartIcon}
                                                      </button>
                                                  )}
                                              </div>
                                              <img src={item.all_images[0]}   onClick={()=>{
                                                {item.type === "buy" ? 
                                                navigate(`/detailsbuy/${item.id}`)
                                                :
                                                navigate(`/AdDetail/${item.id}`)
                                              }}}/>
                                              {item?.pinable === 1 ? (
                                                  <div
                                                      className="adsmm"
                                                      style={{
                                                          position: "absolute",
                                                          top: "3%",
                                                          backgroundColor:
                                                              "orange",
                                                          width: "100px",
                                                          height: "40px",
                                                          borderRadius:
                                                              "30px 10px 20px 30px",
                                                          marginLeft: "115px",
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          alignItems: "center",
                                                          paddingTop: "12px",
                                                      }}
                                                      onClick={()=>{
                                                        {item.type === "buy" ? 
                                                        navigate(`/detailsbuy/${item.id}`)
                                                        :
                                                        navigate(`/AdDetail/${item.id}`)
                                                      }}}
                                                  >
                                                      {" "}
                                                      <p
                                                          style={{
                                                              color: "#fff",
                                                          }}
                                                      >
                                                          {" "}
                                                          إعلان مميز
                                                      </p>
                                                  </div>
                                              ) : (
                                                  <></>
                                              )}

                                              <div className="nameandprice d-flex"  onClick={()=>{
                                                {item.type === "buy" ? 
                                                navigate(`/detailsbuy/${item.id}`)
                                                :
                                                navigate(`/AdDetail/${item.id}`)
                                              }}}>
                                                  <h4 style={{ direction:'ltr', width:'50px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }} >{item.price}</h4>
                                                  {item.type === "buy" ? (
                                                      <Link
                                                          style={{ textDecoration:'none'}}
                                                          to={`/detailsbuy/${item.id}`}
                                                      >
                                                          <h4 style={{ direction:'rtl', width:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }}>{item.title} </h4>
                                                      </Link>
                                                  ) : (
                                                      <Link
                                                          style={{ textDecoration:'none'}}
                                                          to={`/AdDetail/${item.id}`}
                                                      >
                                                          <h4 style={{ direction:'rtl', width:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }}>{item.title} </h4>
                                                      </Link>
                                                  )}
                                              </div>
                                              <h5>{item.description}</h5>
                                              <div className="details d-flex"  onClick={()=>{
                                                {item.type === "buy" ? 
                                                navigate(`/detailsbuy/${item.id}`)
                                                :
                                                navigate(`/AdDetail/${item.id}`)
                                              }}}>
                                                  <span>
                                                      {item.from}{" "}
                                                      <img src={clock} />
                                                  </span>
                                                  <span>
                                                      <i
                                                          style={{
                                                              cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                              if (
                                                                  authInfo.user
                                                                      ?.id ==
                                                                  item.id
                                                              ) {
                                                                  navigate(
                                                                      "/statics"
                                                                  );
                                                              } else
                                                                  navigate(
                                                                      `/profile/${item.id}`
                                                                  );
                                                          }}
                                                      >
                                                          {" "}
                                                          {
                                                              item.user.username
                                                          }{" "}
                                                      </i>
                                                      <img src={user} />
                                                  </span>
                                              </div>
                                              <div className=" rating">
                                                  <h6
                                                      style={{
                                                          paddingBottom: "10px",
                                                      }}
                                                  >
                                                      {item.rating_count}{" "}
                                                  </h6>
                                                  <span>
                                                      {item.rating == 0
                                                          ? countRatingZero
                                                          : item.rating == 1
                                                          ? countRatingOne
                                                          : item.rating == 2
                                                          ? countRatingTow
                                                          : item.rating == 3
                                                          ? countRatingThree
                                                          : item.rating == 4
                                                          ? countRatingFourth
                                                          : item.rating == 5
                                                          ? countRatingFifth
                                                          : "invalid Rating"}
                                                  </span>
                                              </div>
                                          </div>
                                      );
                                  })
                                : props?.data?.ads?.map((item) => {
                                      return (
                                          <div
                                              className="carditem"
                                              key={item.id}
                                              
                                          >
                                              <div className="heart">
                                                  <button
                                                      onClick={() => {
                                                          axios
                                                              .post(
                                                                  `https://api.whitesooq.com/api/v1/favorites/${item.id}`,
                                                                  "",
                                                                  {
                                                                      headers: {
                                                                          accept: "application/json",
                                                                          app_key:
                                                                              "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                                                          authorization:
                                                                              "Bearer " +
                                                                              authInfo.token,
                                                                      },
                                                                  }
                                                              )
                                                              .then(
                                                                  (
                                                                      response
                                                                  ) => {
                                                                      console.log(
                                                                          response.data
                                                                      );
                                                                      setAlert({
                                                                          open: true,
                                                                          type: "success",
                                                                          message:
                                                                              "تمَّ الأضافة بنجاح",
                                                                      });
                                                                  }
                                                              )
                                                              .catch(
                                                                  (Error) => {
                                                                      console.log(
                                                                          Error
                                                                      );
                                                                      if (
                                                                          Error
                                                                              .response
                                                                              .status ===
                                                                          401
                                                                      ) {
                                                                          setAlert(
                                                                              {
                                                                                  open: true,
                                                                                  type: "error",
                                                                                  message:
                                                                                      "يرجى التاكد من تسجيل الدخول",
                                                                              }
                                                                          );
                                                                      }
                                                                  }
                                                              );
                                                      }}
                                                  >
                                                      {heartIcon}
                                                  </button>
                                              </div>
                                              <img src={item.all_images[0]} onClick={()=>{
                                                {item.type === "buy" ? 
                                                navigate(`/detailsbuy/${item.id}`)
                                                :
                                                navigate(`/AdDetail/${item.id}`)
                                              }}}/>
                                              {item?.pinable === 1 ? (
                                                  <div
                                                  onClick={()=>{
                                                    {item.type === "buy" ? 
                                                    navigate(`/detailsbuy/${item.id}`)
                                                    :
                                                    navigate(`/AdDetail/${item.id}`)
                                                  }}}
                                                      className="adsmm"
                                                      style={{
                                                          position: "absolute",
                                                          top: "3%",
                                                          backgroundColor:
                                                              "orange",
                                                          width: "100px",
                                                          height: "40px",
                                                          borderRadius:
                                                              "30px 10px 20px 30px",
                                                          marginLeft: "115px",
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          alignItems: "center",
                                                          paddingTop: "12px",
                                                      }}
                                                  >
                                                      {" "}
                                                      <p
                                                          style={{
                                                              color: "#fff",
                                                          }}
                                                      >
                                                          {" "}
                                                          إعلان مميز
                                                      </p>
                                                  </div>
                                              ) : (
                                                  <></>
                                              )}
                                              <div className="nameandprice d-flex" onClick={()=>{
                                                {item.type === "buy" ? 
                                                navigate(`/detailsbuy/${item.id}`)
                                                :
                                                navigate(`/AdDetail/${item.id}`)
                                              }}}>
                                                  <h4 style={{ direction:'ltr', width:'50px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }}>{item.price}</h4>
                                                  {item.type === "buy" ? (
                                                      <Link
                                                          style={{ textDecoration:'none'}}
                                                          to={`/detailsbuy/${item.id}`}
                                                      >
                                                          <h4 style={{ direction:'rtl', width:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }}>{item.title} </h4>
                                                      </Link>
                                                  ) : (
                                                      <Link
                                                          style={{ textDecoration:'none'}}
                                                          to={`/AdDetail/${item.id}`}
                                                      >
                                                          <h4 style={{ direction:'rtl', width:'150px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' }}>{item.title} </h4>
                                                      </Link>
                                                  )}
                                              </div>
                                              <h5>{item.description}</h5>
                                              <div className="details d-flex">
                                                  <span onClick={()=>{
                                                {item.type === "buy" ? 
                                                navigate(`/detailsbuy/${item.id}`)
                                                :
                                                navigate(`/AdDetail/${item.id}`)
                                              }}}>
                                                      {item.from}{" "}
                                                      <img src={clock} />
                                                  </span>
                                                  <span onClick={()=>{
                                                {item.type === "buy" ? 
                                                navigate(`/detailsbuy/${item.id}`)
                                                :
                                                navigate(`/AdDetail/${item.id}`)
                                              }}}>
                                                      <i
                                                          style={{
                                                              cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                              if (
                                                                  authInfo.user
                                                                      ?.id ==
                                                                  item.id
                                                              ) {
                                                                  navigate(
                                                                      "/statics"
                                                                  );
                                                              } else
                                                                  navigate(
                                                                      `/profile/${item.id}`
                                                                  );
                                                          }}
                                                      >
                                                          {" "}
                                                          {
                                                              item.user.username
                                                          }{" "}
                                                      </i>
                                                      <img src={user} />
                                                  </span>
                                              </div>
                                              <div className=" rating">
                                                  <h6
                                                      style={{
                                                          paddingBottom: "10px",
                                                      }}
                                                  >
                                                      {item.rating_count}{" "}
                                                  </h6>
                                                  <span>
                                                      {item.rating == 0
                                                          ? countRatingZero
                                                          : item.rating == 1
                                                          ? countRatingOne
                                                          : item.rating == 2
                                                          ? countRatingTow
                                                          : item.rating == 3
                                                          ? countRatingThree
                                                          : item.rating == 4
                                                          ? countRatingFourth
                                                          : item.rating == 5
                                                          ? countRatingFifth
                                                          : "invalid Rating"}
                                                  </span>
                                              </div>
                                          </div>
                                      );
                                  })}
                        </Slider>
                    </div>
                </Container>
            </div>
        </>
    );
};
