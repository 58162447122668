import React from 'react'
import { Container, Image, Form } from 'react-bootstrap'
import { InsideTest } from '../InsideTest/InsideTest'
import { Topcategory } from './Topcategory'
import home from './../admin-image/categ/home.svg'
import './topcategory.css'
import Home from './Home.svg'

export const CategoryTets = () => {
  return (
  
    <div>
        <div style={{backgroundColor:"#F2F2F2"}}>
        <div className='homing d-flex' dir='rtl'>
            <Form.Label style={{display:"flex", direction:"rtl", alignItems:"flex-start", marginRight:"25px", marginTop:"16px" }}>
              <Image style={{ marginTop:'-2px' }} src={Home} />
              <h4 style={{ color:"#1D3557", fontSize:"18px", marginRight:"5px" }}>الفئات المحذوفة / فئة تجريبية</h4>
            </Form.Label>
        </div>
        <Container style={{borderRadius: "8px",
background: "#FFF",padding:"40px"}}>
        <Topcategory/>
<InsideTest/>
</Container>

    </div>
    </div>
  )
}
