import React, { useState } from "react";
import { Button, Container, Modal, ModalFooter, ModalHeader } from "react-bootstrap";
import "./beforefinal.css";
import "../../Landingpagevisitor/Viewmore/Viewmore.css";
import app from "../../../../assest/images/Productdetailsbuy/categ/app.svg";
import google from "../../../../assest/images/Productdetailsbuy/categ/play.svg";
import { Link } from "react-router-dom";
export const Beforefinal = () => {
  const [showAlert, setShowAlert] = useState(false);

    const handleClick = () => {
      setShowAlert(true);
    };
  return (
    <div className="beforefinal">
      <Container>
        <div className="row">
          {/* <button className="more">شاهد المزيد</button> */}
        </div>
        <div className="row6"></div>
        <div className="downloadimage d-flex justify-content-center">
          <img src={app} alt="App Store"  onClick={handleClick} />
              <Modal
                show={showAlert}
                onHide={() => setShowAlert(false)}
                style={{ direction: "rtl" }}
                backdrop="static"
                centered
              >
                <ModalHeader>
                  <Modal.Title className="nneext">انتظرونا في الإصدارات القادمة</Modal.Title>
                </ModalHeader>
                <ModalFooter>
                  <Button
                  className="nneext-button"
                    variant="secondary"
                    onClick={() => setShowAlert(false)}
                    style={{
                      backgroundColor: "#1D3557",
                    }}
                  >
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>

              <Link
                className="lin-footer"
                target="_blank"
                to="https://play.google.com/store/apps/details?id=online.whitesooq"
                style={{ textDecoration: "none" }}
              >
                <img className="erere" src={google} alt="Google Play"/>
              </Link>
        </div>
      </Container>
    </div>
  );
};
