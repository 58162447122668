import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography, Container, Button } from "@mui/material";
import classes from "./ActivePackages.module.css";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import nextPackage from "../../../assest/images/Admin/LeftAndRightButtons/arrow-right-circle.png";
import prevPackage from "../../../assest/images/Admin/LeftAndRightButtons/arrow-left-circle.png";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";
import { Add } from "@mui/icons-material";
import { Check, Close } from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const PackagesForAdmin = () => {
  const [packages, setPackages] = useState([]);
  const [allData, setAllData] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const fetchPackagesHandler = useCallback(async () => {
    setError(null);
    try {
      const response = await fetch(
        "https://api.whitesooq.com/api/v1/packages",
        {
          method: "GET",
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "حدث خطأ أثناء تحميل صفحة الباقات يرجى اعادة المحاولة مرة أخرى"
        );
      }
      const data = await response.json();
      setAllData(data);
      // console.log(data);
      if (data.status) {
        const loadedPackages = [];
        for (const key in data.packages) {
          loadedPackages.push({
            id: data.packages[key].id,
            name: data.packages[key].name,
            type: data.packages[key].type,
            buyAdsLimit: data.packages[key].buy_ads_limit,
            buyAdsResultable: data.packages[key].buy_ads_resultable,
            buyAdsUpdateable: data.packages[key].buy_ads_updateable,
            buyAdsValidity: data.packages[key].buy_ads_validity,
            cost: data.packages[key].cost,
            hideOffer: data.packages[key].hide_offer,
            offersLimit: data.packages[key].offers_limit,
            validity: data.packages[key].validity,
            saleAdsLimit: data.packages[key].sale_ads_limit,
            saleAdsResultable: data.packages[key].sale_ads_resultable,
            saleAdsUpdateable: data.packages[key].sale_ads_updateable,
            saleAdsValidity: data.packages[key].sale_ads_validity,
            pinAble: data.packages[key].pinable,
            pinAbleValidity: data.packages[key].pinable_validity,
            offerHighlighting: data.packages[key].offer_highlighting,
            serviceDiscount: data.packages[key].service_discount,
            description: data.packages[key].description,
          });
        }
        setPackages(loadedPackages);
      }
    } catch (error) {
      setError(error.message);
    }
  }, []);
  useEffect(() => {
    fetchPackagesHandler();
  }, [fetchPackagesHandler]);

  const carousel = useRef(null);
  const handleLeftClick = (event) => {
    event.preventDefault();
    carousel.current.scrollLeft -= 320;
  };
  const handleRightClick = (event) => {
    event.preventDefault();
    carousel.current.scrollLeft += 320;
  };
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "25px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          الباقات النشطة
        </h4>
      </Form.Label>
      <Container sx={{ my: { xs: 3, sm: 1 } }}>
        <Box
          dir="rtl"
          style={{
            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
          }}
          sx={{ width: { xs: 310, sm: 620, md: 1000 } }}
          className={classes.PackagesTitleAdmin}
        >
          {/* Start title Box */}
          <Box
            sx={{ textAlign: "right", p: "25px 15px" }}
            className={classes.boxTitle}
          >
            <Typography className={classes.title} variant="h5" component="h5">
              عرض الباقات النشطة
            </Typography>
            <Box className={classes.boxAddPackage}>
              <Button
                sx={{
                  px: { xs: 2, sm: 4 },
                  py: 0,
                  fontWeight: { xs: 600, sm: 800 },
                  fontSize: { xs: "18px", sm: "25px" },
                  color: "#fff",
                  backgroundColor: "#f9a703",
                  "&:hover": {
                    color: "#f9a703",
                    backgroundColor: "#fff",
                  },
                }}
                startIcon={<Add sx={{ ml: 1 }} />}
                onClick={() => navigate("/addpackage-page")}
              >
                إضافة باقة
              </Button>
              <Typography
                sx={{
                  fontSize: "22px",
                  mr: 2,
                  fontWeight: 800,
                  color: "#457b9d",
                }}
                component="p"
              >
                عدد الباقات {allData?.count}
              </Typography>
            </Box>
          </Box>
          {/* End title Box */}
          {/* Start packages Box */}
          <div
            className={classes.carousel}
            ref={carousel}
            style={{ direction: "ltr" }}
          >
            {packages?.map((pack) => (
              <Grid item key={pack.id}>
                <Box
                  className={classes.boxPackages}
                  sx={{
                    height: 625,
                  }}
                >
                  <Card
                    variant="outlined"
                    className={classes.package}
                    sx={{
                      border: `2px solid ${
                        pack.type === "private" ? "#f9a703" : "#A8DADC"
                      }`,
                    }}
                  >
                    <CardContent>
                      {/*Start section different */}
                      <Typography className={classes.different}>
                        {pack.type === "private" ? "مميز" : ""}
                      </Typography>
                      {/*End section different */}
                      {/*Start section type */}
                      <Typography
                        className={classes.type}
                        sx={{
                          fontSize: 15,
                          textAlign: "right",
                          pr: 2,
                          pt: 1,
                          color: "#1d3557",
                          fontWeight: "800",
                        }}
                        gutterBottom
                      >
                        {pack.name}
                      </Typography>
                      {/*End section type */}
                      {/*Start section price */}
                      <Typography
                        variant="h3"
                        component="h3"
                        className={classes.price}
                        sx={{
                          color: "#1d3557 !important",
                          textAlign: "center",
                          pl: 5,
                          my: 1,
                          fontSize: "35px !important",
                          fontWeight: "bold",
                        }}
                      >
                        {pack.id === 1 ? "مجانية" : pack.cost}
                      </Typography>
                      {/*End section price */}
                      {/*Start section line */}
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            pack.type === "private" ? "#FB8500" : "#A8DADC",
                          mb: 2,
                        }}
                      >
                        __________________________________
                      </Typography>
                      {/*End section line */}
                      {/*Start section description */}
                      <Typography
                        sx={{ textAlign: "right" }}
                        // @ts-ignore
                        variant="div"
                      >
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .صلاحية الباقة {pack.validity} يوم
                          </p>
                          {pack.validity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            {`.مدة صلاحية إعلانات بيع ${pack.saleAdsValidity} أيام `}
                          </p>
                          {pack.saleAdsValidity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            {` .  حد نشر   ${pack.saleAdsLimit}  إعلانات بيع  `}
                            {pack.saleAdsLimit === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            {`.السماح بتعديل إعلانات البيع `}
                            {pack.saleAdsUpdateable === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .السماح بالحصول على إحصائيات إعلانات البيع
                          </p>
                          {pack.saleAdsResultable === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            {`.مدة صلاحية إعلانات الشراء ${pack.buyAdsValidity}  أيام`}
                          </p>
                          {pack.buyAdsValidity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            {`.حد نشر ${pack.buyAdsLimit} إعلانات شراء  `}
                            {pack.buyAdsLimit === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            .السماح بتعديل إعلانات الشراء
                            {pack.buyAdsUpdateable === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .السماح بالحصول على إحصائيات إعلانات الشراء
                          </p>
                          {pack.buyAdsResultable === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .تثبيت الإعلانات
                          </p>
                          {pack.pinAble === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .مدة صلاحية تثبيت الإعلان {pack.pinAbleValidity}
                          </p>
                          {pack.pinAble === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .نمييز العرض
                          </p>
                          {pack.offerHighlighting === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .إخفاء قيمة العرض
                          </p>
                          {pack.hideOffer === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .حد نشر {pack.offersLimit} عروض
                          </p>
                          {pack.offersLimit === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .خصومات الخدمات
                          </p>
                          {pack.serviceDiscount === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                      </Typography>
                      {/*End section description */}
                    </CardContent>
                    {/*Start section button */}
                    <Link to={`/activepackages-page/${pack.id}`}>
                      <Button
                        className={classes.btnDetails}
                        sx={{
                          bgcolor:
                            pack.type === "private" ? "#FB8500" : "#457B9D",
                          "&:hover": {
                            color:
                              pack.type === "private" ? "#FB8500" : "#457B9D",
                            bgcolor: "#fff",
                            outline: "none",
                            border: "none",
                          },
                        }}
                        variant="outlined"
                        // onClick={() => handleDetails(pack.id)}
                      >
                        {/* navigate(`/activepackages-page/${packageId}`); */}
                        تفاصيل الباقة
                      </Button>
                    </Link>
                    {/*End section button */}
                  </Card>
                  {error}
                </Box>
              </Grid>
            ))}
          </div>
          <div className={classes.buttons}>
            <button onClick={handleRightClick}>
              <img src={nextPackage} alt="Scroll Left" />
            </button>
            <button onClick={handleLeftClick}>
              <img src={prevPackage} alt="Scroll Right" />
            </button>
          </div>
          {/* End packages Box */}
        </Box>
      </Container>
    </div>
  );
};
export default PackagesForAdmin;
