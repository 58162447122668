// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Buyandsell from "../../components/User/Landingpagevisitor/Buyandsell/Buyandsell";
import { Category } from "../../components/User/Landingpagevisitor/Category/Category";
import { Navigation } from "../../components/User/Landingpagevisitor/Navigation/Navigation";
import { Popularity } from "../../components/User/Landingpagevisitor/Popularity/Popularity";
import { Searchbar } from "../../components/User/Landingpagevisitor/Searchbar/Searchbar";
import { Shopingeverywhere } from "../../components/User/Landingpagevisitor/Shopingeverywhere/Shopingeverywhere";
import { Viewmore } from "../../components/User/Landingpagevisitor/Viewmore/Viewmore";
import Footer from "../../layouts/Footer/Footer";
import Headerlogin from "../../layouts/Headerlogin/Headerlogin";
import { filterDataContext } from "store/FilterDataContext";
import Favorite from "components/User/favorite/Favorite";
import googleplay from "../../assest/images/Landingpagevisitor/shopingeverywhere/googleplay.svg";
import appstore from "../../assest/images/Landingpagevisitor/shopingeverywhere/appstore.svg";
import { ViewMoreContext } from "store/ViewMoreContext";
import { AuthContext } from "store/UseAuthContext";
import { Button, Modal, ModalFooter, ModalHeader } from "react-bootstrap";
import { Link } from "react-router-dom";

const Landingpagevisitor = (props) => {
    const { filterData } = useContext(filterDataContext);
    const { ViewMore } = useContext(ViewMoreContext);
    const { authInfo } = useContext(AuthContext);
    const [popular, setpopular] = useState([{}]);
    const [popularNav, setpopularNav] = useState([{}]);
    const [popularBottom, setpopularBottom] = useState([{}]);
    const [All, setAll] = useState([{}]);
    const [All_Ads, setAll_Ads] = useState([]);
    const [menu, setMenu] = useState([]);
    const [category, setCategory] = useState([]);
    const [mainCategory, setMainCategory] = useState([]);
    const [MoreCategory, setMoreCategory] = useState([]);
    const [checkViewMore, setcheckViewMore] = useState("");
    const usertoken = authInfo.token;

    let url = `https://api.whitesooq.com/api/v1/home/data?${
        filterData.category ? `category=${filterData.category}&` : ""
    }${filterData.sortBy ? filterData.sortBy + "&" : ""}${
        filterData.minPrice && filterData.maxPrice
            ? `price=${filterData.minPrice}to${filterData.maxPrice}&`
            : ""
    }${filterData.type ? `type=${filterData.type}&` : ""}${
        filterData.search ? `search=${filterData.search}` : ""
    }${filterData.tag ? `tag=${filterData.tag}` : ""}`;

    const headers = {
        accept: "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
    };

    if (usertoken) {
        headers["Authorization"] = `Bearer ${usertoken}`;
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(url, {
                headers: headers,
            });
            setCategory(response.data.all);
            setpopular(response.data.popular);
            setAll_Ads(response.data.all_ads);
            setAll(response.data.all);
            setMenu(response.data.menu);
            handleLoad(response.data.all, response.data.popular);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleLoad = (allData, popularData) => {
        if (popularData.length <= 7) {
            setpopularNav(popularData);
            setpopularBottom(popularData);
        } else {
            setpopularNav(popularData.slice(0, 7));
            setpopularBottom(popularData.slice(8, popularData.length));
        }
        if (allData.length > 2) {
            setMainCategory(allData.slice(0, 1));
            setMoreCategory(allData.slice(2, 6));
            setcheckViewMore("AddViewMore");
        } else {
            setMainCategory(allData);
        }
    };

    useEffect(() => {
        fetchData();
    }, [url]);

    useEffect(() => {
        handleLoad(All, popular);
    }, [All, popular]);

    const [showAlert, setShowAlert] = useState(false);

    const handleClick = () => {
      setShowAlert(true);
    };

    return (
        <div onLoad={handleLoad}>
            <Headerlogin />
            <Navigation data={popularNav} listData={menu} />
            <div id="searchbar">
                <Searchbar data={All} />
            </div>
            {props.type === "favorite" ? (
                <>
                    <Favorite />
                </>
            ) : (
                <>
                    <Buyandsell data={All} />
                    <Category data={All_Ads} checkCategory="All" />
                    {mainCategory.length > 0 &&
                        mainCategory?.map((category) => (
                            <Category key={category.id} data={category} checkCategory="daw" />
                        ))}
                    {category.length <= 2 ? (
                        <></>
                    ) : ViewMore === "" ? (
                        <Viewmore check={checkViewMore} />
                    ) : (
                        <>
                            {MoreCategory.map((category) => (
                                <Category key={category.id} data={category} checkCategory="daw" />
                            ))}
                        </>
                    )}
                </>
            )}
            <Popularity data={popularBottom} />

            {props.type === "favorite" ? (
                <>
                    <div className="downloadimage d-flex justify-content-center">
                        <img src={appstore} alt="App Store"  onClick={handleClick} />
                        <Modal
                        show={showAlert}
                        onHide={() => setShowAlert(false)}
                        style={{ direction: "rtl" }}
                        backdrop="static"
                        centered
                      >
                        <ModalHeader>
                          <Modal.Title className="nneext">انتظرونا في الإصدارات القادمة</Modal.Title>
                        </ModalHeader>
                        <ModalFooter>
                          <Button
                          className="nneext-button"
                            variant="secondary"
                            onClick={() => setShowAlert(false)}
                            style={{
                              backgroundColor: "#1D3557",
                            }}
                          >
                            إغلاق
                          </Button>
                        </ModalFooter>
                      </Modal>

              <Link
                className="lin-footer"
                target="_blank"
                to="https://play.google.com/store/apps/details?id=online.whitesooq"
                style={{ textDecoration: "none" }}
              >
                <img className="erere" src={googleplay} alt="Google Play"/>
              </Link>
                    </div>
                </>
            ) : (
                <>
                    <Shopingeverywhere />
                </>
            )}

            <Footer />
        </div>
    );
};

export default Landingpagevisitor;
