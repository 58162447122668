import { Stack, Button, Box } from "@mui/material";
import { BarChart} from "@mui/x-charts/BarChart";
import { useState, useEffect } from "react";

export default function Section2({ data }) {
    // console.log(data);
    // charts
    const [yAxisData, setYAxisData] = useState();
    const [activeButton, setActiveButton] = useState(0);
    const handelClick = (i) => {
        setActiveButton(i);
    };

    const x = [5, 6, 7];
    const y = x.slice(0, x.length);
    // console.log(y.reverse(), x);
    useEffect(() => {
        if (data) {
            let yData = [];
            if (activeButton === 0) {
                yData = data.users_count.slice(0, data.users_count.length);
                // console.log(data.users_count);
                // console.log(yData);
                setYAxisData(yData.reverse());
            } else if (activeButton === 1) {
                yData = data.sale_ads_count.slice(0, data.sale_ads_count.length);
                setYAxisData(yData.reverse());
            } else if (activeButton === 2) {
                yData = data.buy_ads_count.slice(0, data.buy_ads_count.length);
                setYAxisData(yData.reverse());
            } else if (activeButton === 3) {
                yData = data.offers_count.slice(0, data.offers_count.length);
                setYAxisData(yData.reverse());
            }
        }
    }, [data, activeButton]);
    return (
        <Box sx={{ p: 3, bgcolor: "#F7F7F7", borderRadius: 5 }}>
            <Stack direction={"row"} flexWrap={"wrap"}>
                {buttonsTitles.map((botton, i) => (
                    <MyButton
                        sx={{
                            color: activeButton === i ? "#1D3557" : "#1D355780",
                        }}
                        onClick={() => handelClick(i)}
                        key={botton}
                    >
                        {botton}
                    </MyButton>
                ))}
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} dir={"ltr"}>
                <BarChart
                    width={1024}
                    height={400}
                    series={[
                        {
                            data: yAxisData
                                ? yAxisData
                                : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            id: "pvId",
                        },
                    ]}
                    colors={[commonColor]}
                    xAxis={[{ data: xLabels, scaleType: "band" }]}
                />
            </Stack>
        </Box>
    );
}
//Buttons
const buttonsTitles = [
    "عدد المستخدمين",
    "عدد إعلانات البيع",
    "عدد إعلانات الشراء",
    "عدد العروض",
];

const xLabels = [
    "ديسمبر",
    "نوفمبر",
    "أكتوبر",
    "سبتمبر",
    "أغسطس",
    "يوليو",
    "يونيو",
    "مايو",
    "أبريل",
    "مارس",
    "فبراير",
    "يناير",
];
const commonColor = "#1D3557";

function MyButton({ children, onClick, sx }) {
    return (
        <Button
            sx={{
                ...sx,
                fontWeight: 600,
                fontSize: 15,
                fontFamily: "Noto Sans Arabic",
            }}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}
