// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import "./AddRole.css";
import { Alert, Form, Modal, ModalFooter, ModalHeader } from "react-bootstrap";
import { Button } from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { Link, useParams } from "react-router-dom";
const AddRole = (props) => {
  const params = useParams();
  const authInfo = useContext(AuthContext);
  const [showAdd, setshowAdd] = useState(false);
  const [role, setRole] = useState({
    roleId: "",
    userId: "",
  });

  //  getting the data from the inputs
  const handleChange = (e) => {
    setRole({ ...role, [e.target.name]: e.target.value });
    console.log(role);
  };
  // create a request
  const AddRoleRequest = () => {
    axios
      .post(
        `https://api.whitesooq.com/api/v1/admins/${role.userId}/${role.roleId}`,
        {},
        {
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            authorization: `Bearer ${authInfo.authInfo.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setshowAdd(true);
      })
      .catch((Error) => console.log(Error));
  };
  // send the request
  const handleClick = (e) => {
    e.preventDefault();
    AddRoleRequest();
  };

  const HomeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
    >
      <path
        d="M3 7.875L12 1.75L21 7.875V17.5C21 17.9641 20.7893 18.4092 20.4142 18.7374C20.0391 19.0656 19.5304 19.25 19 19.25H5C4.46957 19.25 3.96086 19.0656 3.58579 18.7374C3.21071 18.4092 3 17.9641 3 17.5V7.875Z"
        stroke="#FB8500"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19.25V10.5H15V19.25"
        stroke="#FB8500"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const Arrowrighticon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M8.3335 20H31.6668"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 8.33398L31.6667 20.0007L20 31.6673"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  return (
    <div className="AddRolePage">
      <section className="logInAlert">
        <Modal
          show={showAdd}
          variant="none"
          variant="none"
          backdrop="static"
          centered
        >
          <ModalHeader>
            تم {props.type === "edit" ? "تعديل" : "اضافة"} الدور بنجاح
          </ModalHeader>

          <ModalFooter className="d-flex justify-content-start">
            <Button
              onClick={() => setshowAdd(false)}
              variant="none"
              style={{
                backgroundColor: "#1D3557",
                marginLeft: "180px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
                to=" "
              >
                موافق
              </Link>
            </Button>
          </ModalFooter>
        </Modal>
        {!showAdd}
      </section>

      <span className="AddRoleHeader">
        <Link
          style={{
            textDecoration: "none",
            fontFamily: "inherit",
            color: "inherit",
            cursor: "pointer",
          }}
          to="/management-page"
        >
          {HomeIcon}{" "}
          {props.type === "edit" ? "الإدارة/ تفاصيل المستخدم" : "الإدارة "}
        </Link>
      </span>

      <div className="AddRoleContent">
        <div className="AddRoleInputs">
          <Form>
            {props.type === "edit"
                ? (
                  <Link style={{ textDecoration: "none" }} to={`/management-page/${params.roleId}`}>
                    <nav id="icon">{Arrowrighticon}</nav>
                  </Link> 
                ) : (
                  <Link style={{ textDecoration: "none" }} to="/management-page">
                    <nav id="icon">{Arrowrighticon}</nav>
                  </Link> 
                )}
            <Form.Text id="FormHeader">
              {" "}
              {props.type === "edit"
                ? "  تعديل مسؤول"
                : "إضافة مسؤول جديد"}{" "}
            </Form.Text>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {" "}
                معرف الدور <i>*</i>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder=" 01"
                name="roleId"
                value={params.roleId}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                معرف المستخدم <i>*</i>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="045"
                name="userId"
                value={params.userId}
                onChange={handleChange}
              />
            </Form.Group>
            <span>
              {props.type === "edit" ? (
                <Button type="submit" onClick={handleClick}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#FFFFFF",
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                    to="/management-page"
                  >
                    تحديث
                  </Link>
                </Button>
              ) : (
                <Button type="submit" onClick={handleClick}>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#FFFFFF",
                      fontSize: "22px",
                      fontWeight: "700",
                    }}
                    to="/management-page"
                  >
                    إضافة
                  </Link>
                </Button>
              )}
            </span>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
