// @ts-nocheck
import OfferChoice from "../../components/User/Ads/Ads_Detail/details/OfferChoice/OfferChoice";
import React, { useEffect, useState } from "react";
import { Category } from "../../components/User/Landingpagevisitor/Category/Category";
import { Searchbar } from "../../components/User/Landingpagevisitor/Searchbar/Searchbar";
import { Addnew } from "../../components/User/Productdetailsbuy/Addnew/Addnew";
import { AddsBuy } from "../../components/User/Productdetailsbuy/AddsBuy/AddsBuy";
import { Beforefinal } from "../../components/User/Productdetailsbuy/Beforefinal/Beforefinal";
import Footer from "../../layouts/Footer/Footer";
import Headerlogin from "../../layouts/Headerlogin/Headerlogin";
import { Detailsofferandcomment } from "components/User/Ads/Ads_Detail/details/Detailsofferandcomment/Detailsofferandcomment";
import { useParams } from "react-router-dom";
import axios from "axios";

export const Productdetailsbuy = () => {
    const params = useParams();
    const [DetailData, setDetailData] = useState({});
    const [userData, setUserdata] = useState({});
    const [adTags, setAdTags] = useState([]);
    const [adOffers, setAdOffers] = useState([]);
    useEffect(() => {
        axios
            .get(
                `https://api.whitesooq.com/api/v1/home/show-ad/${params.id}`,
                {
                    headers: {
                        accept: "application/json",
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                    },
                }
            )
            .then((response) => {
                setDetailData(response.data.data);
                setUserdata(response.data.data.user);
                setAdTags(response.data.data.tags);
                setAdOffers(response.data.data.offers);
            })
            .catch((Error) => console.log(Error));
    }, []);

    return (
        <div className="Productdetails">
            <Headerlogin />
            {/* <Headerlogin /> */}
            {/* <Searchbar /> */}
            <AddsBuy detail={DetailData} user={userData} tags={adTags} />
            <Detailsofferandcomment data={adOffers} />
            <OfferChoice id={params.id} />
            <Addnew type="buy" />
            <Category data={DetailData.similar_ads} checkCategory="" />
            <Beforefinal />
            {/* <Footer /> */}
            <Footer />
        </div>
    );
};
