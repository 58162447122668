import React from "react";
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import { useNavigate , Link, useParams} from "react-router-dom";
import classes from "./EditPackage.module.css";
import EditPackageForm from "../EditPackageForm/EditPackageForm";
import { Form, Image } from "react-bootstrap";
import Home from "./Home.svg";

const EditPackage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div style={{ display: "grid", backgroundColor: "#f1f1f1" }}>
    <Link style={{textDecoration:'none' ,fontFamily:"inherit",color:"inherit", cursor:'pointer'}} to={'/ViewActiveAds-page'} to='/activepackages-page'>
      <Form.Label
        style={{
          display: "flex",
          direction: "rtl",
          alignItems: "flex-start",
          marginRight: "26px",
          marginTop: "16px",
        }}
      >
        <Image style={{ marginTop: "-2px" }} src={Home} />
        <h4 style={{ color: "#1D3557", fontSize: "18px", marginRight: "5px" }}>
          الباقات النشطة
        </h4>
      </Form.Label>
      </Link>
      <Container>
        <Box
          dir="rtl"
          style={{
            width: "100%",

            backgroundColor: "white",
            padding: "32px",
            borderRadius: "8px",
            boxShadow: "0px 4px 25px 0px #41404033",
            marginTop: "0px",
            marginRight: "-30px",
          }}
          className={classes.ContainerBox}
          sx={{
            m: "20px auto",
            width: { xs: 330, sm: 600, md: 800 },
            position: "relative",
            height: 2000,
            borderRadius: 1,
            boxShadow: "0px 4px 35px 0px #00000014",
          }}
        >
          <Box
            style={{ width: "95%", marginRight: "-9px" }}
            sx={{
              m: "50px 15px",
              width: { xs: 300, sm: 570, md: 770 },
              borderRadius: 1,
              position: "absolute",
              height: 1880,
              boxShadow: "0px 4px 35px 0px #00000014",
              px: 1,
            }}
          >
            <IconButton sx={{ m: 1 }} onClick={() => navigate(`/activepackages-page/${id}`)}>
              <East />
            </IconButton>
            <Typography
              sx={{
                color: "#1D3557",
                fontWeight: "800",
                m: 1,
              }}
              variant="h5"
            >
              تعديل باقة
            </Typography>
            <EditPackageForm />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default EditPackage;
