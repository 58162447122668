import React, { useState, useEffect } from 'react';

function Countdown() {
  const [timeLeft, setTimeLeft] = useState(300);

  useEffect(() => {
    const timer =
      timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <div>
      {timeLeft === 0 ? (
        <>00:00</>
      ) : (
        <> {Math.floor(timeLeft / 60)}: {timeLeft % 60} </>
      )}
    </div>
  );
}

export default Countdown;