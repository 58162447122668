// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "./Buyandsell.css";

const Buyandsell = (props) => {
  const navigate = useNavigate();
  const defaultSlidesNumber = 8;
  const [slidesNumber, setSlidesNumber] = useState(0);

  useEffect(() => {
    handleLoad();
  }, [props.data]);

  const handleLoad = () => {
    setSlidesNumber(Math.min(defaultSlidesNumber, props.data.length));
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesNumber,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="buyandsell">
      <Container>
        <h2> بيع واشتري بالحلال</h2>
        <Slider {...settings}>
          {props?.data?.map((item, index) => (
            <div className="slideitem pt-4" key={index}>
              <img src={item.category_image} alt={item.name} />
              <h3 onClick={() => navigate(`/category/${item.id}`)}>
                {item.name}
              </h3>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default Buyandsell;
