// @ts-nocheck
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./Table.css";
import createdBy from "./../../../assest/images/majd/images/Group 519.jpg"; //update
// import userNameImage from '../../images/Ellipse 2.png' //update
import { Button, Form, InputGroup } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import { useNavigate } from "react-router-dom";
import Snack from "components/Utilities/Snack";
const Table = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [nonDeletedData, setNonDeletedData] = useState([]);
    const [nonDeletedDataFilter, setnonDeletedDataFilter] = useState([]);
    const [DeletedData, setDeletedData] = useState([]);
    const [DeletedDataFilter, setDeletedDataFilter] = useState([]);
    const [payments, setPayments] = useState([]);
    const [paymentsFilter, setPaymentsFilter] = useState([]);
    const [rating, setRating] = useState([]);
    const [offers, setOffers] = useState([]);
    const [warnings, setWarnings] = useState([]);
    const [messages, setMessages] = useState([]);
    const [objection, setObjection] = useState([]);
    const [comments, setComments] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [bannedUsers, setBannedUsers] = useState([]);
    const [usersASkToDelete, setUsersASkToDelete] = useState([]);
    const [management, setManagement] = useState([]);
    const [managementFilter, setManagementFilter] = useState([]);
    const [type, setType] = useState("الإضافة");
    const [FilterData, setFilterData] = useState("");
    const { authInfo } = useContext(AuthContext);
    const [paymentCount, setpaymentCount] = useState(0);
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });

    // function to filter the Data
    const filterCardsData = (e) => {
        e.preventDefault();
        setFilterData(e.target.value);
        var FilteredData = nonDeletedData.filter((data) => {
            return data.name.includes(FilterData);
        });
        var FilteredPaymentData = payments.filter((data) => {
            return data.user.username.includes(FilterData);
        });
        var FilteredDEletedData = DeletedData.filter((data) => {
            return data.name.includes(FilterData);
        });
        setPaymentsFilter(FilteredPaymentData);
        setDeletedDataFilter(FilteredDEletedData);
        setnonDeletedDataFilter(FilteredData);
    };
    const filtermanagementData = (e) => {
        e.preventDefault();
        setFilterData(e.target.value);
        var FilteredData = management.filter((role) => {
            return role.role.name.includes(e.target.value);
        });

        setManagementFilter(FilteredData);
    };

    const fetchData = () => {
        {
            props.type === "deleted"
                ? axios
                      .get(
                          "https://api.whitesooq.com/api/v1/categories/only-trashed",
                          {
                              headers: {
                                  accept: "application/json",
                                  app_key:
                                      "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                  authorization: `Bearer ${authInfo.token}`,
                              },
                          }
                      )
                      .then((response) => {
                          console.log(response.data);
                          setDeletedData(response.data.data);
                          setDeletedDataFilter(response.data.data);
                          setType("الحذف");
                      })
                      .catch((Error) => {
                          console.log(Error);
                          if (Error.request.status === 403) {
                              setAlert({
                                  open: true,
                                  type: "warning",
                                  message: "عذراً ليس لديك صلاحية",
                              });
                          } else if (Error?.response?.status === 401) {
                              setAlert({
                                  open: true,
                                  type: "error",
                                  message: "انتهت صلاحية تسجيل الدخول",
                              });
                          } else if (Error?.response?.status === 422) {
                              setAlert({
                                  open: true,
                                  type: "error",
                                  message: Error?.response?.data?.message,
                              });
                          } else if (Error?.message === "Network Error") {
                              setAlert({
                                  open: true,
                                  type: "warning",
                                  message: "مشكلة في الشبكة",
                              });
                          } else {
                              setAlert({
                                  open: true,
                                  type: "warning",
                                  message: "الخادم لا يستجيب",
                              });
                          }
                      })
                : props.type === "management"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/admins", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setManagement(response.data.admins);
                          setManagementFilter(response.data.admins);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "askToDelete"
                ? axios
                      .get(
                          "https://api.whitesooq.com/api/v1/users/pending-delete",
                          {
                              headers: {
                                  accept: "application/json",
                                  app_key:
                                      "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                  authorization: `Bearer ${authInfo.token}`,
                              },
                          }
                      )
                      .then((response) => {
                          console.log(response.data);
                          setUsersASkToDelete(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "bannedUsers"
                ? axios
                      .get(
                          "https://api.whitesooq.com/api/v1/users/blocked",
                          {
                              headers: {
                                  accept: "application/json",
                                  app_key:
                                      "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                  authorization: `Bearer ${authInfo.token}`,
                              },
                          }
                      )
                      .then((response) => {
                          console.log(response.data);
                          setBannedUsers(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "activeUsers"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/users", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setActiveUsers(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "comments"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/comments", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setComments(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "objection"
                ? axios
                      .get(
                          "https://api.whitesooq.com/api/v1/rating/objection",
                          {
                              headers: {
                                  accept: "application/json",
                                  app_key:
                                      "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                  authorization: `Bearer ${authInfo.token}`,
                              },
                          }
                      )
                      .then((response) => {
                          console.log(response.data);
                          setObjection(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "messages"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/messages", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setMessages(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "warnings"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/reports", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setWarnings(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "offers"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/offers", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setOffers(response.data.data);
                      })
                      .catch((Error) => {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "payment"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/payments", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setPayments(response.data.data);
                          setPaymentsFilter(response.data.data);
                          setpaymentCount(response.data.count);
                      })
                      .catch((Error) =>  {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : props.type === "rating"
                ? axios
                      .get("https://api.whitesooq.com/api/v1/ratings", {
                          headers: {
                              accept: "application/json",
                              app_key:
                                  "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                              authorization: `Bearer ${authInfo.token}`,
                          },
                      })
                      .then((response) => {
                          console.log(response.data);
                          setRating(response.data.data);
                      })
                      .catch((Error) =>  {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    })
                : axios
                      .get(
                          "https://api.whitesooq.com/api/v1/categories",
                          {
                              headers: {
                                  accept: "application/json",
                                  app_key:
                                      "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                                  authorization: `Bearer ${authInfo.token}`,
                              },
                          }
                      )
                      .then((response) => {
                          console.log(response.data);
                          setNonDeletedData(response.data.data);
                          setnonDeletedDataFilter(response.data.data);
                      })
                      .catch((Error) =>  {
                        console.log(Error);
                        if (Error.request.status === 403) {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "عذراً ليس لديك صلاحية",
                            });
                        } else if (Error?.response?.status === 401) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: "انتهت صلاحية تسجيل الدخول",
                            });
                        } else if (Error?.response?.status === 422) {
                            setAlert({
                                open: true,
                                type: "error",
                                message: Error?.response?.data?.message,
                            });
                        } else if (Error?.message === "Network Error") {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "مشكلة في الشبكة",
                            });
                        } else {
                            setAlert({
                                open: true,
                                type: "warning",
                                message: "الخادم لا يستجيب",
                            });
                        }
                    });
        }
    };
    useEffect(() => {
        fetchData();
    }, [location.pathname, props.type]);

    const filledStarIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
        >
            <path
                d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
                fill="#FFEE32"
            />
        </svg>
    );

    const emptyStarIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19"
            viewBox="0 0 11 7"
            fill="none"
        >
            <path
                d="M5.50158 1.45581L6.91741 3.71661L10.0836 4.08138L7.79257 5.84018L8.33325 8.3249L5.50158 7.15116L2.66991 8.3249L3.21058 5.84018L0.919586 4.08138L4.08574 3.71661L5.50158 1.45581Z"
                stroke="#FFEE32"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const unreadmessages = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
        >
            <path
                d="M9.5 11.5L12.5 14.5L22.5 4.5"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.5 12.5V19.5C21.5 20.0304 21.2893 20.5391 20.9142 20.9142C20.5391 21.2893 20.0304 21.5 19.5 21.5H5.5C4.96957 21.5 4.46086 21.2893 4.08579 20.9142C3.71071 20.5391 3.5 20.0304 3.5 19.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H16.5"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const readmessages = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const readmessagesHead = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const countRatingZero = (
        <span className="starRating">
            <ul>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );
    const countRatingOne = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );

    const countRatingTow = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );

    const countRatingThree = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );

    const countRatingFourth = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{emptyStarIcon}</li>
            </ul>
        </span>
    );

    const countRatingFifth = (
        <span className="starRating">
            <ul>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
                <li>{filledStarIcon}</li>
            </ul>
        </span>
    );

    const objector = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            مقدم الاعتراض
        </Tooltip>
    );
    const resident = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            مقدم التقييم
        </Tooltip>
    );

    const descriptionFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <div className="deletedDate">{row.description}</div>;
    };
    const iconFormatterDate = (cell, row, rowIndex, formatExtraData) => {
        const DeletedDate = new Date(row.deleted_at);
        const CreatedDate = new Date(row.created_at);

        return (
            <div className="deletedDate">
                {props.type === "deleted"
                    ? `${DeletedDate?.getFullYear()}-${DeletedDate?.getMonth()}-${DeletedDate?.getDate()}`
                    : `${CreatedDate?.getFullYear()}-${CreatedDate?.getMonth()}-${CreatedDate?.getDate()}`}
                {props.type === "deleted" ? (
                    <Link to={`/category-services-page/${row.id}/deleted`}>
                        {eyeIcon}
                    </Link>
                ) : props.type === "payment" ? (
                    <Link to={`/paymentitem-page/${row.id}`}>{eyeIcon}</Link>
                ) : (
                    <Link to={`/category-services-page/${row.id}/nondeleted`}>
                        {eyeIcon}
                    </Link>
                )}
            </div>
        );
    };
    const iconFormatterRate = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div className="TableRating">
                {row.user.rating == 0
                    ? countRatingZero
                    : row.user.rating == 1
                    ? countRatingOne
                    : row.user.rating == 2
                    ? countRatingTow
                    : row.user.rating == 3
                    ? countRatingThree
                    : row.user.rating == 4
                    ? countRatingFourth
                    : row.user.rating == 5
                    ? countRatingFifth
                    : "invalid Rating"}
            </div>
        );
    };
    const iconFormatterUserName = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div >
                <img
                    src={row.user?.full_path_image || row.full_path_image}
                    alt=""
                    style={{
                        
                        width: "30px",
                        height: "30px",
                    }}
                />
                <p >
                    {row.user?.username ||
                        row.username ||
                        row.objection_sender_username ||
                        row.rating_applyer_username}
                </p>
            </div>
        );
    };

    const iconFormatterCreatedBy = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div className="CreatedBy">
                <img src={row.admin?.user?.full_path_image} alt="" />
                {row.admin?.user?.username}
            </div>
        );
    };

    const iconFormatterUserRating = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div className="TableRating">
                {
                    row.rating === 0 || (row.user?.rating === 0)
                    ? countRatingZero
                    : row.rating === 1|| (row.user?.rating === 1)
                    ? countRatingOne
                    : row.rating === 2|| (row.user?.rating === 2)
                    ? countRatingTow
                    : row.rating === 3|| (row.user?.rating === 3)
                    ? countRatingThree
                    : row.rating === 4|| (row.user?.rating === 4)
                    ? countRatingFourth
                    : row.rating === 5|| (row.user?.rating === 5)
                    ? countRatingFifth
                    : "invalid Rating"
                }
            </div>
        );
    };
    

    const iconFormatterRatingValue = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div className="TableRating">
                {row.value == 0
                    ? countRatingZero
                    : row.value == 1
                    ? countRatingOne
                    : row.value == 2
                    ? countRatingTow
                    : row.value == 3
                    ? countRatingThree
                    : row.value == 4
                    ? countRatingFourth
                    : row.value == 5
                    ? countRatingFifth
                    : "invalid Rating"}
            </div>
        );
    };
    const iconFormatterRatingLastIcons = (
        cell,
        row,
        rowIndex,
        formatExtraData
    ) => {
        const DeleteRating = (e) => {
            e.preventDefault();
            axios
                .delete(
                    `https://api.whitesooq.com/api/v1/ratings/${row.id}`,
                    {
                        headers: {
                            accept: "application/json",
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                            authorization: `Bearer ${authInfo.token}`,
                        },
                    }
                )
                .then((response) => {
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّت العملبة بنجاح",
                    });
                    console.log(response.data);
                })
                .catch((Error) =>  {
                    console.log(Error);
                    if (Error.request.status === 403) {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "عذراً ليس لديك صلاحية",
                        });
                    } else if (Error?.response?.status === 401) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: "انتهت صلاحية تسجيل الدخول",
                        });
                    } else if (Error?.response?.status === 422) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: Error?.response?.data?.message,
                        });
                    } else if (Error?.message === "Network Error") {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "مشكلة في الشبكة",
                        });
                    } else {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "الخادم لا يستجيب",
                        });
                    }
                });
        };
        return (
            <div>
                <Button
                    style={{ background: "none", border: "none" }}
                    onClick={DeleteRating}
                >
                    {trashIcon}
                </Button>
                <Button
                    style={{ background: "none", border: "none" }}
                    onClick={() => navigate(`/rating-page/${row.id}`)}
                >
                    {eyeIcon}
                </Button>
            </div>
        );
    };

    const iconFormatterShowOffers = (cell, row, rowIndex, formatExtraData) => {
        const acceptOffer = () => {
            axios
                .put(
                    `https://api.whitesooq.com/api/v1/offers/accept/${row.id}`,
                    {},
                    {
                        headers: {
                            accept: "application/json",
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                            authorization: `Bearer ${authInfo.token}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّت العملبة بنجاح",
                    });
                })
                .catch((Error) =>  {
                    console.log(Error);
                    if (Error.request.status === 403) {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "عذراً ليس لديك صلاحية",
                        });
                    } else if (Error?.response?.status === 401) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: "انتهت صلاحية تسجيل الدخول",
                        });
                    } else if (Error?.response?.status === 422) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: Error?.response?.data?.message,
                        });
                    } else if (Error?.message === "Network Error") {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "مشكلة في الشبكة",
                        });
                    } else {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "الخادم لا يستجيب",
                        });
                    }
                });
        };
        const refuseOffer = () => {
            axios
                .put(
                    `https://api.whitesooq.com/api/v1/offers/unaccept/${row.id}`,
                    {},
                    {
                        headers: {
                            accept: "application/json",
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                            authorization: `Bearer ${authInfo.token}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّت العملبة بنجاح",
                    });
                })
                .catch((Error) =>  {
                    console.log(Error);
                    if (Error.request.status === 403) {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "عذراً ليس لديك صلاحية",
                        });
                    } else if (Error?.response?.status === 401) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: "انتهت صلاحية تسجيل الدخول",
                        });
                    } else if (Error?.response?.status === 422) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: Error?.response?.data?.message,
                        });
                    } else if (Error?.message === "Network Error") {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "مشكلة في الشبكة",
                        });
                    } else {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "الخادم لا يستجيب",
                        });
                    }
                });
        };
        return (
            <Container className="showOffericons">
                <Button
                    style={{ background: "none", border: "none " }}
                    onClick={acceptOffer}
                >
                    {acceptOfferIcon}
                </Button>
                <Button
                    style={{ background: "none", border: "none " }}
                    onClick={refuseOffer}
                >
                    {refuseofferIcon}
                </Button>
                <Button
                    style={{ background: "none", border: "none " }}
                    onClick={() => navigate(`/showOffers-page/${row.id}`)}
                >
                    {eyeIcon}
                </Button>
            </Container>
        );
    };

    const iconFormatterShow = (cell, row, rowIndex, formatExtraData) => {
        
        return (
            <Container className="showOffericons">
                {props.type === "warnings" ? (
                    <Button
                        style={{
                            background: "none",
                            border: "none ",
                            width: "200px",
                        }}
                        onClick={() => navigate(`/warnings-page/${row.id}`)}
                    >
                        {eyeIcon}
                    </Button>
                ) : props.type === "messages" ? (
                    <Button
                        style={{
                            background: "none",
                            border: "none ",
                            width: "200px",
                        }}
                        onClick={() => navigate(`/messages-page/${row.id}`)}
                    >
                        {eyeIcon}
                    </Button>
                ) : props.type === "activeUsers" ? (
                    <Button
                        style={{
                            background: "none",
                            border: "none ",
                            width: "200px",
                        }}
                        onClick={() => navigate(`/activeUsers-page/${row.id}`)}
                    >
                        {eyeIcon}
                    </Button>
                ) : props.type === "bannedUsers" ? (
                    <Button
                        style={{
                            background: "none",
                            border: "none ",
                            width: "200px",
                        }}
                        onClick={() => navigate(`/bannedUsers-page/${row.id}`)}
                    >
                        {eyeIcon}
                    </Button>
                ) : props.type === "management" ? (
                    <Button
                        style={{
                            background: "none",
                            border: "none ",
                            width: "200px",
                        }}
                        onClick={() =>
                            navigate(`/management-page/${row.user.id}`)
                        }
                    >
                        {eyeIcon}
                    </Button>
                ) : (
                    <Button
                        style={{
                            background: "none",
                            border: "none ",
                            width: "200px",
                        }}
                        onClick={() => navigate(`/askToDelete-page/${row.id}`)}
                    >
                        {eyeIcon}
                    </Button>
                )}
            </Container>
        );
    };

    const iconFormatterShowObjection = (
        cell,
        row,
        rowIndex,
        formatExtraData
    ) => {
        return (
            <Container className="showOffericons">
                <Button
                    style={{
                        background: "none",
                        border: "none ",
                        width: "60px",
                    }}
                    onClick={() =>
                        navigate(`/objection-on-user-page/${row.id}`)
                    }
                >
                    {eyeIcon}
                </Button>
            </Container>
        );
    };

    const iconFormatterReadMessages = (
        cell,
        row,
        rowIndex,
        formatExtraData
    ) => {
        return (
            <Container className="showOffericons">
                <Row>
                    {" "}
                    {row.read_at === null ? unreadmessages : readmessages}{" "}
                </Row>
            </Container>
        );
    };

    const DateFormatter = (cell, row, rowIndex, formatExtraData) => {
        const CreatedDate = new Date(row.created_at);

        return (
            <div>
                {`${CreatedDate?.getFullYear()}-${CreatedDate?.getMonth()}-${CreatedDate?.getDate()}`}
            </div>
        );
    };

    const iconFormatterComments = (cell, row, rowIndex, formatExtraData) => {
        const acceptComment = () => {
            axios
                .put(
                    `https://api.whitesooq.com/api/v1/comments/accept/${row.id}`,
                    {},
                    {
                        headers: {
                            accept: "application/json",
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                            authorization: `Bearer ${authInfo.token}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّت العملبة بنجاح",
                    });
                })
                .catch((Error) =>  {
                    console.log(Error);
                    if (Error.request.status === 403) {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "عذراً ليس لديك صلاحية",
                        });
                    } else if (Error?.response?.status === 401) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: "انتهت صلاحية تسجيل الدخول",
                        });
                    } else if (Error?.response?.status === 422) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: Error?.response?.data?.message,
                        });
                    } else if (Error?.message === "Network Error") {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "مشكلة في الشبكة",
                        });
                    } else {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "الخادم لا يستجيب",
                        });
                    }
                });
        };
        const refuseComment = () => {
            axios
                .delete(
                    `https://api.whitesooq.com/api/v1/comments/unaccept/${row.id}`,
                    {
                        headers: {
                            accept: "application/json",
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                            authorization: `Bearer ${authInfo.token}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّت العملبة بنجاح",
                    });
                })
                .catch((Error) =>  {
                    console.log(Error);
                    if (Error.request.status === 403) {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "عذراً ليس لديك صلاحية",
                        });
                    } else if (Error?.response?.status === 401) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: "انتهت صلاحية تسجيل الدخول",
                        });
                    } else if (Error?.response?.status === 422) {
                        setAlert({
                            open: true,
                            type: "error",
                            message: Error?.response?.data?.message,
                        });
                    } else if (Error?.message === "Network Error") {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "مشكلة في الشبكة",
                        });
                    } else {
                        setAlert({
                            open: true,
                            type: "warning",
                            message: "الخادم لا يستجيب",
                        });
                    }
                });
        };
        return (
            <Container className="showOffericons">
                <Button
                    style={{ background: "none", border: "none " }}
                    onClick={acceptComment}
                >
                    {acceptOfferIcon}
                </Button>
                <Button
                    style={{ background: "none", border: "none " }}
                    onClick={refuseComment}
                >
                    {refuseofferIcon}
                </Button>
                <Button
                    style={{ background: "none", border: "none " }}
                    onClick={() => navigate(`/showComments-page/${row.id}`)}
                >
                    {eyeIcon}
                </Button>
            </Container>
        );
    };

    {
        /*   columns */
    }

    const NonDeltedcolumns = [
        {
            dataField: "id",
            text: "معرف الفئة",
        },
        {
            dataField: "name",
            text: "اسم الفئة",
        },
        {
            dataField: "id",
            text: " أنشئ بواسطة",
            formatter: iconFormatterCreatedBy,
        },

        {
            dataField: "status",
            text: " حالة الفئة",
        },

        {
            dataField: "description",
            text: "وصف",
        },
        {
            dataField: "type",
            text: " نوع الفئة",
        },

        {
            dataField: "created_at",
            text: `تاريخ  ${type} `,
            formatter: iconFormatterDate,
        },
    ];

    const Deltedcolumns = [
        {
            dataField: "id",
            text: "معرف الفئة",
        },
        {
            dataField: "name",
            text: "اسم الفئة",
        },

        {
            dataField: "status",
            text: "حالة الفئة",
        },

        {
            dataField: "admin?.user?.username",
            text: " أنشئ بواسطة",
            formatter: iconFormatterCreatedBy,
        },
        {
            dataField: "description",
            text: "وصف",
            formatter: descriptionFormatter,
        },

        {
            dataField: "type",
            text: " نوع الفئة",
        },

        {
            dataField: "created_at",
            text: `تاريخ  ${type} `,
            formatter: iconFormatterDate,
        },
    ];

    const paymentColumns = [
        {
            dataField: "id",
            text: "معرف الدفعة",
        },
        {
            dataField: "admin.user.username",
            text: " أنشئ بواسطة",
            formatter: iconFormatterCreatedBy,
        },
        {
            dataField: "user.id",
            text: "معرف المستخدم",
        },
        {
            dataField: "user.username",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "user.rating",
            text: "تقييم المستخدم",
            formatter: iconFormatterRate,
        },
        {
            dataField: "package.name",
            text: "اسم الباقة",
        },

        {
            dataField: "package.id",
            text: "معرف الباقة",
        },
        {
            dataField: "package.type",
            text: "نوع الباقة",
        },

        {
            dataField: "",
            text: `تاريخ  ${type} `,
            formatter: iconFormatterDate,
        },
    ];

    const ratingColumns = [
        {
            dataField: "email",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "rateable.title",
            text: "اسم الإعلان",
        },
        {
            dataField: "user.rating",
            text: "تقييم المستخدم",
            formatter: iconFormatterUserRating,
        },

        {
            dataField: "value",
            text: "قيمة التقييم",
            formatter: iconFormatterRatingValue,
        },
        {
            dataField: "",
            text: "",
            formatter: iconFormatterRatingLastIcons,
        },
    ];

    const offersColumns = [
        {
            dataField: "email",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "ad.title",
            text: "اسم الإعلان",
        },
        {
            dataField: "value",
            text: " قيمة العرض",
        },
        {
            dataField: "",
            text: "",
            formatter: iconFormatterShowOffers,
        },
    ];
    const warningsColumns = [
        {
            dataField: "user.id",
            text: " معرف المستخدم",
        },
        {
            dataField: "user.username",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "user.fullname",
            text: "الاسم الكامل  ",
        },
        {
            dataField: "",
            text: "",
            formatter: iconFormatterShow,
        },
    ];
    const messagesColumns = [
        {
            dataField: "fname",
            text: "الاسم الأول ",
        },
        {
            dataField: "lname",
            text: "  الاسم الأخير",
        },
        {
            dataField: "created_at",
            text: " تاريخ الرسالة",
            formatter: DateFormatter,
        },
        {
            dataField: "id",
            text: "رسالة مقروءة",
            headerFormatter: (column, colIndex) => {
                return (
                    <div>
                        {column.text}
                        {readmessagesHead}
                    </div>
                );
            },
            formatter: iconFormatterReadMessages,
        },
        {
            dataField: "postId",
            text: "",
            formatter: iconFormatterShow,
        },
    ];

    const objectionColumns = [
        {
            dataField: "objection_sender_id",
            text: " معرف المستخدم",
            headerFormatter: (column, colIndex) => {
                return (
                    <div style={{ width: "150px" }}>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={objector}
                        >
                            <a> {column.text} </a>
                        </OverlayTrigger>
                    </div>
                );
            },
        },
        {
            dataField: "objection_sender_name",
            text: "  اسم المستخدم",
            headerFormatter: (column, colIndex) => {
                return (
                    <div>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={objector}
                        >
                            <a> {column.text} </a>
                        </OverlayTrigger>
                    </div>
                );
            },
            formatter: iconFormatterUserName,
        },
        {
            dataField: "objection_sender_username",
            text: " الاسم الكامل",
            headerFormatter: (column, colIndex) => {
                return (
                    <div>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={objector}
                        >
                            <a> {column.text} </a>
                        </OverlayTrigger>
                    </div>
                );
            },
        },

        {
            dataField: "rating_applyer_id",
            text: " معرف المستخدم",
            headerFormatter: (column, colIndex) => {
                return (
                    <div style={{ width: "150px" }}>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={resident}
                        >
                            <a> {column.text} </a>
                        </OverlayTrigger>
                    </div>
                );
            },
        },
        {
            dataField: "rating_applyer_name",
            text: "  اسم المستخدم",
            headerFormatter: (column, colIndex) => {
                return (
                    <div>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={resident}
                        >
                            <a> {column.text} </a>
                        </OverlayTrigger>
                    </div>
                );
            },
            formatter: iconFormatterUserName,
        },
        {
            dataField: "rating_applyer_fullname",
            text: " الاسم الكامل",
            headerFormatter: (column, colIndex) => {
                return (
                    <div>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={resident}
                        >
                            <a> {column.text} </a>
                        </OverlayTrigger>
                    </div>
                );
            },
        },
        {
            dataField: "postId",
            text: " ",
            formatter: iconFormatterShowObjection,
        },
    ];

    const commentsColumns = [
        {
            dataField: "user.username",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "ad.title",
            text: "اسم الإعلان",
        },
        {
            dataField: "postId",
            text: "",
            formatter: iconFormatterComments,
        },
    ];

    const activeUsersColumns = [
        {
            dataField: "id",
            text: " معرف المستخدم",
        },
        {
            dataField: "email",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "fullname",
            text: "الاسم الكامل ",
        },
        {
            dataField: "rating",
            text: " تقييم المستخدم ",
            formatter: iconFormatterUserRating,
        },
        {
            dataField: "",
            text: "",
            formatter: iconFormatterShow,
        },
    ];
    const bannedUsersColumns = [
        {
            dataField: "id",
            text: " معرف المستخدم",
        },
        {
            dataField: "username",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "fullname",
            text: "الاسم الكامل ",
        },
        {
            dataField: "rating",
            text: " تقييم المستخدم ",
            formatter: iconFormatterUserRating,
        },
        {
            dataField: "",
            text: "",
            formatter: iconFormatterShow,
        },
    ];
    const usersASkToDeleteColumns = [
        {
            dataField: "id",
            text: " معرف المستخدم",
        },
        {
            dataField: "username",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "fullname",
            text: "الاسم الكامل ",
        },
        {
            dataField: "rating",
            text: " تقييم المستخدم ",
            formatter: iconFormatterUserRating,
        },
        {
            dataField: "",
            text: "",
            formatter: iconFormatterShow,
        },
    ];
    const managementColumns = [
        {
            dataField: "user.id",
            text: " معرف المستخدم",
        },
        {
            dataField: "user.username",
            text: "اسم المستخدم",
            formatter: iconFormatterUserName,
        },
        {
            dataField: "user.fullname",
            text: "الاسم الكامل ",
        },
        {
            dataField: "role.name",
            text: " الدور  ",
        },
        {
            dataField: "",
            text: "",
            formatter: iconFormatterShow,
        },
    ];

    {
        /*   icons */
    }

    const HomeIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="21"
            viewBox="0 0 24 21"
            fill="none"
        >
            <path
                d="M3 7.875L12 1.75L21 7.875V17.5C21 17.9641 20.7893 18.4092 20.4142 18.7374C20.0391 19.0656 19.5304 19.25 19 19.25H5C4.46957 19.25 3.96086 19.0656 3.58579 18.7374C3.21071 18.4092 3 17.9641 3 17.5V7.875Z"
                stroke="#FB8500"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 19.25V10.5H15V19.25"
                stroke="#FB8500"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const plusIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
        >
            <path
                d="M11.5 4.58337V17.4167"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.08325 11H17.9166"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const serachIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="27"
            viewBox="0 0 22 27"
            fill="none"
        >
            <path
                d="M20.1812 16.0677C23.2171 11.61 22.0643 5.53366 17.6067 2.49771C13.149 -0.538241 7.07266 0.61458 4.03672 5.07224C1.34726 9.02114 1.94923 14.24 5.21944 17.4886L0.880531 23.1512C0.139299 24.1146 0.364579 25.5022 1.36755 26.1852L1.51291 26.2842C2.51588 26.9673 3.88955 26.6687 4.51449 25.6262L8.19445 19.5148C12.4199 21.3712 17.4917 20.0166 20.1812 16.0677ZM8.49881 15.8707C5.57226 13.8775 4.81505 9.88636 6.80822 6.95981C8.80138 4.03326 12.7925 3.27604 15.7191 5.26921C18.6456 7.26238 19.4029 11.2535 17.4097 14.1801C15.4132 17.1115 11.4254 17.8639 8.49881 15.8707Z"
                fill="#FB8500"
            />
        </svg>
    );

    const eyeIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
        >
            <g clipPath="url(#clip0_110_3877)">
                <path
                    d="M1.75 15.5C1.75 15.5 6.75 5.5 15.5 5.5C24.25 5.5 29.25 15.5 29.25 15.5C29.25 15.5 24.25 25.5 15.5 25.5C6.75 25.5 1.75 15.5 1.75 15.5Z"
                    stroke="#1D3557"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5 19.25C17.5711 19.25 19.25 17.5711 19.25 15.5C19.25 13.4289 17.5711 11.75 15.5 11.75C13.4289 11.75 11.75 13.4289 11.75 15.5C11.75 17.5711 13.4289 19.25 15.5 19.25Z"
                    stroke="#1D3557"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_110_3877">
                    <rect
                        width="30"
                        height="30"
                        fill="white"
                        transform="translate(0.5 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );

    const trashIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
        >
            <path
                d="M3.75 8H6.25H26.25"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.75 8V25.5C23.75 26.163 23.4866 26.7989 23.0178 27.2678C22.5489 27.7366 21.913 28 21.25 28H8.75C8.08696 28 7.45107 27.7366 6.98223 27.2678C6.51339 26.7989 6.25 26.163 6.25 25.5V8M10 8V5.5C10 4.83696 10.2634 4.20107 10.7322 3.73223C11.2011 3.26339 11.837 3 12.5 3H17.5C18.163 3 18.7989 3.26339 19.2678 3.73223C19.7366 4.20107 20 4.83696 20 5.5V8"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 14.25V21.75"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.5 14.25V21.75"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
    const refuseofferIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
        >
            <path
                d="M23 8L8 23"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 8L23 23"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    const acceptOfferIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
        >
            <path
                d="M25.5 8L11.75 21.75L5.5 15.5"
                stroke="#457B9D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return (
        <>
            <div className="nonDeletedDataPage ">
                <Snack alert={alert}></Snack>
                <h5>
                    {" "}
                    {HomeIcon}{" "}
                    {props.type === "management"
                        ? "  الإدارة"
                        : props.type === "askToDelete"
                        ? "  المستخدمين / طلبات حذف الحساب "
                        : props.type === "bannedUsers"
                        ? " المستخدمين / المستخدمون المحظورون "
                        : props.type === "activeUsers"
                        ? " المستخدمين / المستخدمون النشطون "
                        : props.type === "comments"
                        ? "التعليقات "
                        : props.type === "objection"
                        ? "  الاعتراضات "
                        : props.type === "messages"
                        ? " الرسائل الواردة للموقع"
                        : props.type === "warnings"
                        ? " البلاغات"
                        : props.type === "offers"
                        ? " العروض"
                        : props.type === "rating"
                        ? "  التقييمات"
                        : props.type === "deleted"
                        ? " الفئات المحذوفة"
                        : props.type === "payment"
                        ? " الدفعات"
                        : " الفئات غير المحذوفة"}{" "}
                </h5>
                <div className="nonDeletedDataContent">
                    <InputGroup className="header">
                        {props.type === "deleted" ||
                        props.type === "rating" ||
                        props.type === "offers" ||
                        props.type === "warnings" ||
                        props.type === "messages" ||
                        props.type === "objection" ||
                        props.type === "comments" ||
                        props.type === "bannedUsers" ||
                        props.type === "askToDelete" ? (
                            <Link to="/add-category-page">
                                {" "}
                                <Button style={{ visibility: "hidden" }}>
                                    إضافة فئة {plusIcon}
                                </Button>{" "}
                            </Link>
                        ) : props.type === "activeUsers" ? (
                            <Link to="/add-user-page-page">
                                <Button
                                    className="longWord"
                                    onClick={() =>
                                        navigate("/add-user-page-page/:id")
                                    }
                                >
                                    إضافة مستخدم جديد {plusIcon}
                                </Button>
                            </Link>
                        ) : props.type === "management" ? (
                            <Link to="/AddRole-page">
                                <Button
                                    style={{ fontSize: "24px" }}
                                    className="longWord managementButton"
                                >
                                    إضافة دور {plusIcon}
                                </Button>
                            </Link>
                        ) : props.type === "payment" ? (
                            <Link to="/add-payment-page">
                                <Button style={{ fontSize: "24px" }}>
                                    إضافة دفعة {plusIcon}
                                </Button>
                            </Link>
                        ) : (
                            <Link to="/add-category-page">
                                <Button
                                    style={{ fontSize: "24px" }}
                                    id="responiveButton"
                                >
                                    إضافة فئة {plusIcon}
                                </Button>
                            </Link>
                        )}

                        <Form>
                            <Form.Label>
                                {" "}
                                {props.type === "management"
                                    ? " عرض الإدارة"
                                    : props.type === "askToDelete"
                                    ? " عرض طلبات حذف الحساب"
                                    : props.type === "bannedUsers"
                                    ? " عرض المستخدمون المحظورون"
                                    : props.type === "activeUsers"
                                    ? "  عرض المستخدمون النشطون "
                                    : props.type === "comments"
                                    ? " عرض التعليقات "
                                    : props.type === "objection"
                                    ? " عرض الاعتراضات "
                                    : props.type === "messages"
                                    ? " عرض الرسائل الواردة للموقع  "
                                    : props.type === "warnings"
                                    ? " عرض البلاغات"
                                    : props.type === "offers"
                                    ? "عرض العروض"
                                    : props.type === "rating"
                                    ? "عرض تقييمات الإعلانات"
                                    : props.type === "deleted"
                                    ? "عرض الفئات المحذوفة"
                                    : props.type === "payment"
                                    ? "عرض الدفعات"
                                    : "عرض الفئات غير المحذوفة"}{" "}
                            </Form.Label>
                            {props.type === "rating" ||
                            props.type === "offers" ||
                            props.type === "warnings" ||
                            props.type === "messages" ||
                            props.type === "objection" ||
                            props.type === "comments" ||
                            props.type === "activeUsers" ||
                            props.type === "bannedUsers" ||
                            props.type === "askToDelete" ? (
                                ""
                            ) : props.type === "management" ? (
                                <Form.Select
                                    aria-label="Default select example"
                                    className="managementSelect"
                                    onChange={filtermanagementData}
                                >
                                    <option
                                        className="options"
                                        value=""
                                    ></option>
                                    <option className="options" value="owner">
                                        مالك
                                    </option>
                                    <option className="options" value="admin">
                                        مشرف
                                    </option>
                                    <option
                                        className="options"
                                        value="super-admin"
                                    >
                                        مسؤول
                                    </option>
                                </Form.Select>
                            ) : (
                                <span id="inputSpan">
                                    <Form.Control
                                        placeholder="..بحث "
                                        onChange={filterCardsData}
                                    />
                                    <i id="inputIcon"> {serachIcon} </i>
                                </span>
                            )}
                            {props.type === "payment" ? (
                                <Form.Text className=" form_text">
                                    . عرض {paymentCount} دفعة مدخلة
                                </Form.Text>
                            ) : (
                                <Form.Text
                                    style={{ margin: "10px 0px 0px 0px" }}
                                ></Form.Text>
                            )}
                        </Form>
                    </InputGroup>

                    <span className="table table-responsive-xl">
                        {props.type === "management" ? (
                            <BootstrapTable
                                keyField="id"
                                data={managementFilter}
                                columns={managementColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "askToDelete" ? (
                            <BootstrapTable
                                keyField="id"
                                data={usersASkToDelete}
                                columns={usersASkToDeleteColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "bannedUsers" ? (
                            <BootstrapTable
                                keyField="id"
                                data={bannedUsers}
                                columns={bannedUsersColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "activeUsers" ? (
                            <BootstrapTable
                                keyField="id"
                                data={activeUsers}
                                columns={activeUsersColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "comments" ? (
                            <BootstrapTable
                                id="comments"
                                keyField="id"
                                data={comments}
                                columns={commentsColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "objection" ? (
                            <BootstrapTable
                                keyField="id"
                                data={objection}
                                columns={objectionColumns}
                                bootstrap4={true}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "messages" ? (
                            <BootstrapTable
                                keyField="id"
                                data={messages}
                                columns={messagesColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "warnings" ? (
                            <BootstrapTable
                                keyField="id"
                                data={warnings}
                                columns={warningsColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "offers" ? (
                            <BootstrapTable
                                keyField="id"
                                data={offers}
                                columns={offersColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "rating" ? (
                            <BootstrapTable
                                keyField="id"
                                data={rating}
                                columns={ratingColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "deleted" ? (
                            <BootstrapTable
                                keyField="id"
                                data={DeletedDataFilter}
                                columns={Deltedcolumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : props.type === "payment" ? (
                            <BootstrapTable
                                keyField="id"
                                data={paymentsFilter}
                                columns={paymentColumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        ) : (
                            <BootstrapTable
                                keyField="id"
                                data={nonDeletedDataFilter}
                                columns={NonDeltedcolumns}
                                pagination={paginationFactory({
                                    nextPageText: "التالي",
                                    prePageText: "السابق",
                                })}
                            />
                        )}
                    </span>
                </div>
            </div>
            <Snack alert={alert} />
        </>
    );
};

export default Table;
