// @ts-nocheck
import MenuItems from "./MenuItems";
import "./ListItem.css";
const ListItem = (props) => {

  return (
    <div className="nav-area">
      <ul className="menus">
        {props.listData?.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </div>
  );
};
export default ListItem;
