import { Grid } from "@mui/material";
import StatisticCard from "./StatisticCard";
export default function section4({ data }) {
    const statistics_section1 = [
        {
            title: "عدد نقرات زر مراسلة",
            ratio: data?.messages_ratio,
            count: data?.messages_count,
        },
        {
            title: "عدد نقرات زر اتصال",
            ratio: data?.call_ratio,
            count: data?.call_count,
        },
        {
            title: "عدد نقرات زر واتساب",
            ratio: data?.whatsapp_ratio,
            count: data?.whatsapp_count,
        },
        {
            title: "عدد الاشتراكات",
            ratio: data?.subscriptions_ratio,
            count: data?.subscriptions_count,
        },
    ];
    // console.log(data);
    return (
        <Grid container columnSpacing={5} rowSpacing={3}>
            {statistics_section1?.map((statistic, i) => (
                <Grid item xs={12} md={6} lg={3}>
                    <StatisticCard
                        title={statistic.title}
                        ratio={statistic.ratio}
                        count={statistic.count}
                        bgcolor={i % 2 === 0 ? "#F1FAEE" : "#D9EEEE"}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
