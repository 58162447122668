import React from "react";
import { Container } from "react-bootstrap";
import { Editeuser } from "../Editeuser/Editeuser";
import home from "./../admin-image/categ/home.svg";
import "./Adduserpage.css";
import { Link } from "react-router-dom";

export const Editeuserpage = () => {
  return (
    <div className="adduserpage" dir="rtl">
      <Container>

        <div className="topuser">
          <img src={home} />
          <h6>المستخدمين /
            <Link
              style={{
                textDecoration: "none",
                fontFamily: "inherit",
                color: "inherit",
                cursor: "pointer",
              }}
              to="/activeUsers-page"
            >المستخدمون النشطون</Link></h6>
        </div>
      <div className="usercontent">
        <Editeuser />
      </div>
    </Container>
    </div >
  );
};
