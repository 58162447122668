// @ts-nocheck
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import appstore from "../../../../assest/images/Landingpagevisitor/shopingeverywhere/appstore.svg";
import googleplay from "../../../../assest/images/Landingpagevisitor/shopingeverywhere/googleplay.svg";


import React, { useContext, useRef, useEffect, useState } from "react";
import classes from "./CategoryFilter.module.css"; // Importing CSS class module
import { Search } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import Headerlogin from "layouts/Headerlogin/Headerlogin";
import Footer from "layouts/Footer/Footer";
import { Button, Form, Modal, ModalFooter, ModalHeader } from "react-bootstrap";
import { Category } from "components/User/Landingpagevisitor/Category/Category";
import CardsDashbord from "components/Admin/Viewads/Carddashbord/CardsDashbord";
const CategoryFilter = (props) => {
  const { authInfo } = useContext(AuthContext);
  const [catData, setCatData] = useState({});
  const [search, setSearch] = useState("");
  const [ads,setAds] = useState([])
  const params = useParams();
  const carouselRef = useRef(null);

  const handleLeftClick = (event) => {
    event.preventDefault();
    carouselRef.current.scrollLeft -= 258;
  };
  const filterData = (e) => {
  const searchString = e.target.value;
  setSearch(searchString);

  if (searchString === "") {
    // If the search input is empty, display all ads
    setAds(catData.ads);
  } else {
    // Filter ads based on the search input
    const filteredAds = catData.ads.filter((ad) =>
      ad.title.includes(searchString)
    );
    setAds(filteredAds);
  }
};

  const handleRightClick = (event) => {
    event.preventDefault();
    carouselRef.current.scrollLeft += 258;
  };

  useEffect(() => {
    // Fetching category data based on the search query
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(
          `https://api.whitesooq.com/api/v1/home/show-category/${params.id}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${authInfo.token}`,
              app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
          }
        );
        // console.log(response);
        setCatData(response.data.data);
        setAds(response.data.data.ads)
      } catch (err) {
        // console.log(err);
      }
    };

    fetchCategoryData();
  }, []);
  const [showAlert, setShowAlert] = useState(false);

    const handleClick = () => {
      setShowAlert(true);
    };

  return (
    <div>
      <Headerlogin/>
    <Container sx={{ my: { xs: 1, sm: 3 } }}>
      <Box
      
        className={classes.BoxFiltering}
        sx={{
          padding: "16px 8px",
          m: "0 auto",
          width: { xs: "100%", sm: "calc(100%)" },
          
        }}
      >
        <Form.Label 
        id="catefiilter"
        style={{
          color:'#1d3557', 
          fontSize:'27px', 
          fontWeight:'700', 
          fontFamily:'Noto Sans Arabic', 
          marginTop:'16px',
          marginRight:'7px'
        }}>
              فئة {catData?.name}
        </Form.Label>
       
        <Box sx={{ m: 1 }}>
          <Typography sx={{ color: "#FB8500" }}>
            البحث عن إعلان {/* Search for an ad */}
          </Typography>
          <FormControl
            sx={{
              my: 1,
              width: "50%",
              border: "1px solid #FB8500",
              p: 0,
              outline: "none",
              borderRadius: "10px",
            }}
            variant="outlined"
          >
            <OutlinedInput
              sx={{
                textAlign: "right",
                px: 2,
                py: 0,
                height: "45px",
                outline: "none",
                borderRadius: "10px",
                border: "1px solid #FB8500",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              placeholder="...بحث" /* Search placeholder text */
              value={search}
              onChange={filterData}
            />
          </FormControl>
        </Box>
        <Typography
          className={classes.detailsParagraph}
          sx={{ color: "#fff", m: 1 }}
        ></Typography>
        <Typography className={classes.line}></Typography>
        <Typography
          sx={{
            mt: "9px",
            fontSize: "14px",
            fontWeight: "600",
            padding: "0px 24px",
            marginBottom:'10px'
          }}
        >
          الإعلانات ذات الصلة بالفئة المحددة
          <span
            style={{ marginRight: "3px", color: "#457b9d", fontSize:'11px' }}
          >{`(${ads?.length} إعلان)`}</span>
        </Typography>
        <Category data={ads} checkCategory="category"/>
      </Box>
      <Typography
        sx={{
          position: "relative",
          height: "1px",
          m: "20px auto",
          width: "80%",
          backgroundColor: "#457b9d",
        }}
      ></Typography>
      <Typography sx={{ display: "flex", justifyContent: "space-evenly" }}>
      
              <div className="downloadimage d-flex justify-content-center">
          <img style={{marginRight:'30px'}} src={appstore} alt="App Store"  onClick={handleClick} />
              <Modal
                show={showAlert}
                onHide={() => setShowAlert(false)}
                style={{ direction: "rtl" }}
                backdrop="static"
                centered
              >
                <ModalHeader>
                  <Modal.Title className="nneext">انتظرونا في الإصدارات القادمة</Modal.Title>
                </ModalHeader>
                <ModalFooter>
                  <Button
                  className="nneext-button"
                    variant="secondary"
                    onClick={() => setShowAlert(false)}
                    style={{
                      backgroundColor: "#1D3557",
                    }}
                  >
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>

              <Link
                className="lin-footer"
                target="_blank"
                to="https://play.google.com/store/apps/details?id=online.whitesooq"
                style={{ textDecoration: "none" }}
              >
                <img className="erere" style={{marginTop:'3px'}}  src={googleplay} alt="Google Play"/>
              </Link>
        </div>
      </Typography>
    </Container>
    <Footer/>
    </div>
  );
};

export default CategoryFilter;
