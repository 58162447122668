import { useEffect, useContext } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
// @ts-ignore
import classes from "./EditPurchaseADForm.module.css";
import { useState } from "react";
import ButtonAD from "../../User/Ads/Button/ButtonAD";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import axios from "axios";
import NestedList from "components/Utilities/NestedList";
import Snack from "components/Utilities/Snack";
const EditPurchaseADForm = () => {
    const [category, setCategory] = useState({
        name: "الفئات",
        id: "",
        oldId: "",
    });
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });
    const handleCategory = (newCategory) => {
        setCategory(newCategory);
    };
    // @ts-ignore
    const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
    const { id } = useParams();
    // @ts-ignore
    const navigate = useNavigate();
    // @ts-ignore
    const [nameAD, setNameAD] = useState("");
    // @ts-ignore
    const [detilsAD, setDetailAD] = useState("");
    // @ts-ignore
    const [lowestPrice, setLowestPrice] = useState("");
    // @ts-ignore
    const [highestPrice, setHighestPrice] = useState("");
    // @ts-ignore
    const [semanticWordsAD, setSementicWordsAD] = useState([]);
    useEffect(() => {
        const getADDetails = async () => {
            try {
                // @ts-ignore
                const response = await axios.get(
                    `https://api.whitesooq.com/api/v1/ads/${id}`,
                    {
                        headers: {
                            accept: "application/json",
                            Authorization: `Bearer ${authInfo.token}`,
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        },
                    }
                );
                // console.log(response);
                if (response?.data?.data) {
                    if (
                        response.data.data.type !== "buy" ||
                        response.data.data === null
                    ) {
                        navigate("/");
                    } else {
                        setNameAD(response.data.data.title);
                        setDetailAD(response.data.data.description);
                        // @ts-ignore
                        var tagNames = response.data.data.tags.map(function (
                            tag
                        ) {
                            return tag.name;
                        });
                        // console.log(tagNames);
                        setSementicWordsAD(tagNames.join("،"));
                        const price = response.data.data.price.split(" - ");
                        // console.log(price);
                        setLowestPrice(price[0]);
                        setHighestPrice(price[1]);
                        setCategory({
                            name: category.name,
                            id: category.id,
                            oldId: response.data.data.category_id,
                        });
                    }
                }
            } catch (err) {
                if (err?.response?.status === 401) {
                    // @ts-ignore
                    navigate("/");
                }
                // console.log(err);
                navigate("/");
            }
        };
        getADDetails();
    }, []);
    // @ts-ignore
    const submitHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        // @ts-ignore
        const tags = semanticWordsAD.split("،");
        // @ts-ignore
        formData.append("category_id", category.id);
        formData.append("title", nameAD);
        formData.append("description", detilsAD);
        formData.append("min", lowestPrice);
        formData.append("max", highestPrice);
        tags.forEach((tag) => {
            formData.append("tags[]", tag);
        });
        // console.log("tags[]", formData.getAll("tags[]"));
        // if (files) formData.append("images[]", files);
        // console.log(Object.fromEntries(formData));
        setTimeout(async () => {
            try {
                const response = await axios.post(
                    `https://api.whitesooq.com/api/v1/ads/${id}`,
                    formData,
                    {
                        headers: {
                            accept: "application/json",
                            Authorization: `Bearer ${authInfo.token}`,
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                        },
                    }
                );
                if (response?.data?.status === 1) {
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّ التعديل بنجاح",
                    });
                } else if (response?.data?.status === 0) {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: response?.data?.message,
                    });
                }
            } catch (err) {
                if (err.request.status === 403) {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                } else if (err?.response?.status === 401) {
                    ChangeAuthInfo({});
                    setAlert({
                        open: true,
                        type: "error",
                        message: "انتهت صلاحية تسجيل الدخول",
                    });
                    navigate("/");
                } else if (err?.response?.status === 422) {
                    setAlert({
                        open: true,
                        type: "error",
                        message: err?.response?.data?.message,
                    });
                } else if (err?.message === "Network Error") {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "مشكلة في الشبكة",
                    });
                } else {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "الخادم لا يستجيب",
                    });
                }
            }
        }, 1000);
    };
    return (
        <>
            <Snack alert={alert} />
            <Box className={classes.NewAd}>
                <form onSubmit={submitHandler}>
                    {/* <!--Start Name AD--> */}
                    <div className={classes.control}>
                        <label htmlFor="name_ad">
                            اسم الإعلان<span> *</span>
                        </label>
                        <input
                            className={classes.Input}
                            type="text"
                            id="name_ad"
                            required
                            value={nameAD}
                            onChange={(e) => setNameAD(e.target.value)}
                            placeholder="ساعة يد بيضاء"
                        />
                    </div>
                    {/* <!--End Name AD--> */}
                    {/* <!--Start Details AD--> */}
                    <div className={classes.control}>
                        <label htmlFor="details_ad">
                            تفاصيل الإعلان<span> *</span>
                        </label>
                        <textarea
                            id="details_ad"
                            className={classes.Input}
                            // @ts-ignore
                            rows="3"
                            required
                            value={detilsAD}
                            onChange={(e) => setDetailAD(e.target.value)}
                            placeholder="للبيع ساعة يد بيضاء مع غطاء بلاستيك مع وجود خدش صغيرة في الجانب الايسر للساعة للبيع ساعة يد بيضاء مع غطاء بلاستيك للبيع ساعة يد بيضاء مع غطاء بلاستيك للبيع ساعة يد بيضاء مع غطاء بلاستيك"
                        ></textarea>
                    </div>
                    {/* <!--End Details AD--> */}
                    {/* <!--Start Categories AD--> */}
                    <div className={classes.control}>
                        <label htmlFor="Categories_ad">
                            اختيار فئات الإعلان
                        </label>
                        <NestedList
                            category={category}
                            handleCategory={handleCategory}
                        />
                    </div>
                    {/* <!--End Categories AD--> */}
                    {/* <!--Start Prices AD--> */}
                    <div className={classes.control}>
                        <Grid container spacing={6} columns={16}>
                            <Grid item xs={8}>
                                <div>
                                    <label htmlFor="highest_price">
                                        السعر الأعلى<span> *</span>
                                    </label>
                                    <input
                                        className={classes.Input}
                                        type="number"
                                        id="highest_price"
                                        required
                                        value={highestPrice}
                                        onChange={(e) =>
                                            setHighestPrice(e.target.value)
                                        }
                                        placeholder="5000"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div>
                                    <label htmlFor="lowest_price">
                                        السعر الأدنى<span> *</span>
                                    </label>
                                    <input
                                        className={classes.Input}
                                        type="number"
                                        id="lowest_price"
                                        required
                                        value={lowestPrice}
                                        onChange={(e) =>
                                            setLowestPrice(e.target.value)
                                        }
                                        placeholder="1200"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {/* <!--End Prices AD--> */}
                    {/* <!--Start Semantic words AD--> */}
                    <div className={classes.control}>
                        <label htmlFor="name_ad">
                            كلمات دلالية للإعلان<span> *</span>
                        </label>
                        <input
                            className={classes.Input}
                            type="text"
                            id="semantic_words_ad"
                            required
                            value={semanticWordsAD}
                            // @ts-ignore
                            onChange={(e) => setSementicWordsAD(e.target.value)}
                            placeholder="الكترونيات، العاب، بلاي ستيشن، أجهزة الكترونيات، مستعمل، قطع الكترونية"
                        />
                    </div>
                    {/* <!--End Semantic words AD--> */}
                    {/* <!--Start Button ADD AD--> */}
                    <div className={classes.control}>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: {
                                    xs: "center",
                                    sm: "flex-start",
                                },
                            }}
                            item
                            xs={8}
                        >
                            <ButtonAD title={"تحديث"} />
                        </Grid>
                    </div>
                    {/* <!--End Button ADD AD--> */}
                </form>
            </Box>
        </>
    );
};

export default EditPurchaseADForm;
