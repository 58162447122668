// @ts-nocheck

import { Avatar, Box, Container, IconButton, Typography } from "@mui/material";
import { East } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import classes from "./ViewOffer.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";
import Slide from "@mui/material/Slide";
import Home from "./Home.svg";
import { Form, Image } from "react-bootstrap";
import { AuthContext } from "store/UseAuthContext";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Snack from "components/Utilities/Snack";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Root = styled("div")(
    ({ theme }) => `
  table {
    border-collapse: collapse;
    width: 100%;
  }
  td {
    border: 1px solid #457B9D;
    color: #1d3557;
    text-align: center;
    padding: 8px;
    font-size: 17px;
    font-weight: 700;
  }
  `
);

const ViewOffer = (props) => {
    const { authInfo } = useContext(AuthContext);
    const { id } = useParams();
    const [offer, setOffer] = useState("");
    const navigate = useNavigate();
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });
    useEffect(() => {
        fetch(`https://api.whitesooq.com/api/v1/offers/${id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authInfo.token}`,
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
        })
            .then((response) => {
                if (response.status === 403)
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                return response.json();
            })
            .then((responseData) => {
                // console.log(responseData);
                if (responseData?.offer) {
                    setOffer(responseData.offer);
                }
            });
    }, []);
    const Price = offer?.ad?.price.split(" - ");
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOption("");
    };
    const [option, setOption] = useState("");
    const handleChange = (event) => {
        setOption(event.target.value);
    };
    const [acceptOffer, setAcceptOffer] = useState(false);
    const [rejectOffer, setRejectOffer] = useState(false);
    const [error, setError] = useState("");
    const handleReject = () => {
        fetch(`https://api.whitesooq.com/api/v1/offers/unaccept/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authInfo.token}`,
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
        })
            .then((response) => {
                if (response.status === 403)
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                return response.json();
            })
            .then((data) => {
                if (data?.status === 1) {
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّت رفض العرض بنجاح",
                    });
                    setOpen(false);
                } else {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: data?.message ? data?.message : "هناك مشكلة",
                    });
                }
                setTimeout(() => {
                    navigate("/showOffers-page");
                }, 1000);
            })
            .catch((error) => {});
    };
    const handleAccept = () => {
        fetch(`https://api.whitesooq.com/api/v1/offers/accept/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authInfo.token}`,
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
        })
            .then((response) => {
                if (response.status === 403)
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });

                return response.json();
            })
            .then((data) => {
                if (data?.status === 1) {
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّت قبول العرض بنجاح",
                    });
                    setOpen(false);
                } else {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: data?.message ? data?.message : "هناك مشكلة",
                    });
                }
                setTimeout(() => {
                  navigate("/showOffers-page");
              }, 1000);
                // if (data.status) {
                //     setAcceptOffer(true);
                //     navigate("/showOffers-page");
                // }
                // // setOpen(false);
            })
            .catch((error) => setError(error.message));
    };

    return (
        <div style={{ display: "grid", backgroundColor: "#dadada" }}>
            <Snack alert={alert} />

            <Form.Label
                style={{
                    display: "flex",
                    direction: "rtl",
                    alignItems: "flex-start",
                    marginRight: "10px",
                    marginTop: "16px",
                }}
            >
                <Image style={{ marginTop: "-2px" }} src={Home} />
                <h4
                    style={{
                        color: "#1D3557",
                        fontSize: "18px",
                        marginRight: "5px",
                        fontWeight: "700",
                    }}
                >
                    <Link
                        style={{
                            textDecoration: "none",
                            fontFamily: "inherit",
                            color: "inherit",
                            cursor: "pointer",
                        }}
                        to="/showOffers-page"
                    >
                        العروض
                    </Link>
                    / تفاصيل العروض
                </h4>
            </Form.Label>
            <Container sx={{ my: { xs: 3, sm: 1 } }}>
                <Box
                    dir="rtl"
                    style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        padding: "32px",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 25px 0px #41404033",
                        marginRight: "-40px",
                        marginTop: "-9px",
                    }}
                    className={classes.ContainerBoxAdmin}
                    sx={{
                        padding: { xs: "16px 8px", sm: 2 },
                        width: { xs: 375, sm: 650 },
                        height: { xs: 570, sm: 800 },
                    }}
                >
                    <Typography
                        className={classes.detailsParagraph}
                        sx={{ color: "#fff", m: 1 }}
                    >
                        تفاصيل العرض
                    </Typography>
                    <Typography className={classes.line}></Typography>
                    <Box
                        style={{ width: "101%", marginRight: "-9px" }}
                        className={classes.ContainerBoxAdminInside}
                        sx={{
                            padding: { xs: "16px 8px", sm: 2 },
                            width: { xs: 360, sm: 560 },
                            height: { xs: 480, sm: 600 },
                        }}
                    >
                        <IconButton
                            sx={{ m: 1 }}
                            onClick={() => navigate("/showOffers-page")}
                        >
                            <East />
                        </IconButton>
                        <Box className={classes.menuOption}>
                            <FormControl sx={{ m: 1 }}>
                                <Select
                                    IconComponent={ExpandMore}
                                    value={option}
                                    onChange={handleChange}
                                    displayEmpty
                                    autoWidth
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                >
                                    <MenuItem
                                        onClick={handleClickOpen}
                                        value={"قبول"}
                                        sx={{ pl: 6 }}
                                    >
                                        قبول
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleClickOpen}
                                        value={"رفض"}
                                        sx={{ pl: 6 }}
                                    >
                                        رفض
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {(!acceptOffer || !rejectOffer) && (
                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    sx={{ textAlign: "center" }}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    {option === "رفض" ? (
                                        <Close
                                            sx={{
                                                color: "red",
                                                m: "20px auto 0",
                                                width: "80px",
                                                height: "80px",
                                            }}
                                        />
                                    ) : (
                                        <Check
                                            sx={{
                                                color: "green",
                                                m: "20px auto 0",
                                                width: "80px",
                                                height: "80px",
                                            }}
                                        />
                                    )}
                                    <DialogTitle
                                        sx={{
                                            textAlign: "center",
                                            m: "0 auto",
                                            p: "0 50px",
                                            color: "#457B9D",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {`هل أنت متأكد من ${option} هذا العرض ؟`}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText
                                            sx={{ m: 0, p: 0 }}
                                            id="alert-dialog-slide-description"
                                        ></DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ m: "0 auto" }}>
                                        <button
                                            className={classes.btnCancelDialog}
                                            onClick={handleClose}
                                        >
                                            إلغاء
                                        </button>
                                        <button
                                            className={classes.btnOkDialog}
                                            onClick={
                                                option === "رفض"
                                                    ? handleReject
                                                    : handleAccept
                                            }
                                        >
                                            متأكد
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            )}
                            {error && (
                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    sx={{ textAlign: "center" }}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <WarningAmber
                                        sx={{
                                            color: "#f9a703",
                                            m: "5px auto",
                                            width: "80px",
                                            height: "80px",
                                        }}
                                    />
                                    <DialogTitle
                                        sx={{
                                            textAlign: "center",
                                            m: "0 auto",
                                            p: "10px 20px",
                                            color: "#457B9D",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {error}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText
                                            sx={{ m: 0, p: 0 }}
                                            id="alert-dialog-slide-description"
                                        >
                                            <Close sx={{ color: "red" }} />
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ m: "0 auto" }}>
                                        <button
                                            className={classes.btnOkDialog}
                                            onClick={handleClose}
                                        >
                                            ok
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            )}
                            {(acceptOffer || rejectOffer) && (
                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    sx={{ textAlign: "center" }}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <WarningAmber
                                        sx={{
                                            color: "#f9a703",
                                            m: "0 auto",
                                            width: "80px",
                                            height: "80px",
                                        }}
                                    />
                                    <DialogTitle
                                        sx={{
                                            textAlign: "center",
                                            m: "0 auto",
                                            p: "0 20px",
                                            color: "#457B9D",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {`تم ${option} العرض؟ بنجاح`}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText
                                            sx={{ m: 0, p: 0 }}
                                            id="alert-dialog-slide-description"
                                        >
                                            <Check sx={{ color: "red" }} />
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ m: "0 auto" }}>
                                        <button
                                            className={classes.btnOkDialog}
                                            onClick={handleClose}
                                        >
                                            ok
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            )}
                        </Box>
                        <Typography
                            variant="p"
                            sx={{
                                color: "#1d3557",
                                fontWeight: 800,
                                fontSize: "25px",
                            }}
                        >
                            عرض العرض
                        </Typography>
                        <Box sx={{ my: 1 }} style={{ direction: "ltr" }}>
                            <Root
                                sx={{ maxWidth: "100%", width: "100%", mt: 1 }}
                            >
                                <table
                                    className={classes.tableDetails}
                                    aria-label="custom pagination table"
                                >
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 400 }}>
                                                <div
                                                    className={
                                                        classes.sentence1WithoutPadding
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.flexTable
                                                        }
                                                    >
                                                        <IconButton
                                                            onClick={() =>
                                                                navigate(
                                                                    `/profile/${offer?.user_id}`
                                                                )
                                                            }
                                                        >
                                                            <VisibilityOutlinedIcon />
                                                        </IconButton>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        >
                                                            {offer?.user
                                                                ? offer?.user
                                                                      ?.username
                                                                : ""}
                                                        </Typography>
                                                        <Avatar
                                                            sx={{
                                                                ml: 1,
                                                                width: "40px",
                                                                height: "40px",
                                                                p: 0,
                                                            }}
                                                            src={
                                                                offer?.user
                                                                    ? offer
                                                                          ?.user
                                                                          ?.full_path_image
                                                                    : ""
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                style={{ width: 200 }}
                                                align="right"
                                            >
                                                اسم المستخدم
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 400 }}>
                                                {offer?.ad
                                                    ? offer?.ad?.title
                                                    : ""}
                                            </td>
                                            <td
                                                style={{ width: 200 }}
                                                align="right"
                                            >
                                                اسم الإعلان
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 400 }}>
                                                {offer?.ad?.description}
                                            </td>
                                            <td
                                                style={{ width: 200 }}
                                                align="right"
                                            >
                                                وصف الإعلان
                                            </td>
                                        </tr>
                                        {offer?.ad?.type === "buy" && (
                                            <>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        {Price[0]}
                                                    </td>
                                                    <td
                                                        style={{ width: 200 }}
                                                        align="right"
                                                    >
                                                        القيمة الأدنى
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        {Price[1]}
                                                    </td>
                                                    <td
                                                        style={{ width: 200 }}
                                                        align="right"
                                                    >
                                                        القيمة الأعلى
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <td style={{ fontWeight: 400 }}>
                                                {offer.content}
                                            </td>
                                            <td
                                                style={{ width: 200 }}
                                                align="right"
                                            >
                                                وصف العرض
                                            </td>
                                        </tr>
                                        {offer?.ad?.type === "sale" && (
                                            <tr>
                                                <td style={{ fontWeight: 400 }}>
                                                    {offer?.ad
                                                        ? offer?.ad?.price
                                                        : ""}
                                                </td>
                                                <td
                                                    style={{ width: 200 }}
                                                    align="right"
                                                >
                                                    قيمة العرض
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Root>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default ViewOffer;
