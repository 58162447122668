import React from "react";
import "./Ruler.css";
export const Ruler = () => {
  return (
    <div>
      <div class="ruler" dir="ltr">
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm">
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
          <div class="mm"></div>
        </div>
        <div class="cm" style={{width:'380px'}}></div>
      </div>
    </div>
  );
};
