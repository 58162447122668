import {
    Stack,
    Typography,
} from "@mui/material";
import up from "./ArrowRise.svg";
import down from "./ArrowFall.svg";
export default function StatisticCard({ count, ratio, title, bgcolor }) {
    return (
        <Stack
            direction={"column"}
            gap={2}
            sx={{
                bgcolor: bgcolor,
                py: 3,
                px: 4,
                borderRadius: 5,
                minWidth: 200,
                boxShadow: "0px 4px 8px 0px #00000033",
            }}
        >
            <Typography
                sx={{
                    fontFamily: "Noto Sans Arabic",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#1D3557",
                }}
            >
                {title}
            </Typography>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                gap={3}
                alignItems={"center"}
                flexWrap={"wrap"}
            >
                <Typography
                    sx={{
                        fontFamily: "Noto Sans Arabic",
                        fontWeight: 600,
                        fontSize: 24,
                        color: "#1D3557",
                    }}
                >
                    {count}
                </Typography>
                <Stack
                    direction={"row"}
                    dir={"ltr"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    
                >
                    <Typography
                        sx={{
                            fontFamily: "Noto Sans Arabic",
                            fontWeight: 400,
                            fontSize: 12,
                            color:
                                parseFloat(ratio) > 0
                                    ? "#02C697"
                                    : parseFloat(ratio) < 0
                                    ? "#E63946"
                                    : "#1D3557",
                            mr: 0.7,
                        }}
                    >
                        {ratio}%
                    </Typography>
                    {parseFloat(ratio) > 0 ? (
                        <Typography component={"img"} src={up} />
                    ) : parseFloat(ratio) < 0 ? (
                        <Typography component={"img"} src={down} />
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
}
