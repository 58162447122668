// @ts-nocheck
import React, { useContext, useState } from "react";
import { Container, Modal, ModalFooter, ModalHeader } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./Viewmore.css";
import { ViewMoreContext } from "store/ViewMoreContext";
import { AuthContext } from "store/UseAuthContext";
import { Link, useNavigate } from "react-router-dom";
import plus from "../../../../assest/images/Landingpagevisitor/searchbar/plus (1).svg";
import Alert from "react-bootstrap/Alert";
export const Viewmore = (props) => {
  const { ChangeMoreValue } = useContext(ViewMoreContext);
  const { authInfo } = useContext(AuthContext);
  const [navigateUser, setNavigateUser] = useState("/login");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const clickHandler = () => {
    window.scrollTo(0, 0);
    if (authInfo.user === undefined) {
      setError("قم بتسجيل الدخول وأعد المحاولة");
    } else {
      navigate("/new-ad");
    }
  };
  return (
    <div className="viewmore">
      {error && (
        <section className="logInAlert">
          <Modal
            show={error}
            variant="none"
            style={{ direction: "rtl" }}
            centered
          >
            <ModalHeader>{error}</ModalHeader>
            <ModalFooter className="d-flex text-align-center justify-content-center">
              <Button
                onClick={() => setError("")}
                style={{
                  backgroundColor: "#1D3557",
                  marginLeft: "30px",
                }}
              >
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to={navigateUser}
                >
                  موافق
                </Link>
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      )}
      <Container>
        <div className="row">
          {props.check === "AddViewMore" ? (
            <button
              className="more"
              style={{
                borderRadius: "8px",
                border: "1px solid var(--primary, #457B9D)",
              }}
              onClick={() => ChangeMoreValue("ViewMore")}
            >
              شاهد المزيد
            </button>
          ) : (
            <></>
          )}
        </div>
        <br />
        <br />
        <br />

        <div className="add-ads-button">
          <button
            onClick={() => {
              clickHandler();
            }}
            className="add-ads"
          >
            <img style={{ marginLeft: "5px" }} src={plus} />
            <span style={{ color: "white" }}>أضف إعلانك الآن</span>{" "}
          </button>
        </div>
      </Container>
    </div>
  );
};
