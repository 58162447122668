
import { useState } from "react";
import { createContext } from "react";
const filterDataContext = createContext({});
const UseFilterContext = ({ children }) => {
 const [filterData, setFilterData] = useState({});
    const changeFilterData = (Data) => {
        setFilterData(Data);
        console.log(filterData);
    };
    
    return (
        <filterDataContext.Provider value={{ filterData, changeFilterData}}>
            {children}
        </filterDataContext.Provider>
    );
};
export { UseFilterContext, filterDataContext };
