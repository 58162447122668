// @ts-ignore
import React, { useContext, useState, useCallback, useEffect } from "react";
import classes from "./EditPackageForm.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ButtonAD from "../../User/Ads/Button/ButtonAD";
import { AuthContext } from "store/UseAuthContext";
import Select from "@mui/material/Select";
import Snack from "components/Utilities/Snack";
// @ts-ignore
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
// @ts-ignore
const EditPackageForm = (props) => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  // @ts-ignore
  const { authInfo } = useContext(AuthContext);
  const [namePackage, setNamePackage] = useState("");
  const [detailsPackage, setDetailsPackage] = useState("");
  const [pricePackage, setPricePackage] = useState("");
  const [validityPackage, setValidityPackage] = useState("");
  const [periodOfVliditySale, setPeriodOfVliditySale] = useState("");
  const [countOfSpreadSaleAds, setCountOfSpreadSaleAds] = useState("");
  const [periodOfValidityPinAd, setPeriodOfValidityPinAd] = useState("");
  const [periodValidityPurchaseAd, setPeriodValidityPurchaseAd] = useState("");
  const [countOfSpreadPurchaseAds, setCountOfSpreadPurchaseAds] = useState("");
  const [serviceDiscounts, setServiceDiscounts] = useState("");
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });
  const [error, setError] = useState("");
  const [typePackage, setTypePackage] = useState("");
  const [validityEditSaleAD, setValidityEditSaleAD] = useState("");
  const [validityGetStaticsSaleAD, setValidityGetStaticsSaleAD] =
    useState("نعم");
  const [countSpreadOffers, setCountSpreadOffers] = useState("");
  const [hideValueOffer, setHideValueOffer] = useState("");
  const [highlightOffer, setHighlightOffer] = useState("");
  const [pinAds, setPinAds] = useState("");
  const [validityEditPurchaseAD, setValidityEditPurchaseAD] = useState("");
  const [validityGetStaticsPurchaseAD, setValidityGetStaticsPurchaseAD] =
    useState("");
  const [discountPackage, setDiscountPackage] = useState(0);
  const fetchPackageHandler = useCallback(async () => {
    setError(null);
    try {
      const response = await fetch(
        `https://api.whitesooq.com/api/v1/packages/${id}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "حدث خطأ أثناء تحميل صفحة تعديل الباقة يرجى اعادة المحاولة مرة أخرى"
        );
      }
      const data = await response.json();
      //   console.log(data);
      if (data?.package) {
        setData(data.package);
        setNamePackage(data?.package?.name);
        setDetailsPackage(data?.package?.description);
        setPricePackage(data?.package?.cost);
        setValidityPackage(data?.package?.validity);
        setDiscountPackage(data?.package?.discount === 1 ? "نعم" : "لا");
        setTypePackage(
          data?.package?.type === "private"
            ? "خاصة"
            : data.id === 1
            ? "افتراضية"
            : "عامة"
        );
        setPeriodOfVliditySale(data?.package?.sale_ads_validity);
        setCountOfSpreadSaleAds(data?.package?.sale_ads_limit);
        setValidityEditSaleAD(
          data?.package?.sale_ads_updateable === 0 ? "لا" : "نعم"
        );
        setValidityGetStaticsSaleAD(
          data?.package?.sale_ads_resultable === 0 ? "لا" : "نعم"
        );
        setCountSpreadOffers(data?.package?.offers_limit);
        setHideValueOffer(data?.package?.hide_offer === 0 ? "لا" : "نعم");
        setHighlightOffer(
          data?.package?.offer_highlighting === 0 ? "لا" : "نعم"
        );
        setPinAds(data?.package?.pinable === 0 ? "لا" : "نعم");
        setPeriodOfValidityPinAd(data?.package?.pinable_validity);
        setPeriodValidityPurchaseAd(data?.package?.buy_ads_validity);
        setCountOfSpreadPurchaseAds(data?.package?.buy_ads_limit);
        setValidityEditPurchaseAD(
          data?.package?.buy_ads_updateable === 0 ? "لا" : "نعم"
        );
        setValidityGetStaticsPurchaseAD(
          data?.package?.buy_ads_resultable === 0 ? "لا" : "نعم"
        );
        setServiceDiscounts(data?.package?.service_discounts);
      }
    } catch (error) {
      setError(error.message);
    }
  }, []);
  useEffect(() => {
    fetchPackageHandler();
  }, [fetchPackageHandler]);
  //   console.log(data);

  const submitHandler = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    let sale_ads_updateable = validityEditSaleAD === "نعم" ? 1 : 0;
    let sale_ads_resultable = validityGetStaticsSaleAD === "نعم" ? 1 : 0;
    let buy_ads_updateable = validityEditPurchaseAD === "نعم" ? 1 : 0;
    let buy_ads_resultable = validityGetStaticsPurchaseAD === "نعم" ? 1 : 0;
    let hide_offer = hideValueOffer === "نعم" ? 1 : 0;
    let offer_highlighting = highlightOffer === "نعم" ? 1 : 0;
    let pinable = pinAds === "نعم" ? 1 : 0;
    // @ts-ignore
    let disc = discountPackage === "نعم" ? 1 : 0;
    let typePack = typePackage === "خاصة" ? "private" : "public";
    const formData = new FormData();
    formData.append("name", namePackage);
    formData.append("description", detailsPackage);
    // @ts-ignore
    formData.append("cost", +pricePackage);
    // @ts-ignore
    formData.append("validity", +validityPackage);
    formData.append("type", typePack);
    // @ts-ignore
    formData.append("sale_ads_validity", +periodOfVliditySale);
    // @ts-ignore
    formData.append("sale_ads_limit", +countOfSpreadSaleAds);
    // @ts-ignore
    formData.append("sale_ads_updateable", sale_ads_updateable);
    // @ts-ignore
    formData.append("sale_ads_resultable", sale_ads_resultable);
    // @ts-ignore
    formData.append("buy_ads_validity", +periodValidityPurchaseAd);
    // @ts-ignore
    formData.append("buy_ads_limit", +countOfSpreadPurchaseAds);
    // @ts-ignore
    formData.append("buy_ads_updateable", buy_ads_updateable);
    // @ts-ignore
    formData.append("buy_ads_resultable", buy_ads_resultable);
    // @ts-ignore
    formData.append("offers_limit", +countSpreadOffers);
    // @ts-ignore
    formData.append("service_discounts", +serviceDiscounts);
    // @ts-ignore
    formData.append("hide_offer", hide_offer);
    // @ts-ignore
    formData.append("pinable", pinable);
    // @ts-ignore
    formData.append("offer_highlighting", offer_highlighting);
    // @ts-ignore
    formData.append("pinable_validity", +periodOfValidityPinAd);
    // @ts-ignore
    formData.append("discount", disc);

    // console.log(formData);
    fetch(`https://api.whitesooq.com/api/v1/packages/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authInfo.token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
      },
      body: JSON.stringify({
        name: namePackage,
        description: detailsPackage,
        cost: +pricePackage,
        validity: +validityPackage,
        type: typePack,
        sale_ads_validity: +periodOfVliditySale,
        sale_ads_limit: +countOfSpreadSaleAds,
        sale_ads_updateable: sale_ads_updateable,
        sale_ads_resultable: sale_ads_resultable,
        buy_ads_validity: +periodValidityPurchaseAd,
        buy_ads_limit: +countOfSpreadPurchaseAds,
        buy_ads_updateable: buy_ads_updateable,
        buy_ads_resultable: buy_ads_resultable,
        offers_limit: +countSpreadOffers,
        service_discounts: +serviceDiscounts,
        hide_offer: hide_offer,
        pinable: pinable,
        offer_highlighting: offer_highlighting,
        pinable_validity: +periodOfValidityPinAd,
        discount: disc,
      }),
    })
      .then((response) => {
        if (response.status === 403)
          setAlert({
            open: true,
            type: "warning",
            message: "عذراً ليس لديك صلاحية",
          });
      return response.json();
      })
      .then((response) => {
        // console.log(response);
        // if (response?.status) {
        if (response.status === 1) {
          setAlert({
            open: true,
            type: "success",
            message: "تمَّ التعديل بنجاح",
          });
          setTimeout(() => {
            navigate(`/activepackages-page/${id}`);
          }, 750);
        } else {
          setAlert({
            open: true,
            type: "warning",
            message: response.message,
          });
        }
      })
      .catch((error) => {
    console.log(error);
    setAlert({
      open: true,
      type: "warning",
      message: "هناك مشكلة في عملية التعديل",
    });
  });
  };

  return (
    <Box className={classes.NewPackage}>
      <Snack alert={alert} />
      <form onSubmit={submitHandler}>
        {/* <!--Start Name Package--> */}
        <div className={classes.control}>
          <label htmlFor="name_package">
            اسم الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="name_package"
            required
            value={namePackage}
            onChange={(e) => setNamePackage(e.target.value)}
          />
        </div>
        {/* <!--End Name Package--> */}
        {/* <!--Start Details Package--> */}
        <div className={classes.control}>
          <label htmlFor="details_package">
            وصف الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="details_package"
            required
            onChange={(e) => setDetailsPackage(e.target.value)}
            // @ts-ignore
            value={detailsPackage}
          />
        </div>
        {/* <!--End Details Package--> */}
        {/* <!--Start Cost Package--> */}
        <div className={classes.control}>
          <label htmlFor="Cost_Package">
            تكلفة الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="Cost_Package"
            required
            onChange={(e) => setPricePackage(e.target.value)}
            // @ts-ignore
            value={pricePackage}
          />
        </div>
        {/* <!--End Cost Package--> */}
        {/* <!--Start Validity Package--> */}
        <div className={classes.control}>
          <label htmlFor="validity_Package">
            صلاحية الباقة<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="validity_Package"
            required
            onChange={(e) => setValidityPackage(e.target.value)}
            // @ts-ignore
            value={validityPackage}
          />
        </div>
        {/* <!--End Validity Package--> */}
        {/* <!--Start discount Package--> */}
        <div className={classes.control}>
          <label htmlFor="discount">
            إضافة خصم على الباقة<span> *</span>
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="discount"
                value={discountPackage}
                label="type"
                onChange={(e) => setDiscountPackage(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* <!--End discount Package--> */}
        {/* Start Type package */}
        <div className={classes.control}>
          <label htmlFor="type_package">
            نوع الباقة<span> *</span>
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                className={classes.inputDetails}
                labelId="demo-simple-select-label"
                id="type_package"
                value={typePackage}
                label="type"
                onChange={(e) => setTypePackage(e.target.value)}
              >
                {["عامة", "خاصة"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* <!--End type package--> */}
        {/* <!--Start period of Validity Sale Ads--> */}
        <div className={classes.control}>
          <label htmlFor="validity_Period">
            مدة صلاحية إعلانات البيع<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="validity_Period"
            required
            value={periodOfVliditySale}
            onChange={(e) => setPeriodOfVliditySale(e.target.value)}
          />
        </div>
        {/* <!--End period of Validity Sale Ads--> */}
        {/* <!--Start count spread Sale Ads --> */}
        <div className={classes.control}>
          <label htmlFor="count_spread">
            حد نشر إعلانات البيع<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="text"
            id="count_spread"
            required
            // @ts-ignore
            value={countOfSpreadSaleAds}
            onChange={(e) => setCountOfSpreadSaleAds(e.target.value)}
          />
        </div>
        {/* <!--End count spread Sale Ads--> */}
        {/* Start Validity edit Ads */}
        <div className={classes.control}>
          <label htmlFor="Validity_edit_Ads">
            سماحية تعديل إعلانات البيع<span> *</span>
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_edit_Ads"
                value={validityEditSaleAD}
                label="type"
                onChange={(e) => setValidityEditSaleAD(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity edit Ads */}
        {/* Start Validity to get statics Sale Ads*/}
        <div className={classes.control}>
          <label htmlFor=" Validity_get_statics_Sale_Ads">
            سماحية الحصول على إحصائيات إعلانات البيع<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_get_statics_Sale_Ads"
                value={validityGetStaticsSaleAD}
                label="type"
                onChange={(e) => setValidityGetStaticsSaleAD(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity to get statics Sale Ads*/}
        {/* Start count spread offres*/}
        <div className={classes.control}>
          <label htmlFor="count_spread_offres">
            حد نشر العروض<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="number"
            id="count_spread_offres"
            required
            // @ts-ignore
            value={countSpreadOffers}
            onChange={(e) => setCountSpreadOffers(e.target.value)}
          />
        </div>
        {/* End count spread offres*/}
        {/* Start Hide value offer */}
        <div className={classes.control}>
          <label htmlFor="Hide_value_offer">
            إخفاء قيمة العرض<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Hide_value_offer"
                value={hideValueOffer}
                label="type"
                onChange={(e) => setHideValueOffer(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Hide value offer */}
        {/* Start Highlight offer*/}
        <div className={classes.control}>
          <label htmlFor="Highlight_offer">
            تمييز العرض<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Highlight_offer"
                value={highlightOffer}
                label="type"
                onChange={(e) => setHighlightOffer(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Highlight offer*/}
        {/* Start Pin Ads */}
        <div className={classes.control}>
          <label htmlFor="Pin_Ads">
            تثبيت الإعلانات<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Pin_Ads"
                value={pinAds}
                label="type"
                onChange={(e) => setPinAds(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Pin Ads */}
        {/* Start period of validity Pin Ad */}
        <div className={classes.control}>
          <label htmlFor="period_validity_pin_AD">
            مدة صلاحية تثبيت الإعلان<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="number"
            id="period_validity_pin_AD"
            required
            value={periodOfValidityPinAd}
            onChange={(e) => setPeriodOfValidityPinAd(e.target.value)}
          />
        </div>
        {/* End period of validity Pin Ad */}
        {/* Start period of spread Purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="period_validity_purchase_Ad">
            مدة صلاحية إعلانات الشراء<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="number"
            id="period_validity_purchase_Ad"
            required
            value={periodValidityPurchaseAd}
            onChange={(e) => setPeriodValidityPurchaseAd(e.target.value)}
          />
        </div>
        {/* End period of spread Purchase Ads */}
        {/* Start count of spread Purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="count_spread_purchase_Ad">
            حد نشر إعلانات الشراء<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="number"
            id="count_spread_purchase_Ad"
            required
            value={countOfSpreadPurchaseAds}
            onChange={(e) => setCountOfSpreadPurchaseAds(e.target.value)}
          />
        </div>
        {/* End count of spread Purchase Ads */}
        {/* Start Validity edit Purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="Validity_edit_Purchase_Ads">
            سماحية تعديل إعلانات الشراء<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_edit_Purchase_Ads"
                value={validityEditPurchaseAD}
                label="type"
                onChange={(e) => setValidityEditPurchaseAD(e.target.value)}
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity edit Purchase Ads */}
        {/* Start Validity to get statics purchase Ads */}
        <div className={classes.control}>
          <label htmlFor="Validity_get_statics_purchase_Ads">
            سماحية الحصول على إحصائيات إعلانات الشراء<span> *</span>
          </label>
          <Box sx={{ minWidth: 120, p: 0 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Validity_get_statics_purchase_Ads"
                value={validityGetStaticsPurchaseAD}
                label="type"
                onChange={(e) =>
                  setValidityGetStaticsPurchaseAD(e.target.value)
                }
              >
                {["نعم", "لا"].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* End Validity to get statics purchase Ads */}
        {/* <!--Start service discounts Ads--> */}
        <div className={classes.control}>
          <label htmlFor="service_discounts">
            خصومات الخدمات<span> *</span>
          </label>
          <input
            className={classes.Input}
            type="number"
            id="service_discounts"
            required
            value={serviceDiscounts}
            onChange={(e) => setServiceDiscounts(e.target.value)}
            placeholder="%10"
          />
        </div>
        {/* <!--End Validity Ads--> */}
        {/* <!--Start Button ADD AD--> */}
        <div className={classes.control} style={{ direction: "ltr" }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
            item
            xs={8}
          >
            <ButtonAD title={"تحديث"} />
          </Grid>
        </div>
        {/* <!--End Button ADD AD--> */}
      </form>
    </Box>
  );
};
export default EditPackageForm;
