// @ts-nocheck
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useState, forwardRef, useEffect } from "react";

export default function Snack({ alert }) {
    console.log(alert);
    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    useEffect(() => {
        setOpenSnack(alert.open);
        console.log(openSnack);
    }, [alert]);
    const [openSnack, setOpenSnack] = useState(false);

    const handleClick = () => {
        setOpenSnack(true);
    };

    const handleClose = (event, reason) => {
        setOpenSnack(false);
    };
    return (
        <Snackbar
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
            <Alert
                onClose={handleClose}
                severity={alert.type}
                sx={{ width: "100%" }}
            >
                {alert.message}
            </Alert>
        </Snackbar>
    );
}
