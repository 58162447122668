// @ts-nocheck
import Box from "@mui/material/Box";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, useTreeItem } from "@mui/x-tree-view/TreeItem";
import { forwardRef, useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ChevronLeft, ExpandMore } from "@mui/icons-material";
import { AuthContext } from "store/UseAuthContext";
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import clsx from "clsx";
const theme = createTheme({
    components: {
        MuiTreeItem: {
            styleOverrides: {
                group: { marginRight: "17px", marginLeft: "0px" },
            },
        },
    },
});
const CustomContent = forwardRef(function CustomContent(props, ref) {
    const {
        // @ts-ignore
        classes,
        // @ts-ignore
        className,
        // @ts-ignore
        label,
        // @ts-ignore
        nodeId,
        // @ts-ignore
        icon: iconProp,
        // @ts-ignore
        expansionIcon,
        // @ts-ignore
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
        handleSelection(event);
        handleExpansionClick(event);
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
            dir="rtl"
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                onClick={handleExpansionClick}
                className={classes.iconContainer}
            >
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                sx={{
                    mt: 1,
                    fontFamily: "Noto Sans Arabic",
                }}
            >
                {label}
            </Typography>
        </div>
    );
});

// @ts-ignore
const CustomTreeItem = forwardRef(function CustomTreeItem(props, ref) {
    // @ts-ignore
    return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
});

export default function NestedList({ category, handleCategory }) {
    // @ts-ignore
    const { authInfo } = useContext(AuthContext);
    const [categories, setCategories] = useState([]);
    // console.log(category);
    // console.log(categories);
    useEffect(() => {
        fetch("https://api.whitesooq.com/api/v1/get-categories-tags", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authInfo.token}`,
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setCategories(response.categories);
                // console.log(response);
            })
            // @ts-ignore
            .catch((error) => {});
    }, [category]);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTreeItem = (id, name) => {
        handleCategory({
            id,
            name,
            idHidden: category.idHidden,
            category: category.category,
        });
    };

    const renderTree = (nodes) => {
        if (category?.oldId === nodes.id) {
            handleCategory({
                id: nodes.id,
                name: nodes.name,
                idHidden: category.idHidden,
                category: category.category,
            });
            // console.log(category.idHidden);
            // console.log(nodes.id);
        }
        if (
            category?.idHidden !== nodes.id &&
            category.category === "category"
        ) {
            return (
                <CustomTreeItem
                    key={nodes.id}
                    nodeId={nodes.id}
                    label={nodes.name}
                    desiabled
                >
                    {Array.isArray(nodes.children)
                        ? nodes.children.map((node) => {
                              return renderTree(node);
                          })
                        : null}
                </CustomTreeItem>
            );
        } else if (category.category !== "category") {
            return (
                <CustomTreeItem
                    key={nodes.id}
                    nodeId={nodes.id}
                    label={nodes.name}
                    desiabled
                >
                    {Array.isArray(nodes.children)
                        ? nodes.children.map((node) => {
                              return renderTree(node);
                          })
                        : null}
                </CustomTreeItem>
            );
        }
    };
    const handleSelect = (event, nodeIds) => {
        // console.log(event, nodeIds);
        // if(category.oldId === nodeIds)
        handleTreeItem(nodeIds, event.target.innerText);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box dir="rtl">
                <Button
                    variant="outlined"
                    sx={{
                        display: "flex",
                        fontFamily: "Noto Sans Arabic",
                        justifyContent: "start",
                        color: "#1D3557",
                        borderColor: "#1D3557",
                        ":focus": {
                            bgcolor: "white",
                        },
                    }}
                    fullWidth
                    onClick={handleClickOpen}
                >
                    {category?.name}
                </Button>
                <Dialog
                    sx={{
                        "& .MuiDialog-paper": { width: "80%", maxHeight: 435 },
                    }}
                    maxWidth="xs"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            fontFamily: "Noto Sans Arabic",
                            textAlign: "right",
                        }}
                    >
                        {"الفئات"}
                    </DialogTitle>
                    <DialogContent>
                        <TreeView
                            aria-label="rich object"
                            defaultCollapseIcon={<ExpandMore />}
                            defaultExpanded={["root"]}
                            defaultExpandIcon={<ChevronLeft />}
                            selected={category?.id !== "" ? category.id : ""}
                            // selected={16}
                            onNodeSelect={handleSelect}
                            disabledItemsFocusable={true}
                        >
                            {category.name === "أساسية" ||
                            category.category === "category" ? (
                                <CustomTreeItem nodeId={""} label={"أساسية"} />
                            ) : null}

                            {categories?.map((cat) => {
                                return renderTree(cat);
                            })}
                        </TreeView>
                    </DialogContent>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
}
