import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography, Container } from "@mui/material";
import classes from "./Packages.module.css";
import Grid from "@mui/material/Grid";
import Footer from "../../../layouts/Footer/Footer";
import Headerloginn from "../../../layouts/Headerlogin/Headerloginn";
import nextPackage from "../../../assest/images/Admin/LeftAndRightButtons/arrow-right-circle.png";
import prevPackage from "../../../assest/images/Admin/LeftAndRightButtons/arrow-left-circle.png";
import { Check, Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";

import CardContent from "@mui/material/CardContent";
const Packages = () => {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const fetchPackagesHandler = useCallback(async () => {
    setError(null);
    try {
      const response = await fetch(
        "https://api.whitesooq.com/api/v1/packages",
        {
          method: "GET",
          headers: {
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "حدث خطأ أثناء تحميل صفحة الباقات يرجى اعادة المحاولة مرة أخرى"
        );
      }
      const data = await response.json();
      // console.log(data);
      if (data.status) {
        const loadedPackages = [];
        for (const key in data.packages) {
          loadedPackages.push({
            id: data.packages[key].id,
            name: data.packages[key].name,
            type: data.packages[key].type,
            buyAdsLimit: data.packages[key].buy_ads_limit,
            buyAdsResultable: data.packages[key].buy_ads_resultable,
            buyAdsUpdateable: data.packages[key].buy_ads_updateable,
            buyAdsValidity: data.packages[key].buy_ads_validity,
            cost: data.packages[key].cost,
            hideOffer: data.packages[key].hide_offer,
            offersLimit: data.packages[key].offers_limit,
            validity: data.packages[key].validity,
            saleAdsLimit: data.packages[key].sale_ads_limit,
            saleAdsResultable: data.packages[key].sale_ads_resultable,
            saleAdsUpdateable: data.packages[key].sale_ads_updateable,
            saleAdsValidity: data.packages[key].sale_ads_validity,
            pinAble: data.packages[key].pinable,
            pinAbleValidity: data.packages[key].pinable_validity,
            offerHighlighting: data.packages[key].offer_highlighting,
            serviceDiscount: data.packages[key].service_discount,
            description: data.packages[key].description,
            costBeforeDiscount: data.packages[key].cost_before_discount,
            savingsRatio: data.packages[key].savings_ratio,
            discountRatio: data.packages[key].discount_ratio,
            discountMessage: data.packages[key].discount_message,
          });
        }
        setPackages(loadedPackages);
      }
    } catch (error) {
      setError(error.message);
    }
  }, []);
  useEffect(() => {
    fetchPackagesHandler();
  }, [fetchPackagesHandler]);
  // console.log(packages);
  const carousel = useRef(null);
  const handleLeftClick = (event) => {
    event.preventDefault();
    carousel.current.scrollLeft -= 360;
  };
  const handleRightClick = (event) => {
    event.preventDefault();
    carousel.current.scrollLeft += 360;
  };
  return (
    <div>
      <Headerloginn />
      <Container>
        <Box className={classes.PackagesTitle}>
          {/* Start title Box */}
          <Box>
            <Typography className={classes.title} variant="h3" component="h3">
              اختر الباقة التي تناسبك
            </Typography>
            <Typography sx={{ fontSize: "18px", pb: 4 }} component="p">
              إبدأ بنشر إعلانك وقم باستثمار وقتك
            </Typography>
          </Box>
          {/* End title Box */}
          {/* Start packages Box */}
          <div className={classes.carousel} ref={carousel}>
            {packages?.map((pack) => (
              <Grid item key={pack.id}>
                {/* <PackageItem package={pack} /> */}
                <Box
                  className={classes.boxPackages}
                  sx={{
                    height: 670,
                  }}
                >
                  <Card
                    variant="outlined"
                    className={classes.package}
                    sx={{
                      border: `2px solid ${
                        pack.type === "private" ? "#f9a703" : "#A8DADC"
                      }`,
                    }}
                  >
                    <CardContent>
                      {/*Start section different */}
                      <Typography className={classes.different}>
                        {pack.type === "private" ? "مميز" : ""}
                      </Typography>
                      {/*End section different */}
                      {pack.cost === pack.costBeforeDiscount && (
                        <>
                          <Typography
                            className={classes.type}
                            sx={{
                              fontSize: 15,
                              textAlign: "right",
                              pr: 2,
                              pt: 1,
                              color: "#1d3557",
                              fontWeight: "800",
                            }}
                            gutterBottom
                          >
                            {pack.name}
                          </Typography>

                          <Typography
                            variant="h3"
                            component="h3"
                            className={classes.price}
                            sx={{
                              color: "#1d3557",
                              textAlign: "center",
                              pl: 5,
                              fontSize: "35px",
                              fontWeight: "bold",
                            }}
                          >
                            {pack.id === 1 ? "مجانية" : pack.cost}
                          </Typography>
                        </>
                      )}
                      {pack.cost !== pack.costBeforeDiscount && (
                        <>
                          <Typography
                            className={classes.type}
                            sx={{
                              fontSize: 17,
                              textAlign: "center",
                              pr: 2,
                              pt: 1,
                              color: "#1d3557",
                              fontWeight: "600",
                            }}
                            gutterBottom
                          >
                            {pack.name}
                          </Typography>
                          <Typography
                            variant="div"
                            component="div"
                            // className={classes.price}
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              mt: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#1d3557",
                                fontSize: "18px",
                                fontWeight: 600,
                              }}
                            >
                              <Typography>{`${pack.savingsRatio} نسبة التوفير`}</Typography>
                              <Typography>{`${pack.discountRatio} نسبة التخفيض`}</Typography>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#1d3557",
                                fontSize: "18px",
                                fontWeight: "800",
                              }}
                            >
                              <Typography
                                className={classes.discount}
                                sx={{ ml: 3, color: "red" }}
                              >
                                {pack.costBeforeDiscount}
                              </Typography>
                              <Typography
                                sx={{ fontWeight: "800", fontSize: "22px" }}
                              >
                                {pack.cost}
                              </Typography>
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              mt: 2,
                              color: "#f9a703",
                              fontWeight: 600,
                            }}
                          >
                            ({pack?.discountMessage})
                          </Typography>
                        </>
                      )}
                      {/*Start section line */}
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            pack.type === "private" ? "#FB8500" : "#A8DADC",
                          mb: 2,
                        }}
                      >
                        __________________________________
                      </Typography>
                      {/*End section line */}
                      {/*Start section description */}
                      <Typography
                        sx={{ textAlign: "right" }}
                        // @ts-ignore
                        variant="div"
                      >
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .صلاحية الباقة {pack.validity} يوم
                          </p>
                          {pack.validity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            {`.مدة صلاحية إعلانات بيع ${pack.saleAdsValidity} أيام `}
                          </p>
                          {pack.saleAdsValidity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            {` .  حد نشر   ${pack.saleAdsLimit}  إعلانات بيع  `}
                            {pack.saleAdsLimit === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            {`.السماح بتعديل إعلانات البيع  `}
                            {pack.saleAdsUpdateable === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .السماح بالحصول على إحصائيات إعلانات البيع
                          </p>
                          {pack.saleAdsResultable === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            {`.مدة صلاحية إعلانات الشراء ${pack.buyAdsValidity}  أيام`}
                          </p>
                          {pack.buyAdsValidity === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            {`.حد نشر ${pack.buyAdsLimit} إعلانات شراء  `}
                            {pack.buyAdsLimit === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "2px", marginBottom: 0 }}>
                            .السماح بتعديل إعلانات الشراء
                            {pack.buyAdsUpdateable === 0 ? (
                              <Close sx={{ fill: "red" }} />
                            ) : (
                              <Check />
                            )}
                          </p>
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .السماح بالحصول على إحصائيات إعلانات الشراء
                          </p>
                          {pack.buyAdsResultable === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .تثبيت الإعلانات
                          </p>
                          {pack.pinAble === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .مدة صلاحية تثبيت الإعلان {pack.pinAbleValidity}{" "}
                            أيام
                          </p>
                          {pack.pinAble === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .تمييز العرض
                          </p>
                          {pack.offerHighlighting === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .إخفاء قيمة العرض
                          </p>
                          {pack.hideOffer === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .حد نشر {pack.offersLimit} عروض
                          </p>
                          {pack.offersLimit === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#1D3557B2",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ marginRight: "8px", marginBottom: 0 }}>
                            .خصومات الخدمات
                          </p>
                          {pack.serviceDiscount === 0 ? (
                            <Close sx={{ fill: "red" }} />
                          ) : (
                            <Check />
                          )}
                        </Typography>
                      </Typography>
                      {/*End section description */}
                    </CardContent>
                    {/*Start section button */}
                    <Link to={`/packages/${pack.id}`}>
                      <Button
                        className={classes.btnDetails}
                        sx={{
                          bgcolor:
                            pack.type === "private" ? "#FB8500" : "#457B9D",
                          "&:hover": {
                            color:
                              pack.type === "private" ? "#FB8500" : "#457B9D",
                            bgcolor: "#fff",
                            outline: "none",
                            border: "none",
                          },
                        }}
                        variant="outlined"
                        // onClick={() => handleDetails(pack.id)}
                      >
                        تفاصيل الباقة
                      </Button>
                    </Link>
                    {/*End section button */}
                  </Card>
                  {error}
                </Box>
              </Grid>
            ))}
          </div>
          <div className={classes.buttons}>
            <button onClick={handleLeftClick}>
              <img src={prevPackage} alt="Scroll Right" />
            </button>
            <button onClick={handleRightClick}>
              <img src={nextPackage} alt="Scroll Left" />
            </button>
          </div>
          {/* End packages Box */}
          <p style={{ color: "red", margin: "0 auto" }}>{error}</p>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default Packages;
