import React, { useState, useContext } from "react";
import { Container } from "react-bootstrap";
import "./Addnew.css";
import chervon from "../../../../assest/images/Productdetailsbuy/categ/chevron-left.svg";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { AuthContext } from "store/UseAuthContext";
export const Addnew = (props) => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const Filternavigate = useNavigate();
  const [navigateUser, setNavigateUser] = useState("/login");
  const { authInfo } = useContext(AuthContext);
  const clickHandler = () => {
    window.scrollTo(0, 0);
    if (authInfo.user === undefined) {
      setError("قم بتسجيل الدخول وأعد المحاولة");
    }
    if (authInfo?.user?.phone_verified_at !== null) {
      navigate(props.type === "sell" ? "/add-sale-ad" : "/add-purchase-ad");
    } else if (
      authInfo.user.phone_verified_at === null &&
      authInfo.user.status === "active"
    ) {
      setError("حسابك غير مؤكد رجاءاّ اذهب الى صفحة الاعدادات و قم بتأكيده");
      setNavigateUser("/setting-account");
    } else {
      setError("قم بتسجيل الدخول وأعد المحاولة");
    }
  };
  return (
    <div className="Addneww">
      <Container>
        {error && (
          <section className="logInAlert">
            <Alert show={error} variant="none">
              <p>{error}</p>
              <hr />
              <div className="d-flex text-align-center justify-content-center">
                <Button onClick={() => setError("")} variant="none">
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to={navigateUser}
                  >
                    موافق
                  </Link>
                </Button>
              </div>
            </Alert>
          </section>
        )}
        <div className="row" style={{ padding: "10px 16px" }}>
          <div className="col-lg-12 col-md-12 col-sm-12 firstdropdown">
            {/* packages */}
            <h4>هل تريد مشاهدات أكثر لإعلانك ؟</h4>
            <button>
              <img src={chervon} />
              <Link
                to="/packages"
                style={{
                  color: "#1d3557",
                  width: "100%",
                  textDecoration: "none",
                }}
              >
                قم بتفعيل تمييز الإعلان
              </Link>{" "}
            </button>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 firstdropdown">
            <h4> هل تود إضافة إعلان مماثل ؟ </h4>
            <button onClick={clickHandler}>
              <img src={chervon} />
              {props.type === "sell" ? (
                <Link
                  style={{
                    color: "#1d3557",
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  أضف إعلان مماثل
                </Link>
              ) : (
                <Link
                  style={{
                    color: "#1d3557",
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  أضف إعلان مماثل
                </Link>
              )}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};
