import React, { useState } from 'react'
import { Container,Button, Col } from 'react-bootstrap'
import './topcategory.css'
import { Link } from 'react-router-dom'

export const Topcategory = () => {

  return (
   
    <div className='topcate' dir="rtl">
        <Container>
           
        <div style={{  display: "grid", placeItems:"center", marginRight:"17px", marginLeft:"93px", backgroundColor:"#FFFFFF", borderRadius:"8px" }}>
        <Col style={{ marginLeft:'95.5%', marginTop:'-30px' }}>
          <Button className="details-button">
          <Link className="link-details-button" to=''>تفاصيل الفئة</Link>
          </Button>
        </Col>
      <div
          style={{
            width: '111%',
            height: '1px',
            backgroundColor: '#FB850066',
            marginBottom: '85px',
            marginRight:'60px'  
          }}
        ></div>
        </div>

        </Container>
    </div>
  )
}
