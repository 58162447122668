import React from "react";
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import EditPurchaseADForm from "../EditPurchaseADFormUser/EditPurchaseADFormUser";
import { useNavigate } from "react-router-dom";
import classes from "./EditPurchaseADUser.module.css";
const EditPurchaseAD = () => {
    const navigate = useNavigate();
    return (
        <Container sx={{ my: { xs: 1, sm: 3 } }}>
            <Box
                className={classes.ContainerBoxAdmin}
                sx={{
                    padding: 2,
                    pl: 6,
                    width: { 
                        xs: 300, 
                        sm: 580,
                        md: 650,
                        lg: 700,
                        xl: 750, 
                      },
                      height: { 
                        xs: 655, 
                        sm: 630,
                        md: 635,
                        lg: 625,
                        xl: 630, 
                       },
                    }}
            >
                <IconButton onClick={() => navigate("/advert")}>
                    <East />
                </IconButton>
                <Typography
                    sx={{
                        color: "#1D3557",
                        fontWeight: "800",
                        mr: 1,
                    }}
                    variant="h5"
                >
                    تعديل إعلان شراء جديد
                </Typography>
                <EditPurchaseADForm />
            </Box>
        </Container>
    );
};

export default EditPurchaseAD;
