import PurchaseAD from "../../components/User/Ads/PurchaseAD/PurchaseAD";
import React from "react";
import { AuthContext } from "store/UseAuthContext";
import { useEffect, useContext } from "react";

const PurchaseADPage = () => {
  
  // @ts-ignore
  return <PurchaseAD />;
};

export default PurchaseADPage;
