// @ts-nocheck
import React, { useCallback, useContext } from "react";
import { Col, Container, Modal, ModalFooter, ModalHeader } from "react-bootstrap";
import "./Advertstatic.css";
// import Ruler  from './../../Ruler/Ruler'
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import Headerlogin from "layouts/Headerlogin/Headerlogin";
import { Navigation } from "components/User/Landingpagevisitor/Navigation/Navigation";
import Footer from "layouts/Footer/Footer";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { Ruler } from "components/User/Ruler/Ruler";
const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M11.875 4H4.875C4.34457 4 3.83586 4.21071 3.46079 4.58579C3.08571 4.96086 2.875 5.46957 2.875 6V20C2.875 20.5304 3.08571 21.0391 3.46079 21.4142C3.83586 21.7893 4.34457 22 4.875 22H18.875C19.4054 22 19.9141 21.7893 20.2892 21.4142C20.6643 21.0391 20.875 20.5304 20.875 20V13"
      stroke="#457B9D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.375 2.50023C19.7728 2.1024 20.3124 1.87891 20.875 1.87891C21.4376 1.87891 21.9772 2.1024 22.375 2.50023C22.7728 2.89805 22.9963 3.43762 22.9963 4.00023C22.9963 4.56284 22.7728 5.1024 22.375 5.50023L12.875 15.0002L8.875 16.0002L9.875 12.0002L19.375 2.50023Z"
      stroke="#457B9D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const deleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
  >
    <path
      d="M3.875 8H6.375H26.375"
      stroke="#457B9D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.875 8V25.5C23.875 26.163 23.6116 26.7989 23.1428 27.2678C22.6739 27.7366 22.038 28 21.375 28H8.875C8.21196 28 7.57607 27.7366 7.10723 27.2678C6.63839 26.7989 6.375 26.163 6.375 25.5V8M10.125 8V5.5C10.125 4.83696 10.3884 4.20107 10.8572 3.73223C11.3261 3.26339 11.962 3 12.625 3H17.625C18.288 3 18.9239 3.26339 19.3928 3.73223C19.8616 4.20107 20.125 4.83696 20.125 5.5V8"
      stroke="#457B9D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.625 14.25V21.75"
      stroke="#457B9D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.625 14.25V21.75"
      stroke="#457B9D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const reloadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <g clip-path="url(#clip0_1407_4817)">
      <path
        d="M1.5 4.5V10.5H7.5"
        stroke="#457B9D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.01 15.5004C4.65839 17.3408 5.88734 18.9206 7.51166 20.0018C9.13598 21.083 11.0677 21.607 13.0157 21.4949C14.9637 21.3828 16.8226 20.6406 18.3121 19.3802C19.8017 18.1198 20.8413 16.4094 21.2742 14.5068C21.7072 12.6042 21.5101 10.6124 20.7126 8.83154C19.9152 7.05068 18.5605 5.57723 16.8528 4.6332C15.1451 3.68917 13.1769 3.3257 11.2447 3.59755C9.31245 3.86941 7.52091 4.76186 6.14 6.14044L1.5 10.5004"
        stroke="#457B9D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1407_4817">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_963_509)">
      <path
        d="M18 6L6 18"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#1D3557"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_963_509">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Advert_Static = () => {
  const [show, setShow] = useState(false);
  const [progressData, setProgressData] = useState("");
  const [data, setData] = useState([]);
  const [allAdverts, setAllAdverts] = useState({});
  const [startDate, setStartDate] = useState("2017-06-01");
  const [endDate, setEndDate] = useState("2024-06-01");
  const navigate = useNavigate();
  const { authInfo } = useContext(AuthContext);
  const [error, setError] = useState("");
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    fetchPackagesHandler()
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.blue,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData( icon1,icon2,icon3, descAd, nameAd, id,type) {
    return { icon1,icon2,icon3, descAd, nameAd, id,type };
  }
  
  const rows = data?.map((e) => {
    return createData( editIcon,e.status==="inactive"?reloadIcon:"",deleteIcon, e.status==="active" ? "نشطة" : "غير نشطة", e.title,e.id,e.type);
  });
  
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    fetchPackagesHandler()

  };
  const fetchPackagesHandler = useCallback(async () => {
    // console.log(authInfo.token);
    setError(null);
    try {
      const response = await fetch(
        `https://api.whitesooq.com/api/v1/results/data?date=${startDate}to${endDate}`,
        {
          method: "GET",
          headers: {
            Content_Type: "application/json",
            Authorization: `Bearer ${authInfo.token}`,
            accept: "application/json",
            app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "حدث خطأ أثناء تحميل صفحة الإحصائيات يرجى اعادة المحاولة مرة أخرى"
        );
      }
      const data = await response.json();
      console.log(data);
      // await setAllAdverts(data);
      setAllAdverts(data);

    } catch (error) {
      setError(error.message);
    }
  }, [authInfo,startDate,endDate]);
  useEffect(() => {
    fetchPackagesHandler();
  }, [fetchPackagesHandler]);
  const editHandler = (id) => {
    // console.log(id);
  };
  
  const deleteHandler=async(e)=>{
    // console.log(e)
    try {
      const response = await axios.delete(`https://api.whitesooq.com/api/v1/ads/${e}`,{
        headers: {
          Authorization: `Bearer ${authInfo.token}`,
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc="
        }
      });
      // console.log(response);
      fetchPackagesHandler()
    } catch (err) {
      // console.log(err);
    }
  }
  const editAds = (e,id)=>{
    // console.log(e)
    e === "sale" ? navigate(`/editsale-user/${id}`) : navigate(`/editpurchase-user/${id}`)
  }
  const rePostHandler=async(e)=>{
    // console.log(e)
    try {
      const response = await axios.put(`https://api.whitesooq.com/api/v1/ads/re-post/${e}`,{},{
        headers: {
          Authorization: `Bearer ${authInfo.token}`,
          accept: "application/json",
          app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc="
        }
      });
      // console.log(response);
      fetchPackagesHandler()
    } catch (err) {
      // console.log(err);
    }
  }
  const handleClick = (e) => {
    setShow(true);
    setProgressData(e);
    // console.log(e);
    if (e === "الإعلانات النشطة  ") setData(allAdverts?.active_ads);
    else if (e === "الاعلانات قيد الانتظار ") setData(allAdverts?.pending_ads);
    else if (e === "الإعلانات القابلة للتحديث  ")
      setData(allAdverts?.updateable_ads);
    else if (e === "  الإعلانات المرفوضة ") setData(allAdverts?.unaccept_ads);
    else if (e === "إعلانات على وشك الانتهاء")
      setData(allAdverts?.almost_inactive);
    else if (e === "العروض النشطة ") setData(allAdverts?.active_offers);
    else if (e === "الإعلانات الأكثر ظهورا في نتائج البحث ")
      setData(allAdverts?.most_searched);
    else if (e === "الإعلانات الأكثر مشاهدة من قبل المستخدمين ")
      setData(allAdverts?.most_viewed);
    else if (e === "الإعلانات الأكثر مشاركة من قبل المستخدمين ")
      setData(allAdverts?.active_shared);
    else if (e === "الإعلانات الأكثر تفضيل من قبل المستخدمين ")
      setData(allAdverts?.most_favorited);
    else if (e === "الإعلانات الأكثر نقرا على زر الاتصال ")
      setData(allAdverts?.most_call_click);
    else if (e === "الإعلانات الأكثر نقرا على زر الواتساب  ")
      setData(allAdverts?.most_whatsapp_click);
    else if (e === "الإعلانات الأكثر نقرا على زر المراسلة  ")
      setData(allAdverts?.most_messages_click);
    else if (e === "الإعلانات المنتهية الصلاحية")
      setData(allAdverts?.inactive_ads);
    else setData(null);
  };
  // console.log(data);


  const closeIcon=<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_1468_1183)">
        <path d="M18 6L6 18" stroke="#1D3557" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 6L18 18" stroke="#1D3557" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_1468_1183">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>


  return (
    <div>
      {/* <Headerlogin/>
      <Navigation/> */}
    <div className="Advert" dir="rtl">
      <Container>
        {error && (
          <section className="logInAlert">
            <Modal show={error} variant="none" variant="none" style={{direction:'rtl', fontSize:'15px'}}  backdrop='static' centered>
              <ModalHeader>{error}</ModalHeader>
              <ModalFooter className="d-flex text-align-center justify-content-center">
                <Button
                style={{ textDecoration:'none' ,backgroundColor:'#1D3557', marginLeft:'30px',color:'#FFFFFF'}}
                  onClick={() => setError("")}
                >
                  موافق
                </Button>
              </ModalFooter>
            </Modal>
          </section>
        )}
        <Link  className="iconcloo" to='/'>{closeIcon}</Link>
        <h1>احصائيات الإعلانات</h1>
        <div className="d-flex date" dir="rtl">
          <span>تحديد تاريخ</span>
          <div
									className="linyyy"
									style={{
										width: "1px",
										height: "35px",
                    backgroundColor:'#1d3557'
									}}></div>
          <span className="date-from"> من: </span>
          <input
          style={{
            width: '212px',
            height: '56px',
            borderRadius: '8px',
            backgroundColor: '#FCFCFC',
            textAlign: 'center'
          }}
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
        />
          <span> الى:</span>
          <input
          style={{
            width: '212px',
            height: '56px',
            borderRadius: '8px',
            backgroundColor: '#FCFCFC',
            textAlign: 'center'
          }}
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
        />
        </div>
        <div className="allranges" dir="rtl">
          <div className="range d-flex">
            <h3 onClick={() => handleClick("الإعلانات النشطة  ")}>
              الإعلانات النشطة{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.active_ads?.length)*10}%`,
                  backgroundColor: " #457B9D",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.active_ads?.length}%`}
                className="mm"
              >
                <span className="sss">{allAdverts?.active_ads?.length}</span>
              </span>
            </div>
          </div>

          <div className="range d-flex">
            <h3 onClick={() => handleClick("الإعلانات قيد الانتظار ")}>
              {" "}
              الإعلانات قيد الانتظار{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.pending_ads?.length)*10}%`,
                  backgroundColor: "#738997",
                  cursor: "pointer",
                }}
                dataWidth={`${(allAdverts?.pending_ads?.length)*10}%`}
              >
                <span className="sss">{allAdverts?.pending_ads?.length}</span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3 onClick={() => handleClick("الإعلانات القابلة للتحديث  ")}>
              {" "}
              الإعلانات القابلة للتحديث{" "}
            </h3>
            <div className="the-progress" dir="rtl">
              <span
                style={{
                  width: `${(allAdverts?.updateable_ads?.length)*10}%`,
                  backgroundColor: "#1D3557",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.updateable_ads?.length}%`}
              >
                <span className="sss">
                  {allAdverts?.updateable_ads?.length}
                </span>
              </span>
            </div>
          </div>
          <div className="range  d-flex">
            <h3 onClick={() => handleClick("  الإعلانات المرفوضة ")}>
              {" "}
              الإعلانات المرفوضة{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.unaccept_ads?.length)*10}%`,
                  backgroundColor: "#E63946",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.unaccept_ads?.length}%`}
              >
                <span className="sss">{allAdverts?.unaccept_ads?.length}</span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3 onClick={() => handleClick("إعلانات على وشك الانتهاء")}>
              {" "}
              إعلانات على وشك الانتهاء{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.almost_inactive?.length)*10}%`,

                  backgroundColor: "#C68C90",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.almost_inactive?.length}%`}
              >
                <span className="sss">
                  {allAdverts?.almost_inactive?.length}
                </span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3 onClick={() => handleClick("العروض النشطة ")}>
              {" "}
              العروض النشطة{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.active_offers?.length)*10}%`,

                  backgroundColor: "#197E65",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.active_offers?.length}%`}
              >
                <span className="sss">{allAdverts?.active_offers?.length}</span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3
              onClick={() =>
                handleClick("الإعلانات الأكثر ظهورا في نتائج البحث ")
              }
            >
              {" "}
              الإعلانات الأكثر ظهورا في نتائج البحث{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.most_searched?.length)*10}%`,
                  backgroundColor: "#02C697",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.most_searched?.length}%`}
              >
                <span className="sss">{allAdverts?.most_searched?.length}</span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3
              onClick={() =>
                handleClick("الإعلانات الأكثر مشاهدة من قبل المستخدمين ")
              }
            >
              {" "}
              الإعلانات الأكثر مشاهدة من قبل المستخدمين{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.most_viewed?.length)*10}%`,
                  backgroundColor: "#69A093",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.most_viewed?.length}%`}
              >
                <span className="sss">{allAdverts?.most_viewed?.length}</span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3
              onClick={() =>
                handleClick("الإعلانات الأكثر مشاركة من قبل المستخدمين ")
              }
            >
              الإعلانات الأكثر مشاركة من قبل المستخدمين{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.most_shared?.length)*10}%`,
                  backgroundColor: "#60706C",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.most_shared?.length}%`}
              >
                <span className="sss"> {allAdverts?.most_shared?.length}</span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3
              onClick={() =>
                handleClick("الإعلانات الأكثر تفضيل من قبل المستخدمين ")
              }
            >
              الإعلانات الأكثر تفضيل من قبل المستخدمين{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.most_favorited?.length)*10}%`,
                  backgroundColor: "#B6BAB9",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.most_favorited?.length}%`}
              >
                <span className="sss">
                  {allAdverts?.most_favorited?.length}
                </span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3
              onClick={() =>
                handleClick("الإعلانات الأكثر نقرا على زر الاتصال ")
              }
            >
              {" "}
              الإعلانات الأكثر نقرا على زر الاتصال{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.most_call_click?.length)*10}%`,
                  backgroundColor: "#A8DADC",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.most_call_click?.length}%`}
              >
                <span className="sss">
                  {allAdverts?.most_call_click?.length}
                </span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3
              onClick={() =>
                handleClick(" الإعلانات الأكثر نقرا على زر الواتساب  ")
              }
            >
              {" "}
              الإعلانات الأكثر نقرا على زر الواتساب{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.most_whatsapp_click?.length)*10}%`,
                  backgroundColor: "#A8DADC",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.most_whatsapp_click?.length}%`}
              >
                <span className="sss">
                  {allAdverts?.most_whatsapp_click?.length}
                </span>
              </span>
            </div>
          </div>

          <div className="range  d-flex">
            <h3
              onClick={() =>
                handleClick("الإعلانات الأكثر نقرا على زر المراسلة  ")
              }
            >
              {" "}
              الإعلانات الأكثر نقرا على زر المراسلة{" "}
            </h3>
            <div className="the-progress">
              <span
                style={{
                  width: `${(allAdverts?.most_messages_click?.length)*10}%`,
                  backgroundColor: "#25989C",
                  cursor: "pointer",
                }}
                dataWidth={`${allAdverts?.most_messages_click?.length}%`}
              >
                <span className="sss">
                  {allAdverts?.most_messages_click?.length}
                </span>
              </span>
            </div>
            </div>
            <div className="range d-flex">
              <h3 onClick={() => handleClick("الإعلانات المنتهية الصلاحية")}>
                {" "}
                الإعلانات المنتهية الصلاحية{" "}
              </h3>
              <div className="the-progress">
                <span
                  style={{
                    width: `${(allAdverts?.inactive_ads?.length)*10}%`,
                    backgroundColor: "#B6BAB9",
                    cursor: "pointer",
                  }}
                  dataWidth={`${allAdverts?.inactive_ads?.length}%`}
                >
                  <span className="sss">
                    {allAdverts?.inactive_ads?.length}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6"><Ruler/></div>
          </div>

          <div className={`box  ${show ? "active zIndex" : ""}`}>
           <Alert show={show} className="boxTable  table-responsive-xl">
              <Button
                onClick={() => setShow(false)}
                style={{
                  position: "absolute",
                  top: "1%",
                  right: "1%",
                  background: "none",
                  border: "none",
                }}
              >
                {closeIcon}
              </Button>
              <TableContainer component={Paper}>
              <p >{progressData}</p>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right">وصف</StyledTableCell>
            <StyledTableCell align="right">اسم الاعلان</StyledTableCell>
            <StyledTableCell align="right">معرف الاعلان</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <StyledTableRow >
              <StyledTableCell  align="right" onClick={()=>editAds(row.type,row.id)}>{row.icon1}</StyledTableCell>
              <StyledTableCell align="right" onClick={row.descAd !=="نشطة"?() => rePostHandler(row.id):""}>{row.icon2}</StyledTableCell>
              <StyledTableCell align="right" onClick={() => deleteHandler(row.id)}>{row.icon3}</StyledTableCell>
              <StyledTableCell align="right">{row.descAd}</StyledTableCell>
              <StyledTableCell align="right">{row.nameAd}</StyledTableCell>
              <StyledTableCell align="right">{row.id}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
          </Alert>
        </div>
      </Container>
    </div>
    <Footer/>
    </div>
  );
};
export default Advert_Static;
