import React, { useEffect, useContext, useState } from "react";
import {
    Form,
    Row,
    Table,
    Button,
    NavDropdown,
    Col,
    Image,
    Alert,
} from "react-bootstrap";
import Home from "./../AddCategory/Home.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { East } from "@mui/icons-material";
import Dots from "./../View/Dots.svg";
import Eye1 from "./Eye1.svg";
import Group from "./Group.svg";
import "./../View/ViewOffer.module.css";
import "./InforCategory.css";
import { Avatar, Box, IconButton } from "@mui/material";
import classes from "../View/ViewOffer.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Snack from "components/Utilities/Snack";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InfoCategory = () => {
    const { authInfo } = useContext(AuthContext);
    const [catData, setCatData] = useState({});
    const [adminData, setAdminData] = useState({});
    const navigate = useNavigate();
    const params = useParams();
    const [isDeleted, setIsDeleted] = useState(false);
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const { id } = useParams();
    useEffect(() => {
        const showDetailes = async () => {
            try {
                const response = await axios.get(
                    `https://api.whitesooq.com/api/v1/categories/${params.id}`,
                    {
                        headers: {
                            accept: "application/json",
                            app_key:
                                "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                            Authorization: `Bearer ${authInfo.token}`,
                        },
                    }
                );
                // console.log(response);

                setCatData(response.data.data[0]);
                setAdminData(response.data.data[0].admin.user);
            } catch (err) {
                console.log(err);
            }
        };
        showDetailes();
    }, []);
    console.log(adminData);
    const [open, setOpen] = useState(false);

    const date = new Date(catData?.created_at);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOption("");
    };
    const [option, setOption] = useState("");
    const handleChange = (event) => {
        setOption(event.target.value);
    };
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });
    const [informationDeleted, setInformationDeleted] = useState(false);
    const [error, setError] = useState("");
    const handleDeletee = () => {
        fetch(
            `https://api.whitesooq.com/api/v1/categories/delete/${id}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authInfo.token}`,
                    accept: "application/json",
                    app_key:
                        "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                },
            }
        )
            .then((response) => {
                if (response.status === 403)
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                response.json();
            })
            .then((data) => {
                if (data.status) {
                    setInformationDeleted(true);
                    setOpen(false);
                    navigate("/nonDeletedData-page");
                }
            })
            .catch((error) => {});
    };

    return (
        <div style={{ display: "grid", backgroundColor: "#ededed" }}>
            <Snack alert={alert} />
            <Form.Label
                style={{
                    display: "flex",
                    direction: "rtl",
                    alignItems: "flex-start",
                    marginRight: "10px",
                    marginTop: "16px",
                }}
            >
                <Image style={{ marginTop: "-2px" }} src={Home} />
                <h4
                    style={{
                        color: "#1D3557",
                        fontSize: "18px",
                        marginRight: "5px",
                        fontWeight: "700",
                    }}
                >
                    {catData.name} /
                    {params.type === "deleted" ? (
                        <Link
                            to={"/DeletedData-page"}
                            style={{
                                color: "#1D3557",
                                fontSize: "18px",
                                marginRight: "5px",
                                fontFamily: "inherit",
                            }}
                        >
                            الفئات المحذوفة
                        </Link>
                    ) : (
                        <Link
                            to={"/nonDeletedData-page"}
                            style={{
                                color: "#1D3557",
                                fontSize: "18px",
                                marginRight: "5px",
                                fontFamily: "inherit",
                            }}
                        >
                            الفئات الغير المحذوفة
                        </Link>
                    )}
                    / معلومات الفئة
                </h4>
            </Form.Label>
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    marginRight: "17px",
                    marginLeft: "93px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                }}
            >
                <Col style={{ marginLeft: "89.5%" }}>
                    <Button className="details-button">
                        <Link className="link-details-button" to="">
                            تفاصيل الدفعة
                        </Link>
                    </Button>
                </Col>
                <div
                    style={{
                        width: "95%",
                        height: "1px",
                        backgroundColor: "#FB850066",
                        marginBottom: "36px",
                        marginRight: "-16px",
                    }}
                ></div>
                <Row
                    dir="rtl"
                    style={{
                        width: "95%",
                        backgroundColor: "#fcfff8",
                        padding: "32px",
                        borderRadius: "8px",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 25px 0px #41404033",
                        marginRight: "-35px",
                    }}
                >
                {params.type === "deleted" ? (
                    <div style={{direction:'rtl'}}>
                        <IconButton sx={{ m: 1 }} onClick={() => navigate(`/category-services-page/${id}/deleted`)}>
                            <East />
                        </IconButton>
                    </div>
                    ) : (
                    <div style={{direction:'rtl'}}>
                        <IconButton sx={{ m: 1 }} onClick={() => navigate(`/category-services-page/${id}/nondeleted`)}>
                            <East />
                        </IconButton>
                    </div>
                )}
                    <Box className={classes.menuOption}>
                        <FormControl sx={{ mb: 2 }}>
                            <Select
                                IconComponent={ExpandMore}
                                value={option}
                                onChange={handleChange}
                                displayEmpty
                                autoWidth
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                <MenuItem
                                    onClick={handleClickOpen}
                                    sx={{ pl: 6 }}
                                >
                                    حذف
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {!informationDeleted && (
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                sx={{ textAlign: "center" }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <WarningAmber
                                    sx={{
                                        color: "#f9a703",
                                        m: "0 auto",
                                        width: "80px",
                                        height: "80px",
                                    }}
                                />
                                <DialogTitle
                                    sx={{
                                        textAlign: "center",
                                        m: "0 auto",
                                        p: "0 20px",
                                        color: "#457B9D",
                                        fontWeight: 700,
                                    }}
                                >
                                    {"هل أنت متأكد من حذف معلومات الفئة"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText
                                        sx={{ m: 0, p: 0 }}
                                        id="alert-dialog-slide-description"
                                    >
                                        لا يمكن استعادة المعلومات عند الحذف
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ m: "0 auto" }}>
                                    <button
                                        className={classes.btnCancelDialog}
                                        onClick={handleClose}
                                    >
                                        إلغاء
                                    </button>
                                    <button
                                        className={classes.btnOkDialog}
                                        onClick={handleDeletee}
                                    >
                                        متأكد
                                    </button>
                                </DialogActions>
                            </Dialog>
                        )}
                        {error && (
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                sx={{ textAlign: "center" }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <WarningAmber
                                    sx={{
                                        color: "#f9a703",
                                        m: "0 auto",
                                        width: "80px",
                                        height: "80px",
                                    }}
                                />
                                <DialogTitle
                                    sx={{
                                        textAlign: "center",
                                        m: "0 auto",
                                        p: "0 20px",
                                        color: "#457B9D",
                                        fontWeight: 700,
                                    }}
                                >
                                    {error}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText
                                        sx={{ m: 0, p: 0 }}
                                        id="alert-dialog-slide-description"
                                    >
                                        <Close sx={{ color: "red" }} />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ m: "0 auto" }}>
                                    <button
                                        className={classes.btnOkDialog}
                                        onClick={handleClose}
                                    >
                                        ok
                                    </button>
                                </DialogActions>
                            </Dialog>
                        )}
                        {informationDeleted && (
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                sx={{ textAlign: "center" }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <WarningAmber
                                    sx={{
                                        color: "#f9a703",
                                        m: "0 auto",
                                        width: "80px",
                                        height: "80px",
                                    }}
                                />
                                <DialogTitle
                                    sx={{
                                        textAlign: "center",
                                        m: "0 auto",
                                        p: "0 20px",
                                        color: "#457B9D",
                                        fontWeight: 700,
                                    }}
                                >
                                    {"تم حذف المعلومات بنجاح"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText
                                        sx={{ m: 0, p: 0 }}
                                        id="alert-dialog-slide-description"
                                    >
                                        <Check sx={{ color: "red" }} />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ m: "0 auto" }}>
                                    <button
                                        className={classes.btnOkDialog}
                                        onClick={handleClose}
                                    >
                                        موافق
                                    </button>
                                </DialogActions>
                            </Dialog>
                        )}
                    </Box>

                    <Col>
                        <h2
                            style={{
                                color: "#1D3557",
                                fontSize: "35px",
                                marginTop: "40px",
                            }}
                        >
                            معلومات الفئة
                        </h2>
                    </Col>
                    <Table
                        bordered
                        style={{
                            marginTop: "19px",
                            border: "1px solid #A8DADC",
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        width: "200px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    معرف الفئة
                                </td>
                                <td
                                    style={{
                                        width: "766px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {catData.id}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: "200px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    اسم الفئة
                                </td>
                                <td
                                    style={{
                                        width: "766px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {catData.name}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: "200px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    أنشئ بواسطة
                                </td>
                                <td
                                    style={{
                                        width: "766px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                    }}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div
                                            style={{
                                                width: "50px",
                                                height: "43px",
                                                borderRadius: "50%",
                                                background: "#A8DADC",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Avatar
                                                className=""
                                                sx={{ width: 50, height: 50 }}
                                                src={adminData?.full_path_image}
                                                alt={adminData?.username}
                                            />
                                        </div>
                                        <span
                                            style={{
                                                marginLeft: "20px",
                                                marginRight: "8px",
                                                fontWeight: "700",
                                            }}
                                        >
                                            {adminData.username}
                                        </span>
                                        <Image
                                            src={Eye1}
                                            alt="show profile"
                                            onClick={() =>
                                                navigate(
                                                    `/profile/${adminData.id}`
                                                )
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: "200px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    حالة الفئة
                                </td>
                                <td
                                    style={{
                                        width: "766px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {catData.status}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: "200px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    وصف
                                </td>
                                <td
                                    style={{
                                        width: "766px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {catData.description}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: "200px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    نوع الفئة
                                </td>
                                <td
                                    style={{
                                        width: "766px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {catData.type}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: "200px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    تاريخ الإضافة
                                </td>
                                <td
                                    style={{
                                        width: "766px",
                                        height: "56px",
                                        color: "#1D3557",
                                        textAlign: "center",
                                        padding: "16px 16px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {catData?.created_at
                                        ? `${date?.getFullYear()}-${date?.getMonth()}-${date?.getDate()}`
                                        : ""}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </div>
        </div>
    );
};

export default InfoCategory;
