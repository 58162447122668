// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { Container, Dropdown, Form } from "react-bootstrap";
import home from "./home (1).svg";
import "./Activeads.css";
import ell from "./Frame 1000005960.svg";
import cher from "./chevron-down.svg";
import user from "./user.svg";
import eye from "./eye.svg";
import axios from "axios";
import { AuthContext } from "store/UseAuthContext";
import { Avatar, Box, IconButton } from "@mui/material";
import { East } from "@mui/icons-material";
import classes from "../View/ViewOffer.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Check, Close, ExpandMore, WarningAmber } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slide from "@mui/material/Slide";
import Snack from "components/Utilities/Snack";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Activeads = () => {
    const params = useParams();
    const { authInfo } = useContext(AuthContext);

    console.log(authInfo);
    const [DetailData, setDetailData] = useState({});
    const [Detailtags, setDetailtags] = useState([]);
    const [Price, setPrice] = useState("");
    const [lowPrice, setlowPrice] = useState([]);
    const [alert, setAlert] = useState({
        open: false,
        type: "info",
        message: "",
    });
    // fetching the data
    useEffect(() => {
        axios
            .get(`https://api.whitesooq.com/api/v1/ads/${params.id}`, {
                headers: {
                    accept: "application/json",
                    app_key:
                        "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                    Authorization: `Bearer ${authInfo.token}`,
                },
            })
            .then((response) => {
                setDetailData(response.data.data);
                setDetailtags(response.data.data.tags);
                console.log(response.data.data);
                setPrice(response.data.data.price);
            })
            .catch((Error) => {
                console.log(Error);
                if (Error.request.status === 403) {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                } else if (Error?.response?.status === 401) {
                    setAlert({
                        open: true,
                        type: "error",
                        message: "انتهت صلاحية تسجيل الدخول",
                    });
                } else if (Error?.response?.status === 422) {
                    setAlert({
                        open: true,
                        type: "error",
                        message: Error?.response?.data?.message,
                    });
                } else if (Error?.message === "Network Error") {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "مشكلة في الشبكة",
                    });
                } else {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "الخادم لا يستجيب",
                    });
                }
            });
    }, []);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOption("");
    };
    const [option, setOption] = useState("");
    const handleChangeeee = (event) => {
        setOption(event.target.value);
    };
    const [activeDetailDeleted, setActiveDetailDeleted] = useState(false);
    const [error, setError] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();
    const handleDetailDeleted = () => {
        fetch(`https://api.whitesooq.com/api/v1/ads/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authInfo.token}`,
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
            body: {},
        })
            .then((response) => {
                if (response.status === 403)
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                response.json();
            })
            .then((data) => {
                // console.log(data);
                if (data.status) {
                    setActiveDetailDeleted(false);
                    setOpen(false);
                    navigate("/ViewActiveAds-page");
                }
            })
            .catch((error) => setError(error.message));
    };
    const [acceptActive, setAcceptActive] = useState(false);
    const [rejectActive, setRejectActive] = useState(false);

    const handleReject = () => {
        fetch(`https://api.whitesooq.com/api/v1/ads/unaccept/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authInfo.token}`,
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
            body: {},
        })
            .then((response) => {
                if (response.status === 403)
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                return response.json();
            })
            .then((data) => {
                console.log(data);
                handleClose();
                if (data.status === 1) {
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّ عملية الرفض بنجاح ",
                    });
                    setTimeout(() => {
                        navigate("/ViewActiveAds-page");
                    }, 1000);
                } else {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "هناك مشكلة في عملية الرفض، حاول مرة أخرى",
                    });
                }
            })
            // .then((data) => {
            //     // console.log(data);
            //     if (data.status) {
            //         setRejectActive(true);
            //         setOpen(false);
            //         navigate("/ViewActiveAds-page");
            //     } else {
            //         setError("عذراّ حدث خطأ حاول مرة أخرى");
            //     }
            // })
            .catch((error) => {});
    };
    const handleAccept = () => {
        fetch(`https://api.whitesooq.com/api/v1/ads/accept/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authInfo.token}`,
                accept: "application/json",
                app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
            },
            body: {},
        })
            .then((response) => {
                if (response.status === 403)
                    setAlert({
                        open: true,
                        type: "warning",
                        message: "عذراً ليس لديك صلاحية",
                    });
                else if (response.status === 200) {
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);
                handleClose();
                if (data.status === 1) {
                    setAlert({
                        open: true,
                        type: "success",
                        message: "تمَّ قبول الإعلان ",
                    });
                    setTimeout(() => {
                        navigate("/ViewActiveAds-page");
                    }, 1000);
                } else {
                    setAlert({
                        open: true,
                        type: "warning",
                        message: data.message,
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    open: true,
                    type: "warning",
                    message: "هناك مشكلة في عملية القبول",
                });
            });
    };
    const OfferAds =
        DetailData.status === "pending" ? (
            <>
                <div>
                    <Box className={classes.menuOption}>
                        <FormControl sx={{ m: 1 }}>
                            <Select
                                IconComponent={ExpandMore}
                                value={option}
                                onChange={handleChangeeee}
                                displayEmpty
                                autoWidth
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                            >
                                <MenuItem
                                    onClick={handleClickOpen}
                                    value={"قبول"}
                                    sx={{ pl: 6 }}
                                >
                                    قبول
                                </MenuItem>
                                <MenuItem
                                    onClick={handleClickOpen}
                                    value={"رفض"}
                                    sx={{ pl: 6 }}
                                >
                                    رفض
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {(!acceptActive || !rejectActive) && (
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                sx={{ textAlign: "center" }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                {option === "رفض" ? (
                                    <Close
                                        sx={{
                                            color: "red",
                                            m: "20px auto 0",
                                            width: "80px",
                                            height: "80px",
                                        }}
                                    />
                                ) : (
                                    <Check
                                        sx={{
                                            color: "green",
                                            m: "20px auto 0",
                                            width: "80px",
                                            height: "80px",
                                        }}
                                    />
                                )}
                                <DialogTitle
                                    sx={{
                                        textAlign: "center",
                                        m: "0 auto",
                                        p: "0 50px",
                                        color: "#457B9D",
                                        fontWeight: 700,
                                    }}
                                >
                                    {`هل أنت متأكد من ${option} هذا الاعلان ؟`}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText
                                        sx={{ m: 0, p: 0 }}
                                        id="alert-dialog-slide-description"
                                    ></DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ m: "0 auto" }}>
                                    <button
                                        className={classes.btnCancelDialog}
                                        onClick={handleClose}
                                    >
                                        إلغاء
                                    </button>
                                    <button
                                        className={classes.btnOkDialog}
                                        onClick={
                                            option === "رفض"
                                                ? handleReject
                                                : handleAccept
                                        }
                                    >
                                        متأكد
                                    </button>
                                </DialogActions>
                            </Dialog>
                        )}
                        {error && (
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                sx={{ textAlign: "center" }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <WarningAmber
                                    sx={{
                                        color: "#f9a703",
                                        m: "5px auto",
                                        width: "80px",
                                        height: "80px",
                                    }}
                                />
                                <DialogTitle
                                    sx={{
                                        textAlign: "center",
                                        m: "0 auto",
                                        p: "10px 20px",
                                        color: "#457B9D",
                                        fontWeight: 700,
                                    }}
                                >
                                    {error}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText
                                        sx={{ m: 0, p: 0 }}
                                        id="alert-dialog-slide-description"
                                    >
                                        <Close sx={{ color: "red" }} />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ m: "0 auto" }}>
                                    <button
                                        className={classes.btnOkDialog}
                                        onClick={handleClose}
                                    >
                                        ok
                                    </button>
                                </DialogActions>
                            </Dialog>
                        )}
                        {(acceptActive || rejectActive) && (
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                sx={{ textAlign: "center" }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <WarningAmber
                                    sx={{
                                        color: "#f9a703",
                                        m: "0 auto",
                                        width: "80px",
                                        height: "80px",
                                    }}
                                />
                                <DialogTitle
                                    sx={{
                                        textAlign: "center",
                                        m: "0 auto",
                                        p: "0 20px",
                                        color: "#457B9D",
                                        fontWeight: 700,
                                    }}
                                >
                                    {`تم ${option} العرض؟ بنجاح`}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText
                                        sx={{ m: 0, p: 0 }}
                                        id="alert-dialog-slide-description"
                                    >
                                        <Check sx={{ color: "red" }} />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ m: "0 auto" }}>
                                    <button
                                        className={classes.btnOkDialog}
                                        onClick={handleClose}
                                    >
                                        ok
                                    </button>
                                </DialogActions>
                            </Dialog>
                        )}
                    </Box>
                </div>
            </>
        ) : (
            <div>
                <Box className={classes.menuOption}>
                    <FormControl sx={{ m: 1 }} style={{ marginRight: "70px" }}>
                        <Select
                            IconComponent={ExpandMore}
                            value={option}
                            onChange={handleChangeeee}
                            displayEmpty
                            autoWidth
                        >
                            <MenuItem
                                onClick={handleClickOpen}
                                value={"حذف"}
                                sx={{ pl: 6 }}
                            >
                                حذف
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    // navigate(`/editpurchase-page/${id}`)
                                    navigate(
                                        `${
                                            DetailData.type === "buy"
                                                ? `/editpurchase-page/${id}`
                                                : `/editsale-page/${id}`
                                        }`
                                    )
                                }
                                value={"تعديل"}
                                sx={{ pl: 6 }}
                            >
                                تعديل
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {!activeDetailDeleted && (
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            sx={{ textAlign: "center" }}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            {option === "حذف" ? (
                                <Check
                                    sx={{
                                        color: "green",
                                        m: "20px auto 0",
                                        width: "80px",
                                        height: "80px",
                                    }}
                                />
                            ) : (
                                ""
                            )}
                            <DialogTitle
                                sx={{
                                    textAlign: "center",
                                    m: "0 auto",
                                    p: "0 50px",
                                    color: "#457B9D",
                                    fontWeight: 700,
                                }}
                            >
                                {`هل أنت متأكد من حذف هذا الإعلان ؟`}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                    sx={{ m: 0, p: 0 }}
                                    id="alert-dialog-slide-description"
                                ></DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ m: "0 auto" }}>
                                <button
                                    className={classes.btnCancelDialog}
                                    onClick={handleClose}
                                >
                                    إلغاء
                                </button>
                                <button
                                    className={classes.btnOkDialog}
                                    onClick={handleDetailDeleted}
                                >
                                    متأكد
                                </button>
                            </DialogActions>
                        </Dialog>
                    )}
                    {error && (
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            sx={{ textAlign: "center" }}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <WarningAmber
                                sx={{
                                    color: "#f9a703",
                                    m: "5px auto",
                                    width: "80px",
                                    height: "80px",
                                }}
                            />
                            <DialogTitle
                                sx={{
                                    textAlign: "center",
                                    m: "0 auto",
                                    p: "10px 20px",
                                    color: "#457B9D",
                                    fontWeight: 700,
                                }}
                            >
                                {error}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                    sx={{ m: 0, p: 0 }}
                                    id="alert-dialog-slide-description"
                                >
                                    <Close sx={{ color: "red" }} />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ m: "0 auto" }}>
                                <button
                                    className={classes.btnOkDialog}
                                    onClick={handleClose}
                                >
                                    موافق
                                </button>
                            </DialogActions>
                        </Dialog>
                    )}
                    {activeDetailDeleted && (
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            sx={{ textAlign: "center" }}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <WarningAmber
                                sx={{
                                    color: "#f9a703",
                                    m: "0 auto",
                                    width: "80px",
                                    height: "80px",
                                }}
                            />
                            <DialogTitle
                                sx={{
                                    textAlign: "center",
                                    m: "0 auto",
                                    p: "0 20px",
                                    color: "#457B9D",
                                    fontWeight: 700,
                                }}
                            >
                                {`تم ${option} الإعلان؟ بنجاح`}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                    sx={{ m: 0, p: 0 }}
                                    id="alert-dialog-slide-description"
                                >
                                    <Check sx={{ color: "red" }} />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ m: "0 auto" }}>
                                <button
                                    className={classes.btnOkDialog}
                                    onClick={handleClose}
                                >
                                    موافق
                                </button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Box>
            </div>
        );

    return (
        <div
            className="activeads"
            onLoad={() => setlowPrice(Price.split(" - "))}
        >
            <Snack alert={alert} />

            <Container style={{ padding: "0px 32px" }}>
                <div className="topads d-flex" dir="rtl">
                    <img src={home} style={{ marginLeft: "50px" }} />
                    <h3>
                        {DetailData.status === "pending" ? (
                            <Link
                                style={{
                                    fontFamily: "inherit",
                                    color: "inherit",
                                }}
                                to={"/ViewWaitingAds-page"}
                            >
                                الإعلانات قيد الانتظار
                            </Link>
                        ) : (
                            <Link
                                style={{
                                    fontFamily: "inherit",
                                    color: "inherit",
                                }}
                                to={"/ViewActiveAds-page"}
                            >
                                الإعلانات النشطة
                            </Link>
                        )}{" "}
                        / مطلوب {DetailData.title}
                    </h3>
                </div>
                <div className="basic">
                    <div className="btnbo">
                        <button className="but"> تفاصيل الاعلان</button>
                    </div>
                    
                    <div className="basicdet">
                    <div style={{direction:'rtl', margin:'10px 40px'}}>
                        <IconButton onClick={() => navigate(
                        `${
                            DetailData.status === "pending"
                            ? `/ViewWaitingAds-page`
                            : `/ViewActiveAds-page`
                        }`
                        )}>
                            <East />
                        </IconButton>
                    </div>
                    
                        <Form
                            className="offerDetailsDashMenu"
                            style={{ width: "20px" }}
                        >
                            {OfferAds}
                        </Form>
                        
                        <Dropdown className="droping">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                                <img src={ell} style={{ marginLeft: "10px" }} />
                                <img src={cher} />
                            </Dropdown.Toggle>
                        </Dropdown>

                        <Container
                            style={{
                                padding: "16px 32px",
                                backgroundColor: "#f1faee47",
                            }}
                        >
                            <div className="row row1" dir="ltr">
                                <div className="col-lg-6 col-md-12 col-sm-12  logobuy">
                                    <div className="trainglee">
                                        {" "}
                                        <h3
                                            className="ad-buy-title"
                                            style={{ marginRight: "4px" }}
                                        >
                                            إعلان شراء
                                        </h3>
                                        <div
                                            style={{
                                                width: "59px",
                                                height: "1px",
                                                marginTop: "-23px",
                                                backgroundColor: "#F1FAEE",
                                                marginLeft: "50px",
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 detailspro">
                                    <h2>{DetailData?.title}</h2>
                                    <h3
                                        style={{
                                            marginRight: "10px",
                                            color: "#1D3557",
                                        }}
                                    >
                                        {" "}
                                        {DetailData?.description}
                                    </h3>
                                    .
                                </div>
                            </div>
                            <div className="div11 d-flex" dir="ltr">
                                <h3
                                    style={{
                                        marginRight: "40px",
                                        wordBreak: "break-all",
                                    }}
                                >
                                    السعر الأعلى:{lowPrice[1]}{" "}
                                </h3>
                                <h3 style={{ wordBreak: "break-all" }}>
                                    {" "}
                                    السعر الأدنى:{lowPrice[0]}{" "}
                                </h3>
                            </div>
                        </Container>
                        <div className="visitor d-flex" dir="ltr">
                            <span> {DetailData?.rating_count} زائر</span>
                            <div className="starss d-flex">
                                {DetailData.rating == 0
                                    ? countRatingZero
                                    : DetailData.rating == 1
                                    ? countRatingOne
                                    : DetailData.rating == 2
                                    ? countRatingTow
                                    : DetailData.rating == 3
                                    ? countRatingThree
                                    : DetailData.rating == 4
                                    ? countRatingFourth
                                    : DetailData.rating == 5
                                    ? countRatingFifth
                                    : "invalid Rating"}
                            </div>
                        </div>
                        <div className="timing" dir="ltr">
                            <span style={{ margin: "32px 108px" }}>
                                {" "}
                                قبل {DetailData?.from}{" "}
                            </span>
                        </div>
                        <div className="ownerdet">
                            <h4> معلومات صاحب الإعلان</h4>
                            <div
                                className="infoowner d-flex"
                                style={{
                                    justifyContent: "space-between",
                                    padding: "12px 40px",
                                }}
                            >
                                <div className="d-flex">
                                    {/* <img src={DetailData?.user?.full_path_image} /> */}
                                    <Avatar
                                        className=""
                                        sx={{
                                            width: 50,
                                            height: 50,
                                        }}
                                        src={DetailData?.user?.full_path_image}
                                        alt={DetailData?.user?.username}
                                    />
                                    <img src={user} />
                                    <span style={{ margin: "15px 10px" }}>
                                        {" "}
                                        {DetailData?.user?.username}
                                    </span>
                                    <span
                                        style={{
                                            direction: "ltr",
                                            padding: "7px 0px",
                                        }}
                                    >
                                        {DetailData.rating == 0
                                            ? countRatingZero
                                            : DetailData.rating == 1
                                            ? countRatingOne
                                            : DetailData.rating == 2
                                            ? countRatingTow
                                            : DetailData.rating == 3
                                            ? countRatingThree
                                            : DetailData.rating == 4
                                            ? countRatingFourth
                                            : DetailData.rating == 5
                                            ? countRatingFifth
                                            : "invalid Rating"}
                                    </span>
                                    <span
                                        style={{
                                            margin: "10px",
                                            marginRight: "-8px",
                                        }}
                                    >
                                        {" "}
                                        ({DetailData?.user?.rating_count} عدد
                                        التقييمات)
                                    </span>
                                </div>
                                <div dir="ltr">
                                <IconButton
                        onClick={() =>
                          navigate(`/profile/${DetailData?.user_id}`)
                        }
                      >
                      
                                    <img
                                        src={eye}
                                        style={{
                                            display: "flex",
                                            marginLeft: "0",
                                        }}
                                    />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className="buttonowner">
                            {Detailtags?.map((tag) => (
                                <button key={tag.id}>{tag.name}</button>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
            {/* <div className={`box ${show ? "active zIndex " : ""}`}>
          <span className="majd">
            <Alert show={show} id="box">
              <Alert.Heading>
                {action === "حذف"
                  ? warningIcon
                  : action === "قبول"
                  ? acceptIcon
                  : action === "رفض"
                  ? rejectIcon
                  : "majd"}
              </Alert.Heading>
              <p>هل انت متأكد من {action} هذا الإعلان ؟</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShow(false)}>متأكد</Button>
                <Button
                  onClick={() => setShow(false)}
                  style={{
                    border: "1px solid var(--3, #1D3557)",
                    background: "none",
                    color: "#1D3557",
                  }}
                >
                  الغاء
                </Button>
              </div>
            </Alert>
          </span>
        </div> */}

            {/* {!show} */}
        </div>
    );
};

const filledStarIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
    >
        <path
            d="M14.673 7.17173C15.7437 6.36184 15.1709 4.65517 13.8284 4.65517H11.3992C10.7853 4.65517 10.243 4.25521 10.0617 3.66868L9.33754 1.32637C8.9309 0.0110567 7.0691 0.0110564 6.66246 1.32637L5.93832 3.66868C5.75699 4.25521 5.21469 4.65517 4.60078 4.65517H2.12961C0.791419 4.65517 0.215919 6.35274 1.27822 7.16654L3.39469 8.78792C3.85885 9.1435 4.05314 9.75008 3.88196 10.3092L3.11296 12.8207C2.71416 14.1232 4.22167 15.1704 5.30301 14.342L7.14861 12.9281C7.65097 12.5432 8.34903 12.5432 8.85139 12.9281L10.6807 14.3295C11.7636 15.159 13.2725 14.1079 12.8696 12.8046L12.09 10.2827C11.9159 9.71975 12.113 9.10809 12.5829 8.75263L14.673 7.17173Z"
            fill="#FFEE32"
        />
    </svg>
);

const emptyStarIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="19"
        viewBox="0 0 11 7"
        fill="none"
    >
        <path
            d="M5.50158 1.45581L6.91741 3.71661L10.0836 4.08138L7.79257 5.84018L8.33325 8.3249L5.50158 7.15116L2.66991 8.3249L3.21058 5.84018L0.919586 4.08138L4.08574 3.71661L5.50158 1.45581Z"
            stroke="#FFEE32"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const countRatingZero = (
    <span className="starRating">
        <ul>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
        </ul>
    </span>
);
const countRatingOne = (
    <span className="starRating">
        <ul>
            <li>{filledStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
        </ul>
    </span>
);

const countRatingTow = (
    <span className="starRating">
        <ul>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
        </ul>
    </span>
);

const countRatingThree = (
    <span className="starRating">
        <ul>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{emptyStarIcon}</li>
            <li>{emptyStarIcon}</li>
        </ul>
    </span>
);

const countRatingFourth = (
    <span className="starRating">
        <ul>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{emptyStarIcon}</li>
        </ul>
    </span>
);

const countRatingFifth = (
    <span className="starRating">
        <ul>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
            <li>{filledStarIcon}</li>
        </ul>
    </span>
);
