import React from "react";
import { East } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import EditSaleADFormUser from "../EditSaleADFormUser/EditSaleADFormUser";
import { useNavigate } from "react-router-dom";
import classes from "./EditSaleADUser.module.css";
const EditSaleADUser = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ my: { xs: 1, sm: 3 } }}>
      <Box
        className={classes.ContainerBoxAdmin}
        sx={{
          padding: 2,
          pl: { xs: 2 },
          width: { 
            xs: 300, 
            sm: 580,
            md: 650,
            lg: 700,
            xl: 750, 
          },
          height: { 
            xs: 655, 
            sm: 630,
            md: 635,
            lg: 625,
            xl: 630, 
           },
        }}
      >
        <IconButton onClick={() => navigate("/advert")}>
          <East />
        </IconButton>
        <Typography
          sx={{
            color: "#1D3557",
            fontWeight: "800",
          }}
          variant="h5"
        >
          تعديل إعلان بيع جديد
        </Typography>
        <EditSaleADFormUser />
      </Box>
    </Container>
  );
};
export default EditSaleADUser;
