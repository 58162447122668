// @ts-nocheck
import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/UseAuthContext";
import axios from "axios";

export default function PersistLogin() {
    const navigate = useNavigate();
    const [laoding, setLoading] = useState(true);
    const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
    console.log(authInfo);
    const token = localStorage.getItem("token");
    console.log(token);
    const refresh = async () => {
        await axios
            .post(
                "https://api.whitesooq.com/api/v1/refresh-token",
                {},
                {
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${token}`,
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                    },
                }
            )
            .then((response) => {
                console.log(response);
                ChangeAuthInfo(response.data);
                localStorage.setItem("token", response.data.token);
            })
            .catch((err) => {
                console.log(err);
                // if (err.data.message === "Unauthenticated") {
                navigate("/login", { replace: true });
                //     ChangeAuthInfo({});
                // }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        token !== "" ? refresh() : setLoading(false);
    }, []);
    return <>{laoding ? <div>Loading</div> : <Outlet />}</>;
}
