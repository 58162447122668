import React from "react";
import "./ModalImage.css";

const ModalImage = ({ image, onClose, newClass }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <img src={image} alt={image} className={"modal-image"} />
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};
export default ModalImage;
