import { useState } from "react";
import { createContext } from "react";
const AuthContext = createContext({});
const UseAuthContext = ({ children }) => {
    // console.log(document.documentElement.classList.contains("dark"));
    const [authInfo, setAuthInfo] = useState({});
    const ChangeAuthInfo = (newObj) => {
        setAuthInfo(newObj);
    };

    return (
        <AuthContext.Provider value={{ authInfo, ChangeAuthInfo }}>
            {children}
        </AuthContext.Provider>
    );
};
export { UseAuthContext, AuthContext };
