
import { useState } from "react";
import { createContext } from "react";
const HomeContext = createContext({});
const UseHomeContext = ({ children }) => {
const [HomeData, setHomeData] = useState({});
const changeHomeData = (Data) => {
    setHomeData(Data);
    console.log(Data)
};  
return (
    <HomeContext.Provider value={{ HomeData, changeHomeData}}>
        {children}
    </HomeContext.Provider>
    );
};
export { UseHomeContext, HomeContext };
