import Footer from 'layouts/Footer/Footer'
import Headerlogin from 'layouts/Headerlogin/Headerlogin'
import './NotificationsViewDetails.css'
import { Col, Container, Row } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AuthContext } from 'store/UseAuthContext'
import { useParams } from 'react-router-dom'
import ads from "../../assest/images/notification/Ads.svg"
import bookmark from "../../assest/images/notification/bookmark.svg"
import message from "../../assest/images/notification/message-square.svg"
import subscription from "../../assest/images/notification/subscription.svg"
import user from "../../assest/images/notification/user.svg"
import users from "../../assest/images/notification/users.svg"
const NotificationsViewDetails = () => {
    const {authInfo} = useContext(AuthContext)
    const params = useParams()
    const [data,setData] = useState({})
    useEffect(()=>{
        const ReadNotifictation = async () => {
            try {
              const response = await axios.get(`https://api.whitesooq.com/api/v1/notifications/show/${params.id}`, {
                headers: {
                  accept: "application.json",
                  app_key: "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                  Authorization: `Bearer ${(authInfo.token)}`
                }
              });
              console.log(response);
              setData(response.data.notification)
            } catch (err) {
              console.log(err);
            }
        };
        ReadNotifictation()
    },[])
  return (
    <div className='notifications-view-details'>
    <Headerlogin/>

    <Container>
        <h1 className='notification-details-title'>
        إشعارات الموقع/ تفاصيل الإشعار 
        </h1>
        <div className='boxx1'>
            <div className='ads-commit d-flex justify-content-between'>
                <div className='title-syst'>{data.data?.subject}</div>
                <img src={data?.data?.from==="نظام التعليقات"? message :data?.data?.from === "نظام المسؤولين"? user : data?.data?.from==="نظام المستخدمين"?users:data?.data?.from==="نظام الإعلانات"?ads:data?.data?.from==="نظام الإشتراكات"?subscription:data?.data?.from==="نظام العروض"?bookmark:""} className="favv" />
                
            </div>
            <div style={{padding:'15px 44px'}}>
                <Col style={{marginBottom:'12px'}}>
                <span className='title-syst-from'> 
                  من
                </span>
                    <span className='title-syst-to'>  {data.data?.from}
                    </span>
                    </Col>
                <Col>
                <span className='title-syst-from'>
                إلى
                </span>
                <span className='title-syst-to'> {data.data?.to}
                     </span>
                     </Col>
            </div>
        </div>

        <div className='boxx2'>
            <div className='title-syst-desc'> {data.data?.body} </div>
            <div className='title-syst-desc-time'> {data.from} </div>
        </div>
    </Container>
    <Footer/>
    </div>
  )
}

export default NotificationsViewDetails
