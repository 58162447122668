import { Container } from "react-bootstrap";
import "./HeaderLogin.css";
import "./Headerloginn.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "store/UseAuthContext";
import { useContext } from "react";
import axios from "axios";
const Headerloginn = () => {
    const navigate = useNavigate();
    // @ts-ignore
    const { authInfo, ChangeAuthInfo } = useContext(AuthContext);
    console.log(authInfo);
    const handlerLogout = async () => {
        try {
            const response = await axios.delete(
                `https://api.whitesooq.com/api/v1/logout`,
                {
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${authInfo.token}`,
                        app_key:
                            "base64:YlhNyQ51txR9cBTfCYz1m1Oo14nmX3agx5r2mQLHkRc=",
                    },
                }
            );
            console.log(response);
            await ChangeAuthInfo({});
            localStorage.removeItem("token");
            navigate("/");
        } catch (err) {
            if (err?.response?.status === 401) {
                ChangeAuthInfo({});
                navigate("/");
            }
            console.log(err);
        }
    };
    return (
        <div className="Headerlogin header">
            <Container>
                <div className="row">
                    <div className=" logo">
                        <Link to="/" style={{textDecoration: 'none'}}>
                            {" "}
                            <h3 className="arabic">السوق الأبيض</h3>
                        </Link>
                        <Link to="/" style={{textDecoration: 'none'}}>
                            {" "}
                            <h3 className="english">WhiteSooq</h3>
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default Headerloginn;
