import { createContext, useState } from "react";
const CategoryContext = createContext({});
const UseCategoryContext = ({ children }) => {
  const [category, setCategory] = useState("");
  const CategoryValueHandler = (Value) => {
    setCategory(Value);
  };
  return (
    <CategoryContext.Provider value={{ category, CategoryValueHandler }}>
      {children}
    </CategoryContext.Provider>
  );
};
export { UseCategoryContext, CategoryContext };
