export const ArrowRight = () => {
    return(
        <svg width="52" height="40" marginTop="8.33" marginRight="32" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-right">
            <path id="Vector" d="M10.8333 20H41.1666" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M26 8.3335L41.1667 20.0002L26 31.6668" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </svg>

    )
}