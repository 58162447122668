import { Box, Container, IconButton, Typography, Button } from "@mui/material";
import React, { useContext } from "react";
import { East, West } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import classes from "./TypeAD.module.css";
import Footer from "../../../../layouts/Footer/Footer";
import Headerlogin from "../../../../layouts/Headerlogin/Headerlogin";
import { AuthContext } from "store/UseAuthContext";

const TypeAD = () => {
    const navigate = useNavigate();
    const { authInfo } = useContext(AuthContext);
    return (
        <div>
            <Headerlogin />
            <Container sx={{ my: { xs: 13 } }}>
                <Box
                    className={classes.BoxType}
                    sx={{
                        padding: 2,
                        width: { xs: 300, sm: 600 },
                    }}
                >
                    <IconButton
                        onClick={() => {
                            if (authInfo.user.is_admin === '1') navigate("/ViewActiveAds-page");
                            else navigate("/");
                        }}
                    >
                        <East />
                    </IconButton>
                    <br />
                    <Typography
                        sx={{
                            color: "#1D3557",
                            fontWeight: "800",
                            py: 2,
                        }}
                        variant="h5"
                    >
                        نوع الإعلان
                    </Typography>
                    <Box>
                        <Typography
                            sx={{
                                mr: "3px",
                                color: "#1D3557",
                                fontSize: "14.5px",
                            }}
                        >
                            اختر نوع الإعلان
                        </Typography>
                        <Button
                            variant="outlined"
                            startIcon={<West />}
                            sx={{
                                width: "100%",
                                px: 5,
                                fontSize: "15px",
                                color: "#1D3557",
                                display: "flex",
                                mb: "10px !important",
                                fontWeight: "800",
                                justifyContent: "space-between",
                                borderRadius: "10px",
                            }}
                            onClick={() => navigate("/add-sale-ad")}
                        >
                            إعلان بيع
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<West />}
                            sx={{
                                width: "100%",
                                px: 5,
                                mb: "40px !important",
                                fontSize: "15px",
                                color: "#1D3557",
                                fontWeight: "800",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            onClick={() => navigate("/add-purchase-ad")}
                        >
                            إعلان شراء
                        </Button>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </div>
    );
};
export default TypeAD;
