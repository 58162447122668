import React from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick'
import './Categadmin.css'
import rac from './../admin-image/categ/rac.svg'
import sh from './../admin-image/categ/sh.svg'
import play from './../admin-image/categ/play.svg'
import star from './../../../assest/images/Landingpagevisitor/categ/star.svg'
import user from './../../../assest/images/Landingpagevisitor/categ/user.svg'
import clock from './../../../assest/images/Landingpagevisitor/categ/clock.svg'
import location from './../../../assest/images/Landingpagevisitor/categ/location.svg'
export const Categadmin = () => {
    
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 1290,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  initialSlide: 2,
                  infinite: false,
                }
              },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: false,
              dots: true
            }
          },
         
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
              infinite: false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
            }
          }
        ]
      };


    const cdata = [
        {
            id:1,
            img:play,
            name:"ساعة",
            price:"2000 ا.د",
            description:"  للبيع ساعة يد كاسيو بكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية و جذابة لون برونزي جميل .",
            user:"ٌRanim01",
            createdat:"قبل عشر ساعات",
            range:"1.0"
        },
        {
            id:2,
            img:sh,
            name:"ساعة",
            price:"2000 ا.د",
            description:"  للبيع ساعة يد كاسيو بكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية و جذابة لون برونزي جميل .",
            user:"ٌRanim01",
            createdat:"قبل عشر ساعات",
            range:"1.0"
        },
        {
            id:3,
            img:rac,
            name:"ساعة",
            price:"2000 ا.د",
            description:"  للبيع ساعة يد كاسيو بكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية و جذابة لون برونزي جميل .",
            user:"ٌRanim01",
            createdat:"قبل عشر ساعات",
            range:"1.0"
        },
        {
            id:4,
            img:sh,
            name:"ساعة",
            price:"2000 ا.د",
            description:"  للبيع ساعة يد كاسيو بكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية و جذابة لون برونزي جميل .",
            user:"ٌRanim01",
            createdat:"قبل عشر ساعات",
            range:"1.0"
        },
        {
            id:5,
            img:rac,
            name:"ساعة",
            price:"2000 ا.د",
            description:"  للبيع ساعة يد كاسيو بكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية و جذابة لون برونزي جميل .",
            user:"ٌRanim01",
            createdat:"قبل 10 ساعات",
            range:"1.0"
        },
        {
          id:6,
          img:play,
          name:"ساعة",
          price:"2000 ا.د",
          description:"  للبيع ساعة يد كاسيو بكرت الضمان متبقي على الضمان 2 سنتين الساعة كلاسيكية و جذابة لون برونزي جميل .",
          user:"ٌRanim01",
          createdat:"قبل 10 ساعات",
          range:"1.0"
      },
    ]
  return (
    <div className='categadmin'>
<Container>
<div className='cards'>
            <Slider {...settings}>
                {cdata.map((item,key)=>{
return(



    <div className='carditem'>
      <div className='heart'>
     
      </div>
        <img src={item.img}/>
        <div className='nameandprice d-flex'>
        <h4>{item.price}</h4>
            <h4>{item.name}</h4>
          
        </div>
        <h5>{item.description}</h5>
        <div className='details d-flex'>
    <span>{item.createdat}  <img src={clock}/></span>    
        <span>{item.user}  <img src={user}/></span>   

        
        </div>
        <div className='d-flex star'  style={{display:"flex",justifyContent:"start"}}>
        <h6>{item.range} </h6>
         <img src={star} style={{width:"20px"}}/>
        </div>
    </div>
)
                })}
                </Slider>
                </div>
</Container>
    </div>
  )
}
