// @ts-nocheck
import React, { useState, useEffect, useRef, useContext } from "react";
import Dropdown from "./Dropdown";
import { ArrowBackIos } from "@mui/icons-material";
import { CategoryContext } from "store/CategoryContext";
import { useNavigate } from "react-router-dom";
// @ts-ignore

const MenuItems = React.memo(({ items, depthLevel }) => {
  const naviage=useNavigate()
  const { CategoryValueHandler } = useContext(CategoryContext);
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };
  return (
    <li
      className="menu-items"
      ref={ref}
      // onClick={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.children ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            key={items.id}
            onClick={() => {
              setDropdown((prev) => !prev);
              CategoryValueHandler(items.id);
              naviage(`/category/${items.id}`)
            }}
          >
            {items.name}
            {depthLevel > 0 && <ArrowBackIos sx={{ fill: "#1d3557" }} />}
          </button>
          {!(items.children >= 0) && (
            // @ts-ignore
            <Dropdown
              depthLevel={depthLevel}
              submenus={items.children}
              dropdown={dropdown}
              
            />
          )}
        </>
      ) : (
        <span key={items.id} onClick={() => {naviage(`/category/${items.id}`)}}>{items.name}</span>
      )}
    </li>
  );
});
export default MenuItems;
