import React, { useState } from "react";
import {
  Button,
  Container,
  Modal,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import "./Shopingeverywhere.css";
import mobile from "../../../../assest/images/Layouts/Headerlogin/mobile.svg";
import appstore from "../../../../assest/images/Landingpagevisitor/shopingeverywhere/appstore.svg";
import googleplay from "../../../../assest/images/Landingpagevisitor/shopingeverywhere/googleplay.svg";
import { Link } from "react-router-dom";
export const Shopingeverywhere = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleClick = () => {
    setShowAlert(true);
  };
  return (
    <div className="shopping">
      <Container>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 imagephone">
            <img className="photo-sooq" src={mobile} />
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 download"
            id="shoppingevery"
          >
            <h4>تسوق أينما كنت</h4>
            <h2>حمل تطبيق السوق الأبيض مجانا</h2>
            <div className="downloadimage d-flex">
              <img src={appstore} onClick={handleClick} />
              <Modal
                show={showAlert}
                onHide={() => setShowAlert(false)}
                style={{ direction: "rtl" }}
                backdrop="static"
                centered
              >
                <ModalHeader>
                  <Modal.Title className="nneext">انتظرونا في الإصدارات القادمة</Modal.Title>
                </ModalHeader>
                <ModalFooter>
                  <Button
                  className="nneext-button"
                    variant="secondary"
                    onClick={() => setShowAlert(false)}
                    style={{
                      backgroundColor: "#1D3557",
                    }}
                  >
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>

              <Link
                className="lin-footer"
                target="_blank"
                to="https://play.google.com/store/apps/details?id=online.whitesooq"
                style={{ textDecoration: "none" }}
              >
                <img className="erere" src={googleplay} />
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
