import { KeyboardArrowDown } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Button, Box, Menu, MenuItem, Divider, Grid } from "@mui/material";
import { useState } from "react";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
export default function Section2({
    data,
    CategoriesFilter,
    categoryFilter,
    changeCategoryFilter,
}) {
    // console.log(data);
    // Menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelectedItem = (itemName) => {
        changeCategoryFilter(itemName);
        handleClose();
    };
    return (
        <>
            <div>
                <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    disableElevation
                    onClick={handleClick}
                    sx={{
                        fontFamily: "Noto Sans Arabic",
                        border: 1,
                        borderColor: "#E7E7E7",
                        bgcolor: "#FCFCFC",
                        color: "#1D3557",
                    }}
                >
                    {categoryFilter}
                    <KeyboardArrowDown sx={{ mr: 3, color: "#1D3557" }} />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    {CategoriesFilter.map((itemName) => (
                        <MenuItem
                            onClick={() => handleSelectedItem(itemName)}
                            sx={{
                                fontFamily: "Noto Sans Arabic",
                                color: "#1D3557",
                            }}
                        >
                            {itemName}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
            <Divider
                variant="inset"
                sx={{ bgcolor: "#457B9D", color: "#457B9D", py: 0.1, ml: 19 }}
            />
            <Grid container rowGap={5} columnSpacing={3}>
                {Array.from(Array(2)).map((_, index) => {
                    // console.log(data?.categories.length <=6);
                    if (
                        (index === 0 && data?.categories.length <= 6) ||
                        (index === 1 && data?.categories.length > 6)
                    )
                        return (
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        p: 3,
                                        bgcolor: "#F7F7F7",
                                        borderRadius: 5,
                                    }}
                                >
                                    <Grid container spacing={4}>
                                        {data?.categories?.map((cat, i) => {
                                            if (
                                                // @ts-ignore
                                                (index === 0 && i < 6) ||
                                                (index === 1 && i >= 6)
                                            )
                                                return (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sm={5}
                                                            lg={4}
                                                        >
                                                            {cat.name}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sm={7}
                                                            lg={8}
                                                            sx={{
                                                                mt: 1,
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <CustomTooltip
                                                                title={`${cat.ratio}%`}
                                                            >
                                                                <BorderLinearProgress
                                                                    variant="determinate"
                                                                    value={parseInt(
                                                                        cat.ratio
                                                                    )}
                                                                />
                                                            </CustomTooltip>
                                                        </Grid>
                                                    </>
                                                );
                                        })}
                                    </Grid>
                                </Box>
                            </Grid>
                        );
                })}
            </Grid>
        </>
    );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#1D3557" : "#1D3557",
    },
}));
const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#D9D9D9",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#D9D9D9",
        color: "#1D3557",
        borderColor: "#A8DADC",
    },
}));
